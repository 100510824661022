import { IQuestionBlock } from '../interfaces';
import { QuestionType } from '../question-type.enum';

const QUESTION_BLOCKS: IQuestionBlock[] = [
  {
    label: 'Vendor Security Program Overview',
    questions: [
      {
        props: {
          id: '77fe369d-1a4e-4a01-9ec1-393c893a6633',
          text: 'Does the Vendor have security policies?',
        },
        type: QuestionType.RadioYesNo,
      },
      {
        props: {
          id: '61ee6be2-8828-4f7e-9674-1680386f5a27',
          text: 'Please list any applicable third party audits that the Vendor has completed.',
          placeholder: 'SOC 2, ISO 27001, FedRamp, etc.',
        },
        type: QuestionType.TextInput,
      },
      {
        props: {
          id: 'da01ea4a-5952-4b84-8a7d-310d87f3721a',
          text: 'Does the Vendor have a designated security leader?',
        },
        type: QuestionType.RadioYesNo,
      },
      {
        props: {
          id: 'f1fa7266-abee-465f-8fd8-998e52b2e304',
          text: 'If \'Yes\', please provide their contact info and their overall role in the company.',
          label: 'Security leader name',
        },
        type: QuestionType.TextInput,
      },
      {
        props: {
          id: '8a07fde6-57b5-4205-8f63-6a472aeb9354',
          label: 'Security leader email',
        },
        type: QuestionType.TextInput,
      },
      {
        props: {
          id: '4c0343c1-b06b-4d07-8af3-395232d4c58b',
          label: 'Security leader role in company',
        },
        type: QuestionType.TextInput,
      },
      {
        props: {
          id: '5a4cef51-5139-4414-83ea-183499b7b721',
          text: 'Do Vendor employees go through annual security training appropriate to their job function?',
        },
        type: QuestionType.RadioYesNo,
      },
    ],
  },
  {
    label: 'Access Control',
    questions: [
      {
        props: {
          id: '890627b8-c750-4681-8115-c3eb9f460e9f',
          text: 'Do Vendor employees or contractors have access to client data?',
        },
        type: QuestionType.RadioYesNo,
      },
      {
        props: {
          id: 'e9e1d079-c85c-4e73-88b3-ce85d363d9dc',
          text: 'Does the Vendor limit access to client data to named accounts protected with multi-factor authentication?',
        },
        type: QuestionType.RadioYesNo,
      },
      {
        props: {
          id: '63f24688-d523-455f-890d-1c3a5742d753',
          text: 'Does the Vendor implement access controls according to Least Privilege so that access to client data is only provided when needed for clear and approved business reasons?',
        },
        type: QuestionType.RadioYesNo,
      },
      {
        props: {
          id: '31382959-8ffa-403a-9b8a-8dfaaf45898b',
          text: 'Does the Vendor maintain audit trails to identify user access to Client data?',
        },
        type: QuestionType.RadioYesNo,
      },
      {
        props: {
          id: '8e683452-6c33-4e7c-a3db-c0d4c85b9122',
          text: 'What is the system of record for identity?',
          placeholder: 'Okta, AWS IAM, Azure AD, Google, etc.'
        },
        type: QuestionType.TextInput,
      },
      {
        props: {
          id: 'cee4f16b-dff9-4694-a405-09659ad05a77',
          text: 'Does the Vendor perform monthly user audits?',
        },
        type: QuestionType.RadioYesNo,
      },
      {
        props: {
          id: 'fac78a1c-3809-452d-822b-be33d75490f8',
          text: 'Are subcontractors included in the audit processes?',
        },
        type: QuestionType.RadioYesNo,
      },
    ],
  },
  {
    label: 'Data Protection',
    questions: [
      {
        props: {
          id: '17e78e52-31b1-4536-960f-cb2ba7235bd9',
          text: 'Is Client data encrypted with TLS 1.2+ in transit everywhere in the Vendor system?',
        },
        type: QuestionType.RadioYesNo,
      },
      {
        props: {
          id: '01d40d20-8cc6-4ffd-95fd-cec1c1321f2a',
          text: 'Is Client data always encrypted at rest with AES-256 or comparable encryption?',
        },
        type: QuestionType.RadioYesNo,
      },
      {
        props: {
          id: '7a0e30c7-9edf-4f59-b546-7f647b9af3b1',
          text: 'Does the Vendor allow customers to use and manage their own encryption keys?',
        },
        type: QuestionType.RadioYesNo,
      },
      {
        props: {
          id: 'befd483e-db59-48cd-a7f5-e85925783eb6',
          text: 'If \'Yes\' please provide further details.',
        },
        type: QuestionType.TextInput,
      },
    ],
  },
  {
    label: 'Patching and Configuration',
    questions: [
      {
        props: {
          id: '64395680-d625-4a2e-bd26-ca92bbe08676',
          text: 'How often are regular and critical patches applied?',
        },
        type: QuestionType.TextInput,
      },
      {
        props: {
          id: '775918e6-5859-4343-bed9-5f9acc9d49b2',
          text: 'Are cloud security configurations actively managed?',
        },
        type: QuestionType.RadioYesNo,
      },
      {
        props: {
          id: 'facbdc22-29ae-41c9-bc3f-0e04810a434b',
          text: 'If \'Yes\' please provide further details.',
        },
        type: QuestionType.TextInput,
      },
      {
        props: {
          id: 'db3e6ee2-7211-4243-8aa4-91fc472d9d99',
          text: 'How often does the Vendor scan for vulnerabilities in their external systems?',
        },
        type: QuestionType.TextInput,
      },
      {
        props: {
          id: 'aa3d393e-a111-4860-b60a-d58e0d16936b',
          text: 'How often does the Vendor scan for vulnerabilities in their internal systems?',
        },
        type: QuestionType.TextInput,
      },
      {
        props: {
          id: '7f345182-c3a5-478c-b826-dcf079045a32',
          text: 'How often does the Vendor perform penetration testing?',
        },
        type: QuestionType.TextInput,
      },
    ],
  },
  {
    label: 'Incident Response',
    questions: [
      {
        props: {
          id: '1c6abe1d-fde3-4e8d-a927-2fabc3b64d90',
          text: 'Does the Vendor have a security incident response plan?',
        },
        type: QuestionType.RadioYesNo,
      },
      {
        props: {
          id: 'aebc668b-8f95-4a78-a1ce-44eb93e33eec',
          text: 'What systems does the Vendor have in place to detect security incidents?',
          placeholder: 'IDS, WAF, SEIM, etc.'
        },
        type: QuestionType.TextInput,
      },
      {
        props: {
          id: '2bf0be92-4b29-489a-a7fd-20cf2a547aa2',
          text: 'Please provide details of the Vendor\'s commitment around breach notification.',
          label: 'Who is notified?'
        },
        type: QuestionType.TextInput,
      },
      {
        props: {
          id: '1e8baf9e-54bc-456e-8dd8-48f4499ce40f',
          label: 'In what time frame?'
        },
        type: QuestionType.TextInput,
      },
    ],
  },
  {
    label: 'Business Continuity and Disaster Recovery',
    questions: [
      {
        props: {
          id: '91bb1ecb-5f0d-4ad9-a89d-33f58dcaead3',
          text: 'Does the Vendor have a Business Continuity Plan (BCP) in place?',
        },
        type: QuestionType.RadioYesNo,
      },
      {
        props: {
          id: '4167e443-dad0-4efd-9885-bd71e115c81c',
          text: 'What are the Vendor\'s Recovery Time Objective and Recovery Point Objective?',
          label: 'Recovery Time Objective',
        },
        type: QuestionType.TextInput,
      },
      {
        props: {
          id: 'c2675e71-9983-4f2d-ae94-8aed72913fbc',
          label: 'Recovery Point Objective',
        },
        type: QuestionType.TextInput,
      },
      {
        props: {
          id: '2174fcfc-319e-4c9c-b3e1-a3c32bea0ceb',
          text: 'When was the BCP program last tested?',
        },
        type: QuestionType.TextInput,
      },
      {
        props: {
          id: 'b863ee48-586f-433a-a1fc-32f94bb0d246',
          text: 'Was it a successful test?',
        },
        type: QuestionType.RadioYesNo,
      },
    ],
  },
  {
    label: 'Data Retention',
    questions: [
      {
        props: {
          id: '6bd1564d-6c7c-4912-b051-8949195e59dc',
          text: 'What is the Vendor\'s data retention policy?',
        },
        type: QuestionType.TextInput,
      },
    ],
  },
  {
    label: 'Partner Management and Contracts',
    questions: [
      {
        props: {
          id: 'f95e327f-75d5-4602-8694-5e99488fa1b8',
          text: 'Does the Vendor have a Partner Security Management program to hold its partners and third party providers to a security standard commensurate with their responsibilities in delivering services to the Vendor?',
        },
        type: QuestionType.RadioYesNo,
      },
      {
        props: {
          id: 'ef356623-2e0e-42a0-a53e-2d7de2287353',
          text: 'Are all employees and contractors with access to customer data screened with at least criminal background checks?',
        },
        type: QuestionType.RadioYesNo,
      },
      {
        props: {
          id: 'c487e24d-207f-461c-98a0-37663cdcccb1',
          text: 'Do contracts with employees and contractors include confidentiality agreements?',
        },
        type: QuestionType.RadioYesNo,
      },
      {
        props: {
          id: '203237a4-8afd-4307-9ae5-08735bdc4261',
          text: 'Does the Vendor have appropriate legal agreements with sub-processors?',
          placeholder: 'BAA, DPA, etc.',
        },
        type: QuestionType.TextInput,
      },
    ],
  },
  {
    label: 'Asset Management',
    questions: [
      {
        props: {
          id: '9bf6f631-cb2a-43d4-8ff8-1163512a43d2',
          text: 'Is antivirus/malware protection deployed on all laptops?',
        },
        type: QuestionType.RadioYesNo,
      },
      {
        props: {
          id: '59dade3d-e7e3-4fdd-b030-baf012414e22',
          text: 'Are assets tracked and updated on a regular basis?',
        },
        type: QuestionType.RadioYesNo,
      },
      {
        props: {
          id: '855371dc-6158-4030-bfb3-301a91c63f70',
          text: 'If so, how often?',
        },
        type: QuestionType.TextInput,
      },
      {
        props: {
          id: 'e0da9220-83d7-4352-8b77-f8d77295f678',
          text: 'Are auto-updates turned on?',
        },
        type: QuestionType.RadioYesNo,
      },
      {
        props: {
          id: 'fb444922-e23b-4019-9e0f-fb5316cbfaad',
          text: 'Are all laptops encrypted?',
        },
        type: QuestionType.RadioYesNo,
      },
    ],
  },
];

export default QUESTION_BLOCKS;
