// See https://material-ui.com/guides/composition/#link
import React, { forwardRef } from 'react';
import Link, { LinkProps } from '@mui/material/Link';
import { Link as ReactRouterLink, LinkProps as ReactRouterLinkProps } from 'react-router-dom';
import { DistributiveOmit } from '@mui/types';

export interface RouterLinkProps extends LinkProps {
  to: string;
}

export const RouterLink = ({ children, to, ...linkProps }: RouterLinkProps) => (
  <Link
    component={forwardRef<any, DistributiveOmit<ReactRouterLinkProps, 'to'>>((props, ref) => <ReactRouterLink to={to} ref={ref} {...props} />)}
    {...linkProps}
  >
    {children}
  </Link>
);
