import { Link } from '@mui/material';
import React from 'react';
import { ICategoryLabelMap, TaskSummaryByCategory } from '../components/TaskSummaryByCategory';

const topCategoryMap: ICategoryLabelMap = {
  'iso-27001:a.5': {
    abbreviation: 'A.5',
    title: 'Information Security Policies',
    subtitle: `
    `,
  },
  'iso-27001:a.6': {
    abbreviation: 'A.6',
    title: 'Organization of Information Security',
    subtitle: `
    `,
  },
  'iso-27001:a.7': {
    abbreviation: 'A.7',
    title: 'Human Resources Security',
    subtitle: `
    `,
  },
  'iso-27001:a.8': {
    abbreviation: 'A.8',
    title: 'Asset Management',
    subtitle: `
    `,
  },
  'iso-27001:a.9': {
    abbreviation: 'A.9',
    title: 'Access Control',
    subtitle: `
    `,
  },
  'iso-27001:a.10': {
    abbreviation: 'A.10',
    title: 'Cryptography',
    subtitle: `
    `,
  },
  'iso-27001:a.11': {
    abbreviation: 'A.11',
    title: 'Physical and Environmental Security',
    subtitle: `
    `,
  },
  'iso-27001:a.12': {
    abbreviation: 'A.12',
    title: 'Operations Security',
    subtitle: `
    `,
  },
  'iso-27001:a.13': {
    abbreviation: 'A.13',
    title: 'Communications Security',
    subtitle: `
    `,
  },
  'iso-27001:a.14': {
    abbreviation: 'A.14',
    title: 'System Acquisition, Development and Maintenance',
    subtitle: `
    `,
  },
  'iso-27001:a.15': {
    abbreviation: 'A.15',
    title: 'Supplier Relationships',
    subtitle: `
    `,
  },
  'iso-27001:a.16': {
    abbreviation: 'A.16',
    title: 'Information Security Incident Management',
    subtitle: `
    `,
  },
  'iso-27001:a.17': {
    abbreviation: 'A.17',
    title: 'Information Security Aspects of Business Continuity Management',
    subtitle: `
    `,
  },
  'iso-27001:a.18': {
    abbreviation: 'A.18',
    title: 'Compliance',
    subtitle: `
    `,
  },
};

function TheTasksSummaryISO27001Page() {
  return (
    <TaskSummaryByCategory
      apiRoute="task/summary/iso27001"
      title="ISO/IEC 27001:2013"
      topCategoryLabel="Control"
      topCategoryMap={topCategoryMap}
    >
      <>
        The <Link href="https://www.iso.org/home.html"
                  target="_blank"
                  rel="noopener noreferrer"
      >
        International Organization for Standardization (ISO)
      </Link> publishes
        the <Link href="https://www.iso.org/isoiec-27001-information-security.html"
                  target="_blank"
                  rel="noopener noreferrer"
      >
        ISO/IEC 27001 standard
      </Link>,
        which specifies requirements for an information security management system.
        The controls are grouped from A.5 to A.18,
        with each group having several associated tasks your organization should complete.
        In the table below we indicate how far along your organization has come in terms of
        implementing the ISO/IEC 27001 standard.
      </>
    </TaskSummaryByCategory>
  );
}

TheTasksSummaryISO27001Page.requiredAuthZ = {
  tier: 1,
  permission: 'tasks',
};
TheTasksSummaryISO27001Page.routePath = '/tasks/iso27001';
TheTasksSummaryISO27001Page.title = 'Task Summary: ISO 27001';

export default TheTasksSummaryISO27001Page;
