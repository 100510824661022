import { Button, DialogActions, DialogContent, DialogProps, Link, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as Sentry from '@sentry/react';
import React, { useState } from 'react';
import { IVendorDto } from '../../../../backend/src/vendor/interfaces';
import API from '../../services/ApiService';
import { showErrorResultBar } from '../ResultSnackbar';
import StyledDialogTitle from '../StyledDialogTitle';
import ResponsiveDialog from './ResponsiveDialog';

const useStyles = makeStyles({
  dialogContent: {
    minWidth: '400px',
  },
  selfFillLink: {
    fontSize: '0.9rem',
  },
});

interface GenerateSelfFillQuestionnaireProps {
  isSubmitting: boolean;
  onCancel: () => void;
  onClickGenerateQuestionnaire: () => Promise<void>;
}

function GenerateSelfFillQuestionnaire({ isSubmitting, onCancel, onClickGenerateQuestionnaire }: GenerateSelfFillQuestionnaireProps) {
  return (
    <>
      <DialogContent>
        <Typography
          variant="body1"
          gutterBottom
        >
          Larger vendors may not respond to security questionnaires.
          For these vendors you may need to fill out the questionnaire yourself.
        </Typography>
        <Typography
          variant="body1"
        >
          Click the button below to generate an external link to
          a new questionnaire for this vendor.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          size="small"
          disabled={isSubmitting}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          size="small"
          color="primary"
          disabled={isSubmitting}
          onClick={onClickGenerateQuestionnaire}
        >
          Generate Questionnaire
        </Button>
      </DialogActions>
    </>
  );
}

function DisplaySelfFillQuestionnaireLink({ url }: { url: string }) {
  const classes = useStyles();

  return (
    <DialogContent
      className={classes.dialogContent}
    >
      <Typography
        variant="body1"
        gutterBottom
      >
        Below is the external link to a questionnaire for this vendor.
        You may want to retain this link, since you will not be able to retrieve it after closing this
        dialog.
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
      >
        <b>Keep this link private. Anybody with access to it will be able to fill out the questionnaire.</b>
      </Typography>
      <Typography
        variant="body1"
        className={classes.selfFillLink}
        gutterBottom
      >
        <Link
          href={url}
          rel="noopener noreferrer"
          target="_blank"
        >
          {url}
        </Link>
      </Typography>
    </DialogContent>
  );
}

export interface VendorSelfFillDialogProps extends DialogProps {
  handleClose: (updatedVendor: IVendorDto | undefined) => void;
  vendor: IVendorDto;
}

export default function VendorSelfFillDialog(props: VendorSelfFillDialogProps) {
  const { open, handleClose, vendor } = props;

  const [ isSubmitting, setIsSubmitting ] = useState<boolean>(false);
  const [ selfFillUrl, setSelfFillUrl ] = useState<string>('');
  const [ updatedVendor, setUpdatedVendor ] = useState<IVendorDto>();

  const onClickGenerateQuestionnaire = () => {
    setIsSubmitting(true);

    return API
      .post(`vendor/${vendor.id}/self-fill-questionnaire`)
      .then((res) => {
        const { url, vendor: theUpdatedVendor } = res.data.data;

        setSelfFillUrl(url);
        setUpdatedVendor(theUpdatedVendor);
      })
      .catch((err) => {
        showErrorResultBar('Unexpected error generating questionnaire');
        Sentry.captureException(err);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const onCancel = () => {
    handleClose(updatedVendor);
  };

  const clearData = () => {
    setSelfFillUrl('');
  };

  return (
    <ResponsiveDialog
      open={open}
      onClose={onCancel}
      TransitionProps={{ onExited: clearData }}
      disableBackdropClick
    >
      <StyledDialogTitle
        onClose={onCancel}
      >
        Self-fill questionnaire
      </StyledDialogTitle>
      {!selfFillUrl ? (
        <GenerateSelfFillQuestionnaire
          isSubmitting={isSubmitting}
          onCancel={onCancel}
          onClickGenerateQuestionnaire={onClickGenerateQuestionnaire}
        />
      ) : (
        <DisplaySelfFillQuestionnaireLink
          url={selfFillUrl}
        />
      )}
    </ResponsiveDialog>
  );
}
