import { Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import TheAppBar from '../components/TheAppBar';
import TheTermsFooter from '../components/TheTermsFooter';
import { useHubSpotChat, useOutletPageMetadata } from '../hooks';
import TheNavDrawer from '../pages/TheNavDrawer';
import useAuth from '../services/auth/useAuth';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },

  content: {
    flexGrow: 1,
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },

  main: {
    flex: '1 0 auto',
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(3),
    },
  },
}));

function TheDashboardLayout() {
  const classes = useStyles();
  const { isGranted, isLoading, orgId } = useAuth();
  const { requiredAuthZ, title } = useOutletPageMetadata();
  useHubSpotChat();

  const [ isDrawerOpen, setIsDrawerOpen ] = useState(false);
  const [ badges ] = useState({});
  const [ activeOrgId, setActiveOrgId ] = useState<string>(orgId ?? '');
  const [ isAuthorized, setIsAuthorized ] = useState(true);

  useEffect(() => {
    setIsAuthorized(isGranted(requiredAuthZ));
  }, [ isGranted, requiredAuthZ ]);


  const handleDrawerToggle = () => setIsDrawerOpen(!isDrawerOpen);
  const handleSwitchOrg = (activeOrgId: string) => setActiveOrgId(activeOrgId);

  return (
    <div className={classes.root}>
      <TheNavDrawer
        open={isDrawerOpen}
        onClose={handleDrawerToggle}
        badges={badges}
      />
      <div className={classes.content}>
        <TheAppBar
          onDrawerToggle={handleDrawerToggle}
          onSwitchOrg={handleSwitchOrg}
          title={title ?? ''}
        />
        <main className={classes.main}>
          {isLoading ? (
            <Typography variant="body1">Loading user profile...</Typography>
          ) : (
            isAuthorized ? <Outlet key={activeOrgId} /> : <Navigate to="/upgrade" replace />
          )}
        </main>
        <TheTermsFooter />
      </div>
    </div>
  );
}

export default TheDashboardLayout;
