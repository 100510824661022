import { Tab, Tabs } from '@mui/material';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TrainingViewStatus } from '../../../backend/src/training/enums';
import {
  ITrackViewStart,
  ITrainingVideoViewDto,
  ITrainingViewDto,
  ITrainingViewUpdateDto,
} from '../../../backend/src/training/interfaces';
import { VideoDialog } from '../components/dialogs';
import { showErrorResultBar } from '../components/ResultSnackbar';
import TrainingVideoLists from '../components/TrainingVideoLists';
import { TrainingCoverageChart } from '../components/widgets';
import { formatDate } from '../helpers';
import API from '../services/ApiService';
import useAuth from '../services/auth/useAuth';

export type TheTrainingPageParams = {
  vid?: string;
};

function TheTrainingPage() {
  const { isGranted } = useAuth();
  const params = useParams<TheTrainingPageParams>();
  const userVid = params.vid;
  const tabIdx = userVid === 'coverage' ? 1 : 0;
  const navigate = useNavigate();
  const [ videos, setVideos ] = useState<ITrainingVideoViewDto[]>([]);
  const [ selectedVideo, setSelectedVideo ] = useState<ITrainingVideoViewDto | null>(null);
  const [ selectedTabIdx, setSelectedTabIdx ] = useState(tabIdx);
  const [ videoUrl, setVideoUrl ] = useState<string | null>(null); // The truthiness of the url opens/closes the modal.
  const [ videoView, setVideoView ] = useState<ITrainingViewDto | null>(null);
  const [ vttCaptionUrl, setVttCaptionUrl ] = useState<string | null>();

  // Fetch the videos when first navigating to this page.
  useEffect(() => {
    API.get('training')
      .then(res => setVideos(res.data?.data ?? []))
      .catch((err) => {
        showErrorResultBar('Unexpected error loading videos');
        Sentry.captureException(err);
      });
  }, []);

  // Select the video based on the :vid url parameter.
  useEffect(() => {
    const video: ITrainingVideoViewDto | undefined = userVid ? videos.find(v => v.videoId === userVid) : undefined;
    setSelectedVideo(video ?? null);
  }, [ videos, userVid ]);

  // Once a video is selected, get its url and view tracking info.
  const handleStartVideo = useCallback(async () => {
    if (selectedVideo) {
      try {
        const videoId = selectedVideo.videoId;
        const trackViewInfo: ITrackViewStart = (await API.post(`training/${videoId}/view`))?.data?.data;

        setVttCaptionUrl(trackViewInfo.captionUrl);
        setVideoUrl(trackViewInfo.url);
        setVideoView(trackViewInfo.view);
      } catch (err: any) {
        Sentry.captureException(err);
        showErrorResultBar('Something went wrong obtaining a link for the video. Please try again later.');
        setVideoUrl(null);
        setVideoView(null);
        setVttCaptionUrl(null);
      }
    } else {
      setVideoUrl(null);
      setVideoView(null);
      setVttCaptionUrl(null);
    }
  }, [ selectedVideo ]);

  useEffect(() => {
    handleStartVideo();
  }, [ handleStartVideo ]);

  // Handle the case where the video link expires and we need to reset:
  const handlePlaybackError = (error: any) => {
    Sentry.captureException(error);
    handleStartVideo();
  };

  // The modal will close once selectedVideo is set to 'undefined'.
  const handleCloseVideo = () => {
    navigate('/training');
  };

  // The modal will open after the selectedVideo is set.
  const handleOpenVideo = (video: ITrainingVideoViewDto) => () => {
    navigate(`/training/${video.videoId}`);
  };

  const updateDisplayedVideoViewStatus = async (videoId: string, newStatus: TrainingViewStatus) => {
    const idx = videos.findIndex(v => v.videoId === videoId);
    // If they are re-watching a 'completed' video don't update the status to 'started' on the fly.
    if (idx >= 0 && videos[idx].status !== 'completed') {
      const updatedVideos = videos.slice();
      updatedVideos[idx].status = newStatus;
      if (newStatus === 'completed') {
        updatedVideos[idx].lastCompletedAt = formatDate(new Date()) as string;
      }

      setVideos(updatedVideos);
    }
  };

  const handleUpdateVideo = async (updateInfo: ITrainingViewUpdateDto) => {
    try {
      if (selectedVideo && videoView) {
        const res = await API.patch(`training/view/${videoView.id}`, updateInfo);
        const updatedStatus: TrainingViewStatus = res?.data?.data;
        updateDisplayedVideoViewStatus(selectedVideo.videoId, updatedStatus);
      }
    } catch (err: any) {
      Sentry.captureException(err);

      // If the auth token has expired then tracking updates won't work. Ask the user to refresh their browser.
      if (axios.isAxiosError(err) && err.response?.data?.statusCode === 401) {
        handleCloseVideo();
        showErrorResultBar('Something went wrong during video playback. You may need to refresh your browser.');
      }
    }
  };

  return <>
    {isGranted(TrainingCoverageChart.requiredAuthZ) ? (<>
      {/* Training Manager view */}
      <Tabs
        value={selectedTabIdx}
        onChange={(e, tabIdx) => setSelectedTabIdx(tabIdx)}
      >
        <Tab label="Training Videos" />
        <Tab label="Reporting" />
      </Tabs>
      {selectedTabIdx === 0 &&
        <TrainingVideoLists
          videos={videos}
          onVideoSelected={handleOpenVideo}
        />
      }
      {selectedTabIdx === 1 &&
        <TrainingCoverageChart />
      }
    </>) : (<>
      {/* Trainee view */}
      <TrainingVideoLists
        videos={videos}
        onVideoSelected={handleOpenVideo}
      />
    </>)}
    {/* Dialog for both Training Managers and Trainees */}
    {selectedVideo && videoUrl && videoView &&
      <VideoDialog
        open={!!videoUrl}
        videoViewInfo={{
          captionUrl: vttCaptionUrl,description: selectedVideo.description,
          name: selectedVideo.name,
          played: videoView.played,
          url: videoUrl,
        }}
        onClose={handleCloseVideo}
        onError={handlePlaybackError}
        onUpdateVideo={handleUpdateVideo}
      />
    }
  </>;
}

TheTrainingPage.requiredAuthZ = {
  tier: 0,
  permission: 'training',
};
TheTrainingPage.routePath = '/training';
TheTrainingPage.routePathCoverage = '/training/coverage';
TheTrainingPage.routePathWithParam = '/training/:vid';
TheTrainingPage.title = 'Training';

export default TheTrainingPage;
