import React from 'react';
import Link from '@mui/material/Link';
import Typography, { TypographyProps } from '@mui/material/Typography';
import * as PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { RouterLinkProps } from './RouterLink';

export type WidgetTitleProps = Pick<TypographyProps, 'variant'> & Pick<RouterLinkProps, 'children' | 'onClick' | 'to'>;

function WidgetTitle({ children, onClick, to, variant = 'h6' }: WidgetTitleProps) {
  return (
    <Typography variant={variant}>
      <Link
        color="inherit"
        component={RouterLink}
        to={to}
        onClick={onClick}
      >
        {children}
      </Link>
    </Typography>
  );
}

WidgetTitle.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  to: PropTypes.string,
  variant: PropTypes.string,
};

WidgetTitle.defaultProps = {
  variant: 'h6',
};

export default WidgetTitle;
