import React from 'react';
import { Card, Grid, Link, List, ListItem, ListItemText, Typography } from '@mui/material';
import { DEFAULT_SUPPORT_EMAIL } from '../helpers';

const supportReferences = [
  {
    label: 'NIST 800-53',
    url: 'https://nvd.nist.gov/800-53',
  },

  {
    label: 'ISO-27001',
    url: 'https://www.iso.org/obp/ui/#iso:std:iso-iec:27001',
  },

  {
    label: 'SIG-Lite',
    url: 'https://sharedassessments.org/sig/',
  },
];

function TheSupportPage() {
  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Card>
          <Typography variant="h6">Common Support Questions</Typography>
          <Typography paragraph>
            The following are some references that pertain to frequently asked
            questions.
          </Typography>
          <List subheader={<Typography>References</Typography>}>
            {supportReferences.map(r =>
              <Link key={r.label} href={r.url} target="_blank" rel="noopener noreferrer">
                <ListItem dense={true}>
                  <ListItemText primary={r.label} />
                </ListItem>
              </Link>
            )}
          </List>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card>
          <Typography variant="h6">Contact Us</Typography>
          <Typography paragraph>
            If you have any questions feel free to use the chat to get in touch with us.
          </Typography>
          <Typography paragraph>
            Or send us an email: <Link href={`mailto:${DEFAULT_SUPPORT_EMAIL}`}>{DEFAULT_SUPPORT_EMAIL}</Link>
          </Typography>
          <Typography paragraph>
            We'll get back to you as soon as we can!
          </Typography>
        </Card>
      </Grid>
    </Grid>
  );
}

TheSupportPage.requiredAuthZ = {
  tier: 0,
  permission: 'support',
};
TheSupportPage.routePath = '/support';
TheSupportPage.title = 'Support';

export default TheSupportPage;
