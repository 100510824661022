import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FilledInput,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  MenuItem,
  Theme,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import * as Sentry from '@sentry/react';
import { Formik } from 'formik';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { FileWithPath } from 'react-dropzone';
import * as Yup from 'yup';
import { IIdNameDto } from '../../../../backend/src/common/id-name-dto.interface';
import { QuestionnaireStatus } from '../../../../backend/src/questionnaire/enums';
import { IQuestionnaireDto, IQuestionnaireUpdateDto } from '../../../../backend/src/questionnaire/interfaces';
import API from '../../services/ApiService';
import useAuth from '../../services/auth/useAuth';
import { uploadDocument } from '../../services/DocService';
import { SaveButton } from '../buttons';
import { FormGridRow, FormikDatePicker, FormikTextField } from '../forms';
import { showErrorResultBar, showSuccessResultBar } from '../ResultSnackbar';
import StyledDialogTitle from '../StyledDialogTitle';
import StyledDropzone from '../StyledDropzone';
import { RemoveFileDialog, ResponsiveDialog } from '../dialogs';

const useStyles = makeStyles((theme: Theme) => createStyles({
  fullWidth: {
    marginRight: 0,
    marginLeft: 0,
  },
  fileNameInput: {
    color: theme.typography.body1.color,
  },
}));

export const questionnaireStatusSelections: Omit<{ [key in QuestionnaireStatus]: string }, 'archived'> = {
  new: 'New',
  in_progress: 'In Progress',
  in_review: 'Under Review',
  completed: 'Completed',
};

interface IFormValues extends Omit<IQuestionnaireUpdateDto, 'dueDate'> {
  dueDate?: Moment | null;
}

const QuestionnaireSchema = Yup.object().shape({
  dueDate: Yup
    .mixed<Moment>()
    .label('Due date'),
  name: Yup
    .string()
    .label('Name')
    .trim()
    .required('Required'),
  notes: Yup
    .string(),
  requestorEmail: Yup
    .string()
    .label('Requestor Email')
    .email('Invalid email format.'),
  requestorName: Yup
    .string()
    .label('Requestor Name')
    .max(255),
  status: Yup
    .mixed()
    .label('Status')
    .oneOf(Object.keys(questionnaireStatusSelections) as QuestionnaireStatus[]),
  url: Yup
    .string()
    .label('Link')
    .matches(/^https?:\/\//i, 'The url must begin with "http://" or "https://')
    .url('Invalid url'),
});

const getInitialFormValues = (questionnaire: IQuestionnaireDto | null): IFormValues => ({
  dueDate: questionnaire?.dueDate ? moment(questionnaire?.dueDate) : null,
  name: questionnaire?.name || '',
  notes: questionnaire?.notes || '',
  requestorEmail: questionnaire?.requestorEmail || '',
  requestorName: questionnaire?.requestorName || '',
  status: questionnaire?.status || 'new',
  url: questionnaire?.url || '',
});

const createUploadData = (formValues: IFormValues): IQuestionnaireUpdateDto => Object({
  dueDate: formValues.dueDate || null,
  name: formValues.name || null,
  notes: formValues.notes || null,
  requestorEmail: formValues.requestorEmail || null,
  requestorName: formValues.requestorName || null,
  status: formValues.status || 'new',
  url: formValues.url || null,
});

const displayedFileName = (file: FileWithPath) => `${file.path} (${Math.round(file.size / 1000)} KB)`;

interface FileAttachmentFieldProps {
  docName: string;
  id: number | string;
  onRemove: () => void;
}

function FileAttachmentField({ docName, id, onRemove }: FileAttachmentFieldProps) {
  const classes = useStyles();

  return (
    <FormControl
      className={classes.fullWidth}
      fullWidth
      variant="filled"
    >
      <InputLabel htmlFor={`file-attachment-${id}`}>
        File attachment
      </InputLabel>
      <FilledInput
        classes={{ disabled: classes.fileNameInput }}
        id={`file-attachment-${id}`}
        type="text"
        disabled
        value={docName}
        endAdornment={
          <InputAdornment position="end">
            <IconButton aria-label="remove file" onClick={onRemove} size="large">
              <DeleteIcon />
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
}

export interface QuestionnaireEditDialogProps extends DialogProps {
  onClose: () => void;
  onSave: (questionnaire: IQuestionnaireDto) => void;
  questionnaireData: IQuestionnaireDto | null;
}

export default function QuestionnaireEditDialog({ onClose, onSave, open, questionnaireData }: QuestionnaireEditDialogProps) {
  const classes = useStyles();
  const { isGranted } = useAuth();
  const [ archiveFile, setArchiveFile ] = useState<IIdNameDto | null>(null);
  const [ existingAttachments, setExistingAttachments ] = useState<IIdNameDto[]>([]);
  const [ newAttachments, setNewAttachments ] = useState<FileWithPath[]>([]);
  const [ toShowArchiveFileDialog, setToShowArchiveFileDialog ] = useState(false);
  const [ uploadFile, setUploadFile ] = useState<FileWithPath>();
  const [ uploadProgress, setUploadProgress ] = useState(0);

  useEffect(() => {
    setExistingAttachments(questionnaireData?.documents || []);
  }, [ questionnaireData ]);

  useEffect(() => {
    setUploadProgress(0);
  }, [ uploadFile ]);

  const handleSave = async (formValues: IQuestionnaireUpdateDto) => {
    try {
      const res = await (questionnaireData === null ? handleCreate(formValues) : handleEdit(questionnaireData.id, formValues));
      onSave(res);
      showSuccessResultBar('Questionnaire saved successfully');

      if (questionnaireData === null) {
        handleClose();
      }
    } catch (err: any) {
      const errorMsg = err.response?.data?.error ?? 'Unexpected error saving questionnaire';
      showErrorResultBar(errorMsg);
      Sentry.captureException(err);
    }
  };

  const handleCreate = async (formValues: IQuestionnaireUpdateDto): Promise<IQuestionnaireDto> => {
    // Create the Questionnaire:
    let questionnaire = (await API.post('questionnaire', formValues))?.data?.data;

    // Attach the docs:
    if (questionnaire?.id) {
      for (const file of newAttachments) {
        setUploadFile(file);
        questionnaire = await uploadAndAttachFile(file, questionnaire.id);
        setUploadFile(undefined);
      }
    }

    if (!questionnaire) {
      throw new Error('Unexpected error creating questionnaire');
    }

    return questionnaire;
  };

  const handleEdit = async (questionnaireId: string, formValues: IQuestionnaireUpdateDto): Promise<IQuestionnaireDto> => {
    const res = await API.patch(`questionnaire/${questionnaireId}`, formValues);

    return res?.data?.data;
  };

  const handleAddAttachment = async (file: FileWithPath) => {
    // For new questionnaires simply add this file to the attachments list; the uploads will be done upon save:
    if (questionnaireData === null) {
      setNewAttachments(newAttachments.concat(file));

      return;
    }

    // For existing questionnaires upload the file immediately; note this happens without pressing 'Save':
    try {
      setUploadFile(file);
      const questionnaire = await uploadAndAttachFile(file, questionnaireData.id);
      const updatedAttachments = questionnaire?.documents || [];
      setExistingAttachments(updatedAttachments);
      // Pass the updated list of attachments back to the parent (but not any other non-saved data):
      onSave({
        ...questionnaireData,
        documents: updatedAttachments,
      });
      setUploadFile(undefined);

      showSuccessResultBar('File attached to questionnaire.');
    } catch (err: any) {
      Sentry.captureException(err);
      showErrorResultBar('Unexpected error while attaching file. Please try again.');
    }
  };

  const uploadAndAttachFile = async (file: FileWithPath, questionnaireId: string): Promise<IQuestionnaireDto> => {
    const doc = await uploadDocument(file, { category: 'questionnaire', documentName: file.name }, setUploadProgress);

    return (await API.post(`questionnaire/${questionnaireId}/documents/${doc?.id}`))?.data?.data;
  };

  const handleRemoveNewAttachment = (idx: number) => () => {
    const updatedNewAttachments = newAttachments.slice();
    updatedNewAttachments.splice(idx, 1);
    setNewAttachments(updatedNewAttachments);
  };

  const handleClickArchiveExistingAttachment = (document: IIdNameDto) => () => {
    setArchiveFile(document);
    setToShowArchiveFileDialog(true);
  };

  const handleArchiveAttachment = async (docId: string) => {
    if (questionnaireData === null) {
      return true;
    }

    try {
      await API.delete(`questionnaire/${questionnaireData.id}/documents/${docId}`);
      const updatedAttachments = existingAttachments.filter(doc => doc.id !== docId);
      setExistingAttachments(updatedAttachments);
      // Pass the updated list of attachments back to the parent (but not any other non-saved data):
      onSave({
        ...questionnaireData,
        documents: updatedAttachments,
      });
      showSuccessResultBar('File archived.');

      return true;
    } catch (err: any) {
      Sentry.captureException(err);
      showErrorResultBar('Unexpected error while archiving file. Please try again.');

      return false;
    }
  };

  const handleClose = () => {
    onClose();
    setNewAttachments([]);
  };

  return (<>
    <ResponsiveDialog
      disableBackdropClick
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleClose}
    >
      <StyledDialogTitle onClose={handleClose}>
        {questionnaireData === null ? 'Create new questionnaire entry' : 'Edit questionnaire entry'}
      </StyledDialogTitle>
      <Formik
        enableReinitialize
        initialValues={getInitialFormValues(questionnaireData)}
        validateOnChange={false} // b/c of the date validation
        validationSchema={QuestionnaireSchema}
        onReset={handleClose}
        onSubmit={async (values, { setSubmitting }) => {
          await handleSave(createUploadData(values));
          setSubmitting(false);
        }}
      >
        {formikProps => (
          <>
            <DialogContent>
              <Grid container>
                <FormGridRow
                  divider
                  title="Questionnaire details"
                >
                  <FormikTextField
                    autoFocus
                    field="name"
                    formikProps={formikProps}
                    label="Name of the requesting entity"
                    required
                  />
                  {isGranted({ permission: 'admin:questionnaire_requests' }) && (
                  <FormikTextField
                    field="status"
                    formikProps={formikProps}
                    label="Status"
                    select
                  >
                    {Object.entries(questionnaireStatusSelections).map(([ key, label ]) => (
                      <MenuItem key={key} value={key}>
                        {label}
                      </MenuItem>
                    ))}
                  </FormikTextField>
                  )}
                  <FormikDatePicker
                    field="dueDate"
                    formikProps={formikProps}
                    initValue={questionnaireData?.dueDate}
                    label="Due date"
                    nullable
                  />
                  <FormikTextField
                    field="url"
                    formikProps={formikProps}
                    label="Link to the questionnaire (if applicable)"
                    placeholder="https://example.com"
                  />
                  {newAttachments.map((file, idx) => (
                    <FileAttachmentField
                      key={idx}
                      docName={displayedFileName(file)}
                      id={idx}
                      onRemove={handleRemoveNewAttachment(idx)}
                    />
                  ))}
                  {existingAttachments.map(doc => (
                    <FileAttachmentField
                      key={doc.id}
                      docName={doc.name}
                      id={doc.id}
                      onRemove={handleClickArchiveExistingAttachment(doc)}
                    />
                  ))}
                  {!uploadFile?.path && (
                  <FormControl
                    className={classes.fullWidth}
                    fullWidth
                  >
                    <StyledDropzone
                      onUpdateFile={handleAddAttachment}
                      placeholder={`Drop
                          ${(newAttachments.length + existingAttachments.length > 0) ? ' additional files ' : ' questionnaire '}
                        here, or click to select a file`}
                    />
                  </FormControl>
                )}
                </FormGridRow>
                <FormGridRow
                  divider
                  title="Requestor info"
                  subtitle="The person on your team asking for this questionnaire"
                >
                  <FormikTextField
                    field="requestorName"
                    formikProps={formikProps}
                    label="Requestor name"
                  />
                  <FormikTextField
                    field="requestorEmail"
                    formikProps={formikProps}
                    label="Requestor email"
                  />
                </FormGridRow>
                <FormGridRow
                  title="Additional notes"
                >
                  <FormikTextField
                    field="notes"
                    formikProps={formikProps}
                    label="Notes"
                    multiline
                  />
                </FormGridRow>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                disabled={formikProps.isSubmitting}
                onClick={formikProps.handleReset}
                color="primary"
              >
                {questionnaireData === null ? 'Cancel' : 'Close'}
              </Button>
              <SaveButton
                disabled={formikProps.isSubmitting || Object.values(formikProps.errors).filter(v => !!v).length > 0}
                onClick={formikProps.handleSubmit}
              />
            </DialogActions>
          </>
        )}
      </Formik>
    </ResponsiveDialog>
    {uploadFile &&
    <Dialog
      fullWidth
      open={!!uploadFile}
    >
      <DialogTitle>
        Uploading {displayedFileName(uploadFile)}
      </DialogTitle>
      <DialogContent>
        <LinearProgress
          variant="determinate"
          value={uploadProgress}
        />
      </DialogContent>
    </Dialog>
    }
    {archiveFile &&
    <RemoveFileDialog
      document={archiveFile}
      onClose={() => setToShowArchiveFileDialog(false)}
      onExited={() => setArchiveFile(null)}
      onRemoveFile={handleArchiveAttachment}
      open={toShowArchiveFileDialog}
    />
    }
  </>);
}
