import { Button, DialogActions, DialogContent, DialogContentText, DialogProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { IIdNameDto } from '../../../../backend/src/common/id-name-dto.interface';
import { DeleteButton } from '../buttons';
import { RouterLink } from '../RouterLink';
import StyledDialogTitle from '../StyledDialogTitle';
import ResponsiveDialog from './ResponsiveDialog';

const useStyles = makeStyles({
  dialogContent: {
    minWidth: '400px',
  },
});

export interface RemoveFileDialogProps extends DialogProps {
  document: IIdNameDto;
  onClose: () => void;
  onExited: () => void;
  onRemoveFile: (docId: string) => Promise<boolean>;
}

export default function RemoveFileDialog(props: RemoveFileDialogProps) {
  const classes = useStyles();
  const { document, onClose, onExited, onRemoveFile, open } = props;
  const [ isSubmitting, setIsSubmitting ] = useState<boolean>(false);

  const handleRemoveFile = async () => {
    setIsSubmitting(true);
    if (await onRemoveFile(document.id)) {
      onClose();
    }
    setIsSubmitting(false);
  };

  return (
    <ResponsiveDialog
      disableBackdropClick={true}
      open={open}
      onClose={onClose}
      TransitionProps={{ onExited }}
    >
      <StyledDialogTitle
        onClose={onClose}
      >
        Remove this file?
      </StyledDialogTitle>
      <DialogContent
        className={classes.dialogContent}
      >
        <DialogContentText>
          The file '{document.name}' will be archived.
          You will need to <RouterLink to="/support">contact us</RouterLink> in
          order to retrieve it.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          disabled={isSubmitting}
          onClick={onClose}
          size="small"
        >
          Cancel
        </Button>
        <DeleteButton
          disabled={isSubmitting}
          onClick={handleRemoveFile}
          text="Remove"
        />
      </DialogActions>
    </ResponsiveDialog>
  );
}
