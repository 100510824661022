import useAuth from '../services/auth/useAuth';

export interface TheSignUpPageProps {
  redirectUrl?: string;
}

function TheSignUpPage({ redirectUrl }: TheSignUpPageProps) {
  useAuth().login(redirectUrl, { screen_hint: 'signup' });

  return null;
}

export default TheSignUpPage;
