import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { CardProps } from '@mui/material/Card';
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import * as Sentry from '@sentry/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { IRoadmapItemDto } from '../../../../backend/src/roadmap/interfaces';
import API from '../../services/ApiService';
import { showErrorResultBar } from '../ResultSnackbar';

enum RoadmapCardStatus {
  PAST,
  CURRENT,
  FUTURE,
}

interface IRoadmapCard {
  defaultText?: string;
  header?: string;
  id: string; // for indexing in lists
  items?: IRoadmapItemDto[];
  status: RoadmapCardStatus,
}

interface RoadmapCardProps extends CardProps {
  card: IRoadmapCard;
}

const RoadmapCard = ({ card, ...otherCardProps }: RoadmapCardProps) => {
  const { defaultText = '', header = '', items = [] } = card;

  return (
    <Card {...otherCardProps}>
      {/* Card header: */}
      <Typography
        fontWeight={card.status === RoadmapCardStatus.PAST ? 400 : 500}
        py={1}
        textAlign="center"
        sx={[ card.status === RoadmapCardStatus.PAST && (theme => ({ color: theme.typography.body2.color }))]}
      >
        {header}
      </Typography>
      {/* Card contents: */}
      {items.length === 0 ? (
        // Default text (if no items):
        <Typography color="text.secondary" fontSize="14px" fontStyle="italic" textAlign="center" sx={{ pt: { xs: 1, sm: 5 }}}>
          {defaultText}
        </Typography>
      ) : (<>
        {/* Items list: */}
        <Divider />
        <List dense sx={{ p: 0, pl: 1, mb: 1, maxHeight: 'calc(100% - 32px)', overflow: 'auto', width: '100%' }}>
          {items.map((item, idx) => (
            // Wrap each item in a tooltip:
            <RoadmapTooltip
              key={item.id}
              arrow
              placement="right"
              title={(
                <Box>
                  <Typography color="inherit" fontWeight={500}>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" color="inherit" gutterBottom>
                    {moment(item.date).format('MMM D YYYY')}
                  </Typography>
                  <Typography variant="body2" color="inherit" fontStyle="italic" pl={1}>
                    {item.description}
                  </Typography>
                </Box>
              )}
            >
              {/* The roadmap item: */}
              <ListItem sx={{ py: 0, pl: 0, pr: 0.5, fontSize: '12px' }}>
                {/* The roadmap item's day icon: */}
                <ListItemButton disableGutters >
                  <ListItemIcon sx={{ minWidth: 0, pr: 1, pl: '2px' }}>
                    {/* Hide the day if it's a duplicate of the previous item's: */}
                    {item.date !== items[idx - 1]?.date ? (
                      <Box sx={{
                        border: '1px solid darkgray',
                        height: '18px',
                        width: '18px',
                        padding: '1px',
                        lineHeight: '14px',
                        textAlign: 'center',
                        fontSize: 'inherit',
                      }}>
                        <Typography component="span" color="inherit" fontSize="inherit" lineHeight="inherit" p={0} >
                          {moment(item.date).format('D')}
                        </Typography>
                      </Box>
                    ) : (
                      <Box sx={{ height: '18px', width: '18px' }} />
                    )}
                  </ListItemIcon>
                  {/* The roadmap item's title: */}
                  <ListItemText
                    primary={item.title}
                    primaryTypographyProps={{ fontSize: 'inherit'}}
                  />
                </ListItemButton>
              </ListItem>
            </RoadmapTooltip>
          ))}
        </List>
      </>)}
    </Card>
  );
};

const RoadmapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: 'rgba(0, 0, 0, 0.9)',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
    color: '#fff',
    fontSize: '12px',
    padding: '6px 12px',
    minWidth: '150px',
  },
}));

const TODAY = moment();
const CARD_HEADER_DATE_FORMAT = 'MMM YYYY';
const TODAYS_PERIOD_CARD_HEADER = TODAY.format(CARD_HEADER_DATE_FORMAT);

const getRoadmapCards = (items: IRoadmapItemDto[]): IRoadmapCard[] => {
  // Assumes the Roadmap items are arranged chronologically.

  const cards: IRoadmapCard[] = [];
  let currCard: IRoadmapCard | null = null;

  // For each item, see if it belongs on the current card based on the generated header.
  // If it doesn't, then push the current card to the stack and create a new card based on the new header.
  // In either case, push the item to the (new) card.
  items.forEach((item) => {
    const currItemDate = moment(item.date);
    const currItemHeader = currItemDate.format(CARD_HEADER_DATE_FORMAT);

    if (currCard?.header !== currItemHeader) {
      if (currCard) cards.push(currCard);

      currCard = {
        header: currItemHeader,
        id: currItemHeader,
        status: currItemHeader === TODAYS_PERIOD_CARD_HEADER ? RoadmapCardStatus.CURRENT :
          (TODAY.isBefore(currItemDate) ? RoadmapCardStatus.FUTURE : RoadmapCardStatus.PAST),
      };
    }

    currCard.items = (currCard.items ?? []).concat(item);
  });

  // Push the final card to the stack:
  if (currCard) cards.push(currCard);

  // Conditionally add a first card:
  let firstCard: IRoadmapCard | null = null;

  if (cards.length === 0) {
    firstCard = {
      defaultText: 'No items in the Roadmap',
      id: 'no-items',
      status: RoadmapCardStatus.CURRENT,
    };
  } else if (cards[0].status === RoadmapCardStatus.FUTURE) {
    firstCard = {
      defaultText: `No items before ${cards[0].header}`,
      id: `pre-${cards[0].header}`,
      status: RoadmapCardStatus.FUTURE,
    };
  }

  return firstCard ? [ firstCard ].concat(cards) : cards;
};

function RoadmapHorizontal() {
  const theme = useTheme();
  const fullWidth = useMediaQuery(theme.breakpoints.down('sm'));
  const lessCards = useMediaQuery(theme.breakpoints.down('md'));
  const numVisibleCards = lessCards ? 4 : 5;

  const [ displayedCards, setDisplayedCards ] = useState<IRoadmapCard[]>([]);
  const [ roadmapCards, setRoadmapCards ] = useState<IRoadmapCard[]>([]);
  const [ roadmapItems, setRoadmapItems ] = useState<IRoadmapItemDto[]>([]);
  const [ startIdx, setStartIdx ] = useState(0);

  // Initial page load gets the raw roadmap items:
  useEffect(() => {
    API.get('roadmapItem')
      .then(res => setRoadmapItems(res.data?.data ?? []))
      .catch((err) => {
        showErrorResultBar('Unexpected error loading roadmap');
        Sentry.captureException(err);
      });
  }, []);

  // Create the roadmap cards from the items and set the starting card (the left-most that appears):
  useEffect(() => {
    const theRoadmapCards = getRoadmapCards(roadmapItems);
    const theStartIdx = roadmapItems.length > numVisibleCards ? theRoadmapCards.findIndex(({ status }) => status === RoadmapCardStatus.CURRENT) : 0;

    setRoadmapCards(theRoadmapCards);
    setStartIdx(theStartIdx !== -1 ? theStartIdx : 0);
  }, [ numVisibleCards, roadmapItems ]);

  // Only show 'numVisibleCards' at a time, and update the slice when 'back'/'next' are clicked:
  useEffect(() => {
    setDisplayedCards(roadmapCards.slice(startIdx, startIdx + numVisibleCards));
  }, [ numVisibleCards, roadmapCards, startIdx ]);

  return (roadmapItems.length > 0 ?
      <Card>
        <Grid container justifyContent="space-between" spacing={1}>
          {/* Header and back button: */}
          <Grid item xs={12} sm={1} container direction="column" justifyContent="space-between"
                alignItems={fullWidth ? 'stretch' : 'flex-start'}>
            <Grid item>
              <Typography variant="h6" sx={{
                display: {
                  xs: 'block',
                  sm: displayedCards.length < 2 ? 'block' : 'none',
                  lg: 'block',
                },
              }}>
                Roadmap
              </Typography>
            </Grid>
            <Grid item>
              <Button
                color="inherit"
                disabled={startIdx === 0}
                fullWidth={fullWidth}
                sx={{ ml: 0 }}
                variant={fullWidth ? 'contained' : 'text'}
                onClick={() => setStartIdx(startIdx - 1)}
              >
                Back
              </Button>
          </Grid>
        </Grid>
        {/* The cards: */}
        <Grid item xs={12} sm={10} container justifyContent="center" spacing={2} alignItems="stretch">
          {displayedCards.map((card, _) => (
            <Grid key={card.id} item xs={12} sm={true}
                  sx={{ height: { sm: 200 }, maxWidth: { xs: 'none', sm: displayedCards.length < 3 ? 300 : 'none' } }}>
              <RoadmapCard
                sx={{ p: 0, m: 0, height: '100%' }}
                elevation={card.defaultText ? 0 : 2}
                card={card}
              />
            </Grid>
          ))}
        </Grid>
        {/* Next button: */}
        <Grid item xs={12} sm={1} container direction="column" justifyContent="flex-end" alignItems={fullWidth ? 'stretch' : 'center'}>
          <Grid item>
            <Button
              color="inherit"
              disabled={startIdx + displayedCards.length >= roadmapCards.length}
              fullWidth={fullWidth}
              sx={{ ml: 0 }}
              variant={fullWidth ? 'contained' : 'text'}
              onClick={() => setStartIdx(startIdx + 1)}
            >
              Next
            </Button>
          </Grid>
        </Grid>
        </Grid>
      </Card>
      : null
  );
}

RoadmapHorizontal.requiredAuthZ = {
  tier: 3,
  permission: 'roadmap:read',
};

export default RoadmapHorizontal;
