import {
  Button,
  DialogActions,
  DialogContent,
  DialogProps,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as Sentry from '@sentry/react';
import React, { useState } from 'react';
import { IPolicyAcknowledgementDto, IPolicyDocDto } from '../../../../backend/src/policy-doc/interfaces';
import { errorParser } from '../../helpers';
import API from '../../services/ApiService';
import { showErrorResultBar, showSuccessResultBar } from '../ResultSnackbar';
import StyledDialogTitle from '../StyledDialogTitle';
import ResponsiveDialog from './ResponsiveDialog';

const useStyles = makeStyles({
  listText: {
    fontSize: '1rem',
  },
});

export interface PolicyAcknowledgementDialogProps extends DialogProps {
  onClose: () => void;
  onUpdate: (acknowledgements: IPolicyAcknowledgementDto[]) => void;
  policies: IPolicyDocDto[];
}

export default function PolicyAcknowledgementDialog({ onClose, onUpdate, open, policies }: PolicyAcknowledgementDialogProps) {
  const classes = useStyles();
  const [ isSubmitting, setIsSubmitting ] = useState(false);

  const handleConfirmAcknowledgement = async () => {
    setIsSubmitting(true);

    try {
      const versionIds = policies.map(p => p.versionId);
      const acknowledgements: IPolicyAcknowledgementDto[] = (await API.post('policyDoc/acknowledge', { versionIds })).data?.data;
      onUpdate(acknowledgements);
      showSuccessResultBar(`${acknowledgements.length === 1 ? 'Policy' : 'Policies'} acknowledged`);
    } catch (err: any) {
      showErrorResultBar(errorParser(err, 'Unexpected error while acknowledging policies'));
      Sentry.captureException(err);
    } finally {
      setIsSubmitting(false);
      onClose();
    }
  };

  return (
    <ResponsiveDialog
      open={open}
      onClose={onClose}
    >
      <StyledDialogTitle>Policy Acknowledgement</StyledDialogTitle>
      <DialogContent>
        <Typography gutterBottom>
          By clicking the Confirm button you acknowledge that you have read, understand, and agree
          to abide by the following policies:
        </Typography>
        <List dense>
          {policies.map(policy => (
            <ListItem key={policy.id}>
              <ListItemText
                className={classes.listText}
                primary={policy.name}
                primaryTypographyProps={{ variant: 'body1' }}
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          disabled={isSubmitting}
          size="small"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={isSubmitting}
          size="small"
          variant="contained"
          onClick={handleConfirmAcknowledgement}
        >
          Confirm
        </Button>
      </DialogActions>
    </ResponsiveDialog>
  );
}
