import {
  Accordion,
  AccordionDetails,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import DoneIcon from '@mui/icons-material/Done';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import React, { useEffect, useState } from 'react';
import { TrainingCategory, TrainingViewStatus } from '../../../backend/src/training/enums';
import { ITrainingVideoViewDto } from '../../../backend/src/training/interfaces';
import { formatDate } from '../helpers';
import AccordionIconSummary from './AccordionIconSummary';

const useStyles = makeStyles({
  metaInfo: {
    fontStyle: 'italic',
    paddingRight: '4px',
  },
  list: {
    width: '100%',
  },
});

interface IPanelFilter {
  categories: TrainingCategory[];
  expanded?: boolean;
  title: string;
}

const PANELS_FILTERS: IPanelFilter[] = [
  {
    categories: [ 'training' ],
    expanded: true,
    title: 'Essential Training',
  },
  {
    categories: [ 'developer' ],
    title: 'Developer Training',
  },
  {
    categories: [ 'extended_developer' ],
    title: 'Extended Developer Training',
  },
  {
    categories: [ 'policy' ],
    title: 'Policy Training',
  },
  {
    categories: [ 'standards_and_regulations' ],
    title: 'Standards and Regulations',
  },
  {
    categories: [ 'preview', 'program_management' ],
    title: 'Effectively Using SecurityProgram.io',
  },
];

interface ITrainingStatusInfo {
  icon: React.ReactElement<any>;
  text: string;
}

function getTrainingStatusInfo(status: TrainingViewStatus): ITrainingStatusInfo {
  switch (status as string) {
    case 'completed':
      return {
        icon: <DoneIcon fontSize="large" />,
        text: 'Completed',
      };

    case 'notstarted':
      return {
        icon: <NewReleasesIcon fontSize="large" />,
        text: 'Not yet completed during the current reporting period',
      };

    case 'started':
      return {
        icon: <TimelapseIcon fontSize="large" />,
        text: 'Started watching but not complete in the current reporting period',
      };

    default:
      throw new Error('Invalid status provided');
  }
}

interface IVideoList {
  expanded?: boolean;
  title: string;
  videos: ITrainingVideoViewDto[];
}

export interface VideoListAccordionProps {
  videoList: IVideoList;
  onVideoSelected: (video: ITrainingVideoViewDto) => () => void;
}

const VideoListAccordion = ({ videoList, onVideoSelected }: VideoListAccordionProps) => {
  const classes = useStyles();
  const { expanded, title, videos } = videoList;
  const [ isExpanded, setIsExpanded ] = useState(expanded ?? false);

  return (
    <Accordion
      elevation={2}
      expanded={isExpanded}
      onChange={() => setIsExpanded(!isExpanded)}
    >
      <AccordionIconSummary title={title} />
      <AccordionDetails>
        <List className={classes.list}>
          {videos.sort((v1, v2) => v1.progression - v2.progression).map(video => (
            <ListItem
              key={video.name}
              button
              onClick={onVideoSelected(video)}
            >
              <Tooltip
                placement="top"
                title={getTrainingStatusInfo(video.status).text}
              >
                <ListItemIcon>
                  {getTrainingStatusInfo(video.status).icon}
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={video.name}
                secondary={
                  <>
                    <Typography
                      component="span"
                      display="block"
                      variant="body2"
                    >
                      {video.description}
                    </Typography>
                    <Typography
                      className={classes.metaInfo}
                      component="span"
                      variant="body2"
                    >
                      {(video.status === 'started' && 'In progress')
                      || (video.lastCompletedAt && `Last completed on ${formatDate(video.lastCompletedAt)}`)}
                    </Typography>
                  </>
                }
              >
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

export interface TrainingVideosProps {
  videos: ITrainingVideoViewDto[];
  onVideoSelected: (video: ITrainingVideoViewDto) => () => void;
}

export default function TrainingVideos({ videos, onVideoSelected }: TrainingVideosProps) {
  const [ videoLists, setVideoLists ] = useState<IVideoList[]>([]);

  useEffect(() => {
    const theVideoLists: IVideoList[] = PANELS_FILTERS.map((videoList) => {
      const vids = videos.filter(vid => videoList.categories.includes(vid.trainingCategory));

      return { expanded: videoList.expanded, title: videoList.title, videos: vids };
    });

    setVideoLists(theVideoLists.filter(videoList => videoList.videos.length > 0));
  }, [ videos ]);

  return (
    <>
      {videoLists.map(videoList => (
        <VideoListAccordion
          key={videoList.title}
          videoList={videoList}
          onVideoSelected={onVideoSelected}
        />
      ))}
    </>
  );
}
