import {
  Button,
  DialogActions,
  DialogContent,
  DialogProps,
  Divider,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import { startCase } from 'lodash';
import React, { useState } from 'react';
import { FileWithPath } from 'react-dropzone';
import IDocument from '../../../../backend/src/document/document.interface';
import { IUploadPathDto } from '../../../../backend/src/document/upload-path-dto.interface';
import { IUploadPathGlobalVendorDto } from '../../../../backend/src/document/upload-path-global-vendor-dto.interface';
import { DocumentCategory } from '../../../../backend/src/document/document-category.enum';
import { uploadDocument, uploadGlobalVendorDocument } from '../../services/DocService';
import { showErrorResultBar } from '../ResultSnackbar';
import StyledDialogTitle from '../StyledDialogTitle';
import StyledDropzone from '../StyledDropzone';
import ResponsiveDialog from './ResponsiveDialog';

export interface FileUploadDialogProps extends DialogProps {
  dialogHeaderText: string;
  documentCategory?: DocumentCategory;
  documentOrgId?: string; // usually undefined, unless uploading to a diff't org
  globalVendorId?: string | null; // if defined, then will upload to global vendors instead of org docs
  onAddDocument?: (doc: IDocument) => void | Promise<void>;
  onClose: () => void;
  showCategorySelection?: boolean;
  showIcon?: boolean;
}

export default function FileUploadDialog(props: FileUploadDialogProps) {
  const {
    dialogHeaderText,
    documentCategory = 'evidence',
    documentOrgId,
    globalVendorId = null,
    onAddDocument = () => {},
    onClose,
    open,
    showCategorySelection = false,
  } = props;

  const [ selectedCategory, setSelectedCategory ] = useState(documentCategory);
  const [ selectedFile, setSelectedFile ] = useState<FileWithPath>();
  const [ uploadProgress, setUploadProgress ] = useState(0);
  const [ toDisable, setToDisable ] = useState(false);

  const onClickClose = () => {
    setSelectedFile(undefined);
    setUploadProgress(0);
    onClose();
  };

  const onClickSubmit = () => {
    if (selectedFile && !toDisable) {
      setToDisable(true);

      const doUpload = globalVendorId !== null ? doUploadGlobalVendorDoc(globalVendorId) : doUploadDoc(documentOrgId!);

      doUpload(selectedFile)
        .then((doc) => {
          onAddDocument(doc);
          onClickClose();
        })
        .catch((err) => {
          showErrorResultBar('There was a problem issuing your request.');
          Sentry.captureException(err);
        })
        .finally(() => {
          setToDisable(false);
        });
    }
  };

  const doUploadDoc = (orgId?: string) => async (fileWithPath: FileWithPath) => {
    const uploadPath: IUploadPathDto = {
      category: selectedCategory,
      documentName: fileWithPath.name,
      orgId,
    };

    return uploadDocument(fileWithPath, uploadPath, setUploadProgress);
  };


  const doUploadGlobalVendorDoc = (vendorId: string) => async (fileWithPath: FileWithPath) => {
    const uploadPath: IUploadPathGlobalVendorDto = {
      documentName: fileWithPath.name,
    };

    return uploadGlobalVendorDocument(fileWithPath, vendorId, uploadPath, setUploadProgress);
  };

  return (
    <ResponsiveDialog
      disableBackdropClick={true}
      open={open}
      maxWidth="sm"
      fullWidth
      onClose={onClickClose}
    >
      <StyledDialogTitle
        id="form-dialog-title"
        onClose={onClickClose}
      >
        {dialogHeaderText}
      </StyledDialogTitle>
      <DialogContent>
      {showCategorySelection && (<>
        <FormControl variant="standard">
          <InputLabel htmlFor="upload-path">Select a category</InputLabel>
          <Select
            value={selectedCategory}
            onChange={e => setSelectedCategory(e.target?.value as DocumentCategory || 'evidence')}
            inputProps={{
              name: 'upload-path',
              id: 'upload-path',
            }}
          >
            {[ 'evidence', 'report' ].map(cat => (
              <MenuItem key={cat} value={cat}>{startCase(cat)}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
      </>)}
      <StyledDropzone
        disabled={toDisable}
        onUpdateFile={setSelectedFile}
      />
      {selectedFile && selectedFile.path && (<>
        <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
        <Typography
          variant="h6"
        >
          {selectedFile.path}
          {/* - { selectedFile.size } bytes */}
        </Typography>
        <LinearProgress
          variant="determinate"
          value={uploadProgress}
        />
      </>)}
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={onClickClose}
        >
          Cancel
        </Button>
        <Button
          disabled={!selectedFile}
          color="primary"
          onClick={onClickSubmit}
        >
          Submit
        </Button>
      </DialogActions>
    </ResponsiveDialog>
  );
}
