import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { makeStyles } from '@mui/styles';
import * as Sentry from '@sentry/react';
import React from 'react';
import { IPolicyDocDto } from '../../../../backend/src/policy-doc/interfaces';
import { errorParser } from '../../helpers';
import API from '../../services/ApiService';
import { showErrorResultBar, showSuccessResultBar } from '../ResultSnackbar';
import { RouterLink } from '../RouterLink';
import StyledDialogTitle from '../StyledDialogTitle';

const useStyles = makeStyles({
  leftIcon: {
    marginRight: '0.5rem',
  },
});

export interface RemoveCustomPolicyDialogProps extends DialogProps {
  onClose: () => void;
  onUpdate: (newData: IPolicyDocDto) => void;
  policy: IPolicyDocDto;
}

export default function RemoveCustomPolicyDialog({ onClose, onUpdate, open, policy }: RemoveCustomPolicyDialogProps) {
  const classes = useStyles();

  const handleRemoveCustomPolicy = async () => {
    try {
      const res = await API.patch(`policyDoc/${policy.versionId}/removeCustomPolicy`);
      onUpdate(res.data.data);
      showSuccessResultBar('Custom policy removed');
    } catch (err: any) {
      showErrorResultBar(errorParser(err, 'Unexpected error while removing custom policy'));
      Sentry.captureException(err);
    } finally {
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <StyledDialogTitle>Remove this custom policy?</StyledDialogTitle>
      <DialogContent>
        <DialogContentText>
          The file will be archived.
          You will need to <RouterLink to="/support">contact us</RouterLink> in
          order to retrieve it.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          size="small"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={handleRemoveCustomPolicy}
        >
          <DeleteIcon className={classes.leftIcon} />
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
}
