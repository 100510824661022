import {
  BaseTextFieldProps,
  FilledInputProps,
  FormHelperText,
  FormHelperTextProps,
  Grid,
  InputProps as StandardInputProps,
  OutlinedInputProps,
  TextField,
  TextFieldProps,
  Theme,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { ChangeEvent, useState } from 'react';
import Interval from '../../../backend/src/common/interval.type';

const useStyles = makeStyles((theme: Theme) => createStyles({
  legend: {
    color: theme.palette.text.secondary,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(12),
    textAlign: 'left',
    lineHeight: '1em',
    minHeight: '1em',
    margin: 0,
  },
}));

interface IBaseIntervalFieldProps extends BaseTextFieldProps {
  value?: Interval;
  onChange?: (newValue: Interval) => void;
  defaultValue?: Interval;
}

export interface IStandardIntervalFieldProps extends IBaseIntervalFieldProps {
  variant?: 'standard';
  InputProps?: Partial<StandardInputProps>;
  inputProps?: StandardInputProps['inputProps'];
}

export interface IFilledIntervalFieldProps extends IBaseIntervalFieldProps {
  variant: 'filled';
  InputProps?: Partial<FilledInputProps>;
  inputProps?: FilledInputProps['inputProps'];
}

export interface IOutlinedIntervalFieldProps extends IBaseIntervalFieldProps {
  variant: 'outlined';
  InputProps?: Partial<OutlinedInputProps>;
  inputProps?: OutlinedInputProps['inputProps'];
}

export type IntervalFieldProps = IStandardIntervalFieldProps | IFilledIntervalFieldProps | IOutlinedIntervalFieldProps;

export default function IntervalField(props: IntervalFieldProps) {
  const classes = useStyles();

  const { label, disabled = false, name, onChange, helperText, value, ...otherProps } = props;
  const textFieldProps: TextFieldProps = otherProps as TextFieldProps;
  const [ fullValue, setFullValue ] = useState<Interval | undefined>(value);

  function handleChange(e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) {
    const numValue: number = Number(e.target.value);

    if (!isNaN(numValue)) {
      const newFullValue = Object.assign({}, fullValue, {[e.target.name]: numValue});
      setFullValue(newFullValue);
      if (onChange) {
        onChange(newFullValue);
      }
    }
  }

  return (
    <fieldset>
      <legend className={classes!.legend}>{label}</legend>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <TextField
            name="years"
            label="Years"
            disabled={disabled}
            value={(fullValue && fullValue.years) || ''}
            onChange={handleChange}

            {...textFieldProps}
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            name="months"
            label="Months"
            disabled={disabled}
            value={(fullValue && fullValue.months) || ''}
            onChange={handleChange}

            {...textFieldProps}
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            name="days"
            label="Days"
            disabled={disabled}
            value={(fullValue && fullValue.days) || ''}
            onChange={handleChange}

            {...textFieldProps}
          />
        </Grid>
        {helperText &&
        <Grid item xs={12}>
          <FormHelperText
            disabled={disabled}

            {...otherProps as FormHelperTextProps}
          >
            {helperText}
          </FormHelperText>
        </Grid>
        }
      </Grid>
    </fieldset>
  );
};
