import * as Sentry from '@sentry/react';
import API from '../services/ApiService';
import Page from '../components/Page';
import React, { useEffect } from 'react';
import { Typography } from '@mui/material';
import TheHomePageRedirect from './TheHomePageRedirect';
import { useNavigate } from 'react-router-dom';

function TheBillingPageRedirect() {
  const navigate = useNavigate();

  useEffect(() => {
    API.post('subscription/customerPortal')
    .then(res => window.location = res.data.data.url)
    .catch((err) => { 
      Sentry.captureException(err);
      navigate(TheHomePageRedirect.routePath);
    });
  },);

  return(
    <Page>
      <Typography sx={{ pl: 2 }} variant="h6">Redirecting to billing portal...</Typography>
    </Page>
  );
}

TheBillingPageRedirect.requiredAuthZ = {
  permission: 'subscription:manage',
};
TheBillingPageRedirect.routePath = '/billing';
TheBillingPageRedirect.title = 'Billing';

export default TheBillingPageRedirect;
