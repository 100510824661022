import { Link } from '@mui/material';
import React from 'react';
import { ICategoryLabelMap, TaskSummaryByCategory } from '../components/TaskSummaryByCategory';

// The ordering here is used to order the displayed results for 'Criteria'.
const topCategoryMap: ICategoryLabelMap = {
  'soc:cc': {
    abbreviation: 'CC',
    title: 'Security - Common Criteria',
    subtitle: `
      Information and systems are protected against unauthorized access, unauthorized disclosure of information,
      and damage to systems that could compromise the availability, integrity, confidentiality, and privacy of
      information or systems and affect the entity’s ability to achieve its objectives
    `,
  },
  'soc:a': {
    abbreviation: 'A',
    title: 'Availability',
    subtitle: `
      Information and systems are available for operation and use to meet the entity’s objectives
    `,
  },
  'soc:pi': {
    abbreviation: 'PI',
    title: 'Processing Integrity',
    subtitle: `
      System processing is complete, valid, accurate, timely, and authorized to meet the entity’s objectives
    `,
  },
  'soc:c': {
    abbreviation: 'C',
    title: 'Confidentiality',
    subtitle: `
      Information designated as confidential is protected to meet the entity’s objectives
    `,
  },
  'soc:p': {
    abbreviation: 'P',
    title: 'Privacy',
    subtitle: `
      Personal information is collected, used, retained, disclosed, and disposed of to meet the entity’s objectives
    `,
  },
};

// The ordering here is used to order the displayed results for the 'Categories' within 'Functions'.
const subCategoryMap: ICategoryLabelMap = {
  // CC
  'soc:cc1': {
    abbreviation: 'CC1',
    title: 'The Control Environment',
  },
  'soc:cc2': {
    abbreviation: 'CC2',
    title: 'Communication and Information',
  },
  'soc:cc3': {
    abbreviation: 'CC3',
    title: 'Risk Assessment',
  },
  'soc:cc4': {
    abbreviation: 'CC4',
    title: 'Monitoring of Controls',
  },
  'soc:cc5': {
    abbreviation: 'CC5',
    title: 'Control Design and Implementation',
  },
  'soc:cc6': {
    abbreviation: 'CC6',
    title: 'Logical and Physical Access Controls',
  },
  'soc:cc7': {
    abbreviation: 'CC7',
    title: 'System Operations',
  },
  'soc:cc8': {
    abbreviation: 'CC8',
    title: 'Change Management',
  },
  'soc:cc9': {
    abbreviation: 'CC9',
    title: 'Risk Mitigation',
  },
  // P
  'soc:p1': {
    abbreviation: 'P1',
    title: 'Communication of Privacy Objectives',
  },
  'soc:p2': {
    abbreviation: 'P2',
    title: 'Choice and Consent',
  },
  'soc:p3': {
    abbreviation: 'P3',
    title: 'Data Collection',
  },
  'soc:p4': {
    abbreviation: 'P4',
    title: 'Use, Retention, and Disposal of Data',
  },
  'soc:p5': {
    abbreviation: 'P5',
    title: 'Access to Data',
  },
  'soc:p6': {
    abbreviation: 'P6',
    title: 'Disclosure and Notification',
  },
  'soc:p7': {
    abbreviation: 'P7',
    title: 'Data Quality',
  },
  'soc:p8': {
    abbreviation: 'P8',
    title: 'Monitoring and Enforcement',
  },
};

function TheTasksSummarySOCPage() {
  return (
    <TaskSummaryByCategory
      apiRoute="task/summary/soc"
      subCategoryMap={subCategoryMap}
      title="System and Organization Controls (SOC)"
      topCategoryLabel="Criteria"
      topCategoryMap={topCategoryMap}
    >
      <>
        The <Link
        href="https://www.aicpa.org/"
        target="_blank"
        rel="noopener noreferrer"
      >
        American Institute of Certified Public Accountants (AICPA)
      </Link> publishes
        a table of <Link
        href="https://www.aicpa.org/interestareas/frc/assuranceadvisoryservices/trustdataintegritytaskforce.html"
        target="_blank"
        rel="noopener noreferrer"
      >
        Trust Services Criteria
      </Link> used
        to evaluate controls in a <Link
        href="https://www.aicpa.org/interestareas/frc/assuranceadvisoryservices/aicpasoc2report.html"
        target="_blank"
        rel="noopener noreferrer"
      >
        SOC 2 assessment
      </Link>.
        These are broken down into a set of common security criteria (CC)
        and additional category-specific criteria.
        Each criterion has several
        associated tasks your organization should complete.
        In the table below we indicate how far along your organization has come in terms of
        implementing the Trust Services Criteria in preparation for a SOC 2.
      </>
    </TaskSummaryByCategory>
  );
}

TheTasksSummarySOCPage.requiredAuthZ = {
  tier: 1,
  permission: 'tasks',
};
TheTasksSummarySOCPage.routePath = '/tasks/soc';
TheTasksSummarySOCPage.title = 'Task Summary: SOC';

export default TheTasksSummarySOCPage;
