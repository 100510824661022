import { Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import { CaptionedAddButton } from './buttons';

const useStyles = makeStyles((theme: Theme) => createStyles({
  titleContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  titleText: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

export interface DataTableTitleWithButtonProps {
  buttonTitle?: string;
  onButtonClick: () => void;
  title: string;
}

export default function DataTableTitleWithButton(props: DataTableTitleWithButtonProps) {
  const classes = useStyles();
  const { buttonTitle = 'New Entry', onButtonClick, title } = props;

  return (
    <div className={classes.titleContainer}>
      <Typography
        variant="h6"
        className={classes.titleText}
      >
        { title }
      </Typography>
      <CaptionedAddButton
        onClick={onButtonClick}
      >
        { buttonTitle }
      </CaptionedAddButton>
    </div>
  );
}
