import { IconButton } from '@mui/material';
import { IconButtonProps } from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import * as React from 'react';

function AddButton(props: IconButtonProps) {
  return (
    <IconButton {...props} aria-label="Add" size="large">
      <AddCircleIcon />
    </IconButton>
  );
}

export default AddButton;
