import { AppBar, Button, Theme, Toolbar } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import { Outlet } from 'react-router-dom';
import TheTermsFooter from '../components/TheTermsFooter';
import logoWithText from '../images/logo-securityprogramio-300x42.jpeg';
import useAuth from '../services/auth/useAuth';

const useStyles = makeStyles((theme: Theme) => createStyles({
  content: {
    flexGrow: 1,
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },

  logo: {
    flexGrow: 1,
    height: '100%',
  },

  main: {
    flex: '1 0 auto',
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(3),
    },
  },

  root: {
    display: 'flex',
    flexDirection: 'row',
  },
}), { name: 'TheLoginLayout' }); // TODO: Figure out why only a hard-coded string works in prod builds

function TheLoginLayout() {
  const classes = useStyles();
  const { isAuthenticated, login, logout } = useAuth();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <AppBar position="static">
          <Toolbar>
            <div className={classes.logo}>
              <img src={logoWithText} alt="logo" height="40em" />
            </div>

            {isAuthenticated ?
              <Button
                // color="inherit"
                onClick={() => logout()}
              >
                Logout
              </Button>
              :
              <Button
                // color="inherit"
                onClick={() => login()}
              >
                Login
              </Button>
            }
          </Toolbar>
        </AppBar>

        <main className={classes.main}>
          <Outlet />
        </main>

        <TheTermsFooter />
      </div>
    </div>
  );
}

export default TheLoginLayout;
