import { Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import BuildingBlocksImage from '../components/BuildingBlocksImage';
import Page from '../components/Page';
import PageActions from '../components/PageActions';
import { showSuccessResultBar } from '../components/ResultSnackbar';
import API from '../services/ApiService';

const useStyles = makeStyles({
  welcomeText: {
    textAlign: 'center',
  },

  featureList: {
    textAlign: 'left',
    display: 'flex',
  },
});

function TheUpgradePage() {
  const classes = useStyles();

  async function handleUpgradeClick() {
    await API.post('org/upgrade', {},
    );

    showSuccessResultBar('Your upgrade request has been recorded. We will contact you soon!');
  }

  return (
    <Page center>
      <BuildingBlocksImage />
      <Typography variant="h6">
        Ready to take the next step in building your security fortress?
      </Typography>
      <div className={classes.featureList}>
        <ul style={{ marginLeft: 'auto', marginRight: 'auto' }}>
          <li>Access 14 policy templates</li>
          <li>Train and monitor your team with limited effort but full insight</li>
          <li>Full dashboard with metrics to help track your progress</li>
          <li>Interact with Jemurai experts for up to 3 hours per month</li>
        </ul>
      </div>
      <PageActions center>
        <Button variant="contained" color="primary" onClick={handleUpgradeClick}>
          Upgrade Now!
        </Button>
      </PageActions>
    </Page>
  );
}

TheUpgradePage.requiredAuthZ = {
  tier: 0,
};
TheUpgradePage.routePath = '/upgrade';
TheUpgradePage.title = 'Upgrade';

export default TheUpgradePage;
