import { AppBar, Button, Link, Theme, Toolbar, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import { Outlet } from 'react-router-dom';
import StylizedName from '../components/StylizedName';
import TheTermsFooter from '../components/TheTermsFooter';
import { StaticLinks } from '../helpers';
import { useOutletPageMetadata } from '../hooks';
import useAuth from '../services/auth/useAuth';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    flexGrow: 1,
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },

  grow: {
    flexGrow: 1,
  },

  main: {
    flex: '1 0 auto',
    padding: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3),
    },
  },

  footer: {
    padding: theme.spacing(2),
    backgroundColor: 'transparent',
    flexShrink: 0,
  },
}));

function TheQuestionnaireLayout() {
  const classes = useStyles();
  const { login } = useAuth();
  const { title } = useOutletPageMetadata();

  const clickLogin = () => {
    login();
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" >
        <Toolbar>
          <Typography
            variant="h6"
            color="inherit"
            className={classes.grow}
          >
            <Link
              color="inherit"
              href={StaticLinks.marketing}
              target="_blank"
            >
              <StylizedName />
            </Link>
            <span style={{ fontWeight: 900 }}>&nbsp;| </span>
            {title ?? ''}
          </Typography>
          <Button
            color="inherit"
            onClick={clickLogin}
          >
            Login
          </Button>
        </Toolbar>
      </AppBar>
      <main className={classes.main}>
        <Outlet />
      </main>
      <footer className={classes.footer}>
        <TheTermsFooter />
      </footer>
    </div>
  );
}

export default TheQuestionnaireLayout;
