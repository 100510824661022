import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Theme,
  Tooltip,
  Typography,
  TypographyProps,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import HelpIcon from '@mui/icons-material/HelpOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import * as Sentry from '@sentry/react';
import classnames from 'classnames';
import React, { useState } from 'react';
import { RiskStatus } from '../../../backend/src/risk/enums';
import { IRiskDto } from '../../../backend/src/risk/interfaces';
import { formatDate } from '../helpers';
import API from '../services/ApiService';
import { STORAGE_KEYS, setStorageValue } from '../services/BrowserStorageService';
import { CloseButton } from './buttons';
import { ArchiveDialog, RiskEditDialog } from './dialogs';
import { HELPER_TEXT, rankMap, riskStatusSelections } from './dialogs/RiskEditDialog';
import { FILTER_VIEW_SELECTIONS } from './FilterViewSelections';
import { showErrorResultBar, showSuccessResultBar } from './ResultSnackbar';
import { MissingDataText, SecondaryField } from './VendorDetailsInfo';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => createStyles({
  buttonLink: {
    'color': 'inherit',
    'textDecoration': 'underline',
    'cursor': 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  cardHeader: {
    paddingBottom: 0,
  },
  dialogCardContent: {
    margin: theme.spacing(2),
    overflowY: 'auto',
  },
  dialogCardRoot: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    margin: '0',
    padding: '0',
  },
  helpIcon: {
    'fontSize': '18px',
    'marginBottom': '-1px',
    'marginLeft': theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  linkItem: {
    '& a': {
      color: theme.typography.body1.color,
      cursor: 'pointer',
    },
    '&:nth-child(n+2)': {
      paddingTop: '4px',
    },
  },
  primaryColumn: {
    color: theme.typography.body1.color,
    fontSize: '14px',
    padding: theme.spacing(2),
  },
  referencesContainer: {
    paddingLeft: '1rem',
    paddingBottom: '0.8rem',
  },
  referenceResponse: {
    color: 'rgba(0,0,0,0.54)',
    whiteSpace: 'pre-line',
  },
  secondaryColumn: {
    backgroundColor: '#f5f5f5',
    opacity: '0.8',
    fontSize: '0.8125rem',
    padding: theme.spacing(2),
  },
}));

const useDetailSectionHeadingStyles = makeStyles({
  referenceHeading: {
    fontSize: '1rem',
  },
});

export function DetailSectionHeading({ children, ...otherTypographyProps }: TypographyProps) {
  const classes = useDetailSectionHeadingStyles();

  return (
    <Typography
      variant="h6"
      className={classes.referenceHeading}
      gutterBottom
      {...otherTypographyProps}
    >
      {children}
    </Typography>
  );
}

export const riskStatusMap: { [key in RiskStatus]: string } = {
  ...riskStatusSelections,
  archived: 'Archived',
};

export interface RiskDetailsProps {
  disableTaskClickThrough?: boolean;
  isDialog?: boolean;
  onArchive: () => void;
  onCloseDialog?: () => void;
  onUpdate: (risk: IRiskDto) => void;
  riskData: IRiskDto;
}

function RiskDetails(props: RiskDetailsProps) {
  const classes = useStyles();
  const {
    disableTaskClickThrough = false,
    isDialog = false,
    onArchive,
    onCloseDialog = () => undefined,
    onUpdate,
    riskData,
  } = props;

  const navigate = useNavigate();
  const [ menuAnchorEl, setMenuAnchorEl ] = useState<null | HTMLElement>(null);
  const [ toShowArchiveDialog, setToShowArchiveDialog ] = useState(false);
  const [ toShowEditDialog, setToShowEditDialog ] = useState(false);

  const handleArchive = async () => {
    try {
      await API.patch(`risk/${riskData.id}`, { status: 'archived' });
      onArchive();
      showSuccessResultBar('Entry archived');
    } catch (err: any) {
      const errMessage = err?.response?.data?.error;
      showErrorResultBar(`Unexpected error while archiving: ${errMessage || 'unspecified error'}`);
      Sentry.captureException(err);
    }
  };

  const handleClickMenuItem = (handler: () => void) => () => {
    handleCloseMenu();
    handler();
  };

  const handleClickTask = (taskId: string) => async () => {
    setStorageValue(STORAGE_KEYS.TASKS_SEARCH, undefined);
    setStorageValue(STORAGE_KEYS.TASKS_VIEW, FILTER_VIEW_SELECTIONS.CUSTOM.value);
    setStorageValue(STORAGE_KEYS.TASKS_FILTERS, { id: [ taskId ] });

    navigate('/tasks');
  };

  const handleCloseArchiveDialog = () => setToShowArchiveDialog(false);

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const handleConfirmArchive = async () => {
    handleArchive();
    handleCloseArchiveDialog();
  };

  return <>
    <Card className={classnames(isDialog && classes.dialogCardRoot)}>
      <CardHeader
        action={<>
          <IconButton
            onClick={({ currentTarget }) => setMenuAnchorEl(currentTarget)}
            size="large">
            <MoreVertIcon />
          </IconButton>
          {isDialog && <CloseButton onClick={onCloseDialog} />}
        </>}
        className={classnames(!isDialog && classes.cardHeader)}
        title={riskData.risk || 'Risk details'}
        subheader={`Status: ${riskStatusMap[riskData.status]}`}
      />
      <Menu
        anchorEl={menuAnchorEl}
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={handleCloseMenu}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={handleClickMenuItem(() => setToShowEditDialog(true))}>
          Edit
        </MenuItem>
        <MenuItem onClick={handleClickMenuItem(() => setToShowArchiveDialog(true))}>
          Archive
        </MenuItem>
      </Menu>
      {isDialog && <Divider />}
      <CardContent className={classnames(isDialog && classes.dialogCardContent)}>
        <Grid container alignItems="flex-start" spacing={2}>
          {/* Primary column */}
          <Grid item xs={12} sm={8} lg={isDialog ? 8 : 9} className={classes.primaryColumn}>
            {/* Details */}
            <DetailSectionHeading>Description</DetailSectionHeading>
            <div className={classes.referencesContainer}>
              <Grid container>
                <Grid item xs={3}>Category:</Grid>
                <Grid item xs={9} className={classes.referenceResponse}>
                  {riskData.category || <MissingDataText message="None specified" />}
                </Grid>
                <Grid item xs={3}>Details:</Grid>
                <Grid item xs={9} className={classes.referenceResponse}>
                  {riskData.details || <MissingDataText message="None" />}
                </Grid>
              </Grid>
            </div>
            {/* Mitigation plan */}
            <DetailSectionHeading>
              Mitigation plan
              <Tooltip title={HELPER_TEXT.mitigationPlan}>
                <HelpIcon color="action" className={classes.helpIcon} />
              </Tooltip>
            </DetailSectionHeading>
            <div className={classes.referencesContainer}>
              {riskData.mitigationPlan ? (
                <span className={classes.referenceResponse}>{riskData.mitigationPlan}</span>
              ) : (
                <MissingDataText message="None" />
              )}
            </div>
            {/* Response plan */}
            <DetailSectionHeading>
              Response plan
              <Tooltip title={HELPER_TEXT.responsePlan}>
                <HelpIcon color="action" className={classes.helpIcon} />
              </Tooltip>
            </DetailSectionHeading>
            <div className={classes.referencesContainer}>
              {riskData.responsePlan ? (
                <span className={classes.referenceResponse}>{riskData.responsePlan}</span>
              ) : (
                <MissingDataText message="None" />
              )}
            </div>
          </Grid>
          {/* Secondary column */}
          <Grid item xs={12} sm={4} lg={isDialog ? 4 : 3} className={classes.secondaryColumn}>
            {/* Lifecyle Dates */}
            <DetailSectionHeading>Lifecyle Dates</DetailSectionHeading>
            <div className={classes.referencesContainer}>
              <SecondaryField
                title="Reported:"
                value={formatDate(riskData.reportedAt) || ''}
              />
              <SecondaryField
                title="Reviewed:"
                value={formatDate(riskData.reviewedAt) || ''}
              />
              <SecondaryField
                title="Status updated:"
                value={formatDate(riskData.statusUpdatedAt) || ''}
              />
              <SecondaryField
                title="Last updated:"
                value={formatDate(riskData.updatedAt) || ''}
              />
            </div>
            {/* Owner */}
            <DetailSectionHeading>Owner</DetailSectionHeading>
            <div className={classes.referencesContainer}>
              {riskData.owner || <MissingDataText message="None specified" />}
            </div>
            {/* Analysis */}
            <DetailSectionHeading>Analysis</DetailSectionHeading>
            <div className={classes.referencesContainer}>
              <SecondaryField
                title="Probability:"
                value={rankMap[riskData.probability].text || rankMap.unknown.text}
              />
              <SecondaryField
                title="Impact:"
                value={rankMap[riskData.impact].text || rankMap.unknown.text}
              />
              <SecondaryField
                title="Risk score"
                value={riskData.score ?? 'None specified'}
              />
            </div>
            {/* Tasks */}
            {riskData.tasks?.length > 0 && (<>
              <DetailSectionHeading>Associated tasks</DetailSectionHeading>
              <div className={classes.referencesContainer}>
                {riskData.tasks.map(task => (
                  <Grid key={task.id} container justifyContent="space-between" wrap="nowrap">
                    <Grid item>
                      {disableTaskClickThrough ? (
                        task.name
                      ) : (
                        <Link
                          className={classes.buttonLink}
                          onClick={handleClickTask(task.id)}
                        >
                          {task.name}
                        </Link>
                      )}
                    </Grid>
                  </Grid>
                ))}
              </div>
            </>)}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
    {/* Edit dialog */}
    <RiskEditDialog
      open={toShowEditDialog}
      riskData={riskData}
      onClose={() => setToShowEditDialog(false)}
      onUpdate={onUpdate}
    />
    {/* Archive dialog */}
    <ArchiveDialog
      open={toShowArchiveDialog}
      onClose={handleCloseArchiveDialog}
      onArchive={handleConfirmArchive}
      title={`Archive the risk '${riskData?.risk}'?`}
    />
  </>;
}

export default RiskDetails;
