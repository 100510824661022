import { Theme, Typography } from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import { TypographyProps } from '@mui/material/Typography';
import classNames from 'classnames';
import React from 'react';

const styles = (theme: Theme) => createStyles({
  partOne: {
    fontSize: 'inherit',
    fontWeight: 900,
    display: 'inline',
  },

  partTwo: {
    color: theme.typography.body1.color,
    fontSize: 'inherit',
    fontWeight: 400,
    display: 'inline',
  },
});

interface StylizedNameProps extends Pick<TypographyProps, 'className' | 'variant'>, WithStyles<typeof styles> {
  partOneContent?: string;
  partTwoContent?: string;
}

function StylizedName({ classes, className, partOneContent, partTwoContent, variant }: StylizedNameProps) {
  return (
    <>
      <Typography component="span" variant={variant} className={classNames(className, classes.partOne)}>{partOneContent ?? 'security'}</Typography>
      <Typography component="span" variant={variant}
                  className={classNames(className, classes.partTwo)}>{partTwoContent ?? 'program.io'}</Typography>
    </>
  );
}

export default withStyles(styles, { name: 'StylizedName', withTheme: true })(StylizedName);
