import { Autocomplete, AutocompleteProps, BaseTextFieldProps, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormikProps, FormikValues } from 'formik';
import React, { useState } from 'react';
import { helperAndErrorText } from '.';

const useStyles = makeStyles({
  inputField: {
    marginRight: 0,
    marginLeft: 0,
  },
});

export interface IOptionLabel {
  label: string
}
export interface FormikAutocompleteTextFieldProps<Values extends FormikValues> extends BaseTextFieldProps {
  autocompleteProps: Omit<AutocompleteProps<string | IOptionLabel, boolean | undefined, boolean | undefined, boolean | undefined>, 'renderInput'>;
  field: keyof Values;
  formikProps: FormikProps<Values>;
  helperTextStr?: string; // can be overridden with the usual 'helperText' prop
}

export default function FormikAutocompleteTextField<Values extends FormikValues>(props: FormikAutocompleteTextFieldProps<Values>) {
  const classes = useStyles();
  const { autocompleteProps, children, field, formikProps, helperTextStr, variant = 'filled', ...otherTextFieldProps } = props;
  const { errors, values, handleChange, handleBlur } = formikProps;
  const fieldStr = field.toString();
  const [ value, setValue ] = useState<string | IOptionLabel | (string | IOptionLabel)[] | null>(values[field]);

  return (
    <Autocomplete
      autoComplete
      openOnFocus
      {...autocompleteProps}
      id={fieldStr}
      value={value}
      onChange={(e, v, r) => {
        formikProps.setFieldValue(fieldStr, v); // handles clearing and selection of an option
        setValue(v); // seems necessary for the option filtering to work
        if (autocompleteProps.onChange) {
          autocompleteProps.onChange(e, v, r);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          className={classes.inputField}
          name={fieldStr}
          onBlur={handleBlur}
          onChange={handleChange} // handles free input
          fullWidth
          variant={variant as any}
          error={!!errors[field]}
          helperText={helperAndErrorText(helperTextStr, errors[field]?.toString())}
          {...otherTextFieldProps}
        >
          {children}
        </TextField>
      )}
    />
  );
}
