import { Button, Card, CardActions, CardContent, Grid, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import PermScanWifi from '@mui/icons-material/PermScanWifi';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import * as Sentry from '@sentry/react';
import moment from 'moment';
import React, { useState } from 'react';
import IDocument from '../../../backend/src/document/document.interface';
import { INetworkScanDto } from '../../../backend/src/network-scan/interfaces';
import { NetworkScanStatus } from '../../../backend/src/network-scan/network-scan-status.enum';
import { FileUploadButtonDialog } from '../components/dialogs';
import NetworkScanStatusIcon from '../components/NetworkScanStatusIcon';
import { showErrorResultBar, showSuccessResultBar } from '../components/ResultSnackbar';
import SpioDataTable, { SpioDataTableColumn } from '../components/SpioDataTable';
import { formatDateTime, truncateString } from '../helpers';
import API from '../services/ApiService';
import * as DocService from '../services/DocService';
import { useEffectAsync } from '../hooks';

const getTableColumns = (tableData: INetworkScanDto[]): SpioDataTableColumn[] => [
  {
    name: 'target.org.name',
    label: 'Org Name',
    options: {
      customFilterListOptions: { render: (v: string) => `Org Name: ${v}` },
    },
  },
  {
    name: 'target.name',
    label: 'Target',
    options: {
      customFilterListOptions: { render: (v: string) => `Target: ${v}` },
    },
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      customBodyRenderLite: (dataIndex) => {
        const { status } = tableData[dataIndex] ?? {};

        return status && <NetworkScanStatusIcon status={status as NetworkScanStatus} />;
      },
      customFilterListOptions: { render: (v: string) => `Status: ${v}` },
    },
  },
  {
    name: 'createdAt',
    label: 'Requested',
    options: {
      customBodyRenderLite: idx =>
        <Tooltip title={formatDateTime(tableData[idx]?.createdAt) ?? ''}>
          <span>{getDateStr(tableData[idx]?.createdAt)}</span>
        </Tooltip>,
      filter: false,
    },
  },
  {
    name: 'startedAt',
    label: 'Started',
    options: {
      customBodyRenderLite: idx =>
        <Tooltip title={formatDateTime(tableData[idx]?.startedAt) ?? ''}>
          <span>{getDateStr(tableData[idx]?.startedAt)}</span>
        </Tooltip>,
      filter: false,
    },
  },
  {
    name: 'target.notify',
    label: 'Notify',
    options: {
      customBodyRenderLite: dataIndex => tableData[dataIndex]?.target?.notify ? <ThumbUpIcon /> : <ThumbDownIcon />,
      customFilterListOptions: { render: (v: string) => `Notify: ${v}` },
    },
  },
];

const getDateStr = (date?: Date) => date ? moment(date).local().fromNow() : '';

interface ScanDetailRowProps {
  data: INetworkScanDto;
  onAddDocument: (newDoc: IDocument) => void;
  onMarkCompleted: React.ReactEventHandler<{}>;
  onMarkStarted: React.ReactEventHandler<{}>;
}

function ScanDetailRow({ data, onAddDocument, onMarkCompleted, onMarkStarted }: ScanDetailRowProps) {
  return (
    <TableRow>
      <TableCell colSpan={7}>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item xs={2}>
                <Typography variant="body1">
                  Addresses:
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Typography variant="body2">
                  {data.target.addresses && data.target.addresses.join(', ')}
                </Typography>
              </Grid>
              {data.documents && data.documents.length > 0 &&
                <>
                  <Grid item xs={2}>
                    <Typography variant="body1">
                      Documents:
                    </Typography>
                  </Grid>
                  <Grid item xs={10}>
                    {data.documents.map((d, i) => (
                      <Tooltip
                        key={i}
                        title={d.name || ''}
                      >
                        <Button
                          size="small"
                          onClick={DocService.documentDownloadHandler(d.id)}
                        >
                          {truncateString(d.name || d.id)}
                        </Button>
                      </Tooltip>
                    ))
                    }
                  </Grid>
                </>
              }
            </Grid>
          </CardContent>

          <CardActions>
            <Button
              disabled={data.status !== 'requested'}
              onClick={onMarkStarted}
            >
              Mark Started
            </Button>
            <Button
              disabled={data.status !== 'started'}
              onClick={onMarkCompleted}
            >
              Mark Complete
            </Button>
            {onAddDocument &&
              <FileUploadButtonDialog
                buttonProps={{ size: 'small' }}
                buttonText="Attach Report"
                documentCategory="report/scan"
                documentOrgId={data.target.orgId}
                onAddDocument={onAddDocument}
              />
            }
          </CardActions>
        </Card>
      </TableCell>
    </TableRow>
  );
}

function TheNetworkScanRequestPage() {
  const [ scanRequests, setScanRequests ] = useState<INetworkScanDto[]>([]);

  useEffectAsync(async () => {
    try {
      const res = await API.get('networkScan/scans');

      setScanRequests(res.data);
    } catch (err) {
      Sentry.captureException(err);
      showErrorResultBar('Error occurred while loading scan requests');
    }
  }, null, []);

  function replaceScanRequest(idx: number, newScanRequest: INetworkScanDto) {
    const newScanRequests = scanRequests.slice();

    newScanRequests[idx] = newScanRequest;
    setScanRequests(newScanRequests);
  }

  function handleMarkCompleted(idx: number) {
    return async () => {
      try {
        const res = await API.patch(`networkScan/scans/${scanRequests[idx].id}`, {
          status: 'completed',
          endedAt: new Date(),
        });

        replaceScanRequest(idx, res.data);
      } catch (err) {
        Sentry.captureException(err);
        showErrorResultBar('Unexpected error occurred while marking scan as completed.');
      }
    };
  }

  function handleMarkStarted(idx: number) {
    return async () => {
      try {
        const res = await API.patch(`networkScan/scans/${scanRequests[idx].id}`, {
          status: 'started',
          startedAt: new Date(),
        });

        replaceScanRequest(idx, res.data);
      } catch (err) {
        Sentry.captureException(err);
        showErrorResultBar('Unexpected error occurred while marking scan as started.');
      }
    };
  }

  function handleAddDocument(idx: number) {
    return async (newDoc: IDocument) => {
      try {
        await API.put(`networkScan/scans/${scanRequests[idx].id}/attach/${newDoc.id}`);
        const newScanRequests = scanRequests.slice();

        if (!newScanRequests[idx].documents) {
          newScanRequests[idx].documents = [];
        }

        newScanRequests[idx]?.documents!.push({ id: newDoc.id, name: newDoc.name || '' });
        setScanRequests(newScanRequests);
        showSuccessResultBar('Document attached successfully.');
      } catch (err: any) {
        showErrorResultBar();
        throw err;
      }
    };
  }

  return (
    <SpioDataTable
      title="Scan Requests"
      columns={getTableColumns(scanRequests)}
      data={scanRequests}
      options={{
        sortOrder: {
          name: 'createdAt',
          direction: 'desc',
        },
        enableNestedDataAccess: '.',
        expandableRows: true,
        expandableRowsOnClick: true,
        renderExpandableRow: (rowData: string[], rowMeta: { dataIndex: number; rowIndex: number }) => {
          const myData = scanRequests[rowMeta.dataIndex];

          return myData ? (
            <ScanDetailRow
              data={myData}
              onAddDocument={handleAddDocument(rowMeta.dataIndex)}
              onMarkCompleted={handleMarkCompleted(rowMeta.dataIndex)}
              onMarkStarted={handleMarkStarted(rowMeta.dataIndex)}
            />
          ) : null;
        },
        selectableRows: 'none',
      }}
    />);
}

TheNetworkScanRequestPage.icon = <PermScanWifi />;
TheNetworkScanRequestPage.requiredAuthZ = {
  tier: 1,
  permission: 'admin:network_scan_requests',
};
TheNetworkScanRequestPage.routePath = '/admin/networkScan/requests';
TheNetworkScanRequestPage.title = 'Scan Requests';

export default TheNetworkScanRequestPage;
