import {
  Box,
  FilledInput,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Paper,
  SxProps,
  Tab,
  Tabs,
  TextField,
  Theme,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import { AllSelection, Selection } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import React, { ChangeEvent, forwardRef, useEffect, useReducer, useRef, useState } from 'react';
import { showErrorResultBar } from '../../ResultSnackbar';
import { createState } from '../lib/createState';
import { getMarkdownParser } from '../lib/markdown/parser';
import { getMarkdownSerializer } from '../lib/markdown/serializer';
import { getMarkdownMenuItems } from '../lib/markdownMenuItems';
import { schema as mySchema } from '../lib/schema';
import { EditorElement } from './EditorElement';
import { LinkEditor } from './LinkEditor';
import { MenuBar } from './MenuBar';

const parser = getMarkdownParser(mySchema);
const serializer = getMarkdownSerializer();

interface EditorInputProps {
  onChange: (v: string) => void;
  value: string;
}

const EditorInput = forwardRef(({ onChange, value }: EditorInputProps, ref) => {
  const contentContainerRef = useRef<HTMLDivElement>(null);

  const [ , forceUpdate ] = useReducer(x => x + 1, 0); // needed b/c the prosemirror state isn't watched by React
  const [ content, setContent ] = useState(value);
  const [ isMarkdownView, setIsMarkdownView ] = useState(false);
  const [ isAddingNewLink, setIsAddingNewLink ] = useState(false);
  const [ selectedTabIdx, setSelectedTabIdx ] = useState(0);
  const [ view ] = useState(
    new EditorView(undefined, {
      state: createState(value),
      dispatchTransaction: (transaction) => {
        const { state: newState } = view.state.applyTransaction(transaction);
        view.updateState(newState);
        forceUpdate();
        view.focus();
        onChange(getMarkdownFromEditor());
      },
    }),
  );

  // Switching b/t Markdown and Editor view (and also the initialization):
  useEffect(() => {
    if (!isMarkdownView) {
      try {
        // The content has changed and we are entering the editor view (from Markdown view or upon initialization).
        const origDoc = view.state.doc;
        const newDoc = parser.parse(content).slice(0); // from the Markdown; this may throw

        // If the newDoc (from the Markdown edits) differs from the origDoc then explicitly replace
        // the origDoc with the newDoc via a transaction, to enter this change into the undo history.
        if (!origDoc.content.eq(newDoc.content)) {
          const selectEntireDoc = new AllSelection(origDoc);
          view.dispatch(view.state.tr.setSelection(selectEntireDoc).replaceSelection(newDoc));
        }

        // Set the cursor to the beginning of the doc.
        const selectBegDoc = Selection.atStart(view.state.doc);
        view.dispatch(view.state.tr.setSelection(selectBegDoc));
      } catch (err: any) {
        showErrorResultBar('Unexpected error when parsing the editor contents');
        Sentry.captureException(err);
      }
    }
  }, [ content, isMarkdownView, view ]);

  const getMarkdownFromEditor = () => {
    return serializer.serialize(view.state.doc);
  };

  const handleMarkdownChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const newContent = e?.target?.value ?? '';
    setContent(newContent);
    onChange(newContent);
  };

  const handleTabChange = (tabIdx: number) => {
    setSelectedTabIdx(tabIdx);
    if (tabIdx === 1) {
      setContent(getMarkdownFromEditor());
      setIsMarkdownView(true);
    } else {
      setIsMarkdownView(false);
    }
  };

  return (
    <Box ref={ref} sx={{ m: 2, width: '100%' }}>
      {/* The tabs and editor bar: */}
      <Grid container>
        <Grid item xs={12} sm={3}>
          <Tabs
            value={selectedTabIdx}
            onChange={(e, tabIdx) => handleTabChange(tabIdx)}
          >
            <Tab label="HTML" />
            <Tab label="Markdown" />
          </Tabs>
        </Grid>
        <Grid item xs={12} sm={9}>
          {!isMarkdownView && (
            <Box sx={{ textAlign: 'right' }}>
              <MenuBar
                menu={getMarkdownMenuItems(mySchema)}
                view={view}
                onAddLink={() => setIsAddingNewLink(true)}
              />
            </Box>
          )}
        </Grid>
      </Grid>
      <Paper ref={contentContainerRef} sx={{ mt: 0, display: selectedTabIdx === 0 ? 'block' : 'none' }}>
        {!isMarkdownView && (
          <LinkEditor
            container={contentContainerRef.current}
            isAddingNewLink={isAddingNewLink}
            onCancelAddNewLink={() => setIsAddingNewLink(false)}
            view={view}
          />
        )}
        <EditorElement view={view} />
      </Paper>
      <Paper sx={{ mt: 0, display: selectedTabIdx === 1 ? 'block' : 'none' }}>
        <TextField
          className="general-md-editor"
          multiline
          onChange={handleMarkdownChange}
          value={content}
          variant="outlined"
          sx={{ m: 0 }}
        />
      </Paper>
    </Box>
  );
});

export interface EditorTextAreaProps {
  helperText?: string;
  label: string;
  onChange: (newContent: string) => void;
  sx?: SxProps<Theme>;
  value: string;
}

export function EditorTextArea({ helperText, label, onChange, sx, value }: EditorTextAreaProps) {
  return (
    <FormControl
      focused
      sx={sx}
      variant="filled"
    >
      <InputLabel htmlFor="my-input">{label}</InputLabel>
      <FilledInput
        id="my-input"
        components={{ Input: EditorInput }}
        componentsProps={{ input: { value } }}
        onChange={e => onChange(e as unknown as string)}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}
