import { Typography } from '@mui/material';
import * as Sentry from '@sentry/browser';
import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_LOGIN_REDIRECT_URL } from '../constants';
import API from '../services/ApiService';
import { STORAGE_KEYS, getStorageValue, setStorageValue } from '../services/BrowserStorageService';
import useAuth from '../services/auth/useAuth';
import { getLoginRoute, LoginRouteHash } from './TheLoginPage';

function TheAuthCallback() {
  const { isGranted, isLoading: isAuthLoading, login, logout, orgId, renewSession } = useAuth();
  const navigate = useNavigate();
  const [ isLoading, setIsLoading ] = useState(true);
  const [ redirectUrl, setRedirectUrl ] = useState<string>(DEFAULT_LOGIN_REDIRECT_URL);

  useEffect(() => {
    const storedRedirect = getStorageValue(STORAGE_KEYS.LOGIN_REDIRECT_URL) || DEFAULT_LOGIN_REDIRECT_URL;
    setStorageValue(STORAGE_KEYS.LOGIN_REDIRECT_URL, undefined);
    setRedirectUrl(storedRedirect);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (isLoading || isAuthLoading) return;

    if (orgId) {
      navigate(redirectUrl);
      return;
    }

    // New user
    if (isGranted({ permission: 'org:create' })) {
      // New user with permission granted to create an org
      // (they've gone through the auto-enroll branch already).
      navigate('/register');
      return;
    } else {
      // Attempt to auto-enroll.
      // If auto-enroll fails, the user will receive permission to create their own org,
      // and the API will respond with a 404. That's handled below.
      // They'll automatically re-login, then the next time around
      // they'll have the permission to go straight to the /register page.
      setIsLoading(true);
      API.post('auth/autoEnroll')
        .then((res) => {
          if (res.status === 201) { // Successful autoEnroll
            return login(redirectUrl);
          } else {
            throw new Error('Login failed');
          }
        })
        .catch((err: AxiosError<any, any>) => {
          // Handle errors from the POST to auth/autoEnroll:
          const status = err.response?.status;

          switch (status) {
            case 404:
              // New users need to be logged in again so they pick up the 'org' permission:
              renewSession().then(() => navigate('/register'));
              break;

            case 401:
              logout(getLoginRoute(LoginRouteHash.AutoEnrollFailed));
              return;

            default:
              Sentry.captureException(err);
              logout(getLoginRoute(LoginRouteHash.GenericFailed));
              return;
          }
        });
    }
  }, [ isAuthLoading, isGranted, isLoading, login, logout, navigate, orgId, redirectUrl, renewSession ]);

  return (
    <Typography variant="body1">
      Loading user profile...
    </Typography>
  );
}

TheAuthCallback.routePath = '/callback';

export default TheAuthCallback;
