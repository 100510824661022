import { createStyles, StyledComponentProps, withStyles } from '@mui/styles';
import React from 'react';
import buildingBlocks from '../images/undraw-building-blocks-n-0-nc.svg';

const styles = createStyles({
  img: {
    width: '80%',
    maxWidth: '480px',
  },
});

function BuildingBlocksImage({ classes }: StyledComponentProps) {
  return (
    <img className={classes!.img} src={buildingBlocks} alt="" />
  );
}

export default withStyles(styles, { name: 'BuildingBlocksImage' })(BuildingBlocksImage);
