import React, { useEffect, useState } from 'react';
import { blueGrey, green, red, yellow } from '@mui/material/colors';
import { Card, CardContent, Step, StepConnector, StepLabel, Stepper, Theme, Tooltip, Typography } from '@mui/material';
import { StepIconProps } from '@mui/material/StepIcon';
import { createStyles, makeStyles, WithStyles, withStyles } from '@mui/styles';
import classNames from 'classnames';
import { capitalize } from 'lodash';
import { Doughnut as DoughnutChart } from 'react-chartjs-2';
import { ITaskStatusCount, ITaskStatusCountByTag } from '../../../../backend/src/task/interfaces';
import { TaskStatus } from '../../../../backend/src/task/task-status.enum';
import { STORAGE_KEYS, setStorageValue } from '../../services/BrowserStorageService';
import { FILTER_VIEW_SELECTIONS, ROUND_TO_ROUND_VIEW } from '../FilterViewSelections';
import WidgetTitle from '../WidgetTitle';
import { useNavigate } from 'react-router-dom';

// This defines the order they appear in the chart:
const STATUSES: TaskStatus[] = [ 'completed', 'in_progress', 'past_due', 'not_started' ];

export interface ITaskStatusChartInfo {
  title: string;
  backgroundColor: string;
  hoverBackgroundColor: string;
  className: 'completedColor' | 'inProgressColor' | 'notStartedColor' | 'pastDueColor';
}

export const STATUS_CHART_INFO_MAP: Record<TaskStatus, ITaskStatusChartInfo> = {
  not_started: {
    title: 'Not Started',
    backgroundColor: blueGrey[100],
    hoverBackgroundColor: blueGrey[300],
    className: 'notStartedColor',
  },
  in_progress: {
    title: 'Started',
    backgroundColor: yellow[500],
    hoverBackgroundColor: yellow[400],
    className: 'inProgressColor',
  },
  past_due: {
    title: 'Past Due',
    backgroundColor: red[500],
    hoverBackgroundColor: red[400],
    className: 'pastDueColor',
  },
  completed: {
    title: 'Completed',
    backgroundColor: green[500],
    hoverBackgroundColor: green[400],
    className: 'completedColor',
  },
};

// The lines (styling + comp't) that connect the 'pie-chart-per-rounds's:
const StyledStepConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      borderColor: green[600],
    },
  },
  completed: {
    '& $line': {
      borderColor: green[600],
    },
  },
  line: {
    borderTopWidth: 3,
    borderColor: blueGrey[100],
    borderRadius: 1,
  },
})(StepConnector);

// The styling for the 'pie-chart-per-round's:
const RoundSummaryStepStyles = makeStyles(createStyles({
  root: {
    backgroundColor: blueGrey[100],
    zIndex: 1,
    width: 50,
    height: 50,
    borderRadius: '50%',
  },
  active: {
    boxShadow: `0 0 4px 4px ${green[900]}`,
  },
  tooltip: {
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
    color: '#fff',
    fontSize: '12px',
    padding: '6px',
  },
  tooltipHeader: {
    fontWeight: 'bold',
    marginBottom: '2px',
  },
  tooltipColorBox: {
    display: 'inline-block',
    width: '12px',
    height: '12px',
    border: '1px solid #fff',
    marginRight: '5px',
  },
  completedColor: {
    backgroundColor: STATUS_CHART_INFO_MAP.completed.backgroundColor,
  },
  inProgressColor: {
    backgroundColor: STATUS_CHART_INFO_MAP.in_progress.backgroundColor,
  },
  notStartedColor: {
    backgroundColor: STATUS_CHART_INFO_MAP.not_started.hoverBackgroundColor,
  },
  pastDueColor: {
    backgroundColor: STATUS_CHART_INFO_MAP.past_due.hoverBackgroundColor,
  },
  tooltipStatusCount: {
    float: 'right',
    marginLeft: '2px',
  },
}));

// The overall styling for the widget ('TaskStatusByRound'):
const styles = (theme: Theme) => createStyles({
  cardContent: {
    'overflowX': 'auto',
    'maxWidth': 'calc(100vw - 64px)',
    '&:last-child': {
      paddingBottom: '12px',
    },
  },
  [theme.breakpoints.down('md')]: {
    hidePaddingDn: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  stepLabel: {
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  roundTitle: {
    whiteSpace: 'nowrap',
  },
  roundDescription: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.65rem',
    lineHeight: '0.85rem',
    marginTop: '8px',
  },
});

const doughnutChartOptions = {
  legend: {
    display: false,
  },
  maintainAspectRatio: false,
  cutoutPercentage: 0,
  tooltips: {
    enabled: false,
  },
};

function RoundSummaryStep({ active, round }: StepIconProps) {
  const classes = RoundSummaryStepStyles();

  const summary = round.data || {};
  const statusesForTooltip = STATUSES.filter(status => summary[status] > 0);
  const statusesForChart = STATUSES;

  const taskData = {
    labels: statusesForChart.map(status => STATUS_CHART_INFO_MAP[status].title),
    width: 50,
    height: 50,
    datasets: [
      {
        data: statusesForChart.map(status => summary[status]),
        backgroundColor: statusesForChart.map(status => STATUS_CHART_INFO_MAP[status].backgroundColor),
        hoverBackgroundColor: statusesForChart.map(status => STATUS_CHART_INFO_MAP[status].hoverBackgroundColor),
        borderWidth: 0,
      },
    ],
  };

  return (
    <Tooltip
      placement="top-end"
      classes={{ tooltip: classes.tooltip }}
      title={
        <>
          <div className={classes.tooltipHeader}>
            {round.title} tasks
          </div>
          {statusesForTooltip.map(status => (
            <div key={status}>
              <div className={classNames(classes.tooltipColorBox, classes[STATUS_CHART_INFO_MAP[status].className])} />
              {STATUS_CHART_INFO_MAP[status].title}:
              <span className={classes.tooltipStatusCount}>
                {summary[status]}
              </span>
            </div>
          ))}
        </>
      }
    >
      <div
        className={classNames(
          classes.root,
          active && classes.active,
        )}
      >
        <DoughnutChart data={taskData} options={doughnutChartOptions} />
      </div>
    </Tooltip>
  );
}

interface IRoundInfo {
  data: IStatusSummary;
  description: string;
  title: string;
}

interface IStatusSummary extends Record<TaskStatus, number> {
  total: number;
  isComplete: boolean;
  isInactive: boolean;
}

const countToNumber = (countRaw?: number | string) => {
  return typeof countRaw === 'string' ? parseInt(countRaw, 10) : (countRaw || 0);
};

const transformData = (data: ITaskStatusCountByTag[]): IRoundInfo[] => (
  data.map((tagData) => {
    const statusSummary: IStatusSummary = {
      total: Object.values(tagData.summary).reduce((netCount, currCount) => netCount + currCount, 0),
      isComplete: false,
      isInactive: false,
      ...tagData.summary
    };

    statusSummary.isInactive = statusSummary.not_started === statusSummary.total;
    statusSummary.isComplete = !!statusSummary.total && statusSummary.completed === statusSummary.total;

    return {
      data: statusSummary,
      description: tagData.tagTitle,
      title: capitalize(tagData.tag),
    };
  })
);

export interface ITaskStatusByRoundProps extends WithStyles<typeof styles> {
  taskOverallSummary: ITaskStatusCount[];
  taskRoundsSummary: ITaskStatusCountByTag[];
}

// The widget component:
function TaskStatusByRound({ classes, taskOverallSummary, taskRoundsSummary }: ITaskStatusByRoundProps) {
  const navigate = useNavigate();
  const [ rounds, setRounds ] = useState<IRoundInfo[]>([]);
  const [ activeRoundIdx, setActiveRoundIdx ] = useState(-1);
  const [ percentComplete, setPercentComplete ] = useState(0);

  // Transform the raw rounds data into something usable here.
  useEffect(() => {
    setRounds(transformData(taskRoundsSummary));
  }, [ taskRoundsSummary ]);

  // Set the active round index.
  // (The first round that is not complete.)
  useEffect(() => {
    const idxRaw = rounds.findIndex(round => !round.data.isComplete);
    const idx = idxRaw === -1 ? rounds.length - 1 : idxRaw;

    setActiveRoundIdx(idx);
  }, [ rounds ]);

  // Calculate the overall percent complete.
  // TODO: Move totaling calculations to server/db?
  useEffect(() => {
    const complete = taskOverallSummary.find(d => d.status === 'completed');
    const nbComplete = countToNumber(complete?.count);
    const nbTotal = taskOverallSummary.reduce((sum, curr) => sum + countToNumber(curr.count), 0);
    const percent = nbTotal === 0 ? 0 : Math.round(100. * nbComplete / nbTotal);

    setPercentComplete(percent);
  }, [ taskOverallSummary ]);

  // Navigate to the tasks page, setting the filter appropriately based on which round was clicked.
  const onClickRound = (roundIdx: number) => () => {
    const roundNb = roundIdx + 1;
    const filterView = ROUND_TO_ROUND_VIEW[roundNb];
    const filterViewValue = FILTER_VIEW_SELECTIONS[filterView]?.value;

    if (filterViewValue) {
      setStorageValue(STORAGE_KEYS.TASKS_SEARCH, undefined);
      setStorageValue(STORAGE_KEYS.TASKS_VIEW, filterViewValue);
    }

    navigate('/tasks');
  };

  return (
    <Card>
      <WidgetTitle to="/tasks">
        Overall: {percentComplete}% complete
      </WidgetTitle>
      <CardContent
        className={classes.cardContent}
      >
        <Stepper
          activeStep={activeRoundIdx}
          alternativeLabel
          connector={<StyledStepConnector />}
          className={classes.hidePaddingDn}
        >
          {rounds.map((round, idx) => (
            <Step key={round.title}>
              <StepLabel
                StepIconComponent={RoundSummaryStep}
                StepIconProps={{ round }}
                onClick={onClickRound(idx)}
                className={classes.stepLabel}
              >
                <div className={classes.roundTitle}>{round.title}</div>
                <Typography
                  className={classes.roundDescription}
                  variant="caption"
                >
                  {round.description}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </CardContent>
    </Card>
  );
}

TaskStatusByRound.requiredAuthZ = {
  tier: 1,
  permission: 'tasks',
};

export default withStyles(styles, { withTheme: true })(TaskStatusByRound);
