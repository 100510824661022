import { Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import * as Sentry from '@sentry/react';
import { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { ITaskDto, ITasksResponse } from '../../../backend/src/task/interfaces';
import { showErrorResultBar, showSuccessResultBar } from '../components/ResultSnackbar';
import TaskCards from '../components/TaskCards';
import TaskTable from '../components/TaskTable';
import API from '../services/ApiService';

const useStyles = makeStyles((theme: Theme) => createStyles({
  viewLink: {
    color: theme.palette.text.primary,
    paddingTop: '1rem',
    paddingBottom: '1rem',
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

function TheTasksPage() {
  const classes = useStyles();
  const params = useParams<{ view: 'table' | 'cards' }>();
  const view = params.view || 'table';
  const [ data, setData ] = useState<ITaskDto[]>([]);
  const [ assignees, setAssignees ] = useState([]);
  const [ isLoadingTasks, setIsLoadingTasks ] = useState(false);

  useEffect(() => {
    setIsLoadingTasks(true);

    API
      .get('task')
      .then(res => {
        setData(res.data.data);
      })
      .catch((err) => {
        showErrorResultBar('Unexpected error loading tasks');
        Sentry.captureException(err);
      })
      .finally(() => {
        setIsLoadingTasks(false);
      });

    API
      .get('task/allowedAssignees')
      .then(res => {
        setAssignees(res.data.data);
      })
      .catch(Sentry.captureException);
  }, []);

  const handleDueDateChanged = (idx: number) => async (newDueDate: Moment | null) => {
    try {
      const res = await API.put(`task/${data[idx].id}`, { dueDate: newDueDate });

      const dataCopy = data.slice();

      dataCopy[idx] = res.data.data;
      setData(dataCopy);
      showSuccessResultBar('Due date updated successfully.');
    } catch (err: any) {
      Sentry.captureException(err);
      showErrorResultBar('Unexpected error while updating the due date');
    }
  };

  const handleCompletedDateChanged = (idx: number) => async (newCompletedDate: Moment | null) => {
    try {
      const res = await API.put(`task/${data[idx].id}`, { completedAt: newCompletedDate });

      const dataCopy = data.slice();

      dataCopy[idx] = res.data.data;
      setData(dataCopy);
      showSuccessResultBar('Completed date updated successfully.');
    } catch (err: any) {
      Sentry.captureException(err);
      showErrorResultBar('Unexpected error while updating the completed date');
    }
  };

  const markStarted = (dataIdx: number) => async () => {
    const res = await API.post(`task/${data[dataIdx].id}/start`);

    if (res.status === 200) {
      const dataCopy = data.slice();
      dataCopy[dataIdx] = res.data.data;
      setData(dataCopy);
    }
  };

  const markComplete = (dataIdx: number) => async () => {
    const res = await API.post(`task/${data[dataIdx].id}/complete`);

    if (res.status === 200) {
      const dataCopy = data.slice();
      dataCopy[dataIdx] = res.data.data;
      setData(dataCopy);
    }
  };

  const handleUpdateTasks = async (idxs: number[], updatedTasksInfo: ITasksResponse) => {
    const dataCopy = data.slice();
    idxs.forEach((idx: number) => {
      dataCopy[idx] = {
        ...dataCopy[idx],
        ...updatedTasksInfo,
      };
    });
    setData(dataCopy);
  };

  const tasksProps = {
    assignees,
    tasks: data,
    handleDueDateChanged,
    handleCompletedDateChanged,
    isLoadingTasks,
    markComplete,
    markStarted,
    onUpdateTasks: handleUpdateTasks,
  };

  return (
    <>
      {view === 'cards' ? <TaskCards {...tasksProps} /> : <TaskTable {...tasksProps} />}
      <footer>
        <Typography variant="caption">
          <RouterLink to={view === 'cards' ? '/tasks/table' : '/tasks/cards'} className={classes.viewLink}>
            {view === 'cards' ? 'View as Table' : 'View as Cards'}
          </RouterLink>
        </Typography>
      </footer>
    </>
  );
}

TheTasksPage.requiredAuthZ = {
  tier: 1,
  permission: 'tasks',
};
TheTasksPage.routePath = '/tasks';
TheTasksPage.routePathWithParam = '/tasks/:view';
TheTasksPage.title = 'Tasks';

export default TheTasksPage;
