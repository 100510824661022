import { Button, DialogActions, DialogContent, DialogProps, FormGroup, TextField } from '@mui/material';
import * as Sentry from '@sentry/react';
import React, { useEffect, useState } from 'react';
import Interval from '../../../../backend/src/common/interval.type';
import { ITagDto } from '../../../../backend/src/tag/interfaces';
import { ITaskMaster } from '../../../../backend/src/task/interfaces/task-master.interface';
import API from '../../services/ApiService';
import SaveButton from '../buttons/SaveButton';
import { EditorTextArea } from '../editor/components/EditorTextArea';
import IntervalField from '../IntervalField';
import { showErrorResultBar, showSuccessResultBar } from '../ResultSnackbar';
import StyledDialogTitle from '../StyledDialogTitle';
import TagsInput from '../TagsInput';
import ResponsiveDialog from './ResponsiveDialog';

interface IFormValues {
  defaultDuration?: Interval;
  description?: string;
  name?: string;
  progression?: number;
  recurrence?: Interval;
  tags?: ITagDto[];
}

export interface TaskMasterEditDialogProps extends DialogProps {
  onClose: () => void;
  onUpdate: (taskMaster: ITaskMaster) => void;
  tags: ITagDto[];
  taskMaster: ITaskMaster | null;
}

export default function TaskMasterEditDialog({ onClose, onUpdate, open, tags, taskMaster }: TaskMasterEditDialogProps) {
  const [ saveAllowed, setSaveAllowed ] = useState(true);
  const [ formValues, setFormValues ] = useState<IFormValues>({});

  useEffect(() => {
    setFormValues(taskMaster ?? {});
  }, [ taskMaster ]);

  useEffect(() => {
    const { name, defaultDuration } = formValues;

    if (!name || !defaultDuration || (!defaultDuration.years && !defaultDuration.months && !defaultDuration.days)) {
      setSaveAllowed(false);
    } else {
      setSaveAllowed(true);
    }
  }, [ formValues ]);

  const handleSave = async () => {
    try {
      const res = taskMaster === null ?
        await API.post('taskMaster', formValues) :
        await API.patch(`taskMaster/${taskMaster.id}`, formValues);
      onUpdate(res.data);
      showSuccessResultBar('Task Master saved successfully');

      if (taskMaster === null) {
        onClose();
      }
    } catch (err: any) {
      const errorMsg = err.response?.data?.error ?? 'Unexpected error saving Task Master data';
      showErrorResultBar(errorMsg);
      Sentry.captureException(err);
    }
  };

  const handleChange = (key: keyof IFormValues) => (newValue: string | Interval | ITagDto[] = '') => {
    const newTaskMaster: IFormValues = formValues || {};

    switch (key) {
      case 'name':
        newTaskMaster.name = newValue as string;
        break;

      case 'description':
        newTaskMaster.description = newValue as string;
        break;

      case 'progression':
        const progression = Number(newValue as string);

        if (!isNaN(progression)) {
          newTaskMaster.progression = progression;
        }

        break;

      case 'defaultDuration':
        newTaskMaster.defaultDuration = newValue as Interval;
        break;

      case 'recurrence':
        newTaskMaster.recurrence = newValue as Interval;
        break;

      case 'tags':
        newTaskMaster.tags = newValue as ITagDto[];
        break;

      default:
        console.error(`Unexpected interval key: ${key}`);
    }

    setFormValues({ ...newTaskMaster });
  };

  return (
    <ResponsiveDialog
      disableBackdropClick={true}
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={onClose}
    >
      <StyledDialogTitle onClose={onClose}>
        {taskMaster === null ? 'Add New' : 'Update Existing'} Task Master
      </StyledDialogTitle>
      <DialogContent>
        <FormGroup>
          <TextField
            id="name"
            name="name"
            label="Task Name"
            value={formValues.name || ''}
            onChange={(e) => handleChange('name')(e?.target?.value)}
            margin="normal"
            variant="filled"
            helperText="Name of the task"
            required={true}
          />
          <EditorTextArea
            helperText="Description, instructions, etc. (Supports Markdown.)"
            label="Description"
            onChange={handleChange('description')}
            value={formValues.description || ''}
          />
          <TextField
            id="progression"
            name="progression"
            label="Priority"
            value={formValues.progression || ''}
            onChange={(e) => handleChange('progression')(e?.target?.value)}
            margin="normal"
            variant="filled"
            helperText="When tasks appear and default sort order"
          />
          <TagsInput
            suggestions={tags}
            value={formValues.tags || []}
            onChange={handleChange('tags')}
          />
          <IntervalField
            name="defaultDuration"
            variant="filled"
            value={formValues.defaultDuration}
            label="Default duration *"
            onChange={handleChange('defaultDuration')}
          />
          <IntervalField
            name="recurrence"
            variant="filled"
            value={formValues.recurrence}
            label="Interval on which this task should be repeated"
            onChange={handleChange('recurrence')}
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="primary"
        >
          {taskMaster === null ? 'Cancel' : 'Close'}
        </Button>
        <SaveButton
          disabled={!saveAllowed}
          onClick={handleSave}
        />
      </DialogActions>
    </ResponsiveDialog>
  );
}
