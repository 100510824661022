import { Button, DialogActions, DialogContent, DialogContentText, DialogProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as Sentry from '@sentry/react';
import React, { useState } from 'react';
import { IIdNameDto } from '../../../../backend/src/common/id-name-dto.interface';
import API from '../../services/ApiService';
import { showErrorResultBar } from '../ResultSnackbar';
import StyledDialogTitle from '../StyledDialogTitle';
import ResponsiveDialog from './ResponsiveDialog';

const useStyles = makeStyles({
  cancelButton: {
    marginRight: 'auto',
  },
  dialogContent: {
    minWidth: '400px',
  },
});

export interface RemoveRiskFromTaskDialogProps extends DialogProps {
  risk: IIdNameDto;
  task: IIdNameDto;
  onClose: () => void;
  onExited: () => void;
  onRemoveRisk: () => void;
}

export default function RemoveRiskFromTaskDialog(props: RemoveRiskFromTaskDialogProps) {
  const classes = useStyles();
  const { risk, task, onClose, onExited, onRemoveRisk, open } = props;
  const [ isSubmitting, setIsSubmitting ] = useState<boolean>(false);

  const handleArchive = async () => {
    try {
      await API.patch(`risk/${risk.id}`, { status: 'archived' });
    } catch (err: any) {
      const errMessage = err.response && err.response.data && err.response.data.error;
      showErrorResultBar(`Unexpected error while archiving the risk: ${errMessage || 'unspecified error'}`);
      Sentry.captureException(err);
    }
  };

  const handleRemove = async () => {
    try {
      await API.delete(`task/${task.id}/risk/${risk.id}`);
    } catch (err: any) {
      const errMessage = err.response && err.response.data && err.response.data.error;
      showErrorResultBar(`Unexpected error while removing the risk: ${errMessage || 'unspecified error'}`);
      Sentry.captureException(err);
    }
  };

  const handleRemoveAndConditionallyArchive = (toArchive: boolean) => async () => {
    setIsSubmitting(true);
    await handleRemove();
    if (toArchive) {
      await handleArchive();
    }
    onRemoveRisk();
    setIsSubmitting(false);
  };

  return (
    <ResponsiveDialog
      disableBackdropClick={true}
      open={open}
      onClose={onClose}
      TransitionProps={{ onExited }}
    >
      <StyledDialogTitle
        onClose={onClose}
      >
        Remove this risk?
      </StyledDialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText>
          The risk '{risk.name}' will be unlinked from this task.
          In addition, do you wish to archive this risk?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.cancelButton}
          color="primary"
          disabled={isSubmitting}
          onClick={onClose}
          size="small"
        >
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={isSubmitting}
          onClick={handleRemoveAndConditionallyArchive(false)}
          size="small"
        >
          Remove only
        </Button>
        <Button
          color="primary"
          disabled={isSubmitting}
          onClick={handleRemoveAndConditionallyArchive(true)}
          size="small"
        >
          Remove and archive
        </Button>
      </DialogActions>
    </ResponsiveDialog>
  );
}
