import {
  Card,
  createTheme,
  DialogContent,
  StyledEngineProvider,
  Tab,
  TabProps,
  Tabs,
  Theme,
  ThemeProvider,
} from '@mui/material';
import { createStyles, makeStyles, withStyles } from '@mui/styles';
import DoneIcon from '@mui/icons-material/Done';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import * as Sentry from '@sentry/react';
import * as PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { UNASSIGNED_TASK_STR } from '../TaskTable';
import API from '../../services/ApiService';
import useAuth from '../../services/auth/useAuth';
import { STORAGE_KEYS, getStorageValue, setStorageValue } from '../../services/BrowserStorageService';
import { ResponsiveDialog } from '../dialogs';
import { FILTER_VIEW_SELECTIONS, OPEN_TASKS_FILTERS } from '../FilterViewSelections';
import { showErrorResultBar, showSuccessResultBar } from '../ResultSnackbar';
import SpioDataTable, { SpioDataTableColumn } from '../SpioDataTable';
import TaskDetails from '../TaskDetails';
import WidgetTitle from '../WidgetTitle';
import { ITaskDto, ITasksResponse } from '../../../../backend/src/task/interfaces';
import { Moment } from 'moment';
import { useNavigate } from 'react-router-dom';

const datatableTheme = () => createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          paddingLeft: '0.5rem',
          paddingRight: '0.5rem',
        },
      },
    },
  },
});

const useStyles = makeStyles({
  dialogContent: {
    padding: '0',
    overflowY: 'hidden',
    '&:first-child': {
      paddingTop: '0',
    },
  },
  tableContainer: {
    marginLeft: '1rem',
    marginBottom: '1rem',
    minHeight: '200px',
  },
  tabsContainer: {
    marginTop: '-5px',
  },
});

const StyledTab = withStyles((theme: Theme) => createStyles({
  root: {
    fontSize: '1.25rem',
    fontWeight: 400,
    letterSpacing: '0.0075em',
    paddingTop: '0',
    textTransform: 'none',
  },
  selected: {
    fontWeight: 500,
  },
}))((props: TabProps) => <Tab {...props} />);

const statusMap = {
  not_started: {
    icon: <NewReleasesIcon />,
    text: 'Not Started',
  },
  completed: {
    icon: <DoneIcon />,
    text: 'Completed',
  },
  past_due: {
    icon: <PriorityHighIcon />,
    text: 'Past Due',
  },
  in_progress: {
    icon: <TimelapseIcon />,
    text: 'In Progress',
  },
};

const tableHeaders: SpioDataTableColumn[] = [
  {
    name: 'status',
    label: 'Status',
    options: {
      customBodyRender: (value: string) => Object?.values(statusMap)?.find(m => m.text === value)?.icon,
    },
  },
  {
    name: 'name',
    label: 'Task',
  },
  {
    name: 'dueDate',
    label: 'Due',
  },
];

const getInitialSelectedTab = (): number => {
  const rawTab = getStorageValue(STORAGE_KEYS.DASHBOARD_TOP_TASKS_TAB) ?? 0;

  return (rawTab === 0 || rawTab === 1) ? rawTab : 0;
};

export interface UpNextTasksProps {
  isLoading: boolean;
  onUpdateAssignment: () => void;
  onUpdateStatus: () => void;
  setTasks: (tasks: ITaskDto[]) => void;
  tasks: ITaskDto[];
}

function UpNextTasks({ isLoading, onUpdateAssignment, onUpdateStatus, setTasks, tasks }: UpNextTasksProps) {
  const classes = useStyles();
  const { userId } = useAuth();

  const navigate = useNavigate();
  const [ tasksToDisplay, setTasksToDisplay ] = useState<ITaskDto[]>([]);
  const [ assignees, setAssignees ] = useState([]);
  const [ selectedIdx, setSelectedIdx ] = useState(0);
  const [ isDetailsOpen, setIsDetailsOpen ] = useState(false);
  const [ selectedTab, setSelectedTab ] = useState<number>(getInitialSelectedTab());

  useEffect(() => {
    API
      .get('task/allowedAssignees')
      .then(res => {
        setAssignees(res.data.data);
      })
      .catch(Sentry.captureException);
  }, []);

  useEffect(() => {
    const desiredAssigneeId = selectedTab === 0 ? userId : null;
    setTasksToDisplay(tasks.filter(t => t.assigneeId === desiredAssigneeId));
  }, [ selectedTab, tasks, userId ]);

  // Update the cached tab selection:
  useEffect(() => {
    setStorageValue(STORAGE_KEYS.DASHBOARD_TOP_TASKS_TAB, selectedTab);
  }, [ selectedTab ]);

  const markComplete = async () => {
    const res = await API.post(`task/${tasksToDisplay[selectedIdx].id}/complete`);

    if (res.status === 200) {
      onUpdateStatus();
      setIsDetailsOpen(false);
    }
  };

  const markStarted = async () => {
    const res = await API.post(`task/${tasksToDisplay[selectedIdx].id}/start`);

    if (res.status === 200) {
      onUpdateStatus();
      setIsDetailsOpen(false);
    }
  };

  const handleCompletedDateChanged = async (newCompletedDate: Moment | null) => {
    try {
      const taskId = tasksToDisplay[selectedIdx].id;
      const res = await API.put(`task/${taskId}`, { completedAt: newCompletedDate });

      const taskIdx = tasks.findIndex(task => task.id === taskId);
      if (taskIdx !== -1) {
        const updatedTasks = tasks.slice();
        updatedTasks[taskIdx].dueDate = res.data.data.dueDate;
        setTasks(updatedTasks);
      }

      showSuccessResultBar('Completed date updated successfully.');
    } catch (err: any) {
      Sentry.captureException(err);
      showErrorResultBar('Unexpected error while updating the completed date');
    }
  };

  const handleDueDateChanged = async (newDueDate: Moment | null) => {
    // TODO: Consider making this a full reload (but leave dialog open?) since adding a due date will usually change the 'top tasks'
    try {
      const taskId = tasksToDisplay[selectedIdx].id;
      const res = await API.put(`task/${taskId}`, { dueDate: newDueDate });

      const taskIdx = tasks.findIndex(task => task.id === taskId);
      if (taskIdx !== -1) {
        const updatedTasks = tasks.slice();
        updatedTasks[taskIdx].dueDate = res.data.data.dueDate;
        setTasks(updatedTasks);
      }

      showSuccessResultBar('Due date updated successfully.');
    } catch (err: any) {
      Sentry.captureException(err);
      showErrorResultBar('Unexpected error while updating the due date');
    }
  };

  const handleUpdateTask = (updatedTaskInfo: ITasksResponse) => {
    // Reassignment requires a reload of the Up Next tasks; adding comments/docs does not.

    if (updatedTaskInfo.assigneeId !== undefined) {
      onUpdateAssignment();
      setIsDetailsOpen(false);
    } else {
      const taskIdx = tasks.findIndex(task => task.id === tasksToDisplay[selectedIdx].id);
      if (taskIdx !== -1) {
        const updatedTasks = tasks.slice();
        updatedTasks[taskIdx] = {
          ...updatedTasks[taskIdx],
          ...updatedTaskInfo,
        };
        setTasks(updatedTasks);
      }
    }
  };

  const openDetails = (idx: number) => {
    setSelectedIdx(idx);
    setIsDetailsOpen(true);
  };

  const onClose = () => setIsDetailsOpen(false);

  const handleClickTitle = (e: React.MouseEvent) => {
    e.preventDefault();
    setStorageValue(STORAGE_KEYS.TASKS_SEARCH, undefined);

    if (selectedTab === 0) {
      setStorageValue(STORAGE_KEYS.TASKS_VIEW, FILTER_VIEW_SELECTIONS.MY_OPEN_TASKS.value);
      setStorageValue(STORAGE_KEYS.TASKS_FILTERS, {});
    } else {
      setStorageValue(STORAGE_KEYS.TASKS_VIEW, FILTER_VIEW_SELECTIONS.CUSTOM.value);
      setStorageValue(STORAGE_KEYS.TASKS_FILTERS, {
        statusStr: OPEN_TASKS_FILTERS,
        assigneeName: [ UNASSIGNED_TASK_STR ],
      });
    }

    navigate('/tasks');
  };

  return (
    <Card>
      <Tabs
        className={classes.tabsContainer}
        value={selectedTab}
        onChange={(_, value) => setSelectedTab(value)}
      >
        <StyledTab label="My Top Tasks" />
        <StyledTab label="Top Unassigned Tasks" />
      </Tabs>
      <div className={classes.tableContainer}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={datatableTheme()}>
            <SpioDataTable
              title=""
              data={tasksToDisplay.map(t => Object({
                ...t,
                status: statusMap[t.status].text,
              }))}
              columns={tableHeaders}
              options={{
                download: false,
                elevation: 0,
                filter: false,
                pagination: false,
                print: false,
                search: false,
                selectableRows: 'none',
                sort: false,
                viewColumns: false,
                textLabels: {
                  body: {
                    noMatch: isLoading ?
                      'Loading tasks...' :
                      (selectedTab === 0 ? 'No upcoming tasks assigned to me' : 'No unassigned upcoming tasks'),
                  },
                },
                onRowClick: (_, rowMeta) => openDetails(rowMeta.dataIndex),
              }}
            />
          </ThemeProvider>
        </StyledEngineProvider>
      </div>
      <WidgetTitle
        onClick={handleClickTitle}
        to="/tasks"
        variant="subtitle1"
      >
        See all {selectedTab === 0 ? 'of my' : 'unassigned'} tasks
      </WidgetTitle>
      <ResponsiveDialog
        fullWidth
        maxWidth="md"
        onClose={onClose}
        open={isDetailsOpen}
      >
        <DialogContent
          className={classes.dialogContent}
        >
          <TaskDetails
            assignees={assignees}
            isDialog
            markComplete={markComplete}
            markStarted={markStarted}
            onCloseDialog={onClose}
            onCompletedDateChange={handleCompletedDateChanged}
            onDueDateChange={handleDueDateChanged}
            onUpdateTask={handleUpdateTask}
            taskData={tasksToDisplay[selectedIdx]}
          />
        </DialogContent>
      </ResponsiveDialog>
    </Card>
  );
}

UpNextTasks.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onUpdateAssignment: PropTypes.func,
  onUpdateStatus: PropTypes.func,
  setTasks: PropTypes.func,
  tasks: PropTypes.array.isRequired,
};

UpNextTasks.defaultProps = {
  onUpdateAssignment: Function,
  onUpdateStatus: Function,
  setTasks: Function,
};

UpNextTasks.requiredAuthZ = {
  tier: 1,
  permission: 'tasks',
};

export default UpNextTasks;
