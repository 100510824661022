import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import { CLIENT_ORIGIN } from '../../constants';
import AuthProvider from './authProvider';

export interface AppAuthProviderProps {
  children: React.ReactNode;
}

const AppAuthProvider = ({ children }: AppAuthProviderProps) => (
  <Auth0Provider
    domain={`${process.env.REACT_APP_AUTH_DOMAIN}`}
    audience={`${process.env.REACT_APP_AUTH_API_IDENTIFIER}`}
    clientId={`${process.env.REACT_APP_AUTH_CLIENT_ID}`}
    redirectUri={`${CLIENT_ORIGIN}${process.env.REACT_APP_ROUTER_BASENAME || ''}/callback`}
    responseType="token id_token"
    scope="openid profile email"
    skipRedirectCallback={window.location.pathname !== '/callback'}
    useRefreshTokens={true}
  >
    <AuthProvider>
      {children}
    </AuthProvider>
  </Auth0Provider>
);

export default AppAuthProvider;
