import React, { useEffect, useState } from 'react';
import { Card } from '@mui/material';
import * as Sentry from '@sentry/react';
import { ICoverageReportWidgetDto } from '../../../../backend/src/user/interfaces';
import { showErrorResultBar } from '../ResultSnackbar';
import API from '../../services/ApiService';
import WidgetTitle from '../WidgetTitle';
import { HorizontalBarCoverageChart } from '.';

function TrainingCoverageChart() {
  const [ chartData, setChartData ] = useState<ICoverageReportWidgetDto>({ total: 0, data: [] });

  useEffect(() => {
    API.get('training/coverage')
      .then(res => setChartData(res.data?.data ?? { total: 0, data: [] }))
      .catch((err) => {
        showErrorResultBar('Unexpected error loading Training Coverage data');
        Sentry.captureException(err);
      });
  }, [ ]);

  return (
    <Card>
      <WidgetTitle to="/training">Training Coverage</WidgetTitle>
      <HorizontalBarCoverageChart data={chartData} />
      <WidgetTitle
        to="/training/report"
        variant="subtitle1"
      >
        See detailed report
      </WidgetTitle>
    </Card>
  );
}

TrainingCoverageChart.requiredAuthZ = {
  tier: 1,
  permission: 'training:manage',
};

export default TrainingCoverageChart;
