import { Button, Link, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as Sentry from '@sentry/react';
import React, { useState } from 'react';
import { ITrackViewStart, ITrainingViewDto, ITrainingViewUpdateDto } from '../../../backend/src/training/interfaces';
import { Link as RouterLink } from 'react-router-dom';
import BuildingBlocksImage from '../components/BuildingBlocksImage';
import { VideoDialog } from '../components/dialogs';
import Page from '../components/Page';
import PageActions from '../components/PageActions';
import { showErrorResultBar } from '../components/ResultSnackbar';
import StylizedName from '../components/StylizedName';
import { DEFAULT_SUPPORT_EMAIL, StaticLinks, TrainingVids } from '../helpers';
import API from '../services/ApiService';
import useAuth from '../services/auth/useAuth';

const useStyles = makeStyles({
  welcomeText: {
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '1.5rem',
    maxWidth: '40rem',
    paddingLeft: '1em',
    paddingRight: '1em',
  },
  secondButton: {
    marginLeft: '1rem',
  },
});

const OrgManagerWelcome = () => {
  const classes = useStyles();
  const [ videoPlayerOpen, setVideoPlayerOpen ] = useState(false);
  const [ videoUrl, setVideoUrl ] = useState<string | null>(null);
  const [ videoView, setVideoView ] = useState<ITrainingViewDto | null>(null);

  const handleStartVideo = async () => {
    const trackViewInfo: ITrackViewStart = (await API.post(`training/${TrainingVids.WelcomeToSPIO}/view`))?.data?.data;

    if (trackViewInfo) {
      setVideoUrl(trackViewInfo.url);
      setVideoView(trackViewInfo.view);
      setVideoPlayerOpen(true);
    }
  };

  const handleUpdateVideo = async (updateInfo: ITrainingViewUpdateDto) => {
    try {
      if (videoView) {
        await API.patch(`training/view/${videoView.id}`, updateInfo);
      }
    } catch (err: any) {
      Sentry.captureException(err);
    }
  };

  const handleCloseVideo = () => {
    setVideoPlayerOpen(false);
    setVideoUrl(null);
    setVideoView(null);
  };

  // Handle the case where the video link expires and we need to reset:
  const handlePlaybackError = (error: any) => {
    Sentry.captureException(error);
    handleStartVideo();
  };

  return (
    <>
      <Typography
        className={classes.welcomeText}
        paragraph
        variant="body1"
      >
        Watch the intro video below to find out how to get started.
      </Typography>
      <Button
        variant="contained"
        onClick={handleStartVideo}
      >
        Play Intro Video
      </Button>
      {videoUrl && videoView &&
        <VideoDialog
          open={videoPlayerOpen}
          videoViewInfo={{
            name: 'Welcome to SecurityProgram.io!',
            played: videoView.played,
            url: videoUrl,
          }}
          onClose={handleCloseVideo}
          onError={handlePlaybackError}
          onUpdateVideo={handleUpdateVideo}
        />
      }
    </>
  );
};

const OrgTraineeWelcome = () => {
  const classes = useStyles();

  return (
    <>
      <Typography
        className={classes.welcomeText}
        paragraph
        variant="body1"
      >
        Navigate to the <Link
        component={RouterLink}
        to="/training"
        variant="body1"
      >
        Training
      </Link> tab to access your training videos.
        <br />
        Or click the button below to start the Security Awareness video.
      </Typography>
      <PageActions center>
        <Button
          component={RouterLink}
          to={`/training/${TrainingVids.SecurityAwareness}`}
          variant="contained"
        >
          Play Training Video
        </Button>
      </PageActions>
    </>
  );
};

const OnDemandWelcome = () => {
  const { isGranted } = useAuth();
  const classes = useStyles();
  const [ videoPlayerOpen, setVideoPlayerOpen ] = useState(false);
  const [ videoUrl, setVideoUrl ] = useState<string | null>(null);
  const [ videoView, setVideoView ] = useState<ITrainingViewDto | null>(null);

  // Required to access the 'Request Demo' endpoint:
  const hasOrgPermission = isGranted({ permission: 'org' });

  const handleRequestDemo = async () => {
    try {
      hasOrgPermission && (await API.post('org/requestDemo'));
    } catch (err: any) {
      Sentry.captureException(err);
      showErrorResultBar('Something went wrong requesting a demo. Please try again later');
    }
  };

  const handleStartVideo = async () => {
    // This is the same video the program managers see but it's under a diff't id to allow 'On Demand' users to see it:
    const trackViewInfo: ITrackViewStart = (await API.post(`training/${TrainingVids.IntroToSPIO}/view`))?.data?.data;

    if (trackViewInfo) {
      setVideoUrl(trackViewInfo.url);
      setVideoView(trackViewInfo.view);
      setVideoPlayerOpen(true);
    }
  };

  const handleUpdateVideo = async (updateInfo: ITrainingViewUpdateDto) => {
    try {
      if (videoView) {
        await API.patch(`training/view/${videoView.id}`, updateInfo);
      }
    } catch (err: any) {
      Sentry.captureException(err);
    }
  };

  const handleCloseVideo = () => {
    setVideoPlayerOpen(false);
    setVideoUrl(null);
    setVideoView(null);
  };

  // Handle the case where the video link expires and we need to reset:
  const handlePlaybackError = (error: any) => {
    Sentry.captureException(error);
    handleStartVideo();
  };

  return (
    <>
      <Typography
        className={classes.welcomeText}
        paragraph
        variant="body1"
      >
        We help startups and small to medium-size businesses affordably build
        robust security programs without having to hire a CISO.
      </Typography>
      <Typography
        className={classes.welcomeText}
        paragraph
        variant="body1"
      >
        Currently we do not offer a free tier.
        Please schedule a demo below for a walk-through and to learn more about <StylizedName />.
        We will get back to you asap.
      </Typography>
      <PageActions center>
        <Button
          variant="contained"
          onClick={handleStartVideo}
        >
          Play Intro Video
        </Button>
        <Link
          href={StaticLinks.scheduleDemo}
          target="_blank"
          rel="noreferrer"
        >
          <Button
            variant="contained"
            onClick={handleRequestDemo}
            className={classes.secondButton}
          >
            Schedule Demo
          </Button>
        </Link>
      </PageActions>
      {videoUrl && videoView &&
        <VideoDialog
          open={videoPlayerOpen}
          videoViewInfo={{
            name: 'Overview of SecurityProgram.io',
            played: videoView.played,
            url: videoUrl,
          }}
          onClose={handleCloseVideo}
          onError={handlePlaybackError}
          onUpdateVideo={handleUpdateVideo}
        />
      }
    </>
  );
};

const DefaultWelcome = () => {
  const classes = useStyles();

  // A back-up in case the user is missing permissions.
  return (
    <>
      <Typography
        className={classes.welcomeText}
        paragraph
        variant="body1"
      >
        Interested in learning more?
        Send us an email and we'll get back to you as soon as we can!
        <br />
        <Link href={`mailto:${DEFAULT_SUPPORT_EMAIL}`}>{DEFAULT_SUPPORT_EMAIL}</Link>
      </Typography>
    </>
  );
};

function TheWelcomePage() {
  const { isGranted } = useAuth();
  let welcome;

  if (isGranted({ tier: 1, permission: 'tasks' })) {
    // Use 'tasks' as a proxy for 'OrgManager' permissions
    welcome = <OrgManagerWelcome />;
  } else if (isGranted({ permission: 'training:learn' })) {
    welcome = <OrgTraineeWelcome />;
  } else if (isGranted({ permission: 'training:preview' })) {
    welcome = <OnDemandWelcome />;
  } else {
    welcome = <DefaultWelcome />;
  }

  return (
    <Page center>
      <BuildingBlocksImage />
      <Typography
        variant="h4"
        gutterBottom
      >
        Welcome to <StylizedName />!
      </Typography>

      {welcome}
    </Page>
  );
}

TheWelcomePage.requiredAuthZ = {
  tier: 0,
};
TheWelcomePage.routePath = '/welcome';
TheWelcomePage.title = 'Welcome';

export default TheWelcomePage;
