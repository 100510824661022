import React, { useEffect, useState } from 'react';
import { Link, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { showErrorResultBar } from '../components/ResultSnackbar';
import VendorQuestionnaireDetails from '../components/VendorQuestionnaireDetails';
import { DEFAULT_SUPPORT_EMAIL } from '../helpers';
import { PublicAPI } from '../services/ApiService';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(theme => createStyles({
  root: {
    color: theme.typography.body1.color,
    width: '100%',
    textAlign: 'center',
    margin: 'auto',
  },
}));

const LoadingMessage = () => {
  return (
    <Typography variant="h6">
      Loading form...
    </Typography>
  );
};

const InvalidTokenMessage = () => {
  return (
    <>
      <Typography variant="h6">
        Invalid token.
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
      >
        The form may already have been submitted or the link may have expired.
      </Typography>
      <Typography variant="body1">
        Contact us at <Link href={`mailto:${DEFAULT_SUPPORT_EMAIL}`}>{DEFAULT_SUPPORT_EMAIL}</Link> if
        you are receiving this message in error.
      </Typography>
    </>
  );
};

const ThankYouMessage = () => {
  return (
    <>
      <Typography variant="h6">
        Thank you!
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
      >
        The Client has received your responses and may get back to you if they have follow-up questions.
      </Typography>
      <Typography variant="body1">
        Contact us at <Link href={`mailto:${DEFAULT_SUPPORT_EMAIL}`}>{DEFAULT_SUPPORT_EMAIL}</Link> if
        you have any problems or concerns.
      </Typography>
    </>
  );
};

export type TheVendorQuestionnairePageParams = {
  id: string;
}

function TheVendorQuestionnairePage() {
  const params = useParams<TheVendorQuestionnairePageParams>();
  const classes = useStyles();
  const tokenId = params.id;
  const [ isValidToken, setIsValidToken ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isSuccess, setIsSuccess ] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      try {
        const res = await PublicAPI.get(`vendor-questionnaire/${tokenId}`);
        const isValid = (res.data && res.data.data) ? res.data.data : false;

        setIsValidToken(isValid);
      } catch {
        setIsValidToken(false);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [ tokenId ]);

  const submitResponses = async (formValues: Record<string, any>) => {
    let errorMsg;

    try {
      await PublicAPI.post(`vendor-questionnaire/${tokenId}`, formValues);
      setIsSuccess(true);
    } catch (err: any) {
      errorMsg = err.response?.data?.error || 'Unexpected error while updating new entry';
    }

    if (errorMsg) {
      showErrorResultBar(errorMsg);
    }
  };

  let page;

  if (isLoading) {
    page = <LoadingMessage />;
  } else if (isSuccess) {
    page = <ThankYouMessage />;
  } else if (isValidToken) {
    page = <VendorQuestionnaireDetails submitResponses={submitResponses} />;
  } else {
    page = <InvalidTokenMessage />;
  }

  return (
    <div className={classes.root}>
      {page}
    </div>
  );
}

TheVendorQuestionnairePage.routePath = '/vendor-questionnaire/:id';
TheVendorQuestionnairePage.title = 'Vendor Questionnaire';

export default TheVendorQuestionnairePage;
