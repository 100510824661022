import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Grid,
  Link,
  Theme,
  Typography,
} from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as Sentry from '@sentry/react';
import moment from 'moment';
import { ITaskCommentDto } from '../../../backend/src/task/interfaces';
import { truncateString } from '../helpers';
import API from '../services/ApiService';
import CommentDetails from './CommentDetails';
import CommentInput, { ICommentInputData } from './CommentInput';
import { showErrorResultBar, showSuccessResultBar } from './ResultSnackbar';

const AccordionSummary = withStyles({
  root: {
    'borderBottom': '1px solid rgba(0, 0, 0, .125)',
    'marginBottom': -1,
    'minHeight': 0,
    '&$expanded': {
      minHeight: 0,
    },
  },
  content: {
    'margin': '2px 0',
    '&$expanded': {
      margin: '2px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const styles = (theme: Theme) => createStyles({
  addCommentLink: {
    cursor: 'pointer',
    display: 'inline-block',
    paddingTop: '1rem',
    paddingLeft: '1rem',
  },
  commentsExpansionSummary: {
    'padding': 0,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  commentsExpansionDetails: {
    padding: 0,
  },
  commentIcon: {
    fontSize: '0.875rem',
  },
  commentNumberText: {
    paddingLeft: '2px',
    fontSize: '0.875rem',
  },
  commentHeading: {
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
  },
});

export interface TaskCommentsProps extends WithStyles<typeof styles> {
  comments?: ITaskCommentDto[];
  onUpdateComments: (updatedComments: ITaskCommentDto[]) => void;
  taskId: string;
}

function TaskComments(props: TaskCommentsProps) {
  const {
    classes,
    comments = [],
    onUpdateComments,
    taskId,
  } = props;
  const [ isCommenting, setIsCommenting ] = useState(false);
  const [ commentIdEditing, setCommentIdEditing ] = useState<string>();
  const [ mostRecentComment, setMostRecentComment ] = useState<ITaskCommentDto | null>(null);

  const nbComments = comments ? comments.length : 0;

  useEffect(() => {
    setMostRecentComment(comments[0] ?? null);
  }, [ comments ]);

  async function clickOnAddComment(formData: ICommentInputData) {
    await onSaveComment(formData);
    setIsCommenting(false);
  }

  async function clickOnUpdateComment(formData: ICommentInputData) {
    await onSaveComment(formData);
    setCommentIdEditing(undefined);
  }

  const handleDeleteComment = async (commentId: string) => {
    try {
      await API.delete(`task/${taskId}/comment/${commentId}`);
      const updatedComments = comments.slice().filter(comment => comment.id !== commentId);
      onUpdateComments(updatedComments);
      showSuccessResultBar('Comment deleted.');
    } catch (err: any) {
      Sentry.captureException(err);
      const errMessage = err.response && err.response.data && err.response.data.error;
      showErrorResultBar(`Unexpected error while deleting comment: ${errMessage || 'unspecified error'}`);
    }
  };

  const onSaveComment = async (formData: ICommentInputData) => {
    let updatedComments;
    const commentId = formData.id; // 'undefined' if new comment

    try {
      if (commentId) {
        // Updating an existing comment
        const newComment = (await API.patch(`task/${taskId}/comment/${commentId}`, { text: formData.comment })).data.data;
        const commentIdx = comments.findIndex(comment => comment.id === commentId);
        updatedComments = comments.slice();
        updatedComments[commentIdx] = newComment;
      } else {
        // Adding a new comment
        const newComment = (await API.post(`task/${taskId}/comment`, { text: formData.comment })).data.data;
        updatedComments = [ newComment ].concat(comments);
      }

      onUpdateComments(updatedComments);
    } catch (err: any) {
      Sentry.captureException(err);
      showErrorResultBar('There was an error while saving your comment.');
    }
  };

  return (
    <Accordion elevation={0}>
      <AccordionSummary
        className={classes.commentsExpansionSummary}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography
          variant="body1"
          className={classes.commentHeading}
        >
          {nbComments > 0 ? nbComments : ''} Comment{nbComments === 1 ? '' : 's'}
        </Typography>
        {mostRecentComment && (
          <Grid container direction="column" sx={{ ml: 2, mt: 0.2 }} spacing={0}>
            <Grid item>
              <Typography variant="body2">
                {mostRecentComment.authorName} ({moment(mostRecentComment.updatedAt).fromNow()}):
              </Typography>
            </Grid>
            <Grid item >
              <Typography variant="body2" sx={{ fontStyle: 'italic', pl: 1 }}>
                {truncateString(mostRecentComment.text, 100)}
              </Typography>
            </Grid>
          </Grid>
        )}
      </AccordionSummary>
      <AccordionDetails
        className={classes.commentsExpansionDetails}
      >
        <Grid container>
          <Grid item xs={12}>
            {isCommenting ? (
              <CommentInput
                onCancelComment={() => setIsCommenting(false)}
                onSaveComment={clickOnAddComment}
              />
            ) : (
              <Link
                component="a"
                variant="body2"
                onClick={() => setIsCommenting(true)}
                className={classes.addCommentLink}
              >
                Add a comment
              </Link>
            )}
          </Grid>
          <Grid item xs={12}>
            {comments.map((comment) => (
              comment.id === commentIdEditing ?
                <CommentInput
                  key={comment.id}
                  comment={comment}
                  onCancelComment={() => setCommentIdEditing(undefined)}
                  onSaveComment={clickOnUpdateComment}
                />
                :
                <CommentDetails
                  key={comment.id}
                  comment={comment}
                  onClickDelete={() => handleDeleteComment(comment.id)}
                  onClickEdit={() => setCommentIdEditing(comment.id)}
                />
            ))}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

export default withStyles(styles, { withTheme: true })(TaskComments);
