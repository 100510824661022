import { Menu, MenuItem } from '@mui/material';
import { MenuProps } from '@mui/material/Menu';
import * as Sentry from '@sentry/react';
import React, { useEffect, useState } from 'react';
import { IIdNameDto } from '../../../backend/src/common/id-name-dto.interface';
import API from '../services/ApiService';
import { showErrorResultBar } from './ResultSnackbar';

export interface TaskRiskLinkMenuProps extends MenuProps {
  excludeRiskIds: string[];
  onAttachRisk: (risk: IIdNameDto) => Promise<void>;
}

export default function TaskRiskLinkMenu({ excludeRiskIds, onAttachRisk, ...menuProps }: TaskRiskLinkMenuProps) {
  const [ isLoading, setIsLoading ] = useState(false);
  const [ risks, setRisks ] = useState<IIdNameDto[]>([]);

  useEffect(() => {
    (async function fetchRisks() {
      try {
        setIsLoading(true);
        const res = await API.get('risk?restrictFields');
        setRisks(res.data?.data || []);
      } catch (err: any) {
        const errMessage = err?.response?.data?.error ?? 'Error retrieving list of risks';
        showErrorResultBar(errMessage);
        Sentry.captureException(err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return  (
    <Menu
      {...menuProps}
    >
      {isLoading && (
        <MenuItem disabled>
          Loading...
        </MenuItem>
      )}
      {!isLoading && risks.length === 0 && (
        <MenuItem disabled>
          No available risks
        </MenuItem>
      )}
      {!isLoading && risks.length > 0 && risks.map(risk => (
        <MenuItem
          key={risk.id}
          onClick={() => onAttachRisk(risk)}
          disabled={excludeRiskIds.includes(risk.id)}
        >
          {risk.name}
        </MenuItem>
      ))}
    </Menu>
  );
}
