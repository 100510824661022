import { withStyles } from '@mui/styles';
import React from 'react';
import CircleXIcon from './CircleXIcon';

const styles = theme => ({
  icon: {
    color: theme.palette.error.alt,
    stroke: theme.palette.error.alt,
  },

});

function RejectedIcon({ classes }) {
  return (
    <CircleXIcon
      className={classes.icon}
      text="Rejected"
    />
  );
}

export default withStyles(styles, { withTheme: true })(RejectedIcon);
