import { Box, Button, DialogActions, DialogContent, DialogProps, Grid, Typography } from '@mui/material';
import * as Sentry from '@sentry/react';
import { Formik } from 'formik';
import { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import {
  ITrainingRemindMembersDto,
  ITrainingRemindedMember,
  ITrainingReminderResponse,
  ITrainingStatusDto,
} from '../../../../backend/src/training/interfaces';
import API from '../../services/ApiService';
import { formatDate } from '../../helpers';
import { FormikDatePicker } from '../forms';
import { showErrorResultBar, showSuccessResultBar } from '../ResultSnackbar';
import StyledDialogTitle from '../StyledDialogTitle';
import ResponsiveDialog from './ResponsiveDialog';

interface IFormValues {
  dueDate: Moment | null,
}

const FormValidationSchema = Yup.object({
  dueDate: Yup
    .date()
    .nullable()
    .label('Due date')
    .typeError('Invalid date'),
});

export interface TrainingReminderDialogProps extends DialogProps {
  members: ITrainingStatusDto[];
  onClose: () => void;
  onSave: (remindedUsersInfo: ITrainingRemindedMember[]) => void;
}

export default function TrainingReminderDialog(props: TrainingReminderDialogProps) {
  const { onClose, onSave, open, members } = props;
  // Persist the due date, assuming we'll be sending several emails with the same due date:
  const [ dueDate, setDueDate ] = useState<Moment | null>(null);
  const [ pluralStr, setPluralStr ] = useState('');

  useEffect(() => {
    setPluralStr(members.length > 1 ? 's' : '');
  }, [ members ]);

  const handleSave = async (formValues: IFormValues) => {
    if (members.length === 0) {
      return;
    }

    try {
      const remindInfo: ITrainingRemindMembersDto = {
        dueDate: formatDate(formValues.dueDate) ?? null,
        membersVideos: members.map(({ userId, videoId }) => ({ memberId: userId, videoId })),
      };
      const { reminders, errors }: ITrainingReminderResponse =
        (await API.post('training/remind', remindInfo))?.data?.data ?? { errors: [], reminders: [] };

      if (reminders.length === members.length) {
        showSuccessResultBar(`${reminders.length > 1 ? 'All reminders' : 'Reminder'} sent`);
      } else {
        showErrorResultBar(`${errors.length} of ${members.length} reminders were not sent due to errors`);
      }

      setDueDate(formValues.dueDate ?? null); // retain the dueDate in the form
      if (reminders.length > 0) {
        onSave(reminders);
      }
    } catch (err: any) {
      showErrorResultBar(err.response?.data?.message ?? 'Error sending reminder emails');
      Sentry.captureException(err);
    } finally {
      onClose();
    }
  };

  return members.length === 0 ? null : (
    <ResponsiveDialog
      disableBackdropClick
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
    >
      <StyledDialogTitle onClose={onClose}>
        Send training reminder{pluralStr}
      </StyledDialogTitle>
      <Formik
        initialValues={{ dueDate }}
        validationSchema={FormValidationSchema}
        onReset={onClose}
        onSubmit={async (values, { setSubmitting }) => {
          await handleSave(values);
          setSubmitting(false);
        }}
      >
        {formikProps => (
          <form>
            <DialogContent>
              <Typography gutterBottom>
                Clicking 'Send' will send email{pluralStr} to the following user{pluralStr} reminding them to complete the indicated security training video{pluralStr}:
              </Typography>
              <Box sx={{ border: '1px solid lightgray', maxHeight: 400, overflowY: 'auto', m: 2, p: 1 }}>
                <Grid container spacing={0.5}>
                  {members.map((member, idx) => (<React.Fragment key={idx}>
                    <Grid item xs={6}>
                      <Typography
                        variant="body2"
                        sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                      >
                        {member.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">{member.videoName}</Typography>
                    </Grid>
                  </React.Fragment>))}
                </Grid>
              </Box>
              <Typography>
                You may optionally specify a due date to include in the email message{pluralStr}:
              </Typography>
              <Box sx={{ ml: 2, mr: 2 }}>
                <FormikDatePicker
                  field="dueDate"
                  formikProps={formikProps}
                  label="Due date"
                  clearable
                  disablePast
                  nullable
                />
              </Box>
            </DialogContent>
            <DialogActions disableSpacing>
              <Button
                disabled={formikProps.isSubmitting}
                onClick={formikProps.handleReset}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                color="primary"
                size="small"
                variant="contained"
                type="submit"
                disabled={formikProps.isSubmitting || Object.keys(formikProps.errors).length > 0}
                onClick={(e: any) => formikProps.handleSubmit(e)}
              >
                Send
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </ResponsiveDialog>
  );
}
