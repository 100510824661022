import { Card, CardContent } from '@mui/material';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { IAwsOrg, IProviderOrgIdsUploadDto, IUserAuditIdsUploadDto } from '../../../backend/src/user-audit/interfaces';
import { UserAuditAwsTable } from '../components/user-audit';
import UserAuditAwsSetupDialog from '../components/dialogs/UserAuditAwsSetupDialog';
import ProviderHeader from '../components/ProviderHeader';
import ProviderOrgSummary from '../components/ProviderOrgSummary';
import { showErrorResultBar } from '../components/ResultSnackbar';
import { getOrgAuditStatus } from '../components/user-audit/UserAuditTableHelpers';
import API from '../services/ApiService';

const useStyles = makeStyles({
  headerCard: {
    width: '100%',
    margin: 0,
  },
});

function TheUserAuditAwsPage() {
  const classes = useStyles();

  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ refreshingOrgIdx, setRefreshingOrgIdx ] = useState<number>(-1);
  const [ isSetupDialogOpen, setIsSetupDialogOpen ] = useState<boolean>(false);
  const [ orgs, setOrgs ] = useState<IAwsOrg[]>([]);
  const [ selectedOrgIdx, setSelectedOrgIdx ] = useState<number>(0);
  const [ selectedOrg, setSelectedOrg ] = useState<IAwsOrg>();

  // Initial page load:
  useEffect(() => {
    setIsLoading(true);

    API.get('userAudit/aws/syncs')
      .then(res => {
        const theData: IAwsOrg[] = (res.data && res.data.data) ? res.data.data : [];
        setOrgs(theData);
      })
      .catch(() => {
        showErrorResultBar('Unexpected error fetching audit results');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    setSelectedOrg(orgs[selectedOrgIdx]);
  }, [orgs, selectedOrgIdx]);

  const clickAddOrg = () => {
    setIsSetupDialogOpen(true);
  };

  const handleNewOrg = (newOrgs: IAwsOrg) => {
    const updatedOrgs = orgs.concat(newOrgs);
    setOrgs(updatedOrgs);
  };

  const handleSelectOrgIdx = (idx: number) => {
    setSelectedOrgIdx(idx);
  };

  const clickSyncAllOrgs = async () => {
    setIsLoading(true);

    const ids = orgs.map(org => org.id);
    const uploadDto: IProviderOrgIdsUploadDto = { ids };

    return API.post('userAudit/aws/sync', uploadDto)
      .then((res) => {
        const updatedOrgDataArray: IAwsOrg[] = (res.data && res.data.data) ? res.data.data : [];
        setOrgs(updatedOrgDataArray);
      })
      .catch(() => {
        showErrorResultBar('Unexpected error syncing');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSyncOrg = async (idx: number) => {
    setSelectedOrgIdx(idx);
    setRefreshingOrgIdx(idx);

    const org = orgs[idx];
    if (!org) {
      setRefreshingOrgIdx(-1);

      return;
    }

    const uploadDto: IProviderOrgIdsUploadDto = { ids: [ org.id ] };

    return API.post('userAudit/aws/sync', uploadDto)
      .then((res) => {
        const updatedOrgDataArray: IAwsOrg[] = (res.data && res.data.data) ? res.data.data : [];
        const updatedOrg = updatedOrgDataArray[0];

        if (updatedOrg) {
          const updatedOrgs = orgs.slice();
          updatedOrgs[idx] = updatedOrg;
          setOrgs(updatedOrgs);
        }
      })
      .catch(() => {
        showErrorResultBar('Unexpected error syncing');
      })
      .finally(() => {
        setRefreshingOrgIdx(-1);
      });
  };

  const handleConfirmSelected = async () => {
    const status = getOrgAuditStatus(selectedOrg);
    const audit = selectedOrg && selectedOrg.syncs[0];
    const auditId = audit && audit.id;

    if (status !== 'pending' || !auditId) {
      return;
    }

    setRefreshingOrgIdx(selectedOrgIdx);
    const uploadDto: IUserAuditIdsUploadDto = { auditIds: [auditId] };

    return API.post('userAudit/aws/confirm', uploadDto)
      .then((res) => {
        const updatedOrgDataArray: IAwsOrg[] = (res.data && res.data.data) ? res.data.data : [];
        const updatedOrg = updatedOrgDataArray[0];

        if (updatedOrg) {
          const updatedOrgs = orgs.slice();
          updatedOrgs[selectedOrgIdx] = updatedOrg;
          setOrgs(updatedOrgs);
        }
      })
      .catch(() => {
        showErrorResultBar('Unexpected error confirming user audit');
      })
      .finally(() => {
        setRefreshingOrgIdx(-1);
      });
  };

  return (
    <>
      <Card
        className={classes.headerCard}
      >
        <ProviderHeader
          hasOrgs={orgs.length > 0}
          isLoading={isLoading}
          onClickSyncAllOrgs={clickSyncAllOrgs}
          onClickAddOrg={clickAddOrg}
          provider="aws"
          title="AWS Accounts"
        />
        <CardContent>
          <ProviderOrgSummary
            handleSelectOrgIdx={handleSelectOrgIdx}
            handleSyncOrg={handleSyncOrg}
            isLoading={isLoading}
            orgs={orgs}
            refreshingOrgIdx={refreshingOrgIdx}
            selectedOrgIdx={selectedOrgIdx}
          />
          <UserAuditAwsTable
            handleConfirmSelected={handleConfirmSelected}
            isLoading={isLoading || refreshingOrgIdx === selectedOrgIdx}
            orgs={orgs}
            selectedOrgIdx={selectedOrgIdx}
          />
        </CardContent>
      </Card>
      <UserAuditAwsSetupDialog
        open={isSetupDialogOpen}
        handleClose={() => setIsSetupDialogOpen(false)}
        handleNewOrg={handleNewOrg}
      />
    </>
  );
}

TheUserAuditAwsPage.icon = <PermIdentityIcon />;
TheUserAuditAwsPage.requiredAuthZ = {
  tier: 1,
  permission: 'user_audit',
};
TheUserAuditAwsPage.routePath = '/userAudit/aws';
TheUserAuditAwsPage.title = 'User Audit: AWS';

export default TheUserAuditAwsPage;
