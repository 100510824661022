import React from 'react';
import useAuth from '../services/auth/useAuth';

declare global {
  interface Window {
    hsConversationsOnReady?: Array<() => void>;
    hsConversationsSettings?: object;
    HubSpotConversations: any;
  }
}

export default function useHubSpotChat() {
  const { isAuthenticated, user } = useAuth();
  const [ hasLoaded, setHasLoaded ] = React.useState(false);
  const [ activeConversation, setActiveConversation ] = React.useState(false);
  const eventRef = React.useRef<(payload: any) => void>();

  React.useEffect(() => {
    if (isAuthenticated /* && auth.getHubSpotVisitorIdToken() */) {
      window.hsConversationsSettings = {
        identificationEmail: user?.email,
        //identificationToken: auth.getHubSpotVisitorIdToken(),
        loadImmediately: false,
      };

      window.hsConversationsOnReady = [ () => {
        window.HubSpotConversations.widget.load();
        setHasLoaded(true);
      } ];

      const script = document.createElement('script');
      script.src = `//js.hs-scripts.com/${process.env.REACT_APP_HUBSPOT_PORTAL_ID}.js`;
      script.async = true;
      script.defer = true;

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
        window.hsConversationsOnReady = [];
      };
    }
  }, [ isAuthenticated, user?.email ]);

  // Subscribe to conversation events.
  React.useEffect(() => {
    eventRef.current = (payload: any) => {
      setActiveConversation(payload.conversation.conversationId);
    };

    if (hasLoaded) {
      window.HubSpotConversations.on('conversationStarted', eventRef.current);
    }

    return () => window?.HubSpotConversations?.off('conversationStarted', eventRef.current);
  }, [ hasLoaded ]);

  const openHandler = React.useCallback(() => {
    if (hasLoaded) {
      window.HubSpotConversations.widget.open();
    }
  }, [ hasLoaded ]);

  const closeHandler = React.useCallback(() => {
    if (hasLoaded) {
      window.HubSpotConversations.widget.close();
    }
  }, [ hasLoaded ]);

  return {
    hasLoaded,
    activeConversation,
    openHandler,
    closeHandler,
  };
}
