import { DialogProps } from '@mui/material';
import * as Sentry from '@sentry/react';
import React from 'react';
import IDocument from '../../../../backend/src/document/document.interface';
import { IPolicyDocDto } from '../../../../backend/src/policy-doc/interfaces';
import { errorParser } from '../../helpers';
import API from '../../services/ApiService';
import { showErrorResultBar, showSuccessResultBar } from '../ResultSnackbar';
import { FileUploadDialog } from '.';

export interface UploadCustomPolicyDialogProps extends DialogProps {
  onClose: () => void;
  onUpdate: (newData: IPolicyDocDto) => void;
  policy: IPolicyDocDto;
}

export default function UploadCustomPolicyDialog({ onClose, onUpdate, open, policy }: UploadCustomPolicyDialogProps) {
  const handleUploadCustomPolicy = async (newPolicyDoc: IDocument) => {
    try {
      const res = await API.patch(`policyDoc/${policy.versionId}/upload/${newPolicyDoc.id}`);
      onUpdate(res.data.data);
      showSuccessResultBar('Custom policy successfully uploaded');
    } catch (err: any) {
      showErrorResultBar(errorParser(err, 'Unexpected error while uploading custom policy'));
      Sentry.captureException(err);
    }
  };

  return (
    <FileUploadDialog
      dialogHeaderText="Upload custom policy"
      documentCategory="custom_policy"
      onAddDocument={handleUploadCustomPolicy}
      onClose={onClose}
      open={open}
    />
  );
}
