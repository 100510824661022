import { Link } from '@mui/material';
import React from 'react';
import { ICategoryLabelMap, TaskSummaryByCategory } from '../components/TaskSummaryByCategory';

const topCategoryMap: ICategoryLabelMap = {
  'cisv8:1.': {
    title: 'Control 1',
    subtitle: 'Inventory and Control Enterprise Assets',
  },
  'cisv8:2.': {
    title: 'Control 2',
    subtitle: 'Inventory and Control of Software Assets',
  },
  'cisv8:3.': {
    title: 'Control 3',
    subtitle: 'Data Protection',
  },
  'cisv8:4.': {
    title: 'Control 4',
    subtitle: 'Secure Configuration of Enterprise Assets and Software',
  },
  'cisv8:5.': {
    title: 'Control 5',
    subtitle: 'Account Management',
  },
  'cisv8:6.': {
    title: 'Control 6',
    subtitle: 'Access Control Management',
  },
  'cisv8:7.': {
    title: 'Control 7',
    subtitle: 'Continuous Vulnerability Management',
  },
  'cisv8:8.': {
    title: 'Control 8',
    subtitle: 'Audit Log Management',
  },
  'cisv8:9.': {
    title: 'Control 9',
    subtitle: 'Email and Web Browser Protections',
  },
  'cisv8:10.': {
    title: 'Control 10',
    subtitle: 'Malware Defenses',
  },
  'cisv8:11.': {
    title: 'Control 11',
    subtitle: 'Data Recovery',
  },
  'cisv8:12.': {
    title: 'Control 12',
    subtitle: 'Network Infrastructure Management',
  },
  'cisv8:13.': {
    title: 'Control 13',
    subtitle: 'Network Monitoring and Defense',
  },
  'cisv8:14.': {
    title: 'Control 14',
    subtitle: 'Security Awareness and Skills Training',
  },
  'cisv8:15.': {
    title: 'Control 15',
    subtitle: 'Service Provider Management',
  },
  'cisv8:16.': {
    title: 'Control 16',
    subtitle: 'Application Software Security',
  },
  'cisv8:17.': {
    title: 'Control 17',
    subtitle: 'Incident Response Management',
  },
  'cisv8:18.': {
    title: 'Control 18',
    subtitle: 'Penetration Testing',
  },
};

function TheTasksSummaryCISV8Page() {
  return (
    <TaskSummaryByCategory
      apiRoute="task/summary/cisv8"
      title="CIS V8"
      topCategoryLabel="Control"
      topCategoryMap={topCategoryMap}
    >
      <>
        The <Link
          href="https://www.cisecurity.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Center for Internet Security (CIS)
        </Link> publishes <Link
          href="https://www.cisecurity.org/controls/cis-controls-list/"
          target="_blank"
          rel="noopener noreferrer"
        >
          the CIS V8 controls
        </Link>,
        a prioritized set of guidelines organizations should follow to help protect
        themselves from the most common attack vectors. Each control has at least
        one associated task your organization should complete.
        In the table below we indicate how far along your organization has come in terms of
        implementing these controls.
      </>
    </TaskSummaryByCategory>
  );
}

TheTasksSummaryCISV8Page.requiredAuthZ = {
  tier: 1,
  permission: 'tasks',
};
TheTasksSummaryCISV8Page.routePath = '/tasks/cisv8';
TheTasksSummaryCISV8Page.title = 'Task Summary: CIS V8';

export default TheTasksSummaryCISV8Page;
