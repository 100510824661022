import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import * as React from 'react';

const useStyles = makeStyles({
  action: {
    margin: '0 4px',
  },
  actionPanel: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
    margin: '1rem',
  },
  center: {
    justifyContent: 'center',
  },
});

export interface PageActionsProps {
  center?: boolean;
  children: React.ReactNode;
}

export default function PageActions({ center, children }: PageActionsProps) {
  const classes = useStyles();

  return (
    <div className={classNames(classes!.actionPanel, center && classes!.center)}>
      {children}
    </div>
  );
}
