import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../services/auth/useAuth';
import { TheDashboardPage, ThePolicyPage, TheTrainingPage, TheWelcomePage } from '.';
import { STORAGE_KEYS, getStorageValue, setStorageValue } from '../services/BrowserStorageService';

function TheHomePageRedirect() {
  const { isAuthenticated, isGranted, isOrgManager, isPolicyManager } = useAuth();
  const isFirstVisit = !getStorageValue(STORAGE_KEYS.RETURNING_VISITOR);

  useEffect(() => {
    setStorageValue(STORAGE_KEYS.RETURNING_VISITOR, true);
  }, []);

  if (isOrgManager && !isFirstVisit) {
    return <Navigate to={TheDashboardPage.routePath} replace />;
  } else if (isPolicyManager && !isFirstVisit) {
    return <Navigate to={ThePolicyPage.routePath} replace />;
  } else if (!isPolicyManager && isGranted({ permission: 'training:learn' })) {
    return <Navigate to={TheTrainingPage.routePath} replace />;
  } else if (isAuthenticated) {
    return <Navigate to={TheWelcomePage.routePath} replace />;
  }

  return <Navigate to="/login" replace />;
}

TheHomePageRedirect.routePath = '/';

export default TheHomePageRedirect;
