import {
  Card,
  CardContent,
  Divider,
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { blueGrey, green, red, yellow } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { difference, uniq } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Doughnut as DoughnutChart } from 'react-chartjs-2';
import { ITaskStatusCount, ITaskStatusCountByCategory } from '../../../../backend/src/task/interfaces';
// Importing these from the index file leads to webpack import issues:
import TheTasksSummaryCIS20Page from '../../pages/TheTasksSummaryCIS20Page';
import TheTasksSummaryCISV8Page from '../../pages/TheTasksSummaryCISV8Page';
import TheTasksSummaryCMMCPage from '../../pages/TheTasksSummaryCMMCPage';
import TheTasksSummaryISO27001Page from '../../pages/TheTasksSummaryISO27001Page';
import TheTasksSummaryNIST800171Page from '../../pages/TheTasksSummaryNIST800171Page';
import TheTasksSummaryNistCsfPage from '../../pages/TheTasksSummaryNistCsfPage';
import TheTasksSummarySOCPage from '../../pages/TheTasksSummarySOCPage';
import { STORAGE_KEYS, getStorageValue, setStorageValue } from '../../services/BrowserStorageService';
import { RouterLink } from '../RouterLink';
import WidgetTitle from '../WidgetTitle';
import { ChartOptions } from 'chart.js';

const OVERALL_CATEGORY = 'All Tasks';
const TOP_CATEGORIES = [
  'ccpa',
  'cis 20',
  'cisv8',
  'cmmc',
  'gdpr',
  'hipaa',
  'iso-27001',
  'nist 800-53',
  'nist-800-171',
  'nist-csf',
  'soc',
];
const TASK_MATURITY_LINKS: { [key: string]: string } = {
  'cis 20': TheTasksSummaryCIS20Page.routePath,
  'cisv8': TheTasksSummaryCISV8Page.routePath,
  'cmmc': TheTasksSummaryCMMCPage.routePath,
  'iso-27001': TheTasksSummaryISO27001Page.routePath,
  'nist-800-171': TheTasksSummaryNIST800171Page.routePath,
  'nist-csf': TheTasksSummaryNistCsfPage.routePath,
  'soc': TheTasksSummarySOCPage.routePath,
};

const useStyles = makeStyles({
  selectDivider: {
    padding: '10px',
  },
  reportLink: {
    'color': 'inherit',
    'cursor': 'pointer',
    'textDecoration': 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  disabledReportLink: {
    color: 'gray',
  },
  menuItemSubText: {
    color: 'gray',
    fontStyle: 'italic',
    marginLeft: 'auto',
    marginRight: '1rem',
  },
  hidden: {
    display: 'none',
  },
  selectionClickableDiv: {
    '& .hideWhenSelected': {
      display: 'none',
    },
  },
});

const doughnutChartOptions: ChartOptions = {
  legend: {
    position: 'right',
  },
};

interface IndexSignature {
  [key: string]: any;
}

const statusDisplayMapping: IndexSignature = {
  not_started: {
    title: 'Not Started',
    backgroundColor: blueGrey[100],
    hoverBackgroundColor: blueGrey[300],
  },
  in_progress: {
    title: 'Started',
    backgroundColor: yellow[500],
    hoverBackgroundColor: yellow[400],
  },
  past_due: {
    title: 'Past Due',
    backgroundColor: red[500],
    hoverBackgroundColor: red[400],
  },
  completed: {
    title: 'Completed',
    backgroundColor: green[500],
    hoverBackgroundColor: green[400],
  },
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 8.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const NoDataFound = () => {
  return (
    <Card
      elevation={0}
    >
      <CardContent>
        <Typography
          variant="h5"
          component="p"
        >
          No tasks found
        </Typography>
      </CardContent>
    </Card>
  );
};

interface TagCategorySelectProps {
  currCategory: string;
  handleUpdateCategory: (event: SelectChangeEvent<string>) => void;
  otherCategories: string[];
}

const TagCategorySelect = ({ currCategory, handleUpdateCategory, otherCategories }: TagCategorySelectProps) => {
  const classes = useStyles();

  return (
    <FormControl>
      <InputLabel
        htmlFor="select-task-category"
      >
        Category
      </InputLabel>
      <Select
        name="task-category"
        className={classes.selectionClickableDiv}
        value={currCategory}
        input={<Input
          id="select-task-category"
        />}
        MenuProps={MenuProps}
        onChange={handleUpdateCategory}
      >
        <MenuItem
          value={OVERALL_CATEGORY}
          divider
        >
          {OVERALL_CATEGORY}
        </MenuItem>
        <Divider />
        <li>
          <Typography
            className={classes.selectDivider}
            color="textSecondary"
            variant="caption"
          >
            Top Categories
          </Typography>
        </li>
        {TOP_CATEGORIES.map(category => (
          <MenuItem
            key={category}
            value={category}
          >
            {category.toUpperCase()}
            <span className={classNames(
              'hideWhenSelected',
              TASK_MATURITY_LINKS[category] ? classes.menuItemSubText : classes.hidden,
            )}>
              Breakdown available
            </span>
          </MenuItem>
        ))}
        <Divider />
        <li>
          <Typography
            className={classes.selectDivider}
            color="textSecondary"
            variant="caption"
          >
            Other Categories
          </Typography>
        </li>
        {otherCategories.map(category => (
          <MenuItem
            key={category}
            value={category}
          >
            {category}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export interface ChartTasksByCategoryProps {
  isLoading: boolean;
  taskCategoriesSummary: ITaskStatusCountByCategory[];
  taskOverallSummary: ITaskStatusCount[];
}

function ChartTasksByCategory({ isLoading, taskCategoriesSummary, taskOverallSummary, }: ChartTasksByCategoryProps) {
  const [ otherCategories, setOtherCategories ] = useState<string[]>([]);
  const [ currCategory, setCurrCategory ] = useState<string>(getStorageValue(STORAGE_KEYS.DASHBOARD_TASKS_FILTER) || OVERALL_CATEGORY);
  const [ currTaskSummary, setCurrTaskSummary ] = useState<ITaskStatusCount[]>(taskOverallSummary);
  const [ chartData, setChartData ] = useState<any>({});

  const classes = useStyles();

  // Set the sorted, unique list of tag categories not included in the TOP_CATEGORIES:
  useEffect(() => {
    const otherCats = difference(uniq(taskCategoriesSummary.map(t => t.category)), TOP_CATEGORIES).sort();
    setOtherCategories(otherCats);
  }, [ taskCategoriesSummary ]);

  // Update the cached task category filter:
  useEffect(() => {
    setStorageValue(STORAGE_KEYS.DASHBOARD_TASKS_FILTER, currCategory);
  }, [ currCategory ]);

  // Set the displayed task summary based on the chosen category:
  useEffect(() => {
    if (currCategory === OVERALL_CATEGORY) {
      setCurrTaskSummary(taskOverallSummary);
    } else {
      setCurrTaskSummary(taskCategoriesSummary.filter(summary => summary.category === currCategory));
    }
  }, [ currCategory, taskCategoriesSummary, taskOverallSummary ]);

  // Update the chart data when the task summary changes:
  useEffect(() => {
    setChartData({
      labels: currTaskSummary.map(t => statusDisplayMapping[t.status].title),
      datasets: [ {
        data: currTaskSummary.map(t => t.count),
        backgroundColor: currTaskSummary.map(t => statusDisplayMapping[t.status].backgroundColor),
        hoverBackgroundColor: currTaskSummary.map(t => statusDisplayMapping[t.status].hoverBackgroundColor),
      } ],
    });
  }, [ currTaskSummary ]);

  const handleUpdateCategory = (event: SelectChangeEvent<string>) => {
    setCurrCategory(event.target.value);
  };

  return (
    <Card>
      <WidgetTitle to="/tasks">Tasks</WidgetTitle>
      {currTaskSummary.length === 0 && !isLoading ? (
        <NoDataFound />
      ) : (
        <DoughnutChart data={chartData} options={doughnutChartOptions} />
      )}
      <Grid container>
        <Grid item xs={12}>
          <TagCategorySelect
            currCategory={currCategory}
            handleUpdateCategory={handleUpdateCategory}
            otherCategories={otherCategories}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            {TASK_MATURITY_LINKS[currCategory] ? (
              <RouterLink
                className={classes.reportLink}
                to={TASK_MATURITY_LINKS[currCategory]}
              >
                See detailed breakdown
              </RouterLink>
            ) : (
              <div className={classes.disabledReportLink}>
                Detailed breakdown not available
              </div>
            )}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
}

ChartTasksByCategory.requiredAuthZ = {
  tier: 1,
  permission: 'tasks',
};

export default ChartTasksByCategory;
