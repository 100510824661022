import { Card, CardContent, Grid } from '@mui/material';
import React from 'react';
import { IOrg } from '../../pages/TheOrganizationPage';
import { BillingInfoRow } from '../BillingInfoCard';
import { makeStyles } from '@mui/styles';
import { LinedCardHeader } from '../cards';

const useStyles = makeStyles({
  card: {
    margin: 0,
    padding: 0,
  },
});

export interface RegOrgReviewCardProps {
  org: IOrg;
}

export function RegOrgReviewCard({ org }: RegOrgReviewCardProps) {
  const classes = useStyles();

  return (
    <>
      <Card className={classes.card}>
        <LinedCardHeader
          title="Organization details"
        />
        <CardContent>
          <Grid container>
            <BillingInfoRow
              name="Organization name"
              value={org.name}
            />
            <BillingInfoRow
              name="Auto enroll?"
              value={org.autoEnroll ? 'Yes' : 'No'}
            />
            <BillingInfoRow
              name="Notifications?"
              value={org.notifications ? 'Yes' : 'No'}
            />
            <BillingInfoRow
              name="Policy Owner"
              value={org.policyOwner}
            />
            <BillingInfoRow
              name="Policy Approver"
              value={org.policyApprover}
            />
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
