import { Tab, Tabs } from '@mui/material';
import * as Sentry from '@sentry/react';
import React, { useEffect, useState } from 'react';
import { ICreateOrgDto, IOrgDto, IUpdateOrgDto } from '../../../backend/src/org/interfaces';
import { OrgGeneralSettings, OrgReportingPeriods } from '../components/org';
import { showErrorResultBar, showSuccessResultBar } from '../components/ResultSnackbar';
import API, { ErrorCodes as ApiErrorCodes } from '../services/ApiService';
import useAuth from '../services/auth/useAuth';

export interface IOrgForm {
  autoEnroll: boolean;
  kmsKeyArn: string | null;
  name: string;
  notifications: boolean;
  ownerId?: string;
  policyApprover: string;
  policyOwner: string;
}

export interface IOrg extends IOrgForm {
  tier: number;
}

function TheOrganizationPage() {
  const { isEmailFree, isGranted, orgId, renewSession, user, userId } = useAuth();
  const [ org, setOrg ] = useState<IOrg>({
    autoEnroll: !isEmailFree,
    kmsKeyArn: '',
    name: '',
    notifications: false,
    policyApprover: '',
    policyOwner: '',
    tier: 0,
  });
  const [ selectedTabIdx, setSelectedTabIdx ] = useState<number>(0);

  useEffect(() => {
    if (orgId) {
      API.get(`org/${orgId}`)
        .then((res) => {
          const theOrg: IOrgDto = (res.data && res.data.data) ? res.data.data : {};

          if (!theOrg.policyOwner) {
            theOrg.policyOwner = user?.name;
          }

          if (!theOrg.policyApprover) {
            theOrg.policyApprover = user?.name;
          }

          setOrg(prevOrg => ({...prevOrg, ...theOrg}));
        })
        .catch(err => {
          Sentry.captureException(err);
          showErrorResultBar('An error occurred while loading your settings');
        });
    } else {
      const defaultOwner: string = user?.name ?? '';

      setOrg(prevOrg => ({ ...prevOrg, policyOwner: defaultOwner, policyApprover: defaultOwner }));
    }
  }, [ orgId, user ]);

  const saveOrg = async (formValues: IOrgForm) => {
    try {
      if (org.ownerId === undefined) {
        const createOrg: ICreateOrgDto = {
          ...formValues,
          ownerId: userId ?? '',
          kmsKeyArn: formValues.kmsKeyArn === '' ? null : formValues.kmsKeyArn,
        };

        await API.post('org', createOrg);
        await renewSession();
      } else {
        const updateOrg: IUpdateOrgDto = {
          ...formValues,
          kmsKeyArn: formValues.kmsKeyArn === '' ? null : formValues.kmsKeyArn,
        };

        const res = await API.patch(`org/${orgId}`, updateOrg);

        setOrg(res.data.data);
        showSuccessResultBar('Settings successfully updated.');
      }
    } catch (err: any) {
      switch (err.response.data.statusCode) {
        case ApiErrorCodes.DUPLICATE_VALUE:
          const dupDetails = err.response.data.data;
          const errorMessage = `That organization ${dupDetails.field} is already taken.\nPlease choose another.`;
          showErrorResultBar(errorMessage);
          break;

        default:
          showErrorResultBar('An error occurred while saving your settings.');
      }
    }
  };

  const handleTabChange = (_: any, value: number) => {
    setSelectedTabIdx(value);
  };

  return (
    <>
      <Tabs
        value={selectedTabIdx}
        onChange={handleTabChange}
      >
        <Tab label="General" />
        {isGranted(OrgReportingPeriods.requiredAuthZ) &&
        <Tab label="Reporting Periods" />
        }
      </Tabs>
      {selectedTabIdx === 0 &&
      <OrgGeneralSettings
        onClickSave={saveOrg}
        org={org}
      />
      }
      {isGranted(OrgReportingPeriods.requiredAuthZ) && selectedTabIdx === 1 &&
      <OrgReportingPeriods />
      }
    </>
  );
}

TheOrganizationPage.requiredAuthZ = {
  tier: 1,
  permission: 'org',
};
TheOrganizationPage.routePath = '/org';
TheOrganizationPage.title = 'Organization Settings';

export default TheOrganizationPage;
