import { RedirectLoginOptions, User } from '@auth0/auth0-react';
import { createContext } from 'react';
import { IOrgDto } from '../../../../backend/src/org/interfaces';
import { IRequiredAuthZ } from '../../pages/interfaces';

export interface AuthContextInterface {
  currentOrg?: IOrgDto;
  isAuthenticated: boolean;
  isEmailFree: boolean;
  isGlobalVendorReader: boolean;
  isGranted: (reqAuthZ?: IRequiredAuthZ, isGlobPermissionMatch?: boolean) => boolean;
  isLoading: boolean;
  isOrgManager: boolean;
  isPolicyManager: boolean;
  isRegistered: boolean;
  login: (redirectUrl?: string, options?: RedirectLoginOptions) => void,
  logout: (redirectPath?: string) => void,
  orgId?: string | null;
  primaryOrgId?: string | null;
  renewSession: (ignoreCache?: boolean) => Promise<void>;
  user?: User;
  userId: string | null;
}

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <AuthProvider>.');
};

const initialContext: AuthContextInterface = {
  currentOrg: undefined,
  isAuthenticated: false,
  isEmailFree: false,
  isGlobalVendorReader: false,
  isGranted: stub,
  isLoading: true,
  isOrgManager: false,
  isPolicyManager: false,
  isRegistered: false,
  login: stub,
  logout: stub,
  orgId: undefined,
  primaryOrgId: undefined,
  renewSession: stub,
  user: undefined,
  userId: null,
};

const AuthContext = createContext<AuthContextInterface>(initialContext);

export default AuthContext;
