import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material';
import { DatePicker } from '@mui/lab';
import { makeStyles } from '@mui/styles';
import * as Sentry from '@sentry/react';
import moment, { Moment } from 'moment';
import React, { useRef, useState } from 'react';
import { ITaskAssigneeDto, ITaskDto, ITasksResponse, ITasksUpdateDto } from '../../../backend/src/task/interfaces';
import { TaskStatus } from '../../../backend/src/task/task-status.enum';
import { formatDate } from '../helpers';
import API from '../services/ApiService';
import SaveButton from './buttons/SaveButton';
import { ConfirmationDialog, TaskAssignmentDialog } from './dialogs';
import { showErrorResultBar, showSuccessResultBar } from './ResultSnackbar';
import { SpioDataTableSelectedRows } from './SpioDataTable';
import StyledDialogTitle from './StyledDialogTitle';

const useStyles = makeStyles({
  buttonsContainer: {
    lineHeight: '96px',
    paddingRight: '2rem',
  },
});

interface IIdxTask {
  idx: number;
  task: ITaskDto;
}

interface IPartialUpdate {
  dueDate?: Moment | null;
  status?: TaskStatus;
}

export interface TaskTableSelectedRowsToolbarProps {
  assignees: ITaskAssigneeDto[];
  onCancel: () => void;
  onUpdate: (idxs: number[], updatedTasksInfo: ITasksResponse) => void;
  selectedRows: SpioDataTableSelectedRows;
  tasks: ITaskDto[];
}

export function TaskTableSelectedRowsToolbar(props: TaskTableSelectedRowsToolbarProps) {
  const classes = useStyles();
  const { assignees, onCancel, onUpdate, selectedRows, tasks } = props;

  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const [ newDueDate, setNewDueDate ] = useState<Moment | null>(moment());
  const [ toShowAssignDialog, setToShowAssignDialog ] = useState(false);
  const [ toShowConfirmMarkStarted, setToShowConfirmMarkStarted ] = useState(false);
  const [ toShowConfirmDueDate, setToShowConfirmDueDate ] = useState(false);
  const [ toShowPickDueDate, setToShowPickDueDate ] = useState(false);
  const selectedDataRef = useRef<IIdxTask[]>([]);

  const handleUpdateStatus = async (updateInfo: IPartialUpdate) => {
    const selectedData = selectedDataRef.current;
    const taskIds = selectedData.map(({ task }) => task.id);

    if (taskIds.length === 0) {
      const msg = updateInfo.status ?
        `All of the selected tasks were already ${updateInfo.status === 'in_progress' ? 'started' : 'completed'}.` :
        'None of the selected tasks needed to be updated.';
      showSuccessResultBar(msg);
      onCancel();

      return;
    }

    const updateDto: ITasksUpdateDto = {
      ids: taskIds,
      status: updateInfo.status,
      dueDate: formatDate(updateInfo.dueDate),
    };

    try {
      setIsSubmitting(true);
      const res: ITasksResponse = (await API.patch('task', updateDto)).data.data;
      showSuccessResultBar('Tasks updated.');
      onUpdate(selectedData.map(({ idx }) => idx), res);
    } catch (err: any) {
      Sentry.captureException(err);
      showErrorResultBar('Error updating tasks.');
      setIsSubmitting(false);
    } finally {
      selectedDataRef.current = [];
    }
  };

  const getAllSelectedData = () => {
    const allSelectedIdxs = selectedRows.data.map((row) => row.dataIndex);

    return allSelectedIdxs.map(idx => ({ idx, task: tasks[idx] }));
  };

  const handleClickMarkStarted = async () => {
    // Don't update the Start dates for tasks that are already 'in_progress'.
    const mSelectedData = getAllSelectedData().filter(({ task }) => task.status !== 'in_progress');
    selectedDataRef.current = mSelectedData;

    // Prompt before re-opening 'Completed' tasks.
    if (mSelectedData.some(({ task }) => task.status === 'completed')) {
      setToShowConfirmMarkStarted(true);
    } else {
      return handleUpdateStatus({ status: 'in_progress' });
    }
  };

  const handleClickMarkComplete = async () => {
    // Don't update the Completed dates for tasks that are already 'completed'.
    selectedDataRef.current = getAllSelectedData().filter(({ task }) => task.status !== 'completed');

    return handleUpdateStatus({ status: 'completed' });
  };

  const handleConfirmMarkStarted = async (isConfirmed: boolean) => {
    setToShowConfirmMarkStarted(false);

    if (isConfirmed) {
      handleUpdateStatus({ status: 'in_progress' });
    }
  };

  const handleClickCloseDueDate = () => {
    setToShowPickDueDate(false);
  };

  const handleClickOpenDueDate = () => {
    // Don't update the due dates for tasks that are 'Completed'.
    const allSelectedData = getAllSelectedData();
    const mSelectedData = allSelectedData.filter(({ task }) => task.status !== 'completed');
    selectedDataRef.current = mSelectedData;

    // Prompt before proceeding, since 'Completed' tasks will be skipped.
    if (mSelectedData.length < allSelectedData.length) {
      setToShowConfirmDueDate(true);
    } else {
      setToShowPickDueDate(true);
    }
  };

  const handleClickSaveDueDate = async () => {
    setToShowPickDueDate(false);

    return handleUpdateStatus({ dueDate: newDueDate });
  };

  const handleConfirmDueDate = (isConfirmed: boolean) => {
    setToShowConfirmDueDate(false);
    setToShowPickDueDate(isConfirmed);
  };

  const handleClickAssign = () => {
    selectedDataRef.current = getAllSelectedData();
    setToShowAssignDialog(true);
  };

  const handleUpdateAssignments = (updatedTasksInfo: ITasksResponse) => {
    onUpdate(selectedDataRef.current.map(({ idx }) => idx), updatedTasksInfo);
  };

  return (
    <>
      <div className={classes.buttonsContainer}>
        <Button
          color="primary"
          variant="contained"
          onClick={handleClickOpenDueDate}
          disabled={isSubmitting}
        >
          Change due date
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleClickMarkStarted}
          disabled={isSubmitting}
        >
          Mark Started
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleClickMarkComplete}
          disabled={isSubmitting}
        >
          Mark Complete
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleClickAssign}
          disabled={isSubmitting}
        >
          Assign Tasks
        </Button>
      </div>
      <ConfirmationDialog
        onResponse={handleConfirmMarkStarted}
        open={toShowConfirmMarkStarted}
        title="Reopen closed tasks?"
      >
        Some of the tasks you've selected are completed. Do you want to reopen them?
      </ConfirmationDialog>
      <ConfirmationDialog
        onResponse={handleConfirmDueDate}
        open={toShowConfirmDueDate}
        title="Skip update for completed tasks?"
      >
        Some of the tasks you've selected are completed.
        The due date will not be updated for these tasks.
        Proceed?
      </ConfirmationDialog>
      <Dialog
        fullWidth
        open={toShowPickDueDate}
        onClose={handleClickCloseDueDate}
      >
        <StyledDialogTitle onClose={handleClickCloseDueDate}>
          Pick a due date
        </StyledDialogTitle>
        <DialogContent>
          <DatePicker
            disablePast
            label="Due Date"
            value={newDueDate}
            onChange={date => setNewDueDate(date)}
            inputFormat="YYYY-MM-DD"
            mask="____-__-__"
            renderInput={params => (
              <TextField
                {...params}
                sx={{ margin: 0 }}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <SaveButton
            onClick={handleClickSaveDueDate}
          />
          <Button onClick={handleClickCloseDueDate} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <TaskAssignmentDialog
        assignees={assignees}
        onClose={() => setToShowAssignDialog(false)}
        onUpdateTasks={handleUpdateAssignments}
        open={toShowAssignDialog}
        tasks={selectedDataRef.current.map(({ task }) => task)}
      />
    </>
  );
}
