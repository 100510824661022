import { ButtonProps } from '@mui/material/Button';
import React, { useState } from 'react';
import IDocument from '../../../../backend/src/document/document.interface';
import { DocumentCategory } from '../../../../backend/src/document/document-category.enum';
import { FileUploadButton } from '../buttons';
import { FileUploadDialog } from '.';

export interface FileUploadButtonDialogProps {
  buttonProps?: ButtonProps;
  buttonText: string;
  dialogHeaderText?: string;
  documentCategory?: DocumentCategory;
  documentOrgId?: string;
  onAddDocument?: (doc: IDocument) => void | Promise<void>;
  showCategorySelection?: boolean;
  showIcon?: boolean;
}

export default function FileUploadButtonDialog(props: FileUploadButtonDialogProps) {
  const {
    buttonProps = {},
    buttonText,
    dialogHeaderText,
    documentCategory,
    documentOrgId,
    onAddDocument,
    showCategorySelection,
    showIcon = false,
  } = props;

  const [ isOpen, setIsOpen ] = useState(false);

  return (
    <>
      <FileUploadButton
        showIcon={showIcon}
        onClick={() => setIsOpen(true)}
        {...buttonProps}
      >
        {buttonText}
      </FileUploadButton>
      <FileUploadDialog
        dialogHeaderText={dialogHeaderText || buttonText}
        documentCategory={documentCategory}
        documentOrgId={documentOrgId}
        onAddDocument={onAddDocument}
        onClose={() => setIsOpen(false)}
        open={isOpen}
        showCategorySelection={showCategorySelection}
      />
    </>
  );
}
