import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Hidden,
  IconButton,
  IconButtonProps,
  Link,
  LinkProps,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  TypographyProps,
} from '@mui/material';
import AttachIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/Delete';
import HelpIcon from '@mui/icons-material/HelpOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import * as Sentry from '@sentry/react';
import React, { forwardRef, useEffect, useState } from 'react';
import { IIdNameDto } from '../../../backend/src/common/id-name-dto.interface';
import IDocument from '../../../backend/src/document/document.interface';
import { VendorAuthStatus, VendorStatus } from '../../../backend/src/vendor/enums';
import { IVendorDto } from '../../../backend/src/vendor/interfaces';
import { formatDate, truncateString } from '../helpers';
import API from '../services/ApiService';
import { downloadDocument, downloadGlobalVendorDoc, getUrlAndDownload } from '../services/DocService';
import { FileUploadDialog, RemoveFileDialog } from './dialogs';
import { isDataResellerSelections, isSubprocessorSelections, vendorStatusSelections } from './dialogs/VendorEditDialog';
import { showErrorResultBar, showSuccessResultBar } from './ResultSnackbar';
import VendorQuestionnaireResponse from './VendorQuestionnaireResponse';
import { BoxProps } from '@mui/system';

export interface IVendorUrlInfo {
  value: string;
  text: string;
  href?: string | null;
}

export const vendorUrlMap: IVendorUrlInfo[] = [
  {
    value: 'urlMain',
    text: 'Main website',
  },
  {
    value: 'urlCompliance',
    text: 'Compliance page',
  },
  {
    value: 'urlPrivacy',
    text: 'Privacy policy',
  },
  {
    value: 'urlSecurity',
    text: 'Security policy',
  },
  {
    value: 'urlSupport',
    text: 'Support page',
  },
  {
    value: 'urlTerms',
    text: 'Terms of service',
  },
];

export const authStatusMap: {[ key in VendorAuthStatus ]: string} = {
  enforced: 'Enforced',
  enabled: 'Enabled',
  available: 'Available',
  unavailable: 'Unavailable',
  not_applicable: 'Not applicable',
  unknown: 'Unknown',
};

export const vendorStatusMap: {[ key in VendorStatus ]: string} = {
  ...vendorStatusSelections,
  archived: 'Archived',
};

const getStatusString = (vendorData: IVendorDto) => {
  const { approvedAt, status } = vendorData;
  const statusStr = (status && vendorStatusMap[status]) || 'Unknown';

  let dateStr = '';
  if (status === 'approved' && approvedAt) {
    dateStr = ` (on ${formatDate(approvedAt)})`;
  }

  return `Status: ${statusStr}${dateStr}`;
};

export function MissingDataText({ message }: { message?: string }) {
  return (
    <Typography
      sx={{
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 'inherit',
        fontStyle: 'italic',
      }}
      component="span"
    >
     {message || 'None provided'}
    </Typography>
  );
}

export function ButtonLink({ children, ...otherLinkProps }: LinkProps) {
  return (
    <Link
      sx={{
        color: 'inherit',
        textDecoration: 'underline',
        cursor: 'pointer',
        ':hover': {
          color: 'primary.main',
        },
      }}
      {...otherLinkProps}
    >
      {children}
    </Link>
  );
}

export function ReferencesHeading({ children, ...otherTypographyProps }: TypographyProps) {
  return (
    <Typography
      sx={{ fontSize: '1rem' }}
      variant="h6"
      gutterBottom
      {...otherTypographyProps}
    >
      {children}
    </Typography>
  );
}

export function ReferencesContainer({ children, ...otherBoxProps }: BoxProps) {
  return (
    <Box
      sx={{ pl: 2, pb: 1.6 }}
      {...otherBoxProps}
    >
      {children}
    </Box>
  );
}

export function ReferenceResponse({ children, ...otherTypographyProps }: TypographyProps) {
  return (
    <Typography
      sx={{
        color: 'rgba(0,0,0,0.54)',
        fontSize: 'inherit',
        whiteSpace: 'pre-line',
      }}
      component="span"
      {...otherTypographyProps}
    >
      {children}
    </Typography>
  );
}

export const SmallIconButton = forwardRef<HTMLButtonElement, IconButtonProps>((props, ref) => {
  // Using a forwardRef so that Tooltips work.
  const { children, ...otherIconButtonProps } = props;

  return (
    <IconButton
      ref={ref}
      sx={{
        height: '14px',
        width: '14px',
        mt: '-6px',
        ml: '0.5rem',
        ':hover': {
          color: 'primary.main',
        },
      }}
      size="large"
      {...otherIconButtonProps}
    >
      {children}
    </IconButton>
  );
});

export interface AttachmentContainerProps {
  doc: IIdNameDto;
  handleClickRemoveAttachment?: () => void;
  handleDownloadDoc: () => Promise<void>;
  isGlobal?: boolean;
}

export function AttachmentContainer({ doc, handleClickRemoveAttachment, handleDownloadDoc, isGlobal = false }: AttachmentContainerProps) {
  return (
    <Grid container justifyContent="space-between">
      <Grid item>
        <ButtonLink onClick={handleDownloadDoc}>
          {truncateString(doc.name)}
        </ButtonLink>
      </Grid>
      <Grid item>
        <Tooltip title={isGlobal ?
          'This document is associated with the referenced global vendor and may not be removed' :
          'Remove this attachment'
        }>
          <Box>
            <SmallIconButton
              disabled={isGlobal}
              onClick={handleClickRemoveAttachment}
            >
              <DeleteIcon sx={{ fontSize: '18px' }} />
            </SmallIconButton>
          </Box>
        </Tooltip>
      </Grid>
    </Grid>
  );
}

export interface SecondaryFieldProps {
  title: string;
  value: number | string;
}

export function SecondaryField({ title, value }: SecondaryFieldProps) {
  return <>
    <Hidden smUp>
      <Grid container>
        <Grid item xs={3}>{title}</Grid>
        <Grid item xs={9}>{value}</Grid>
      </Grid>
    </Hidden>
    <Hidden smDown>
      <Grid container justifyContent="space-between" wrap="nowrap">
        <Grid item>{title}</Grid>
        <Grid item>{value}</Grid>
      </Grid>
    </Hidden>
  </>;
}

export interface VendorDetailsProps {
  isGlobalVendor?: boolean;
  onClickArchive?: () => void;
  onClickEdit: () => void;
  onClickSelfFillQuestionnaire?: () => void;
  onClickSendQuestionnaire?: () => void;
  onUpdateVendor: (newVendorData: IVendorDto) => void;
  vendorData: IVendorDto;
}

function VendorDetailsInfo(props: VendorDetailsProps) {
  const {
    isGlobalVendor = false,
    onClickArchive = () => {},
    onClickEdit,
    onClickSelfFillQuestionnaire = () => {},
    onClickSendQuestionnaire = () => {},
    onUpdateVendor,
    vendorData,
  } = props;

  const [ documentToArchive, setDocumentToArchive ] = useState<IIdNameDto | null>(null);
  const [ menuAnchorEl, setMenuAnchorEl ] = useState<null | HTMLElement>(null);
  const [ toShowFileUploadDialog, setToShowFileUploadDialog ] = useState(false);
  const [ toShowRemoveEvidenceDialog, setToShowRemoveEvidenceDialog ] = useState(false);
  const [ urls, setUrls ] = useState<IVendorUrlInfo[]>([]);

  const vendorUrl = isGlobalVendor ? 'globalVendor' : 'vendor';
  const handleDownloadDoc = isGlobalVendor ? downloadGlobalVendorDoc : downloadDocument;

  useEffect(() => {
    const mUrls: IVendorUrlInfo[] = [];

    if (vendorData && vendorData.urls) {
      const rawUrls: { [key: string]: string | null } = Object.assign(vendorData.urls);

      vendorUrlMap.forEach((urlInfo) => {
        const href = rawUrls[urlInfo.value];
        if (href) {
          mUrls.push({ ...urlInfo, href });
        }
      });
    }

    setUrls(mUrls);
  }, [ vendorData ]);

  const handleAttachFile = async (newDoc: IDocument) => {
    try {
      const res = await API.post(`${vendorUrl}/${vendorData.id}/documents/${newDoc.id}`);
      onUpdateVendor(res.data.data);
      showSuccessResultBar('File attached to vendor');
    } catch (err: any) {
      const errorMsg = err.response?.data?.error || 'Unexpected error while attaching file';
      showErrorResultBar(errorMsg);
      Sentry.captureException(err);
    }
  };

  const handleDownloadGlobalVendorRefDoc = (docId: string) => async () => {
    return getUrlAndDownload(`vendor/${vendorData.id}/globalVendor/documents/${docId}`);
  };

  const handleClickRemoveAttachment = (document: IIdNameDto) => () => {
    setDocumentToArchive(document);
    setToShowRemoveEvidenceDialog(true);
  };

  const handleRemoveAttachment = async (docId: string) => {
    try {
      const res = await API.delete(`${vendorUrl}/${vendorData.id}/documents/${docId}`);
      onUpdateVendor(res.data.data);
      showSuccessResultBar('File archived.');

      return true;
    } catch (err: any) {
      Sentry.captureException(err);
      showErrorResultBar('Unexpected error while archiving file. Please try again.');

      return false;
    }
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const handleClickMenuItem = (handler: () => void) => () => {
    handleCloseMenu();
    handler();
  };

  return <>
    <Card>
      <CardHeader
        sx={{ pb: 0 }}
        action={(
          <IconButton
            onClick={({ currentTarget }) => setMenuAnchorEl(currentTarget)}
            size="large"
          >
            <MoreVertIcon/>
          </IconButton>
        )}
        title={<>
          <Typography variant="h5" component="span">{vendorData.vendorName || 'Vendor details'}</Typography>
          {vendorData.globalVendor && (
            <Typography
              component="span"
              color="rgba(0, 0, 0, 0.54)"
              fontStyle="italic"
              pl={1.5}
              variant="caption"
            >
              References global vendor "{vendorData.globalVendor.vendorName}"
            </Typography>
          )}
        </>}
        subheader={getStatusString(vendorData)}
      />
      <Menu
        anchorEl={menuAnchorEl}
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={handleCloseMenu}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={handleClickMenuItem(onClickEdit)}>
          Edit
        </MenuItem>
        <MenuItem onClick={handleClickMenuItem(() => setToShowFileUploadDialog(true))}>
          Attach a file
        </MenuItem>
        { isGlobalVendor ? null : [
          <MenuItem key="send_questionnaire" onClick={handleClickMenuItem(onClickSendQuestionnaire)}>
            Send questionnaire
          </MenuItem>,
          <MenuItem key="self_fill_questionnaire" onClick={handleClickMenuItem(onClickSelfFillQuestionnaire)}>
            Self fill questionnaire
          </MenuItem>,
          <MenuItem key="archive_vendor" onClick={handleClickMenuItem(onClickArchive)}>
            Archive
          </MenuItem>
        ]}
      </Menu>
      <CardContent>
        <Grid container alignItems="flex-start" spacing={2}>
          {/* Primary column */}
          <Grid item xs={12} sm={8} md={9} sx={{ fontSize: '14px', p: 1 }}>
            {/* Data */}
            <ReferencesHeading>Data handled</ReferencesHeading>
            <ReferencesContainer>
              <Grid container>
                <Grid item xs={3}>Description:</Grid>
                <Grid item xs={9}>
                  {vendorData.dataHandled ?
                    <ReferenceResponse>{vendorData.dataHandled}</ReferenceResponse> :
                    <MissingDataText message="No description provided" />
                  }
                </Grid>
                <Grid item xs={3}>Data tier:</Grid>
                <Grid item xs={9}>
                  {typeof vendorData.dataTier === 'number' ?
                    <ReferenceResponse>{vendorData.dataTier}</ReferenceResponse> :
                    <MissingDataText message="Data tier not indicated" />}
                </Grid>
                {vendorData.isSubprocessor && (<>
                  <Grid item xs={3}>Is a subprocessor?</Grid>
                  <Grid item xs={9}>
                    <ReferenceResponse>{isSubprocessorSelections[vendorData.isSubprocessor]}</ReferenceResponse>
                  </Grid>
                </>)}
                {vendorData.isDataReseller && (<>
                  <Grid item xs={3}>Sells data?</Grid>
                  <Grid item xs={9}>
                    <ReferenceResponse>{isDataResellerSelections[vendorData.isDataReseller]}</ReferenceResponse>
                  </Grid>
                </>)}
              </Grid>
            </ReferencesContainer>
            {/* Authentication */}
            <ReferencesHeading>
              Authentication
              <Tooltip
                title={<>
                  <div>Details of how your internal users log in to the vendor's system.</div>
                  <div>
                    Is multi-factor authentication (MFA) available? If so, is it enabled/enforced?
                    Is single sign-on (SSO) available/enabled/enforced?
                  </div>
                </>}
              >
                <HelpIcon
                  color="action"
                  sx={{
                    fontSize: '18px',
                    mb: '-1px',
                    ml: 1,
                    ':hover': { cursor: 'pointer' },
                  }}
                />
              </Tooltip>
            </ReferencesHeading>
            <ReferencesContainer>
              <Grid container>
                <Grid item xs={3}>MFA:</Grid>
                <Grid item xs={9}>
                  <ReferenceResponse>{(vendorData.mfaStatus && authStatusMap[vendorData.mfaStatus]) || 'Unknown'}</ReferenceResponse>
                </Grid>
                <Grid item xs={3}>SSO:</Grid>
                <Grid item xs={9}>
                  <ReferenceResponse>{(vendorData.ssoStatus && authStatusMap[vendorData.ssoStatus]) || 'Unknown'}</ReferenceResponse>
                </Grid>
              </Grid>
            </ReferencesContainer>
            {/* Notes */}
            <ReferencesHeading>
              Additional {isGlobalVendor ? 'vendor' : ''} notes
            </ReferencesHeading>
            <ReferencesContainer>
              {vendorData.notes ?
                <ReferenceResponse>{vendorData.notes}</ReferenceResponse> :
                <MissingDataText message="None" />
              }
            </ReferencesContainer>
            {isGlobalVendor && <>
              <ReferencesHeading>Internal notes</ReferencesHeading>
              <ReferencesContainer>
                {vendorData.internalNotes ?
                  <ReferenceResponse>{vendorData.internalNotes}</ReferenceResponse> :
                  <MissingDataText message="None" />
                }
              </ReferencesContainer>
            </>
            }
          </Grid>
          {/* Secondary column */}
          <Grid item xs={12} sm={4} md={3}
            sx={{
              backgroundColor: '#f5f5f5',
              opacity: '0.8',
              fontSize: '0.8125rem',
              p: 2,
            }}
          >
            {/* Internal tracking */}
            <ReferencesHeading>Internal tracking</ReferencesHeading>
            <ReferencesContainer>
              {vendorData.implementedAt &&
                <SecondaryField title="Start date:" value={formatDate(vendorData.implementedAt) || 'n/a'} />}
              {vendorData.reviewedAt &&
                <SecondaryField title="Last review date:" value={formatDate(vendorData.reviewedAt) || 'n/a'} />}
              {vendorData.retiredAt &&
                <SecondaryField title="Retirement date:" value={formatDate(vendorData.retiredAt) || 'n/a'} />}
              {vendorData.ownerEmail && <SecondaryField title="Owner:" value={vendorData.ownerEmail} />}
              {vendorData.department && <SecondaryField title="Department:" value={vendorData.department} />}
              {vendorData.externalId && <SecondaryField title="External ID:" value={vendorData.externalId} />}
            </ReferencesContainer>
            {/* Contact info */}
            <ReferencesHeading>Vendor contact</ReferencesHeading>
            <ReferencesContainer>
              {(vendorData.contactEmail || vendorData.contactName || vendorData.contactPhone || vendorData.contactTitle) ? (
                <>
                  {vendorData.contactEmail && <SecondaryField title="Email:" value={vendorData.contactEmail} />}
                  {vendorData.contactName && <SecondaryField title="Name:" value={vendorData.contactName} />}
                  {vendorData.contactPhone && <SecondaryField title="Phone:" value={vendorData.contactPhone} />}
                  {vendorData.contactTitle && <SecondaryField title="Title/Role:" value={vendorData.contactTitle} />}
                </>
              ) : (
                <MissingDataText />
              )}
            </ReferencesContainer>
            {/* URLs */}
            <ReferencesHeading>Links</ReferencesHeading>
            <ReferencesContainer>
              {urls.length === 0 ? (
                <MissingDataText />
              ) : (
                urls.map(urlInfo => (
                  <Box key={urlInfo.value} sx={{ '& a': { cursor: 'pointer' }, '&:nth-of-type(n+2)': { pt: 0.5 } }}>
                    <ButtonLink
                      href={urlInfo.href || ''}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {urlInfo.text}
                    </ButtonLink>
                  </Box>
                ))
              )}
            </ReferencesContainer>
            {/* Attachments */}
            <ReferencesHeading>
              Attachments
              <Tooltip title="Attach a file">
                <SmallIconButton onClick={() => setToShowFileUploadDialog(true)}>
                  <AttachIcon sx={{ fontSize: '18px' }} />
                </SmallIconButton>
              </Tooltip>
            </ReferencesHeading>
            <ReferencesContainer>
              {vendorData.globalVendor && vendorData.globalVendor.documents.map(doc => (
                <AttachmentContainer
                  key={doc.id}
                  doc={doc}
                  handleDownloadDoc={handleDownloadGlobalVendorRefDoc(doc.id)}
                  isGlobal={true}
                />
              ))}
              {vendorData.documents.map(doc => (
                <AttachmentContainer
                  key={doc.id}
                  doc={doc}
                  handleClickRemoveAttachment={handleClickRemoveAttachment(doc)}
                  handleDownloadDoc={() => handleDownloadDoc(doc.id)}
                />
              ))}
              {!vendorData.documents.length && !vendorData.globalVendor?.documents.length &&
                <MissingDataText message="None" />
              }
            </ReferencesContainer>
          </Grid>
          {/* Questionnaires */}
          { isGlobalVendor ? null : (
            <Grid item xs={12}>
              {vendorData.questionnaires && vendorData.questionnaires.length > 0 && <>
                <ReferencesHeading>Questionnaires</ReferencesHeading>
                <ReferencesContainer>
                  {vendorData.questionnaires.map((questionnaire) => (
                    <VendorQuestionnaireResponse
                      key={questionnaire.id}
                      onUpdateVendor={onUpdateVendor}
                      questionnaire={questionnaire}
                      vendorData={vendorData}
                    />
                  ))}
                </ReferencesContainer>
              </>}
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
    <FileUploadDialog
      dialogHeaderText={`Attach a file to '${vendorData.vendorName}'`}
      documentCategory="evidence/vendor"
      onAddDocument={handleAttachFile}
      onClose={() => setToShowFileUploadDialog(false)}
      open={toShowFileUploadDialog}
      globalVendorId={isGlobalVendor ? vendorData.id : null}
    />
    {documentToArchive &&
      <RemoveFileDialog
        document={documentToArchive}
        onClose={() => setToShowRemoveEvidenceDialog(false)}
        onExited={() => setDocumentToArchive(null)}
        onRemoveFile={handleRemoveAttachment}
        open={toShowRemoveEvidenceDialog}
      />
    }
  </>;
}

export default VendorDetailsInfo;
