import React, { EventHandler, ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import AssessmentIcon from '@mui/icons-material/Assessment';
import BookIcon from '@mui/icons-material/Book';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FolderIcon from '@mui/icons-material/Folder';
import HelpIcon from '@mui/icons-material/Help';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import SecurityIcon from '@mui/icons-material/Security';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';
import { Divider, List, ListItem, ListItemIcon, ListItemText, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import logo from '../images/jemurai-logo.svg';
import Badge from '@mui/material/Badge';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ReceiptIcon from '@mui/icons-material/Receipt';
import {
  TheAttestationPage,
  TheDashboardPage,
  TheDocumentsPage,
  TheGlobalVendorPage,
  TheMarketingBundlePage,
  TheNetworkScanPage,
  TheNetworkScanRequestPage,
  ThePolicyPage,
  TheQuestionnairePage,
  TheReportsPage,
  TheRiskRegisterPage,
  TheRoadmapAdminPage,
  TheSupportPage,
  TheTaskMasterPage,
  TheTasksPage,
  TheTrainingPage,
  TheUserAuditPage,
  TheVendorTrackingPage,
} from '../pages';
import { IRequiredAuthZ } from '../pages/interfaces';
import useAuth from '../services/auth/useAuth';

const useStyles = makeStyles((theme: Theme) => createStyles({
  upgrade: {
    color: theme.palette.error.main,
  },

  listItems: {
    // color: '#f2f1ed',
  },

  categoryHeader: {
    // color: '#f2f1ed',
    textTransform: 'uppercase',
  },

  navLink: {
    textDecoration: 'none',
    '&[disabled]': {
      cursor: 'default',
      pointerEvents: 'none',
    },
  },

  betaMarker: {
    color: theme.palette.error.main,
    fontSize: 'smaller',
    textTransform: 'lowercase',
    fontWeight: 'bold',
    paddingLeft: '1em',
    cursor: 'pointer',
    pointerEvents: 'all',
    position: 'relative',
    top: '-0.5rem',
    left: '-0.5rem',
  },

  toolbarIcon: {
    backgroundColor: theme.palette.primary.main,
    align: 'center',
  },
}), { name: TheNavLinkList.name });

type Links = {
  [link: string]: {
    path: string;
    text: string;
    icon: ReactElement;
    isBeta?: boolean;
    requiredAuthZ?: IRequiredAuthZ;
  }
}

const LINKS: Links = {
  attestation: {
    path: '/attestation',
    text: 'Attestation',
    icon: <VerifiedUserIcon />,
    requiredAuthZ: TheAttestationPage.requiredAuthZ,
  },
  dashboard: {
    path: '/dashboard',
    text: 'Dashboard',
    icon: <DashboardIcon />,
    requiredAuthZ: TheDashboardPage.requiredAuthZ,
  },
  documents: {
    path: '/documents',
    text: 'Documents',
    icon: <FolderIcon />,
    requiredAuthZ: TheDocumentsPage.requiredAuthZ,
  },
  globalVendor: {
    path: TheGlobalVendorPage.routePath,
    text: TheGlobalVendorPage.title,
    icon: <AssessmentIcon />,
    requiredAuthZ: TheGlobalVendorPage.requiredAuthZ,
  },
  marketingBundle: {
    path: '/marketingBundle',
    text: 'Marketing Bundle',
    icon: <ReceiptIcon />,
    requiredAuthZ: TheMarketingBundlePage.requiredAuthZ,
  },
  networkScanRequests: {
    path: TheNetworkScanRequestPage.routePath,
    text: TheNetworkScanRequestPage.title,
    icon: TheNetworkScanRequestPage.icon,
    requiredAuthZ: TheNetworkScanRequestPage.requiredAuthZ,
  },
  policies: {
    path: '/policies',
    text: 'Policies',
    icon: <BookIcon />,
    requiredAuthZ: ThePolicyPage.requiredAuthZ,
  },
  questionnaires: {
    icon: TheQuestionnairePage.icon,
    path: TheQuestionnairePage.routePath,
    requiredAuthZ: TheQuestionnairePage.requiredAuthZ,
    text: TheQuestionnairePage.title,
  },
  reports: {
    path: TheReportsPage.routePath,
    text: TheReportsPage.title,
    icon: <LibraryBooksIcon />,
    requiredAuthZ: TheReportsPage.requiredAuthZ,
  },
  riskRegister: {
    path: '/risk-register',
    text: 'Risk Register',
    icon: <VerticalSplitIcon />,
    requiredAuthZ: TheRiskRegisterPage.requiredAuthZ,
  },
  roadmapAdmin: {
    path: TheRoadmapAdminPage.routePath,
    text: TheRoadmapAdminPage.title,
    icon:  TheRoadmapAdminPage.icon,
    requiredAuthZ: TheRoadmapAdminPage.requiredAuthZ,
  },
  scans: {
    path: '/scans',
    text: TheNetworkScanPage.title,
    icon: <SecurityIcon />,
    requiredAuthZ: TheNetworkScanPage.requiredAuthZ,
  },
  support: {
    path: '/support',
    text: 'Support',
    icon: <HelpIcon />,
    requiredAuthZ: TheSupportPage.requiredAuthZ,
  },
  taskMasterAdmin: {
    path: TheTaskMasterPage.routePath,
    text: TheTaskMasterPage.title,
    icon: TheTaskMasterPage.icon,
    requiredAuthZ: TheTaskMasterPage.requiredAuthZ,
  },
  tasks: {
    path: '/tasks',
    text: 'Tasks',
    icon: <FormatListBulletedIcon />,
    requiredAuthZ: TheTasksPage.requiredAuthZ,
  },
  training: {
    path: '/training',
    text: 'Training',
    icon: <LocalLibraryIcon />,
    requiredAuthZ: TheTrainingPage.requiredAuthZ,
  },
  userAudit: {
    path: TheUserAuditPage.routePath,
    text: TheUserAuditPage.title,
    icon: TheUserAuditPage.icon,
    isBeta: false,
    requiredAuthZ: TheUserAuditPage.requiredAuthZ,
  },
  vendorTracking: {
    path: TheVendorTrackingPage.routePath,
    text: TheVendorTrackingPage.title,
    icon: <AssessmentIcon />,
    requiredAuthZ: TheVendorTrackingPage.requiredAuthZ,
  },
};

const navMenu = [
  {
    header: 'Program',
    isBeta: false,
    hideUnAuthLinks: true,
    links: [
      LINKS.dashboard,
      LINKS.tasks,
      LINKS.policies,
      LINKS.training,
      LINKS.documents,
      LINKS.reports,
    ],
  },
  {
    header: 'Tools',
    isBeta: false,
    hideUnAuthLinks: true,
    links: [
      LINKS.scans,
      LINKS.questionnaires,
      LINKS.riskRegister,
      LINKS.vendorTracking,
      LINKS.userAudit,
    ],
    requiredAuthZ: {
      tier: 2,
    },
  },
  {
    header: 'Communication',
    isBeta: false,
    hideUnAuthLinks: true,
    links: [
      LINKS.attestation,
      LINKS.marketingBundle,
      LINKS.support,
    ],
  },
  {
    header: 'Administration',
    isBeta: false,
    hideUnAuthLinks: true,
    links: [
      LINKS.roadmapAdmin,
      LINKS.globalVendor,
      LINKS.taskMasterAdmin,
      LINKS.networkScanRequests,
    ],
    requiredAuthZ: {
      permission: 'admin:*',
    },
  },
];

export type TheNavLinkListProps = {
  badges: any;
  onClick: EventHandler<any>;
};

function TheNavLinkList({ badges, onClick }: TheNavLinkListProps) {
  const classes = useStyles();
  const { currentOrg, isGranted } = useAuth();

  const effectiveNavMenu = navMenu.filter((subMenu) => (
    isGranted(subMenu.requiredAuthZ, true)
    && subMenu.links.filter(link => isGranted(link.requiredAuthZ) || !subMenu.hideUnAuthLinks).length > 0
  ));

  return (
    <div onClick={onClick}>
      <div className={classes.toolbarIcon}>
        <NavLink to="/welcome">
          <img src={currentOrg?.resellerLogoSrc ?? logo} height="60px" alt="" />
        </NavLink>
      </div>

      <Divider />

      <List>
        {effectiveNavMenu.map(subMenu => (
          <div key={subMenu.header}>
            <ListItem className={classes.listItems}>
              <ListItemText>
                <Typography component="span" className={classes.categoryHeader}>
                  {subMenu.header}
                  {subMenu.isBeta &&
                    <NavLink
                      to={`/beta/${subMenu.header}`}
                      className={classes.navLink}
                    >
                      <span className={classes.betaMarker}>beta</span>
                    </NavLink>
                  }
                </Typography>
              </ListItemText>
            </ListItem>
            <List
              component="div"
              disablePadding
            >
              {subMenu.links.map(link => {
                const isDisabled = !isGranted(link.requiredAuthZ);

                if (!subMenu.hideUnAuthLinks || !isDisabled) {
                  return (
                    <NavLink
                      to={link.isBeta && isDisabled ? `/beta/${link.text}` : link.path}
                      key={link.text}
                      className={classes.navLink}
                      onClick={e => (!link.isBeta && isDisabled) && e.preventDefault()}
                    >
                      <ListItem
                        button
                        disabled={isDisabled}
                      >
                        <ListItemIcon className={classes.listItems}>
                          <Badge
                            badgeContent={(badges && badges[link.path]) || 0}
                            color="secondary"
                          >
                            {link.icon}
                          </Badge>
                        </ListItemIcon>
                        <ListItemText
                          classes={{ primary: classes.listItems }}
                        >
                          {link.text} {link.isBeta && <span className={classes.betaMarker}>beta</span>}
                        </ListItemText>
                      </ListItem>
                    </NavLink>
                  );
                } else {
                  return null;
                }
              })
              }
            </List>
          </div>
        ))}
      </List>
    </div>
  );
}

export default TheNavLinkList;
