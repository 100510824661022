import { Button, DialogActions, DialogContent, DialogContentText, DialogProps } from '@mui/material';
import React from 'react';
import { DeleteButton } from '../buttons';
import { RouterLink } from '../RouterLink';
import StyledDialogTitle from '../StyledDialogTitle';
import ResponsiveDialog from './ResponsiveDialog';

export interface ArchiveDialogProps extends DialogProps {
  onClose: () => void;
  onArchive: () => void;
  title: string;
}

export default function ArchiveDialog(props: ArchiveDialogProps) {
  const { open, onArchive, onClose, title } = props;

  return (
    <ResponsiveDialog
      open={open}
      onClose={onClose}
    >
      <StyledDialogTitle>
        {title}
      </StyledDialogTitle>
      <DialogContent>
        <DialogContentText>
          You will need to <RouterLink to="/support">contact us</RouterLink> in
          order to retrieve it.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          size="small"
          onClick={onClose}
        >
          Cancel
        </Button>
        <DeleteButton
          onClick={onArchive}
          text="Archive"
        />
      </DialogActions>
    </ResponsiveDialog>
  );
}
