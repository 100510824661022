import axios from 'axios';
import API from './ApiService';
import IDocument from '../../../backend/src/document/document.interface';
import { IUploadPathDto } from '../../../backend/src/document/upload-path-dto.interface';
import { IUploadPathGlobalVendorDto } from '../../../backend/src/document/upload-path-global-vendor-dto.interface';
import { showInfoResultBar } from '../components/ResultSnackbar';
import { FileWithPath } from 'react-dropzone';

//
// Download helpers
//

function initiateDownload(url: string) {
  const link = document.createElement('a');

  link.href = url;
  document.body.appendChild(link);
  link.click();
  link.remove();
}

async function getPresignedDocumentUrl(apiEndpoint: string) {
  // Allow this to throw, else the 'initiateDownload' attempts to open a new window with a bad result.
  const { data } = await API.get(apiEndpoint);

  return (data.data && data.data.url) || data;
}

export async function getUrlAndDownload(apiEndpoint: string) {
  // TODO: Add error handling to callers of document download.
  const url = await getPresignedDocumentUrl(apiEndpoint);
  initiateDownload(url);
}

export async function downloadDocument(id: string) {
  return getUrlAndDownload(`document/${id}/download`);
}

export async function downloadDocumentTemplate(id: string) {
  return getUrlAndDownload(`document/template/${id}/download`);
}

export async function downloadGlobalVendorDoc(id: string) {
  return getUrlAndDownload(`document/globalVendor/${id}/download`);
}

export const documentDownloadHandler = (documentId: string) => () => downloadDocument(documentId);

export const documentTemplateDownloadHandler = (documentTemplateId: string) => () => {
  return downloadDocumentTemplate(documentTemplateId);
};

export async function downloadMarketingDocument(docKey: string) {
  return getUrlAndDownload(`marketingBundle/${docKey}/download`);
}

export const marketingBundleDocDownloadHandler = (docKey: string) => () => {
  showInfoResultBar('Preparing your document. It should start downloading soon.');

  return downloadMarketingDocument(docKey);
};

export async function downloadPolicy(id: string) {
  return getUrlAndDownload(`policyDoc/${id}/download`);
}

//
// Upload helpers
//

export const uploadDocument = async (
  file: FileWithPath,
  uploadPath: IUploadPathDto,
  onUploadProgress?: (progress: number) => void,
): Promise<IDocument> => {
  return API.post('document/create', uploadPath)
    .then(({ data }) => sendAwsPresignedPost(file, data, onUploadProgress))
    .then(getKeyFromAwsResponseXML)
    .then((objectKey: string) => API.put('document/activate', { objectKey }))
    .then(({ data }) => data);
};

export const uploadGlobalVendorDocument = async (
  file: FileWithPath,
  vendorId: string,
  uploadPath: IUploadPathGlobalVendorDto,
  onUploadProgress?: (progress: number) => void,
): Promise<IDocument> => {
  return API.post(`globalVendor/${vendorId}/documents/create`, uploadPath)
    .then(({ data }) => sendAwsPresignedPost(file, data, onUploadProgress))
    .then(getKeyFromAwsResponseXML)
    .then((objectKey: string) => API.put('document/globalVendor/activate', { objectKey }))
    .then(({ data }) => data);
};

// TODO: Improve the AWS types in the following functions.
const getKeyFromAwsResponseXML = (responseXML: any) => {
  return (responseXML.documentElement.getElementsByTagName('Key')[0]).textContent;
};

const initPresignedFormData = (fields: { [key: string]: any }, file: FileWithPath) => {
  const formData = new FormData();

  Object.entries(fields).forEach(([ field, value ]) => {
    formData.append(field, value);
  });

  formData.append('file', file);

  return formData;
};

const sendAwsPresignedPost = async (
  file: FileWithPath,
  { url, fields }: { url: string, fields: { [key: string]: any } },
  onUploadProgress?: (progress: number) => void,
) => {
  const formData = initPresignedFormData(fields, file);

  return axios.post(
    url,
    formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress: (progressEvent) => {
        if (onUploadProgress && progressEvent.lengthComputable) {
          onUploadProgress((progressEvent.loaded / progressEvent.total) * 100);
        }
      },
    })
    .then((res) => {
      if (onUploadProgress) {
        onUploadProgress(100);
      }

      return res.request && res.request.responseXML;
    })
    .catch((err) => {
      if (onUploadProgress) {
        onUploadProgress(0);
      }

      throw err;
    });
};
