import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import MailIcon from '@mui/icons-material/Mail';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import * as Sentry from '@sentry/react';
import lodash from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { TrainingViewStatus } from '../../../backend/src/training/enums';
import { IReportingPeriodDto } from '../../../backend/src/reporting-period/interfaces';
import { ITrainingReport, ITrainingRemindedMember, ITrainingStatusDto } from '../../../backend/src/training/interfaces';
import { formatDate } from '../helpers';
import API from '../services/ApiService';
import DataTableTitleWithFilter from '../components/DataTableTitleWithFilter';
import { TrainingReminderDialog } from '../components/dialogs';
import { getDateRangeStr } from '../components/org/OrgReportingPeriods';
import Page from '../components/Page';
import { showErrorResultBar } from '../components/ResultSnackbar';
import SpioDataTable, { SpioDataTableColumn } from '../components/SpioDataTable';

const TODAYS_DATE = moment();

type TrainingStatusString = 'Not Completed' | 'Started' | 'Completed';

export interface IViewStatusInfo {
  icon: React.ReactElement<any>;
  text: TrainingStatusString;
}

export const VIEW_STATUS_MAP: Record<TrainingViewStatus, IViewStatusInfo> = {
  notstarted: {
    icon: <NewReleasesIcon />,
    text: 'Not Completed',
  },
  started: {
    icon: <TimelapseIcon />,
    text: 'Started',
  },
  completed: {
    icon: <DoneIcon />,
    text: 'Completed',
  },
};

const getTableColumns = (tableData: ITableDatum[]): SpioDataTableColumn[] => [
  {
    name: 'blockedStr',
    label: 'Disabled',
    options: {
      customFilterListOptions: { render: v => `Disabled: ${v}` },
      display: 'false',
      filterOptions: { names: [ 'True', 'False' ] },
    },
  },
  {
    name: 'createdAtStr',
    label: 'User creation date',
    options: {
      display: 'false',
      filter: false,
      searchable: false,
    },
  },
  {
    name: 'name',
    label: 'Name',
  },
  {
    name: 'email',
    label: 'Email',
  },
  {
    name: 'trainingCategoryStr',
    label: 'Category',
  },
  {
    name: 'videoName',
    label: 'Video',
  },
  {
    name: 'videoId',
    label: 'Video Id',
    options: {
      display: 'false',
      filter: false,
    },
  },
  {
    name: 'statusStr',
    label: 'Status',
    options: {
      customBodyRenderLite: (dataIndex) => {
        const statusStr = tableData[dataIndex]?.statusStr || '';
        const status = Object.values(VIEW_STATUS_MAP).find(m => m.text === statusStr);

        if (status && (status.icon && status.text)) {
          return (
            <Tooltip
              placement="top"
              title={status.text}
            >
              {status.icon}
            </Tooltip>
          );
        } else {
          return statusStr;
        }
      },
    },
  },
  {
    name: 'completedAtHisto',
    label: 'Completed',
    options: {
      customBodyRenderLite: dataIndex => <>
        {tableData[dataIndex]?.completedAtHisto.sort().map(date => <div key={date.toString()}>{formatDate(date)}</div>)}
      </>,
      filter: false,
      sort: false,
    },
  },
  {
    name: 'remindedAtStr',
    label: 'Reminded',
    options: {
      filter: false,
    },
  },
    // The underlying column value should be a string or a number; here we (arbitrarily) use the email.
  {
    name: 'email',
    label: 'Actions',
    options: {
      download: false,
      filter: false,
      searchable: false,
      sort: false,
      customBodyRenderLite: dataIndex => tableData[dataIndex]?.reminderButton || '',
    },
  },
];

interface IReportingPeriodSelectionInfo {
  endDate: Date | null;
  startDate: Date | null;
  text: string;
  value: string;
}

interface ITableDatum extends ITrainingStatusDto {
  blockedStr: string;
  completedAtHisto: Date[];
  remindedAtStr: string;
  reminderButton: React.ReactElement<any>;
  statusStr: TrainingStatusString;
  trainingCategoryStr: string;
}

function TheTrainingReportPage() {
  const [ isLoading, setIsLoading ] = useState(true);
  const [ isBulkRemindDisabled, setIsBulkRemindDisabled ] = useState(false);
  const [ isPastReportingPeriod, setIsPastReportingPeriod ] = useState(false);
  const [ isRemindDialogOpen, setIsRemindDialogOpen ] = useState(false);
  const [ membersTrainingStatus, setMembersTrainingStatus ] = useState<ITrainingStatusDto[]>([]);
  const [ tableData, setTableData ] = useState<ITableDatum[]>([]);
  const [ reportingPeriods, setReportingPeriods ] = useState<IReportingPeriodDto[] | null>(null);
  const [ reportingPeriodSelection, setReportingPeriodSelection ] = useState<IReportingPeriodSelectionInfo>();
  const [ reportingPeriodSelections, setReportingPeriodSelections ] = useState<IReportingPeriodSelectionInfo[]>([]);
  const [ rowsSelected, setRowsSelected ] = useState<number[]>([]);
  const [ selectedMembers, setSelectedMembers ] = useState<ITrainingStatusDto[]>([]);

  useEffect(() => {
    API.get('training/report')
      .then((res) => {
        const { membersTrainingStatus = [], reportingPeriods = [] }: ITrainingReport = res.data?.data || {};
        setMembersTrainingStatus(membersTrainingStatus);
        setReportingPeriods(reportingPeriods);
      })
      .catch((err) => {
        showErrorResultBar('Unexpected error loading Training Report data');
        Sentry.captureException(err);
      });
  }, []);

  useEffect(() => {
    setIsBulkRemindDisabled(isPastReportingPeriod || rowsSelected.map(idx => tableData[idx]).some(m => m.statusStr === 'Completed'));
  }, [ isPastReportingPeriod, rowsSelected, tableData ]);

  useEffect(() => {
    const handleClickSendReminder = (idx: number) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.stopPropagation();

      setSelectedMembers([ membersTrainingStatus[idx] ]);
      setIsRemindDialogOpen(true);
    };

    const { startDate, endDate } = reportingPeriodSelection ?? { startDate: null, endDate: null };
    const isPastPeriod = endDate ? TODAYS_DATE.isSameOrAfter(endDate, 'day') : false;

    setIsPastReportingPeriod(isPastPeriod);
    setTableData(membersTrainingStatus
      .filter(d => !isPastPeriod || !endDate || moment(d.createdAt).isBefore(endDate, 'day'))
      .map((d, idx) => {
      const completedAtHisto = d.completedAtHistory.filter((date) => {
        return (startDate === null || date >= startDate) && (endDate === null || date < endDate);
      });
      const status = completedAtHisto.length > 0 ? 'completed' : 'notstarted';

      return {
        ...d,
        blockedStr: d.blocked ? 'True' : 'False',
        createdAtStr: formatDate(d.createdAt) ?? '',
        completedAtHisto,
        remindedAtStr: formatDate(d.remindedAt) ?? '',
        reminderButton: (
          <IconButton
            size="large"
            disabled={status === 'completed' || isPastPeriod}
            onClick={handleClickSendReminder(idx)}
          >
            <Tooltip title="Send reminder email">
              <MailIcon />
            </Tooltip>
          </IconButton>
        ),
        statusStr: VIEW_STATUS_MAP[status].text,
        trainingCategoryStr: lodash.startCase(lodash.startCase(d.trainingCategory)),
      };
    }));
  }, [ membersTrainingStatus, reportingPeriodSelection ]);

  useEffect(() => {
    // Set up the Reporting period filter.
    if (!reportingPeriods) {
      return;
    }

    // Always include 'All':
    const filterSelections: IReportingPeriodSelectionInfo[] = [{ endDate: null, startDate: null, text: 'All', value: 'ALL' }];

    // If there is a reporting period date, then the first reporting period will be 'Before <first reporting period date>':
    const firstDate = reportingPeriods[0]?.startDate ?? null;
    let currFilterSelectionIdx = 0;
    if (firstDate) {
      filterSelections.push({ endDate: firstDate, startDate: null, text: `Before ${formatDate(firstDate)}`, value: 'EARLIEST' });
      currFilterSelectionIdx++;
    }

    // Insert the remaining date ranges:
    reportingPeriods.forEach((period, idx) => {
      const filterStr = `${getDateRangeStr(reportingPeriods, idx)}${period.name ? ` (${period.name})` : ''}`;
      filterSelections.push({ endDate: reportingPeriods[idx + 1]?.startDate ?? null, startDate: period.startDate, text: filterStr, value: period.id });
      if (moment(period.startDate).isBefore(TODAYS_DATE)) {
        currFilterSelectionIdx++;
      }
    });

    if (currFilterSelectionIdx > 0) {
      filterSelections[currFilterSelectionIdx].text = filterSelections[currFilterSelectionIdx].text.concat(' - Current');
    }

    setReportingPeriodSelections(filterSelections);
    setReportingPeriodSelection(filterSelections[currFilterSelectionIdx]);
    setIsLoading(false); // only relevant for the initial page load
  }, [ reportingPeriods ]);

  const handleClickSendBulkRemind = (dataIdxs: number[]) => () => {
    setSelectedMembers(dataIdxs.map(idx => membersTrainingStatus[idx]));
    setIsRemindDialogOpen(true);
  };

  const handleReportingPeriodChange = (newValue: string) => {
    setReportingPeriodSelection(reportingPeriodSelections.find(p => p.value === newValue));
  };

  const handleCloseTrainingReminderDialog = () => {
    setIsRemindDialogOpen(false);
    setSelectedMembers([]);
    setRowsSelected([]);
  };

  const handleSaveTrainingReminder = (remindedMembersInfo: ITrainingRemindedMember[]) => {
    const clonedMembersTrainingStatus = membersTrainingStatus.slice();

    remindedMembersInfo.forEach(({ remindedAt, memberId, videoId }) => {
      const dataIdx = clonedMembersTrainingStatus.findIndex(m => m.userId === memberId && m.videoId === videoId);
      if (dataIdx >= 0) {
        clonedMembersTrainingStatus[dataIdx].remindedAt = remindedAt;
      }
    });

    setMembersTrainingStatus(clonedMembersTrainingStatus);
  };

  return (<>
    {isLoading ? (
      <Page sx={{ minHeight: '70vh' }}>
        <Typography sx={{ pl: 3 }} variant="h6" gutterBottom>
          Training Coverage Report
        </Typography>
        <Typography sx={{ p: 4 }}>
          Loading...
        </Typography>
      </Page>
    ) : (
      <SpioDataTable
        title={<DataTableTitleWithFilter
          filterLabel="Reporting period"
          filterSelection={reportingPeriodSelection}
          filterSelections={reportingPeriodSelections}
          onFilterChange={handleReportingPeriodChange}
          title="Training Coverage Report"
        />}
        columns={getTableColumns(tableData)}
        data={tableData}
        initFilters={[[ 'False' ]]}
        options={{
          customToolbarSelect: ({ data }) => (
            <Box sx={{ lineHeight: '96px', pr: 4 }}>
              <Button
                variant="contained"
                onClick={handleClickSendBulkRemind(data.map(row => row.dataIndex))}
                disabled={isBulkRemindDisabled}
              >
                Send reminder emails
              </Button>
              <Button onClick={() => setRowsSelected([])}>
                Cancel
              </Button>
            </Box>
          ),
          downloadOptions: {
            filename: `TrainingReport_${TODAYS_DATE.format('YYYYMMDD')}.csv`,
            filterOptions: {
              useDisplayedRowsOnly: true,
            },
          },
          filterType: 'multiselect',
          onRowSelectionChange: (_1, _2, theRowsSelected) => {
            setRowsSelected(theRowsSelected ?? []);
          },
          rowsSelected,
          selectableRows: 'multiple',
          textLabels: {
            body: {
              noMatch: isLoading ? 'Loading...' : 'No users found',
              toolTip: 'Sort',
            },
          },
        }}
      />
    )}
    <TrainingReminderDialog
      members={selectedMembers}
      open={isRemindDialogOpen}
      onClose={handleCloseTrainingReminderDialog}
      onSave={handleSaveTrainingReminder}
    />
  </>);
}

TheTrainingReportPage.requiredAuthZ = {
  tier: 1,
  permission: 'training:manage',
};
TheTrainingReportPage.routePath = '/training/report';
TheTrainingReportPage.title = 'Training Report';

export default TheTrainingReportPage;
