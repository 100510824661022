import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';

function CloseButton(props: IconButtonProps) {
  const { 'aria-label': ariaLabel, ...otherProps } = props;
  return (
    <IconButton aria-label={ariaLabel || 'close'} {...otherProps} size="large">
      <CloseIcon />
    </IconButton>
  );
}

export default CloseButton;
