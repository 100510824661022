import {
  Card,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import IdentityIcon from '@mui/icons-material/PermIdentityTwoTone';
import * as Sentry from '@sentry/react';
import { sortBy } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { IProviderOrgSummary } from '../../../backend/src/user-audit/interfaces';
import Page from '../components/Page';
import { showErrorResultBar } from '../components/ResultSnackbar';
import API from '../services/ApiService';
import { BodyText } from '../components/BodyText';
import { TheUserAuditAwsPage, TheUserAuditAzurePage, TheUserAuditGithubPage, TheUserAuditGooglePage } from '.';
import { useNavigate, useSearchParams } from 'react-router-dom';
import UserAuditHistoryTable from '../components/user-audit/UserAuditHistoryTable';

const useTheUserAuditPageStyles = makeStyles({
  identitiesContainer: {
    marginTop: '2rem',
    marginBottom: '5rem',
  },
  identityIcon: {
    fontSize: '100px',
  },
  iconGreen: {
    color: 'green',
  },
  iconRed: {
    color: 'red',
  },
  orgSummaryList: {
    paddingRight: '1rem',
    paddingLeft: '1rem',
  },
  progressCircle: {
    margin: '2rem auto 0 auto',
    display: 'block',
  },
  providerCardHeader: {
    color: 'inherit',
  },
  disabledProviderCard: {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    color: 'rgba(0, 0, 0, 0.26)',
  },
  activeProviderCard: {
    '&:hover': {
      cursor: 'pointer',
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    },
  },
});

function IntroText() {
  return <>
    <BodyText>
      The User Audit allows you to automatically get the current organizational
      users and steps you through the process of auditing them – verifying
      that the correct users are present and in the correct roles.
    </BodyText>
    <BodyText>
      Click a provider from the list above to set up a new audit or to
      check the latest results.
    </BodyText>
  </>;
}

const MAX_DAYS_BETWEEN_AUDITS = 30;

function OrgStatusIcon({ org }: { org: IProviderOrgSummary }) {
  const classes = useTheUserAuditPageStyles();

  const audits = sortBy(org.syncs, [ 'syncedAt' ]).reverse();

  const lastConfirmedAudit = audits.find(audit => audit.status === 'confirmed');
  const lastConfirmedSyncDate = lastConfirmedAudit?.syncedAt;
  const daysSinceLastConfirmedSync = moment().utc().diff(lastConfirmedSyncDate, 'days');
  const daysAgo = moment(lastConfirmedSyncDate).fromNow();
  const isOkay = org.isConnected && lastConfirmedSyncDate && (daysSinceLastConfirmedSync <= MAX_DAYS_BETWEEN_AUDITS);

  let tooltipText;

  if (!org.isConnected) {
    tooltipText = 'Disconnected';
  } else if (!lastConfirmedSyncDate) {
    tooltipText = 'Never audited';
  } else if (daysSinceLastConfirmedSync > MAX_DAYS_BETWEEN_AUDITS) {
    tooltipText = `Over 30 days since data of last audit (${daysAgo})`;
  } else {
    tooltipText = `Last audited with data of ${daysAgo}`;
  }

  return (
    <>
      <Tooltip
        title={tooltipText}
      >
        {isOkay ? (
          <CheckCircleIcon
            color="primary"
            classes={{ colorPrimary: classes.iconGreen }}
            fontSize="small"
          />
        ) : (
          <CancelIcon
            color="primary"
            classes={{ colorPrimary: classes.iconRed }}
            fontSize="small"
          />
        )}
      </Tooltip>
    </>
  );
}

const handleLoadingError = (err: any, provider: string) => {
  showErrorResultBar(`Unexpected error fetching ${provider} orgs`);
  Sentry.captureException(err);
};

interface IProviderCard {
  name: string;
  route: string;
  isLoading: () => boolean;
  disabled: boolean;
  orgs: IProviderOrgSummary[];
}
function TheUserAuditPage() {
  const [ isAwsLoading, setIsAwsLoading ] = useState<boolean>(false);
  const [ isAzureLoading, setIsAzureLoading ] = useState<boolean>(false);
  const [ isGithubLoading, setIsGithubLoading ] = useState<boolean>(false);
  const [ isGoogleLoading, setIsGoogleLoading ] = useState<boolean>(false);
  const [ awsOrgs, setAwsOrgs ] = useState<IProviderOrgSummary[]>([]);
  const [ azureOrgs, setAzureOrgs ] = useState<IProviderOrgSummary[]>([]);
  const [ githubOrgs, setGithubOrgs ] = useState<IProviderOrgSummary[]>([]);
  const [ googleOrgs, setGoogleOrgs ] = useState<IProviderOrgSummary[]>([]);
  const [ selectedTabIdx, setSelectedTabIdx ] = useState(0);
  const [ searchParams ] = useSearchParams();
  const navigate = useNavigate();

  const classes = useTheUserAuditPageStyles();

  // Initial page load:
  useEffect(() => {
    refreshAwsSummary();
    refreshAzureSummary();
    refreshGithubSummary();
    refreshGoogleSummary();
  }, []);

  useEffect(() => {
      const tabIdx = parseInt(searchParams.get('tab') ?? '0', 10);
      setSelectedTabIdx(isNaN(tabIdx) ? 0 : tabIdx);
  }, [searchParams]);

  const PROVIDERS: IProviderCard[] = [
    {
      name: 'Google Workspace',
      route: TheUserAuditGooglePage.routePath,
      isLoading: () => isGoogleLoading,
      disabled: process.env.REACT_APP_DISABLE_GOOGLE_USER_AUDIT !== undefined,
      orgs: googleOrgs,
    },
    {
      name: 'AWS',
      route: TheUserAuditAwsPage.routePath,
      isLoading: () => isAwsLoading,
      disabled: false,
      orgs: awsOrgs,
    },
    {
      name: 'GitHub',
      route: TheUserAuditGithubPage.routePath,
      isLoading: () => isGithubLoading,
      disabled: false,
      orgs: githubOrgs,
    },
    {
      name: 'Azure AD',
      route: TheUserAuditAzurePage.routePath,
      isLoading: () => isAzureLoading,
      disabled: false,
      orgs: azureOrgs,
    },
  ];

  const refreshAwsSummary = () => {
    setIsAwsLoading(true);

    API.get('userAudit/aws/summary')
      .then(res => setAwsOrgs(res.data.data ?? []))
      .catch(err => handleLoadingError(err, 'AWS'))
      .finally(() => setIsAwsLoading(false));
  };

  const refreshAzureSummary = () => {
    setIsAzureLoading(true);

    API.get('userAudit/azure/summary')
      .then(res => setAzureOrgs(res.data.data ?? []))
      .catch(err => handleLoadingError(err, 'Azure'))
      .finally(() => setIsAzureLoading(false));
  };

  const refreshGithubSummary = () => {
    setIsGithubLoading(true);

    API.get('userAudit/github/summary')
      .then(res => setGithubOrgs(res.data.data ?? []))
      .catch(err => handleLoadingError(err, 'Github'))
      .finally(() => setIsGithubLoading(false));
  };

  const refreshGoogleSummary = () => {
    setIsGoogleLoading(true);

    API.get('userAudit/google/summary')
      .then(res => setGoogleOrgs(res.data.data ?? []))
      .catch(err => handleLoadingError(err, 'Google'))
      .finally(() => setIsGoogleLoading(false));
  };

  const onClickProvider = (provider: IProviderCard) => () => {
    if (!provider.disabled) {
      navTo(provider.route);
    }
  };

  const navTo = (route: string) => {
    navigate(route);
  };

  return (
    <>
      <Tabs
        value={selectedTabIdx}
        onChange={(e, tabIdx) => setSelectedTabIdx(tabIdx)}
      >
        <Tab label="Overview" />
        <Tab label="History" />
      </Tabs>
        {selectedTabIdx === 0 &&
              <Page center>
              <Grid
                className={classes.identitiesContainer}
                container
              >
                {PROVIDERS.map((provider) => {
                  return (
                    <Grid
                      key={provider.name}
                      item
                      xs={6}
                      sm={3}
                    >
                      <Card
                        className={provider.disabled ? classes.disabledProviderCard : classes.activeProviderCard}
                        onClick={onClickProvider(provider)}
                      >
                        <Typography
                          className={classes.providerCardHeader}
                          variant="h6"
                        >
                          {provider.name}
                        </Typography>
                        <div>
                          <IdentityIcon className={classes.identityIcon} />
                        </div>
                      </Card>
                      {provider.isLoading() ? (
                        <CircularProgress
                          className={classes.progressCircle}
                        />
                      ) : (
                        <List
                          className={classes.orgSummaryList}
                          dense
                        >
                          {provider.orgs.map(org => (
                            <ListItem key={org.id}>
                              <ListItemText
                                primary={org.name}
                                primaryTypographyProps={{ variant: 'body1' }}
                              />
                              <ListItemSecondaryAction>
                                <OrgStatusIcon org={org} />
                              </ListItemSecondaryAction>
                            </ListItem>
                          ))}
                        </List>
                      )}
                    </Grid>
                  );
                })}
                <Grid item xs={12}>
                  <IntroText />
                </Grid>
              </Grid>
            </Page>
      }
      {selectedTabIdx === 1 &&
        <UserAuditHistoryTable />
      }

    </>
  );
}

TheUserAuditPage.icon = <PermIdentityIcon />;
TheUserAuditPage.requiredAuthZ = {
  tier: 2,
  permission: 'user_audit',
};
TheUserAuditPage.routePath = '/userAudit';
TheUserAuditPage.tabs = {
  default: 0,
  history: 1,
};
TheUserAuditPage.title = 'User Audit';

export default TheUserAuditPage;
