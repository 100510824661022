import { Button, DialogActions, DialogContent, DialogProps } from '@mui/material';
import * as Sentry from '@sentry/react';
import { Formik } from 'formik';
import { sortBy } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { IReportingPeriodDto } from '../../../../backend/src/reporting-period/interfaces';
import API from '../../services/ApiService';
import { formatDate } from '../../helpers';
import { SaveButton } from '../buttons';
import { ResponsiveDialog } from '../dialogs';
import { FormikDatePicker, FormikTextField } from '../forms';
import { showErrorResultBar } from '../ResultSnackbar';
import StyledDialogTitle from '../StyledDialogTitle';
import { getDefaultLastPeriodName } from './ReportingPeriodCreateDialog';

interface IFormValues {
  name: string,
  startDate: Date,
  endDate: Date | null,
}

const getInitialFormValues = (reportingPeriod: IReportingPeriodDto, nextPeriod: IReportingPeriodDto | null): IFormValues => {
  const name = reportingPeriod.name;
  const startDate = reportingPeriod.startDate;
  const endDate = nextPeriod?.startDate ? moment(nextPeriod.startDate).subtract(1, 'day').toDate() : null;

  return { endDate, name, startDate };
};
export interface ReportingPeriodEditDialogProps extends DialogProps {
  reportingPeriod: IReportingPeriodDto;
  reportingPeriods: IReportingPeriodDto[];
  onClose: () => void;
  onSave: (newReportingPeriods: IReportingPeriodDto[]) => void;
}

export default function ReportingPeriodEditDialog(props: ReportingPeriodEditDialogProps) {
  const { onClose, onSave, open, reportingPeriod, reportingPeriods } = props;
  const [ nextPeriod, setNextPeriod ] = useState<IReportingPeriodDto | null>(null);
  const [ minStartDate, setMinStartDate ] = useState<Date | undefined>();
  const [ maxEndDate, setMaxEndDate ] = useState<Date | undefined>();
  const [ toUpdateNextPeriodName, setToUpdateNextPeriodName ] = useState(false);

  const FormValidationSchema = useMemo(() => Yup.object({
    endDate: Yup
      .date()
      .when('startDate', (startDate, schema) => moment(startDate).isValid() ? (
        schema.min(moment(startDate).utc().startOf('day').toDate(), 'Should be on or after the start date.')
      ) : (
        schema
      ))
      .max(maxEndDate ?? new Date(9999, 1, 1), `Should be before the next period's end date (${moment(maxEndDate).utc().add(1, 'day').format('YYYY-MM-DD')}).`)
      .required('Required')
      .typeError('Invalid date'),
    name: Yup
      .string()
      .required('Required')
      .label('Reporting period name'),
    startDate: Yup
      .date()
      .min(minStartDate ?? new Date(0), `Should be after the previous period's start date (at least ${moment(minStartDate).utc().format('YYYY-MM-DD')}).`)
      .required('Required')
      .typeError('Invalid date'),
  }), [ maxEndDate, minStartDate ]);

  useEffect(() => {
    let theNextPeriod: IReportingPeriodDto | null = null;
    let theMinStartDate: Date | undefined;
    let theMaxEndDate: Date | undefined;
    let toUpdateName = false;

    const sortedPeriods = sortBy(reportingPeriods, [ 'startDate' ]);
    const currPeriodIdx = sortedPeriods.findIndex(p => p.id === reportingPeriod.id);

    if (currPeriodIdx !== -1) {
      if (currPeriodIdx > 0) {
        theMinStartDate = moment(sortedPeriods[currPeriodIdx - 1].startDate).add(1, 'day').utc().startOf('day').toDate();
      }

      if (currPeriodIdx < sortedPeriods.length - 1) {
        theNextPeriod = sortedPeriods[currPeriodIdx + 1];
      }

      if (currPeriodIdx < sortedPeriods.length - 2) {
        theMaxEndDate = moment(sortedPeriods[currPeriodIdx + 2].startDate).subtract(1, 'day').utc().startOf('day').toDate();
      }

      toUpdateName = currPeriodIdx === sortedPeriods.length - 2;
    }

    setNextPeriod(theNextPeriod);
    setMinStartDate(theMinStartDate);
    setMaxEndDate(theMaxEndDate);
    setToUpdateNextPeriodName(toUpdateName);
  }, [ reportingPeriod, reportingPeriods ]);

  const handleSave = async (formValues: IFormValues) => {
    try {
      let responseReportingPeriods: IReportingPeriodDto[] = [];

      // Update the end date (ie, the start date of the next period) if it's changed;
      // if we're editing the final date range, then update the default name associated with the final date:
      if (nextPeriod !== null && (toUpdateNextPeriodName || !moment(formValues.endDate).add(1, 'day').isSame(nextPeriod.startDate, 'day'))) {
        responseReportingPeriods = [ (await API.patch(`reportingPeriod/${nextPeriod.id}`, {
          name: toUpdateNextPeriodName ? getDefaultLastPeriodName(formValues.name) : undefined,
          startDate: moment(formValues.endDate).add(1, 'day').toDate(),
        })).data?.data ];
      }

      // Update the start date and name of this period:
      responseReportingPeriods.push((await API.patch(`reportingPeriod/${reportingPeriod.id}`, {
        name: formValues.name,
        startDate: formValues.startDate,
      })).data?.data);

      onSave(responseReportingPeriods);
      onClose();
    } catch (err: any) {
      const errorMsg = err.response?.data?.error ?? 'Unexpected error saving reporting period data';
      showErrorResultBar(errorMsg);
      Sentry.captureException(err);
    }
  };

  return (
    <ResponsiveDialog
      disableBackdropClick
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
    >
      <StyledDialogTitle onClose={onClose}>
        {reportingPeriods.length === 0 ?
          'New reporting period' :
          reportingPeriod === null ?
            'Add date to reporting period timeline' :
            'Edit reporting period name or date'
        }
      </StyledDialogTitle>
      <Formik
        initialValues={getInitialFormValues(reportingPeriod, nextPeriod)}
        validateOnBlur={false}
        validationSchema={FormValidationSchema}
        onReset={onClose}
        onSubmit={async (values, { setSubmitting }) => {
          await handleSave(values);
          setSubmitting(false);
        }}
      >
        {formikProps => (
          <form>
            <DialogContent>
              <FormikTextField
                field="name"
                formikProps={formikProps}
                helperTextStr="Name for this reporting period."
                label="Name"
                placeholder={`FY ${moment(formikProps.values.startDate).isValid() ? moment(formikProps.values.startDate).year() : moment().year()}`}
                required
              />
              <FormikDatePicker
                field="startDate"
                formikProps={formikProps}
                helperTextStr={
                  minStartDate !== undefined &&
                  moment(formikProps.values.startDate).isValid() &&
                  !moment(formikProps.values.startDate).isSame(reportingPeriod.startDate, 'day') ?
                  `Note that changing this date from ${formatDate(reportingPeriod.startDate)} will alter the end date of the previous period.` : ''}
                label="Start date"
                maxDate={moment(formikProps.values.endDate)}
                minDate={moment(minStartDate)}
                required
              />
              <FormikDatePicker
                field="endDate"
                formikProps={formikProps}
                helperTextStr={
                  nextPeriod !== null &&
                  moment(formikProps.values.endDate).isValid() &&
                  !moment(formikProps.values.endDate).add(1, 'day').isSame(nextPeriod.startDate, 'day') ?
                  `Note that changing this date from ${formatDate(moment(nextPeriod.startDate).subtract(1, 'day'))} will alter the start date of the next period.` : ''}
                label="End date"
                maxDate={moment(maxEndDate)}
                minDate={moment(formikProps.values.startDate)}
                required
              />
            </DialogContent>
            <DialogActions disableSpacing>
              <Button
                disabled={formikProps.isSubmitting}
                onClick={formikProps.handleReset}
                color="primary"
              >
                Cancel
              </Button>
              <SaveButton
                disabled={
                  formikProps.isSubmitting ||
                  Object.values(formikProps.errors).filter(v => !!v).length > 0
                }
                type="submit"
                onClick={formikProps.handleSubmit}
              />
            </DialogActions>
          </form>
        )}
      </Formik>
    </ResponsiveDialog>
  );
}
