import { DatePicker, DatePickerProps } from '@mui/lab';
import { BaseTextFieldProps, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormikProps, FormikValues } from 'formik';
import moment from 'moment';
import React from 'react';
import { helperAndErrorText } from '.';

const useStyles = makeStyles({
  inputField: {
    marginRight: 0,
    marginLeft: 0,
  },
});

interface FormikDatePickerProps<Values extends FormikValues> extends Partial<DatePickerProps> {
  field: keyof Values;
  formikProps: FormikProps<Values>;
  helperTextStr?: string; // can be overridden with the usual 'helperText' prop
  initValue?: Date | null; // don't use Formik's values[field] for this b/c it gets updated with each 'onChange'
  nullable?: boolean;
  required?: Boolean;
  textFieldProps?: BaseTextFieldProps;
}

export default function FormikDatePicker<Values extends FormikValues>(props: FormikDatePickerProps<Values>) {
  const classes = useStyles();
  const {
    children,
    field,
    formikProps,
    helperTextStr,
    initValue = null,
    nullable = false,
    textFieldProps,
    ...otherDatePickerProps
  } = props;
  const { errors, setFieldValue, values } = formikProps;
  const fieldStr = field.toString();

  return (
    <DatePicker
      value={values[field]}
      onChange={val => setFieldValue(fieldStr, val, true)}
      inputFormat="YYYY-MM-DD"
      mask="____-__-__"
      renderInput={props => (
        <TextField
          {...props}
          className={classes.inputField}
          fullWidth
          helperText={helperAndErrorText(helperTextStr, errors[field]?.toString(), true)}
          variant="filled"
          error={!!errors[field]}
          onBlur={(e) => {
            if (!e.target.value) {
              setFieldValue(fieldStr, nullable ? null : (initValue ?? moment()), false);
            }
          }}
          {...textFieldProps}
        />
      )}
      {...otherDatePickerProps}
    />
  );
}
