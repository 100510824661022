import { IRequiredAuthZ } from '../pages/interfaces';
import { useOutletElement } from '.';

export default function useOutletPageMetadata() {
  const outletEl = useOutletElement();
  const requiredAuthZ: IRequiredAuthZ | undefined = outletEl['requiredAuthZ'] ?? undefined;
  const title: string | undefined = outletEl['title'] ?? undefined;

  return { requiredAuthZ, title };
}
