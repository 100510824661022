import { Button, Card, CardActions, CardContent, Grid, LinearProgress, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { padStart } from 'lodash';
import React, { useEffect, useState } from 'react';
import Stripe from 'stripe';
import { LinedCardHeader } from './cards';
import classNames from 'classnames';

const useStyles = makeStyles({
  card: {
    margin: 0,
    padding: 0,
  },

  fullHeight: {
    height: '100%',
  },
});

export interface PaymentSourceCardProps {
  isLoading: boolean;
  paymentMethod?: Stripe.PaymentMethod;
  stretchHeight?: boolean;
  onEditClick: () => void;
}

export function PaymentMethodCard({ isLoading, onEditClick, paymentMethod, stretchHeight }: PaymentSourceCardProps) {
  const classes = useStyles();

  const [ expDateStr, setExpDateStr ] = useState<string>('');

  useEffect(() => {
    let expDate = '';
    const { exp_month, exp_year } = (paymentMethod && paymentMethod.card) || {
      exp_month: undefined,
      exp_year: undefined,
    };

    if (exp_month !== undefined && exp_year !== undefined) {
      const monthStr = padStart(`${exp_month}`, 1, '0');
      const yearStr = exp_year.toString().slice(2);

      expDate = `${monthStr}/${yearStr}`;
    }

    setExpDateStr(expDate);
  }, [ paymentMethod ]);

  return (
    <Card
      className={classNames(classes.card, stretchHeight && classes.fullHeight)}
    >
      <LinedCardHeader
        title="Payment method"
        subheader={isLoading && <LinearProgress />}
      />
      <CardContent>
        {!paymentMethod ? (
          <Typography variant="body1">
            None on file
          </Typography>
        ) : (
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="body1">
                {paymentMethod && paymentMethod.card!.brand.toUpperCase()} &bull;&bull;&bull;&bull; {paymentMethod && paymentMethod.card!.last4}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">
                Expires {expDateStr}
              </Typography>
            </Grid>
          </Grid>
        )
        }
      </CardContent>
      <CardActions>
        <Button
          color="primary"
          onClick={onEditClick}
        >
          Edit
        </Button>
      </CardActions>
    </Card>
  );
}
