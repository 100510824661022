import React, { useEffect, useState } from 'react';
import { Card, CardContent } from '@mui/material';
import * as Sentry from '@sentry/browser';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { IMetricsSummaryDto } from '../../../../backend/src/metrics/interfaces';
import API from '../../services/ApiService';
import WidgetTitle from '../WidgetTitle';
import { formatDate } from '../../helpers';
import {
  TheNetworkScanPage,
  ThePolicyPage,
  TheQuestionnairePage,
  TheRiskRegisterPage,
  TheVendorTrackingPage,
} from '../../pages';
import SmallOpenInIconWithLink from '../icons/SmallOpenInNowIconWithLink';


function MetricsSummary() {
  const [ metricsData, setMetricsData ] = useState<IMetricsSummaryDto>();

  useEffect(() => {
    API.get('metrics/summary')
      .then((res) => {
        const newMetricsData = res.data?.data ?? {};
        setMetricsData(newMetricsData);
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
  }, []);

  return (
    <Card>
      <WidgetTitle to="/dashboard">Program Metrics</WidgetTitle>
      <TableContainer component={CardContent} sx={{ padding: 0, margin: 0 }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell variant="head" scope="row">
                <SmallOpenInIconWithLink to={ThePolicyPage.routePath} />Last Policy Approval
              </TableCell>
              <TableCell align="right">
                {(metricsData?.lastPolicyUpdate != null) ? formatDate(metricsData?.lastPolicyUpdate) : ''}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head" scope="row">
                <SmallOpenInIconWithLink to={TheNetworkScanPage.routePath} />Last Scan
              </TableCell>
              <TableCell align="right">
                {(metricsData?.lastScanDate != null) ? formatDate(metricsData?.lastScanDate) : ''}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head" scope="row">
                <SmallOpenInIconWithLink to={TheRiskRegisterPage.routePath} />Open Risks
              </TableCell>
              <TableCell align="right">
                {metricsData?.openRiskCount}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head" scope="row">
                <SmallOpenInIconWithLink to={TheVendorTrackingPage.routePath} />Open Vendors
              </TableCell>
              <TableCell align="right">
                {metricsData?.openVendorCount}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head" scope="row">
                <SmallOpenInIconWithLink to={TheQuestionnairePage.routePath} />Questionnaires
              </TableCell>
              <TableCell align="right">
                {metricsData?.openQuestionnaireCount}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}

MetricsSummary.requiredAuthZ = {
  tier: 2,
  permission: 'dashboard',
};

export default MetricsSummary;
