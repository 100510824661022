import { CardHeader } from '@mui/material';
import { withStyles } from '@mui/styles';

export const LinedCardHeader = withStyles({
  root: {
    padding: '0.5rem',
    borderBottom: '1px solid lightgray',
    margin: '0.5rem',
  },

  action: {
    margin: '1rem 0.5rem 0 0',
  },
}, {
  name: 'LinedCardHeader',
})(CardHeader);
