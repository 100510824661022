import { Theme } from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { ITagDto } from '../../../backend/src/tag/interfaces';
import { ITaskMaster } from '../../../backend/src/task/interfaces/task-master.interface';
import { intervalToString } from '../helpers';
import API from '../services/ApiService';
import DataTableTitleWithButton from './DataTableTitleWithButton';
import { TaskMasterEditDialog } from './dialogs';
import { showErrorResultBar } from './ResultSnackbar';
import SpioDataTable, { SpioDataTableColumn } from './SpioDataTable';

const styles = (theme: Theme) => createStyles({
  [theme.breakpoints.up('md')]: {
    tagDn: {
      display: 'none',
    },
  },
  [theme.breakpoints.down('md')]: {
    tagUp: {
      display: 'none',
    },
  },
});

const getTableColumns = (tableData: ITableDatum[], { classes }: WithStyles<typeof styles>): SpioDataTableColumn[] => [
  {
    name: 'name',
    label: 'Name',
    options: {
      customFilterListOptions: { render: v => `Name: ${v}` },
    },
  },
  {
    name: 'description',
    label: 'Description',
    options: {
      customFilterListOptions: { render: v => `Description: ${v}` },
      display: 'false',
    },
  },
  {
    name: 'policyTemplatesStr',
    label: 'Policies',
    options: {
      customBodyRenderLite: dataIndex => <>
        {tableData[dataIndex]?.policyTemplates?.map(p => <div key={p.id}>{p.name}</div>)}
      </>,
      customFilterListOptions: { render: v => `Policies: ${v}` },
    },
  },
  {
    name: 'tags',
    label: 'Tags',
    options: {
      customBodyRenderLite: (dataIndex) => {
        const tagNames = tableData[dataIndex]?.tags?.map(tag => tag.name).sort() ?? [];

        return <>
          {tagNames.map(tagName => <div className={classes ? classes.tagUp : ''} key={tagName}>{tagName}</div>)}
          {<span className={classes ? classes.tagDn : ''}>{tagNames.join(', ')}</span>}
        </>;
      },
      display: 'false',
      download: false,
      filter: false,
      setCellProps: () => Object({ nowrap: 'true' }),
      searchable: false,
      sort: false,
    },
  },
  {
    name: 'tagsSearchableStr',
    label: 'Tags',
    options: {
      customFilterListOptions: { render: v => `Tags: ${v}` },
      download: true,
      display: 'excluded',
      filter: true,
    },
  },
  {
    name: 'recurrenceStr',
    label: 'Recurrence',
    options: {
      customFilterListOptions: { render: v => `Recurrence: ${v}` },
      filterType: 'multiselect',
    },
  },
  {
    name: 'defaultDurationStr',
    label: 'Duration',
    options: {
      customFilterListOptions: { render: v => `Duration: ${v}` },
      filterType: 'multiselect',
    },
  },
  {
    name: 'progression',
    label: 'Priority',
    options: {
      filter: false,
    },
  },
];

export interface TaskMasterTableProps extends WithStyles<typeof styles> {
  tags: ITagDto[];
  updatedTag?: ITagDto;
}

interface ITableDatum extends ITaskMaster {
  defaultDurationStr: string;
  policyTemplatesStr: string;
  recurrenceStr: string;
  tagsSearchableStr: string;
}

function TaskMasterTable({ classes, tags, updatedTag }: TaskMasterTableProps) {
  const [ detailsOpen, setDetailsOpen ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ selectedIdx, setSelectedIdx ] = useState(0);
  const [ selectedTaskMaster, setSelectedTaskMaster ] = useState<ITaskMaster | null>(null);
  const [ tableData, setTableData ] = useState<ITableDatum[]>([]);
  const [ taskMasters, setTaskMasters ] = useState<ITaskMaster[]>([]);

  // The task masters get refreshed on page load
  // and when we update or delete a tag in the TagTable.
  useEffect(() => {
    setIsLoading(true);

    API.get('taskMaster')
      .then(res => setTaskMasters(res.data))
      .catch(() => showErrorResultBar('Unexpected error loading Task Master data'))
      .finally(() => setIsLoading(false));
  }, [ updatedTag ]);

  useEffect(() => {
    setTableData(taskMasters.map(d => Object({
      ...d,
      defaultDurationStr: intervalToString(d.defaultDuration),
      policyTemplatesStr: d.policyTemplates?.map(p => p.name).join(', ') ?? '',
      recurrenceStr: intervalToString(d.recurrence),
      tagsSearchableStr: (d.tags || []).map(tag => tag.name).join(','),
    })));
  }, [ taskMasters ]);

  const handleUpdate = (taskMaster: ITaskMaster) => {
    let dataCopy;

    if (selectedIdx === -1) {
      dataCopy = [ taskMaster ].concat(taskMasters);
      setSelectedIdx(0);
    } else {
      dataCopy = taskMasters.slice();
      dataCopy[selectedIdx] = taskMaster;
    }

    setTaskMasters(dataCopy);
  };

  function openAddDialog() {
    setSelectedIdx(-1);
    setSelectedTaskMaster(null);
    setDetailsOpen(true);
  }

  function openDetails(idx: number) {
    setSelectedIdx(idx);
    setSelectedTaskMaster(taskMasters[idx]);
    setDetailsOpen(true);
  }

  return <>
    <SpioDataTable
      title={<DataTableTitleWithButton
        onButtonClick={openAddDialog}
        title="Task Master"
      />}
      columns={getTableColumns(tableData, { classes })}
      data={tableData}
      options={{
        // By default the search is skipped for columns with display 'false' or 'excluded'.
        // We want to include those columns and only skip those with searchable 'false'.
        customSearch: (searchQuery, currentRow, columns) => {
          return columns.some((col, i) => col.searchable && currentRow[i]?.toString().indexOf(searchQuery.toLowerCase()) >= 0);
        },
        filterType: 'textField',
        onRowClick: (_, rowMeta) => openDetails(rowMeta.dataIndex),
        print: false,
        selectableRows: 'none',
        textLabels: {
          body: { noMatch: isLoading ? 'Loading...' : 'No records found' },
        },
      }}
    />
    {detailsOpen && (
      <TaskMasterEditDialog
        onClose={() => setDetailsOpen(false)}
        onUpdate={handleUpdate}
        open={detailsOpen}
        tags={tags}
        taskMaster={selectedTaskMaster}
      />
    )}
  </>;
}

export default withStyles(styles, { withTheme: true })(TaskMasterTable);
