import * as Sentry from '@sentry/react';

enum SESSION_STORAGE_KEY {
  AUTH_RESULT = 'authResult',
  IS_LOGGED_IN = 'isLoggedIn',
  LOGIN_REDIRECT_URL = 'SPIO.redirectUrl',
  TASKS_FILTERS = 'SPIO.tasksFilters',
  TASKS_SEARCH = 'SPIO.tasksSearch',
  TASKS_VIEW = 'SPIO.tasksView',
};

enum LOCAL_STORAGE_KEY {
  DASHBOARD_TASKS_FILTER = 'SPIO.dashboardTasksFilter',
  DASHBOARD_TOP_TASKS_TAB = 'SPIO.dashboardTopTasksTab',
  DATA_TABLE_PAGE_SIZE = 'SPIO.dataTablePageSize',
  RETURNING_VISITOR = 'returningVisitor',
};

export const STORAGE_KEYS = { ...SESSION_STORAGE_KEY, ...LOCAL_STORAGE_KEY };

export const clearStorage = () => {
  Object.values(SESSION_STORAGE_KEY).forEach(key => sessionStorage.removeItem(key));
  Object.values(LOCAL_STORAGE_KEY).forEach(key => localStorage.removeItem(key));
};

export const getStorageValue = (key: SESSION_STORAGE_KEY | LOCAL_STORAGE_KEY) => {
  const storage = isSessionStorage(key) ? sessionStorage : localStorage;
  const valueStr = storage.getItem(key);

  try {
    return (valueStr && JSON.parse(valueStr)) || undefined;
  } catch {
    return undefined;
  }
};

export const setStorageValue = (key: SESSION_STORAGE_KEY | LOCAL_STORAGE_KEY, value?: any) => {
  const storage = isSessionStorage(key) ? sessionStorage : localStorage;

  if (value === undefined) {
    storage.removeItem(key);
  } else {
    try {
      storage.setItem(key, JSON.stringify(value));
    } catch (err) {
      Sentry.captureException(err);
    }
  }
};

const isSessionStorage = (key: SESSION_STORAGE_KEY | LOCAL_STORAGE_KEY): key is SESSION_STORAGE_KEY => {
  const sessionKeys: string[] = Object.values(SESSION_STORAGE_KEY);

  return sessionKeys.includes(key);
};
