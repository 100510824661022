import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import React from 'react';
import BuildingBlocksImage from '../components/BuildingBlocksImage';
import Page from '../components/Page';
import PageActions from '../components/PageActions';
import { showErrorResultBar, showSuccessResultBar } from '../components/ResultSnackbar';
import API from '../services/ApiService';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles({
  primaryText: {
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '50rem',
    paddingLeft: '1em',
    paddingRight: '1em',
  },
});

type TheBetaPageRouteParams = {
  feature: string | undefined;
}

function TheBetaPage() {
  const classes = useStyles();
  const params = useParams<TheBetaPageRouteParams>();
  const feature = params.feature;

  async function handleInviteRequest() {
    try {
      await API.post('org/requestBetaInvite', {
          feature,
        },
      );

      showSuccessResultBar('Your request has been logged. We\'ll be in touch soon!');
    } catch (err: any) {
      showErrorResultBar('Something went wrong processing your request. Try again later.');
      throw err;
    }
  }

  return (
    <Page center>
      <BuildingBlocksImage />
      <Typography variant="h6">
        {feature ? feature : 'Feature'} is in Beta
      </Typography>
      <Typography className={classes!.primaryText} variant="body1">
        This feature is beta status and still being developed. Access to use it is currently by invitation only.
      </Typography>
      <Typography className={classes!.primaryText} variant="body1">
        If you'd like to be considered for invitation, click the button below.
      </Typography>
      <PageActions center>
        <Button variant="contained" color="primary" onClick={handleInviteRequest}>
          Request Invite
        </Button>
      </PageActions>
    </Page>
  );
}

TheBetaPage.requiredAuthZ = {
  tier: 0,
};
TheBetaPage.routePath = '/beta';
TheBetaPage.routePathWithParam = '/beta/:feature';
TheBetaPage.title = 'Beta Notice';

export default TheBetaPage;
