import { Accordion, AccordionActions, AccordionDetails, Button, Grid, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as Sentry from '@sentry/react';
import moment from 'moment';
import React, { useState } from 'react';
import { IVendorDto, IVendorQuestionnaireDto } from '../../../backend/src/vendor/interfaces';
import { VendorQuestionnaireSentTo, VendorQuestionnaireStatus } from '../../../backend/src/vendor/enums';
import { formatDate } from '../helpers';
import API from '../services/ApiService';
import AccordionIconSummary from './AccordionIconSummary';
import { showErrorResultBar, showSuccessResultBar } from './ResultSnackbar';
import { ALL_VENDOR_QUESTIONS } from './vendor-questionnaire';

const useStyles = makeStyles({
  questionnaireEntryHeading: {
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  questionnaireQuestion: {
    fontWeight: 'bold',
  },
  questionnaireAnswer: {
    marginInlineStart: '1rem',
  },
  vendorResponseText: {
    color: 'blue',
  },
  questionnaireEntryActions: {
    justifyContent: 'flex-start',
    paddingTop: 0,
  },
});

const questionnaireStatusMap: {[ key in VendorQuestionnaireStatus ]: string} = {
  open: 'Awaiting response',
  resent: 'Link resent',
  in_progress: 'In progress',
  closed: 'Response received',
  canceled: 'Canceled',
};

const SELF_FILLED_STR: VendorQuestionnaireSentTo = 'Self-filled';

const isQuestionnaireCanceled = (questionnaire: IVendorQuestionnaireDto) => {
  return questionnaire.status === 'canceled';
};

const isQuestionnaireExpired = (questionnaire: IVendorQuestionnaireDto) => {
  return moment().isAfter(questionnaire.expiresAt);
};

const isQuestionnairePending = (questionnaire: IVendorQuestionnaireDto) => {
  return questionnaire.status !== 'canceled' && questionnaire.status !== 'closed';
};

const isQuestionnaireSelfFilled = (questionnaire: IVendorQuestionnaireDto) => {
  return questionnaire.sentTo === SELF_FILLED_STR;
};

const getQuestionnaireLinkInfoText = (questionnaire: IVendorQuestionnaireDto) => {
  if (isQuestionnaireCanceled(questionnaire)) {
    return 'Link canceled';
  } else if (isQuestionnaireExpired(questionnaire)) {
    return 'Link expired:';
  } else {
    return 'Link expires:';
  }
};

const getQuestionnaireSubheading = (questionnaire: IVendorQuestionnaireDto) => {
  const statusStr = questionnaireStatusMap[questionnaire.status];

  if (isQuestionnaireCanceled(questionnaire)) {
    return `Canceled (${formatDate(questionnaire.updatedAt)})`;
  } else if (questionnaire.status === 'closed') {
    const updatedAt = questionnaire.response?.updatedAt;
    const updatedAtStr = !!updatedAt ? ` (${formatDate(updatedAt)})` : '';

    return `${statusStr}${updatedAtStr}`;
  } else if (isQuestionnaireExpired(questionnaire)) {
    return `Expired (${formatDate(questionnaire.expiresAt)})`;
  } else if (questionnaire.status === 'in_progress' || questionnaire.status === 'resent') {
    const updatedAt = questionnaire.updatedAt;
    const updatedAtStr = ` (${questionnaire.status === 'in_progress' ? 'accessed on ' : ''}${formatDate(updatedAt)})`;

    return `${statusStr}${updatedAtStr}`;
  } else {
    return statusStr;
  }
};

export interface VendorQuestionnaireResponseProps {
  onUpdateVendor: (newVendorData: IVendorDto) => void;
  questionnaire: IVendorQuestionnaireDto;
  vendorData: IVendorDto;
}

export default function VendorQuestionnaireResponse({ onUpdateVendor, questionnaire, vendorData }: VendorQuestionnaireResponseProps) {
  const classes = useStyles();
  const [ isSubmitting, setIsSubmitting ] = useState(false);

  const handleCancelQuestionnaire = async () => {
    const urlOperation = 'cancel-questionnaire';
    const successMsg = 'Questionnaire link canceled successfully';
    const defaultErrorMsg = 'Unexpected error while canceling questionnaire link';

    return patchQuestionnaire(urlOperation, successMsg, defaultErrorMsg);
  };

  const handleResendQuestionnaire = async () => {
    const urlOperation = 'resend-questionnaire';
    const successMsg = 'Questionnaire link resent successfully';
    const defaultErrorMsg = 'Unexpected error while resending questionnaire link';

    return patchQuestionnaire(urlOperation, successMsg, defaultErrorMsg);
  };

  const patchQuestionnaire = async (urlOperation: string, successMsg: string, defaultErrorMsg: string) => {
    try {
      setIsSubmitting(true);
      const res = await API.patch(`vendor/${vendorData.id}/${urlOperation}/${questionnaire.id}`);
      const updatedVendor: IVendorDto = res.data.data;
      const updatedQuestionnaire = updatedVendor.questionnaires?.find(q => q.id === questionnaire.id);

      if (updatedQuestionnaire) {
        showSuccessResultBar(successMsg);
      }

      onUpdateVendor(updatedVendor);
    } catch (err: any) {
      const errorMsg = err.response?.data?.message ?? defaultErrorMsg;
      showErrorResultBar(errorMsg);
      Sentry.captureException(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Accordion
      elevation={2}
    >
      <AccordionIconSummary>
        <Typography
          className={classes.questionnaireEntryHeading}
          variant="body2"
        >
          {isQuestionnaireSelfFilled(questionnaire) ? 'Self-fill link' : 'Link emailed'} (
          {formatDate(questionnaire.createdAt)})
        </Typography>
        <Typography variant="body2">
          {getQuestionnaireSubheading(questionnaire)}
        </Typography>
      </AccordionIconSummary>

      <AccordionDetails>
        <Grid container>
          <Grid
            item
            xs={12}
          >
            <Typography
              className={classes.questionnaireQuestion}
            >
              Sent to:
            </Typography>
            <Typography
              className={classes.questionnaireAnswer}
            >
              {questionnaire.sentTo}
            </Typography>
            {questionnaire.ccTo?.length ? (
              <Typography
                className={classes.questionnaireAnswer}
              >
                Cc'd: {questionnaire.ccTo.join(', ')}
              </Typography>
            ) : null}
          </Grid>
          { !!questionnaire.response ? (
              <>
                {ALL_VENDOR_QUESTIONS.map(question => {
                  return questionnaire.response.response.hasOwnProperty(question.id) ? (
                    <Grid
                      item
                      xs={12}
                      key={question.id}
                    >
                      <Typography
                        className={classes.questionnaireQuestion}
                      >
                        {question.text}
                      </Typography>
                      <Typography
                        gutterBottom
                        className={classes.questionnaireAnswer}
                      >
                        {!!question.label && `${question.label}: ` }
                        <span className={classes.vendorResponseText}>
                          {questionnaire.response.response[question.id]}
                        </span>
                      </Typography>
                    </Grid>
                  ) : null;
                })}
              </>
              ) : (
                <>
                  <Grid
                    item
                    xs={12}
                  >
                    <Typography
                      className={classes.questionnaireQuestion}
                    >
                      {getQuestionnaireLinkInfoText(questionnaire)}
                    </Typography>
                    {isQuestionnairePending(questionnaire) && (
                      <Typography
                        gutterBottom
                        className={classes.questionnaireAnswer}
                      >
                        {moment(questionnaire.expiresAt).fromNow()}
                      </Typography>
                    )}
                  </Grid>
                </>
              )
            }
        </Grid>
      </AccordionDetails>
      <AccordionActions className={classes.questionnaireEntryActions}>
      {!isQuestionnaireSelfFilled(questionnaire) && isQuestionnairePending(questionnaire) && (
        <Tooltip
          title="Send a reminder email to the above email address(es) and refresh the link's 7d expiry"
        >
          <Button
            size="small"
            color="primary"
            disabled={isSubmitting}
            onClick={handleResendQuestionnaire}
          >
            Send reminder
          </Button>
        </Tooltip>
      )}
      {!isQuestionnaireExpired(questionnaire) && isQuestionnairePending(questionnaire) && (
        <Button
          size="small"
          color="primary"
          disabled={isSubmitting}
          onClick={handleCancelQuestionnaire}
        >
          Cancel link
        </Button>
      )}
      </AccordionActions>
    </Accordion>
  );
}
