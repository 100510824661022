import { Button, Card, CardActions, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ISubscriptionPlanDto } from '../../../backend/src/subscription/interfaces';
import { formatDate } from '../helpers';
import { ConfirmationDialog } from './dialogs';

const getBillingStatusStr = (status: string | undefined, isCanceled: boolean) => {
  switch (status) {
    case 'active':
      return isCanceled ? 'Active - cancellation pending' : 'Active';
    case 'trialing':
      return 'Trial period';
    case 'past_due':
      return 'Past due';
    case 'canceled':
      return 'Canceled';
    case 'unpaid':
      return 'Unpaid';
    default:
      return '???';
  }
};

export interface BillingInfoRowProps {
  name: string;
  value: string | undefined;
}

export function BillingInfoRow({ name, value }: BillingInfoRowProps) {
  return (
    <>
      <Grid item xs={6}>
        <Typography variant="body1">
          {name}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography align="right" variant="body1">
          {value}
        </Typography>
      </Grid>
    </>
  );
}

export interface BillingInfoCardProps {
  isLoading: boolean;
  onConfirmDelete: () => Promise<void>;
  subscriptionPlan?: ISubscriptionPlanDto;
}

export function BillingInfoCard({ isLoading, onConfirmDelete, subscriptionPlan }: BillingInfoCardProps) {
  const [ isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false);
  const [ isCanceled, setIsCanceled ] = useState<boolean>(false);

  useEffect(() => {
    setIsCanceled(subscriptionPlan ? !!subscriptionPlan.canceledAt : false);
  }, [ subscriptionPlan ]);

  const handleConfirmDelete = async (confirmed: boolean) => {
    setIsConfirmOpen(false);

    if (confirmed) {
      await onConfirmDelete();
    }
  };

  return  (
    <>
      <Card>
        <CardHeader
          title="Billing info"
          // subheader={isLoading && <LinearProgress />}
        />
        {!isLoading && (
          <CardContent>
            {!subscriptionPlan ? (
              <Typography variant="body1">
                No billing information found
              </Typography>
            ) : (
              <Grid container>
                <BillingInfoRow
                  name="Status"
                  value={getBillingStatusStr(subscriptionPlan.status, isCanceled)}
                />
                {isCanceled &&
                  <BillingInfoRow
                    name="Cancelled on"
                    value={formatDate(subscriptionPlan.canceledAt)}
                  />
                }
                <BillingInfoRow
                  name="Next closing date"
                  value={formatDate(subscriptionPlan.currentPeriodEnd)}
                />
                <BillingInfoRow
                  name="Cost"
                  value={subscriptionPlan.amount === undefined ? undefined : `$${subscriptionPlan.amount} / month`}
                />
                <BillingInfoRow
                  name="Billing frequency"
                  value={subscriptionPlan.interval === undefined ? undefined : `Every ${subscriptionPlan.interval}`}
                />
              </Grid>
            )}
          </CardContent>
        )}
        <CardActions>
          <Button
            color="primary"
            disabled={isLoading || isCanceled}
            onClick={() => setIsConfirmOpen(true)}
          >
            Cancel subscription
          </Button>
        </CardActions>
      </Card>
      <ConfirmationDialog
        open={isConfirmOpen}
        onResponse={handleConfirmDelete}
        title="Are you sure you want to cancel your subscription?"
      >
        <>
          This will take effect at the end of the current billing period.
        </>
      </ConfirmationDialog>
    </>
  );
}
