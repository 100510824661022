import { DialogProps } from '@mui/material';
import * as Sentry from '@sentry/react';
import React, { useEffect, useState } from 'react';
import { IPolicyDocDto, IPolicyEditUploadDto } from '../../../../backend/src/policy-doc/interfaces';
import { showErrorResultBar, showSuccessResultBar } from '../ResultSnackbar';
import API from '../../services/ApiService';
import { EditorDialog } from '../editor';

export interface PolicyDocEditDialogProps extends DialogProps {
  onClose: () => void;
  onUpdate: (newPolicyDoc: IPolicyDocDto) => void;
  policyDoc: IPolicyDocDto;
}

export default function PolicyDocEditDialog(props: PolicyDocEditDialogProps) {
  const { onClose, onUpdate, open, policyDoc } = props;

  const [ policyDocId, setPolicyDocId ] = useState<string>('');
  const [ originalContent, setOriginalContent ] = useState<string>('');
  const [ isLoading, setIsLoading ] = useState<boolean>(false);

  useEffect(() => {
    setPolicyDocId(policyDoc.versionId);
  }, [ policyDoc ]);

  useEffect(() => {
    if (policyDocId && open) {
      setIsLoading(true);

      API
        .get(`policyDoc/${policyDocId}/edit`)
        .then(res => {
          setOriginalContent(res.data.data.content);
        })
        .catch((err) => {
          showErrorResultBar('Unexpected error fetching the policy');
          Sentry.captureException(err);
        })
        .finally(() => setIsLoading(false));
    }
  }, [ policyDocId, open ]);

  const handleSave = async (updatedContent: string) => {
    setIsLoading(true);
    const upload: IPolicyEditUploadDto = { content: updatedContent };

    API
      .patch(`policyDoc/${policyDoc.versionId}/edit`, upload)
      .then((res) => {
        onUpdate(res.data.data);
        showSuccessResultBar('Saved changes to the policy');
        handleClose();
      })
      .catch((err) => {
        showErrorResultBar('Unexpected error saving the policy');
        Sentry.captureException(err);
      })
      .finally(() => setIsLoading(false));
  };

  const handleClose = () => {
    setOriginalContent('');
    onClose();
  };

  return (
    <EditorDialog
      isLoading={isLoading}
      markdownContent={originalContent}
      onClose={handleClose}
      onSave={handleSave}
      open={open}
      title={`Editing: ${policyDoc.name}`}
    />
  );
}
