import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import React from 'react';

function EditButton(props: IconButtonProps) {
  return (
    <IconButton aria-label="Edit" {...props} size="large">
      <EditIcon />
    </IconButton>
  );
}

export default EditButton;
