import axios from 'axios';
import * as Sentry from '@sentry/react';
import { API_ROOT } from '../constants';

const API = axios.create({
  baseURL: API_ROOT,
  // The auth is added via the interceptor (below).
  headers: {
    'Content-Type': 'application/json',
  },
});

export { API as default };

export const PublicAPI = axios.create({
  baseURL: API_ROOT,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const initAccessTokenInterceptor = (getAccessTokenSilently: () => Promise<string>) => {
  API.interceptors.request.use(async (config) => {
    const token = await getAccessTokenSilently();

    if (config?.headers && token !== null) {
      config.headers.Authorization = `Bearer ${token}`; // eslint-disable-line no-param-reassign
    }

    return config;
  },
    err => Promise.reject(err)
  );
};

export const initErrorInterceptor = () => {
  API.interceptors.response.use(res => res, err => {
    Sentry.captureException(err);

    return Promise.reject(err);
  });
};


export const ErrorCodes = {
  /**
   * One or more values in the request already exist.
   */
  DUPLICATE_VALUE: 23505,
};
