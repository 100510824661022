import { Button, DialogActions, DialogContent, DialogProps, FormGroup, Typography } from '@mui/material';
import * as Sentry from '@sentry/react';
import { Formik } from 'formik';
import moment from 'moment';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { IRoadmapItemDto, IRoadmapItemUpdateDto } from '../../../../backend/src/roadmap/interfaces';
import API from '../../services/ApiService';
import { SaveButton } from '../buttons';
import { FormikDatePicker, FormikTextField } from '../forms';
import { showErrorResultBar, showSuccessResultBar } from '../ResultSnackbar';
import StyledDialogTitle from '../StyledDialogTitle';
import ConfirmationDialog from './ConfirmationDialog';
import ResponsiveDialog from './ResponsiveDialog';

const ValidationSchema = Yup.object().shape({
  date: Yup
    .date()
    .typeError('Invalid date')
    .required()
    .label('Date'),
  description: Yup
    .string()
    .nullable()
    .label('Description'),
  title: Yup
    .string()
    .required()
    .label('Title'),
});

export interface RoadmapItemEditDialogProps extends DialogProps {
  itemData: IRoadmapItemDto | null;
  onClose: () => void;
  onDelete: (item: IRoadmapItemDto) => void;
  onUpdate: (item: IRoadmapItemDto) => void;
}

export default function RoadmapItemEditDialog({ itemData, onClose, onDelete, onUpdate, open }: RoadmapItemEditDialogProps) {
  const [ isDeleteDialogOpen, setIsDeleteDialogOpen ] = useState(false);

  const handleSave = async (formValues: IRoadmapItemUpdateDto) => {
    try {
      const res = itemData === null ?
        await API.post('roadmapItem', formValues) :
        await API.patch(`roadmapItem/${itemData.id}`, formValues);
      onUpdate(res.data?.data);
      showSuccessResultBar('Roadmap saved successfully');
      onClose();
    } catch (err: any) {
      const errorMsg = err.response?.data?.error ?? 'Unexpected error saving roadmap';
      showErrorResultBar(errorMsg);
      Sentry.captureException(err);
    }
  };

  const handleConfirmDeleteResponse = async (confirmed: boolean) => {
    if (confirmed) {
      await handleDelete();
    }

    setIsDeleteDialogOpen(false);
  };

  const handleDelete = async () => {
    if (itemData === null) return;

    try {
      const res = await API.delete(`roadmapItem/${itemData.id}`);
      onDelete(res.data?.data);
      showSuccessResultBar('Roadmap item deleted');
      onClose();
    } catch (err: any) {
      const errorMsg = err.response?.data?.error ?? 'Unexpected error deleting roadmap item';
      showErrorResultBar(errorMsg);
      Sentry.captureException(err);
    }
  };

  return <>
    <ResponsiveDialog
      disableBackdropClick
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
    >
      <StyledDialogTitle onClose={onClose}>
        {itemData === null ? 'Create new roadmap item' : 'Edit roadmap item'}
      </StyledDialogTitle>
        <Formik
          enableReinitialize
          initialValues={{
            date: itemData?.date || moment().startOf('day').toDate(),
            description: itemData?.description || '',
            title: itemData?.title || '',
          }}
          validationSchema={ValidationSchema}
          onReset={onClose}
          onSubmit={async (values, { setSubmitting }) => {
            await handleSave(values);
            setSubmitting(false);
          }}
        >
          {formikProps => (<>
            <DialogContent>
              <FormGroup>
                <FormikDatePicker
                  field="date"
                  formikProps={formikProps}
                  label="Date"
                  textFieldProps={{ autoFocus: true, required: true }}
                />
                <FormikTextField
                  field="title"
                  formikProps={formikProps}
                  label="Title"
                  required
                />
                <FormikTextField
                  field="description"
                  formikProps={formikProps}
                  label="Description"
                  multiline
                />
              </FormGroup>
            </DialogContent>
            <DialogActions>
              {itemData !== null && (
                <Button
                  sx={{ mr: 'auto' }}
                  disabled={formikProps.isSubmitting}
                  onClick={() => setIsDeleteDialogOpen(true)}
                >
                  Delete item
                </Button>
              )}
              <Button
                disabled={formikProps.isSubmitting}
                onClick={formikProps.handleReset}
                color="primary"
              >
                {itemData === null ? 'Cancel' : 'Close'}
              </Button>
              <SaveButton
                disabled={formikProps.isSubmitting || Object.values(formikProps.errors).filter(v => !!v).length > 0}
                onClick={formikProps.handleSubmit}
              />
            </DialogActions>
          </>)}
        </Formik>
    </ResponsiveDialog>
    {itemData !== null && (
      <ConfirmationDialog
        onResponse={handleConfirmDeleteResponse}
        open={isDeleteDialogOpen}
        title={`Remove the item '${itemData.title}' from the roadmap?`}
      >
        <Typography component="span">
          Note that this cannot be undone.
        </Typography>
      </ConfirmationDialog>
    )}
  </>;
}
