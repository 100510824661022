import { Button, CardHeader } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import { makeStyles } from '@mui/styles';
import React from 'react';
import CaptionedAddButton from '../components/buttons/CaptionedAddButton';
import { TheUserAuditPage } from '../pages';
import { RouterLink } from './RouterLink';

const useStyles = makeStyles({
  addOrgButton: {
    marginTop: 0,
    marginBottom: 0,
  },
  backLink: {
    'color': 'inherit',
    'cursor': 'pointer',
    'fontSize': '0.9rem',
    'textDecoration': 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  headerTitle: {
    minHeight: '37px',
  },
  leftIcon: {
    marginRight: '0.5rem',
  },
});

interface ProviderHeaderProps {
  buttonText?: string;
  hasOrgs: boolean;
  isLoading: boolean;
  onClickSyncAllOrgs: any;
  onClickAddOrg: any;
  provider: string;
  title: string;
}

export default function ProviderHeader(props: ProviderHeaderProps) {
  const classes = useStyles();
  const { buttonText, hasOrgs, isLoading, onClickSyncAllOrgs, onClickAddOrg, title } = props;

  return (
    <CardHeader
      action={hasOrgs && (
        <Button
          color="primary"
          disabled={isLoading}
          onClick={onClickSyncAllOrgs}
        >
          <SyncIcon className={classes.leftIcon} />
          Resync all
        </Button>
      )}
      classes={{ title: classes.headerTitle }}
      subheader={(
        <RouterLink
          className={classes.backLink}
          to={TheUserAuditPage.routePath}
        >
          Back to user audit summary
        </RouterLink>
      )}
      title={(
        <>
          {title}
          {!isLoading && (
            <CaptionedAddButton
              className={classes.addOrgButton}
              color="primary"
              variant={hasOrgs ? 'text' : 'contained'}
              onClick={onClickAddOrg}
            >
              {buttonText || 'Add Account'}
            </CaptionedAddButton>
          )}
        </>
      )}
    />
  );
}
