import { Link } from '@mui/material';
import React from 'react';
import { ICategoryLabel, ICategoryLabelMap, TaskSummaryByCategory } from '../components/TaskSummaryByCategory';

const topCategoryMap: ICategoryLabelMap = {
  'cmmc:level-1': {
    tags: [ 'cmmc:level-1' ],
    title: 'Level 1',
  },
  'cmmc:level-2': {
    tags: [ 'cmmc:level-2' ],
    title: 'Level 2',
  },
  'cmmc:level-3': {
    tags: [ 'cmmc:level-3' ],
    title: 'Level 3',
  },
  'cmmc:level-4': {
    tags: [ 'cmmc:level-4' ],
    title: 'Level 4',
  },
  'cmmc:level-5': {
    tags: [ 'cmmc:level-5' ],
    title: 'Level 5',
  },
};

const baseMap = {
  'cmmc:ac': { abbreviation: 'AC', title: 'Access Control' },
  'cmmc:am': { abbreviation: 'AM', title: 'Asset Management' },
  'cmmc:at': { abbreviation: 'AT', title: 'Awareness & Training' },
  'cmmc:au': { abbreviation: 'AU', title: 'Audit & Accountability' },
  'cmmc:ca': { abbreviation: 'CA', title: 'Security Assessment' },
  'cmmc:cm': { abbreviation: 'CM', title: 'Configuration Management' },
  'cmmc:ia': { abbreviation: 'IA', title: 'ID & Authentication' },
  'cmmc:ir': { abbreviation: 'IR', title: 'Incident Response' },
  'cmmc:ma': { abbreviation: 'MA', title: 'Maintenance' },
  'cmmc:ml': { abbreviation: 'ML', title: 'Process Maturity' },
  'cmmc:mp': { abbreviation: 'MP', title: 'Media Protection' },
  'cmmc:pe': { abbreviation: 'PE', title: 'Physical Protection' },
  'cmmc:ps': { abbreviation: 'PS', title: 'Personnel Security' },
  'cmmc:re': { abbreviation: 'RE', title: 'Recovery' },
  'cmmc:rm': { abbreviation: 'RM', title: 'Risk Management' },
  'cmmc:sa': { abbreviation: 'SA', title: 'Situational Awareness' },
  'cmmc:sc': { abbreviation: 'SC', title: 'System & Communications Protection' },
  'cmmc:si': { abbreviation: 'SI', title: 'System & Information Integrity' },
};

const getCatAtLevel = (level: number) => {
  const map: { [key: string]: ICategoryLabel } = {};
  Object.entries(baseMap).forEach(([ key, value ]) => {
    map[`${key}.${level}`] = value;
  });

  return map;
};

const subCategoryMap: ICategoryLabelMap = {
  ...getCatAtLevel(1), // eg, cmmc:ac.1, cmmc:am.1, etc
  ...getCatAtLevel(2),
  ...getCatAtLevel(3),
  ...getCatAtLevel(4),
  ...getCatAtLevel(5),
};

function TheTasksSummaryCMMCPage() {
  return (
    <TaskSummaryByCategory
      apiRoute="task/summary/cmmc"
      subCategoryMap={subCategoryMap}
      title="Cybersecurity Maturity Model Certification (CMMC)"
      topCategoryLabel="Maturity Level"
      topCategoryMap={topCategoryMap}
    >
      <>
        The U.S. Department of Defense's <Link
        href="https://www.acq.osd.mil/cmmc"
        target="_blank"
        rel="noopener noreferrer"
      >
        Cybersecurity Maturity Model Certification (CMMC)
      </Link> combines
        various cybersecurity standards and best practices,
        mapping these controls across 5 Maturity Levels and a series of Capability Domains.
        The intent is for the CMMC to serve as a cybersecurity verification mechanism
        for DoD's industry partners.
        Each Maturity Level has several
        associated tasks your organization should complete across the various Capability Domains.
        In the table below we indicate how far along your organization has come in terms of
        implementing the CMMC framework.
      </>
    </TaskSummaryByCategory>
  );
}

TheTasksSummaryCMMCPage.requiredAuthZ = {
  tier: 1,
  permission: 'tasks',
};
TheTasksSummaryCMMCPage.routePath = '/tasks/cmmc';
TheTasksSummaryCMMCPage.title = 'Task Summary: CMMC';

export default TheTasksSummaryCMMCPage;
