import { Card, CardContent, CardHeader, Grid, TableCell, TableRow, Typography } from '@mui/material';
import { blueGrey, green } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import * as Sentry from '@sentry/react';
import classNames from 'classnames';
import { sortBy } from 'lodash';
import { MUIDataTableTextLabels } from 'mui-datatables';
import React, { useEffect, useState } from 'react';
import { HorizontalBar } from 'react-chartjs-2';
import { ITaskMaturityByCategory } from '../../../backend/src/task/interfaces';
import API from '../services/ApiService';
import { STORAGE_KEYS, setStorageValue } from '../services/BrowserStorageService';
import { FILTER_VIEW_SELECTIONS } from './FilterViewSelections';
import { showErrorResultBar } from './ResultSnackbar';
import SpioDataTable, { SpioDataTableColumn } from './SpioDataTable';
import { useNavigate } from 'react-router-dom';

const INVERSE_GOLDEN_RATIO = 0.62;

const useStyles = makeStyles({
  pageCard: {
    width: '100%',
    margin: 0,
  },
  clickable: {
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  subtitle: {
    color: 'gray',
    paddingLeft: '1em',
  },
  subcategoryTitle: {
    paddingLeft: '1em',
  },
  hiddenRow: {
    height: 0,
  },
  hiddenCell: {
    padding: 0,
    border: 'none',
  },
});

export interface ICategoryLabelMap {
  [key: string]: ICategoryLabel;
}

export interface ICategoryLabel {
  abbreviation?: string;
  subtitle?: string;
  tags?: string[];
  title: string;
}

const categoryToDataLabel = (category: string, categoryLabelMap: ICategoryLabelMap) => {
  const catLabel = categoryLabelMap[category];

  return catLabel?.title || category;
};

const chartOptions = {
  legend: {
    display: false,
  },
  maintainAspectRatio: false,
  responsive: false,
  scales: {
    yAxes: [ { display: false } ],
    xAxes: [ { display: false } ],
  },
  tooltips: {
    enabled: false,
  },
};

const getChartDataObject = (labels: string[], dataCompleted: number[], dataRemaining: number[], barScaleFactor: number = 1.0) => {
  return {
    labels,
    datasets: [
      {
        label: 'Completed',
        backgroundColor: green[500],
        borderColor: green[800],
        borderWidth: 1,
        hoverBackgroundColor: green[800],
        hoverBorderColor: green[800],
        stack: '1',
        data: dataCompleted,
        barPercentage: 1.0,
        barThickness: barScaleFactor * 20,
        categoryPercentage: 1.0,
      },
      {
        label: 'Remaining',
        backgroundColor: blueGrey[100],
        borderColor: blueGrey[300],
        borderWidth: 1,
        hoverBackgroundColor: blueGrey[300],
        hoverBorderColor: blueGrey[300],
        stack: '1',
        data: dataRemaining,
        barPercentage: 1.0,
        barThickness: barScaleFactor * 20,
        categoryPercentage: 1.0,
      },
    ],
  };
};

const useTableColumns = (
  topCategoryLabel: string,
  topCategoryMap: ICategoryLabelMap,
  onClickCategoryTitle: (searchText: string, tags?: string[]) => () => void,
): SpioDataTableColumn[] => {
  const classes = useStyles();

  return [
    {
      name: 'category',
      label: topCategoryLabel,
      options: {
        customBodyRender: (categoryKey: string, meta: any) => {
          const topCategory = topCategoryMap[categoryKey] || { abbreviation: '', title: categoryKey, subtitle: '' };
          const title = `${topCategory.abbreviation ? `${topCategory.abbreviation}: ` : ''}${topCategory.title}`;
          const subtitle = topCategory.subtitle;
          const tags = topCategory.tags || [];
          const searchText = tags.length > 0 ? '' : categoryKey;
          const emptyTags = meta.rowData[3];

          return (
            <>
              <div
                className={classes.clickable}
                onClick={onClickCategoryTitle(searchText, tags)}
              >
                {title}
                {emptyTags > 0 && '*'}
              </div>
              <div className={classes.subtitle}>{subtitle}</div>
            </>
          );
        },
        sort: false,
      },
    },
    // Note that the '% Complete' column is referencing this column.
    {
      name: 'completed',
      label: 'Completed',
      options: {
        sort: false,
      },
    },
    // Note that the '% Complete' column is referencing this column.
    {
      name: 'total',
      label: 'Total',
      options: {
        sort: false,
      },
    },
    // Note that the 'topCategoryLabel' column is referencing this column.
    {
      name: 'emptyTags',
      label: '',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'category',
      label: '% Complete',
      options: {
        customBodyRender: (category: string, meta: any) => {
          // Unless you rescale to percent the chart's width doesn't seem predictable.
          const label = categoryToDataLabel(category, topCategoryMap);
          const completed = meta.rowData[1];
          const total = meta.rowData[2];
          const completedPercent = completed / (total || completed || 1);
          const chartData = getChartDataObject([ label ], [ completedPercent ], [ 1.0 - completedPercent ]);

          return (
            <HorizontalBar
              data={chartData}
              height={25}
              options={chartOptions}
            />
          );
        },
        setCellProps: () => Object({ nowrap: 'true' }),
        sort: false,
      },
    },
  ];
};

export interface TaskSummaryByCategoryProps {
  apiRoute: string;
  children: string | React.ReactElement;
  subCategoryMap?: ICategoryLabelMap;
  title: string;
  topCategoryLabel: string;
  topCategoryMap: ICategoryLabelMap;
}

export function TaskSummaryByCategory(props: TaskSummaryByCategoryProps) {
  const navigate = useNavigate();
  const classes = useStyles();
  const {
    apiRoute,
    children,
    subCategoryMap,
    title,
    topCategoryLabel,
    topCategoryMap,
  } = props;

  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ hasEmptyTags, setHasEmptyTags ] = useState<boolean>(false);
  const [ taskSummaries, setTaskSummaries ] = useState<ITaskMaturityByCategory[]>([]);

  // Initial page load:
  useEffect(() => {
    const topCategoryKeys = Object.keys(topCategoryMap);
    const subCategoryKeys = subCategoryMap ? Object.keys(subCategoryMap) : [];

    setIsLoading(true);

    API.get(apiRoute)
      .then((res) => {
        const theData: { byCategory: ITaskMaturityByCategory[] } = (res.data && res.data.data) ? res.data.data : {};
        const summaries = theData.byCategory || [];
        const sortedSummaries = sortBy(summaries, [ s => topCategoryKeys.indexOf(s.category), 'category' ]);

        // Sort the subcategories in-place:
        sortedSummaries.forEach((sortedSummary) => {
          const subcategorySummaries = sortedSummary.subcategories || [];
          sortedSummary.subcategories = sortBy(subcategorySummaries, [ s => subCategoryKeys.indexOf(s.category), 'category' ]);
        });

        setTaskSummaries(sortedSummaries);
      })
      .catch((err) => {
        Sentry.captureException(err);
        showErrorResultBar('Unexpected error fetching data');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [ apiRoute, subCategoryMap, topCategoryMap ]);

  useEffect(() => {
    setHasEmptyTags(taskSummaries.some(topCategory => topCategory.emptyTags > 0));
  }, [ taskSummaries ]);

  const onClickCategoryTitle = (searchText: string, tags: string[] = []) => () => {
    setStorageValue(STORAGE_KEYS.TASKS_VIEW, FILTER_VIEW_SELECTIONS.CUSTOM.value);
    setStorageValue(STORAGE_KEYS.TASKS_FILTERS, { tagNames: tags });
    setStorageValue(STORAGE_KEYS.TASKS_SEARCH, searchText);
    navigate('/tasks');
  };

  return (
    <>
      <Card
        className={classes.pageCard}
      >
        <CardHeader
          title={title}
        />
        <Grid container>
          <Grid item xs={12}>
            <CardContent>
              <Typography
                variant="body1"
                component="p"
                gutterBottom
              >
                {children}
              </Typography>
            </CardContent>
          </Grid>
          <Grid item xs={12}>
            <SpioDataTable
              title=""
              columns={useTableColumns(topCategoryLabel, topCategoryMap, onClickCategoryTitle)}
              data={taskSummaries.map(d => Object({
                ...d,
                total: d.total + d.emptyTags,
              }))}
              options={{
                elevation: 1,
                textLabels: {
                  body: {
                    noMatch: isLoading ? 'Loading...' : 'No data found',
                  },
                } as MUIDataTableTextLabels,
                filterType: 'multiselect',
                download: false,
                filter: false,
                pagination: false,
                print: false,
                search: false,
                viewColumns: false,
                selectableRows: 'none',
                sort: true,
                expandableRows: !!subCategoryMap,
                isRowExpandable: dataIndex => !!(taskSummaries[dataIndex].subcategories?.length),
                renderExpandableRow: (rowData, rowMeta) => {
                  const nbColumns = rowData.length + 1;
                  const subcategorySummaries = taskSummaries[rowMeta.dataIndex].subcategories || [];
                  const nbSubcategories = subcategorySummaries.length;

                  return (
                    <>
                      <TableRow className={classes.hiddenRow}>
                        <TableCell rowSpan={nbSubcategories + 1} />
                        <TableCell className={classes.hiddenCell} colSpan={nbColumns - 1} />
                      </TableRow>
                      {subcategorySummaries.map((subcategorySummary) => {
                        const subCategoryKey = subcategorySummary.category;
                        const subCategory = (subCategoryMap && subCategoryMap[subCategoryKey]) || {
                          abbreviation: '',
                          title: subCategoryKey,
                          subtitle: '',
                        };
                        const subCategoryTitle = `${subCategory.abbreviation ? `${subCategory.abbreviation}: ` : ''}${subCategory.title}`;
                        const tags = subCategory.tags || [];
                        const searchText = tags.length > 0 ? '' : subCategoryKey;
                        const { completed, emptyTags, total } = subcategorySummary;
                        const totalIncludingEmptyTags = total + emptyTags;
                        const completedPercent = completed / (totalIncludingEmptyTags || completed || 1);
                        const chartData = getChartDataObject([ subCategoryTitle ], [ completedPercent ], [ 1.0 - completedPercent ], INVERSE_GOLDEN_RATIO);

                        return (
                          <TableRow key={subCategoryKey}>
                            <TableCell>
                              <div
                                className={classNames(classes.clickable, classes.subcategoryTitle)}
                                onClick={onClickCategoryTitle(searchText, tags)}
                              >
                                {subCategoryTitle}
                                {emptyTags > 0 && '*'}
                              </div>
                            </TableCell>
                            <TableCell>{completed}</TableCell>
                            <TableCell>{totalIncludingEmptyTags}</TableCell>
                            <TableCell>
                              <HorizontalBar
                                data={chartData}
                                height={25}
                                options={chartOptions}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </>
                  );
                },
              }}
            />
          </Grid>
          {hasEmptyTags && (
            <Grid item xs={12}>
              <CardContent>
                <Typography
                  variant="caption"
                >
                  * Items marked with an asterisk indicate that
                  they include deeper or more advanced controls that aren't practical for
                  small and mid-sized companies. We have intentionally not included tasks for
                  all such controls.
                </Typography>
              </CardContent>
            </Grid>
          )}
        </Grid>
      </Card>
    </>
  );
}
