import { Button, Card, CardActions, CardContent, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { ITaskCommentDto } from '../../../backend/src/task/interfaces';

const useStyles = makeStyles({
  leftButton: {
    marginLeft: 'auto',
  },
});

const TaskCommentSchema = Yup.object().shape({
  comment: Yup
    .string()
    .required('Required'),
});

export interface ICommentInputData {
  id?: string;
  comment: string;
}

export interface CommentInputProps {
  comment?: ITaskCommentDto;
  onCancelComment: () => void;
  onSaveComment: (formData: ICommentInputData) => Promise<void>;
}

export default function CommentInput({ comment, onCancelComment, onSaveComment }: CommentInputProps) {
  const classes = useStyles();

  return (
    <Card
      elevation={5}
    >
      <Formik
        enableReinitialize
        initialValues={{
          id: comment ? comment.id : undefined,
          comment: comment ? (comment.text || '') : '',
        }}
        validationSchema={TaskCommentSchema}
        onSubmit={async (values, { setSubmitting }) => {
          await onSaveComment(values);
          setSubmitting(false);
        }}
        onReset={_ => {
          onCancelComment();
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleReset,
          handleSubmit,
          isSubmitting,
        }) => (
          <>
            <CardContent>
              <TextField
                name="comment"
                value={values.comment}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                margin="dense"
                multiline
                placeholder={`${comment?.id ? 'Edit' : 'Add a'} comment`}
                helperText={errors.comment}
                error={!!errors.comment}
              />
            </CardContent>
            <CardActions>
              <Button
                color="primary"
                size="small"
                disabled={isSubmitting}
                onClick={handleReset}
                className={classes.leftButton}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                size="small"
                variant="contained"
                disabled={isSubmitting || Object.keys(errors).length > 0}
                onClick={(e: any) => handleSubmit(e)}
              >
                {comment?.id ? 'Update' : 'Comment'}
              </Button>
            </CardActions>
          </>
        )}
      </Formik>
    </Card>
  );
}
