import { Button, CircularProgress, DialogActions, DialogContent, DialogProps, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as Sentry from '@sentry/react';
import { Formik } from 'formik';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { IPolicyApprovalDto, IPolicyDocDto } from '../../../../backend/src/policy-doc/interfaces';
import API from '../../services/ApiService';
import { SaveButton } from '../buttons';
import { FormikDatePicker, FormikTextField } from '../forms';
import { showErrorResultBar, showSuccessResultBar } from '../ResultSnackbar';
import StyledDialogTitle from '../StyledDialogTitle';
import ResponsiveDialog from './ResponsiveDialog';

const useStyles = makeStyles({
  formGrid: {
    paddingLeft: '16px',
    paddingRight: '32px',
  },
  progressCircle: {
    margin: '2rem',
  },
});

export interface PolicyApprovalDialogProps extends DialogProps {
  onApprove: (updatedPolicy: IPolicyDocDto) => void;
  onClose: () => void;
  policy: IPolicyDocDto;
}

export default function PolicyApprovalDialog({ onApprove, onClose, open, policy }: PolicyApprovalDialogProps) {
  const classes = useStyles();
  const [ isLoading, setIsLoading ] = useState(false);
  const [ prevPolicyDocVersion, setPrevPolicyDocVersion ] = useState<IPolicyDocDto | null>(null);

  const FormSchema = useMemo(() => Yup.object().shape({
    approvalDate: Yup
      .date()
      .label('Approval date')
      .min(prevPolicyDocVersion?.approvalDate ?? new Date(0), `Cannot be before the previous policy's approval date: ${prevPolicyDocVersion?.approvalDate ?? ''}`)
      .required('Required')
      .typeError('Invalid date'),
    approver: Yup
      .string()
      .label('Policy Approver')
      .required('Required')
      .max(255),
    changeLog: Yup
      .string()
      .label('Changes')
      .max(500)
      .nullable(),
    inForceAt: Yup
      .date()
      .label('In force date')
      .required('Required'),
    owner: Yup
      .string()
      .label('Policy Owner')
      .required('Required')
      .max(255),
    version: Yup
      .string()
      .label('Version identifier')
      .max(40)
      .notOneOf([prevPolicyDocVersion?.version], 'Cannot be a previous version.')
      .required('Required'),
  }), [ prevPolicyDocVersion ]);

  useEffect(() => {
    setPrevPolicyDocVersion(null);

    if (!policy.prevVersionId) {
      return;
    }

    setIsLoading(true);

    API.get(`policyDoc/${policy.prevVersionId}`)
      .then(res => {
        const prevPolicyDoc: IPolicyDocDto | null = res?.data?.data?.content ?? null;
        setPrevPolicyDocVersion(prevPolicyDoc);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [policy]);

  const handleSave = async (formValues: IPolicyApprovalDto) => {
    try {
      const res = await API.post(`policyDoc/${policy.versionId}/approve`, formValues);
      onApprove(res.data.data);
      showSuccessResultBar('Policy successfully approved');
      handleClose();
    } catch (err: any) {
      const errorMsg = err.response?.data?.error ?? 'Unexpected error while updating policy';
      showErrorResultBar(errorMsg);
      Sentry.captureException(err);
    }
  };

  const handleClose = () => {
    setPrevPolicyDocVersion(null);
    onClose();
  };

  return (
    <ResponsiveDialog
      open={open}
      onClose={handleClose}
      disableBackdropClick
    >
      <StyledDialogTitle onClose={handleClose}>
        Approval Details for the {policy.name}
      </StyledDialogTitle>
        { isLoading ? ( <CircularProgress className={classes.progressCircle} /> ) : (
      <Formik
        enableReinitialize
        initialValues={{
          approvalDate: new Date(),
          approver: policy.approver ?? '',
          changeLog: '',
          inForceAt: new Date(),
          owner: policy.owner ?? '',
          version: '',
        }}
        validationSchema={FormSchema}
        onSubmit={async (values, { setSubmitting }) => {
          await handleSave(values);
          setSubmitting(false);
        }}
        onReset={_ => {
          handleClose();
        }}
      >
        {formikProps => (
          <>
            <DialogContent>
              <Typography
                variant="body1"
                gutterBottom
              >
                {/* TODO: Auto-populate with 'Initial Policy' if this is the first approval. */}
                Please enter a version number (1.0, 2.0, etc.) and a brief description of the changes in this version
                (or 'Initial Policy' if this is the first approval).
                Then confirm the official approval date
                and the in-force date (the date the policy goes into effect).
              </Typography>
              {!policy.customPolicy &&
              <Typography
                variant="body1"
              >
                The information you enter will automatically be added to the Policy Tracking section of your policy.
              </Typography>
              }
              <Grid container spacing={2} className={classes.formGrid}>
                <Grid item xs={12}>
                  <FormikTextField
                    field="version"
                    formikProps={formikProps}
                    label="Version number"
                    placeholder={prevPolicyDocVersion?.version ? '' : '1.0'}
                    helperTextStr={prevPolicyDocVersion?.version ? `Previous version is ${prevPolicyDocVersion?.version}` : ''}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormikTextField
                    field="changeLog"
                    formikProps={formikProps}
                    label="Version change log"
                    placeholder="Brief description of changes"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormikTextField
                    field="owner"
                    formikProps={formikProps}
                    label="Owner"
                    placeholder="Policy Owner"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormikTextField
                    field="approver"
                    formikProps={formikProps}
                    label="Approver"
                    placeholder="Policy Approver"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormikDatePicker
                    field="approvalDate"
                    formikProps={formikProps}
                    label="Approval date"
                    minDate={prevPolicyDocVersion?.approvalDate ? moment(prevPolicyDocVersion.approvalDate) : undefined}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormikDatePicker
                    field="inForceAt"
                    formikProps={formikProps}
                    label="In-force date"
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                size="small"
                disabled={formikProps.isSubmitting}
                onClick={formikProps.handleReset}
              >
                Cancel
              </Button>
              <SaveButton
                disabled={formikProps.isSubmitting || Object.values(formikProps.errors).filter(v => !!v).length > 0}
                onClick={formikProps.handleSubmit}
              />
            </DialogActions>
          </>
        )}
      </Formik>
      )}
    </ResponsiveDialog>
  );
}
