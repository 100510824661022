import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import React from 'react';

export interface DataTableTitleWithFilterProps {
  filterLabel: string;
  filterSelection?: { text: string; value: string };
  filterSelections: Array<{
    text: string;
    value: string;
  }>;
  onFilterChange: (newValue: string) => void;
  title: string;
}

export default function DataTableTitleWithFilter(props: DataTableTitleWithFilterProps) {
  const { filterLabel, filterSelection, filterSelections, onFilterChange, title } = props;

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
      <Typography variant="h6" sx={{ mt: 2, mr: 2 }}>
        {title}
      </Typography>
      <FormControl variant="standard" sx={{ minWidth: 400 }}>
        <InputLabel htmlFor="filter-select">
          {filterLabel}
        </InputLabel>
        <Select
          value={filterSelection?.value}
          onChange={e => onFilterChange(e.target.value)}
          inputProps={{
            name: 'filter-select',
            id: 'filter-select',
          }}
        >
          {Object.values(filterSelections).map(filterView => (
            <MenuItem
              key={filterView.value}
              value={filterView.value}
            >
              {filterView.text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
