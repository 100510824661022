import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
  Tooltip,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as Sentry from '@sentry/react';
import { Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { IOrgForm } from '../../pages/TheOrganizationPage';
import API from '../../services/ApiService';
import useAuth from '../../services/auth/useAuth';
import { DeleteButton, SaveButton } from '../buttons';
import { ConfirmationDialog } from '../dialogs';
import { showErrorResultBar, showSuccessResultBar } from '../ResultSnackbar';
import StylizedName from '../StylizedName';

export const AWS_ARN_REGEX = /^arn:aws(?:-cn|-us-gov)?:[a-zA-Z0-9-]+:(?:(?:us|ap|eu|ca|sa|cn)-(east|west|south|north|central|northeast|southeast|northwest|southwest)-[0-9]+)?:(?:[0-9]+|\\*):(?:.+|\\*)/;
const TO_SHOW_KMS_KEY_ARN_FORM_FIELD = false;

const useStyles = makeStyles({
  switchField: {
    marginLeft: 0,
  },
  headerCard: {
    width: '100%',
    margin: 0,
  },
  leftIcon: {
    marginRight: '0.5rem',
  },
  addOrgButton: {
    marginTop: 0,
    marginBottom: 0,
  },
});

const UpdateOrgSchema = Yup.object().shape({
  autoEnroll: Yup.boolean(),
  kmsKeyArn: Yup.string()
    .matches(AWS_ARN_REGEX, { message: 'Incorrect ARN format', excludeEmptyString: true }),
  name: Yup.string()
    .min(5, 'Must be at least 5 characters')
    .max(64, 'Can be at most 64 characters')
    .required('Required'),
  notifications: Yup.boolean(),
  policyApprover: Yup.string()
    .min(5, 'Must be at least 5 characters')
    .max(255, 'Can be at most 255 characters')
    .required('Required'),
  policyOwner: Yup.string()
    .min(5, 'Must be at least 5 characters')
    .max(255, 'Can be at most 255 characters')
    .required('Required'),
});

const helperAndErrorText = (helperText: string, errorText?: string, isTouched?: boolean) => {
  // The 'helperText' always appears under the input field.
  // The 'errorText' will appear as well if there's an error.
  return helperText + (errorText && isTouched ? ` - ${errorText}` : '');
};

export interface OrgGeneralSettingsProps {
  isDeletable?: boolean;
  isLoading?: boolean;
  onClickSave: (values: IOrgForm) => Promise<void>;
  org: IOrgForm;
}

export default function OrgGeneralSettings({ isDeletable = true, isLoading = false, onClickSave, org }: OrgGeneralSettingsProps) {
  const classes = useStyles();
  const { isEmailFree, orgId, userId } = useAuth();
  const [ isConfirmOpen, setIsConfirmOpen ] = useState(false);
  const [ isSoftDeleted, setIsSoftDeleted ] = useState(false);

  async function handleConfirmDelete(confirmed: boolean) {
    setIsConfirmOpen(false);
    if (confirmed) {
      await deleteOrg();
    }
  }

  async function deleteOrg() {
    try {
      await API.delete(`org/${orgId}`);
      showSuccessResultBar('Your request has been noted. We will verify and take action shortly.');
      setIsSoftDeleted(true);
    } catch (err: any) {
      Sentry.captureException(err);
      showErrorResultBar('Unexpected error while requesting delete, please try again later.');
    }
  }

  function onClickDelete() {
    setIsConfirmOpen(true);
  }

  return (
    <>
      <Card className={classes.headerCard}>
      <CardHeader
        title={orgId ? 'General Organization Settings' : (<span>Welcome to <StylizedName /></span>)}
        subheader={!orgId && 'We need a little more information to complete your registration.'}
      />
      <Formik
        enableReinitialize
        initialValues={{
          name: org.name,
          autoEnroll: org.autoEnroll,
          notifications: org.notifications,
          policyOwner: org.policyOwner,
          policyApprover: org.policyApprover,
          kmsKeyArn: org.kmsKeyArn || '',
        }}
        validationSchema={UpdateOrgSchema}
        onSubmit={async (values, { setSubmitting }) => {
          await onClickSave(values);
          setSubmitting(false);
        }}
      >
        {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
          <>
            <CardContent>
              <FormGroup>
                <TextField
                  id="org-name"
                  name="name"
                  label="Organization Name"
                  required={true}
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin="normal"
                  variant="outlined"
                  helperText={helperAndErrorText('Select a unique name to identify your organization', errors.name, touched.name)}
                  error={!!errors.name}
                />

                {(!isEmailFree) &&
                <Tooltip
                  placement="bottom-start"
                  title="When auto enroll is enabled, any new users who register with an e-mail address from this
                      organization will automatically be associated with it."
                >
                  <FormControlLabel
                    className={classes.switchField}
                    control={
                      <Switch
                        checked={values.autoEnroll}
                        onChange={handleChange}
                        name="autoEnroll"
                        disabled={isEmailFree}
                      />
                    }
                    label="Auto Enroll"
                  />
                </Tooltip>
                }
                <Tooltip
                  placement="bottom-start"
                  title="Be notified when new reports are uploaded and when tasks are due soon."
                >
                  <FormControlLabel
                    className={classes.switchField}
                    control={
                      <Switch
                        checked={values.notifications}
                        onChange={handleChange}
                        name="notifications"
                      />
                    }
                    label="Receive Notifications"
                  />
                </Tooltip>
              </FormGroup>
              <FormGroup>
                <TextField
                  id="policyOwner"
                  name="policyOwner"
                  label="Policy Owner"
                  required={true}
                  value={values.policyOwner}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin="normal"
                  variant="outlined"
                  helperText={helperAndErrorText('Name of the policy owner', errors.policyOwner, touched.policyOwner)}
                  error={!!errors.policyOwner}
                />

                <TextField
                  id="policyApprover"
                  name="policyApprover"
                  label="Policy Approver"
                  required={true}
                  value={values.policyApprover}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin="normal"
                  variant="outlined"
                  helperText={helperAndErrorText('Name(s) of the policy approver(s)', errors.policyApprover, touched.policyApprover)}
                  error={!!errors.policyApprover}
                />
              </FormGroup>
              {TO_SHOW_KMS_KEY_ARN_FORM_FIELD && (
                <FormGroup>
                  <TextField
                    id="kms_key_arn"
                    name="kmsKeyArn"
                    label="KMS Key ARN"
                    required={false}
                    value={values.kmsKeyArn}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin="normal"
                    variant="outlined"
                    helperText={helperAndErrorText('ARN of KMS key to use when encrypting your documents', errors.kmsKeyArn, touched.kmsKeyArn)}
                    error={!!errors.kmsKeyArn}
                  />
                </FormGroup>
              )}
            </CardContent>
            <CardActions>
              {orgId ? (
                <SaveButton
                  disabled={isSubmitting || Object.keys(errors).length > 0}
                  onClick={handleSubmit}
                />
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  disabled={isLoading || isSubmitting || Object.keys(errors).length > 0}
                  onClick={() => handleSubmit()}
                >
                  Save and continue
                </Button>
              )}
              {isDeletable && userId === org.ownerId && (
                <Tooltip
                  title="Request deletion of your entire organization."
                  placement="bottom"
                >
                  <span>
                    <DeleteButton
                      disabled={isLoading || isSubmitting || isSoftDeleted}
                      onClick={onClickDelete}
                      text="Delete Org"
                    />
                  </span>
                </Tooltip>
              )}
            </CardActions>
          </>
        )}
      </Formik>
      </Card>
      <ConfirmationDialog
        onResponse={handleConfirmDelete}
        open={isConfirmOpen}
      >
        <>
          You are requesting that all data related to your organization be
          permanently removed from all databases and archives.
          <br />
          <br />
          After we process this request, it is not possible to reverse it. If you decide to
          sign-up for <StylizedName /> again, you will be starting over.
        </>
      </ConfirmationDialog>
    </>
  );
}
