import { TableCell, TableRow } from '@mui/material';
import * as Sentry from '@sentry/react';
import React, { useEffect, useState } from 'react';
import { IVendorDto } from '../../../backend/src/vendor/interfaces';
import API from '../services/ApiService';
import DataTableTitleWithButton from '../components/DataTableTitleWithButton';
import { VendorEditDialog } from '../components/dialogs';
import { isDataResellerSelections, isSubprocessorSelections } from '../components/dialogs/VendorEditDialog';
import { showErrorResultBar } from '../components/ResultSnackbar';
import SpioDataTable, { SpioDataTableColumn } from '../components/SpioDataTable';
import VendorDetailsInfo from '../components/VendorDetailsInfo';
import { authStatusMap, vendorStatusMap } from '../components/VendorDetailsInfo';
import { truncateString } from '../helpers';

export const UNASSIGNED_DATA_STR = '(blank)';

const getTableColumns = (tableData: ITableDatum[]): SpioDataTableColumn[] => [
  {
    name: 'id',
    label: 'ID',
    options: {
      display: 'false',
      filter: false,
    },
  },
  {
    name: 'vendorName',
    label: 'Vendor',
    options: {
      filter: false,
    },
  },
  {
    name: 'statusStr',
    label: 'Decision',
  },
  {
    name: 'approvedAt',
    label: 'Approval Date',
    options: {
      customFilterListOptions: { render: v => `Approval Date: ${v || UNASSIGNED_DATA_STR}` },
      display: 'false',
    },
  },
  {
    name: 'implementedAt',
    label: 'Start Date',
    options: {
      customFilterListOptions: { render: v => `Start Date: ${v || UNASSIGNED_DATA_STR}` },
      display: 'false',
    },
  },
  {
    name: 'reviewedAt',
    label: 'Last Review Date',
    options: {
      customFilterListOptions: { render: v => `Last Review: ${v || UNASSIGNED_DATA_STR}` },
      display: 'false',
    },
  },
  {
    name: 'retiredAt',
    label: 'Retirement Date',
    options: {
      customFilterListOptions: { render: v => `Retirement Date: ${v || UNASSIGNED_DATA_STR}` },
      display: 'false',
    },
  },
  {
    name: 'ownerEmail',
    label: 'Owner Email',
    options: {
      filter: false,
      display: 'false',
    },
  },
  {
    name: 'department',
    label: 'Department',
    options: {
      filter: false,
      display: 'false',
    },
  },
  {
    name: 'externalId',
    label: 'External ID',
    options: {
      customFilterListOptions: { render: v => `External ID: ${v || UNASSIGNED_DATA_STR}` },
      display: 'false',
    },
  },
  {
    name: 'dataTier',
    label: 'Data Tier',
    options: {
      customFilterListOptions: { render: v => `Data Tier: ${v || UNASSIGNED_DATA_STR}` },
    },
  },
  {
    name: 'dataHandled',
    label: 'Data Handled',
    options: {
      customBodyRenderLite: dataIndex => truncateString(tableData[dataIndex]?.dataHandled || ''),
      filter: false,
    },
  },
  {
    name: 'isSubprocessorStr',
    label: 'Is Subprocessor?',
    options: {
      customBodyRenderLite: (dataIndex) => {
        const v = tableData[dataIndex]?.isSubprocessorStr;

        return (!v || v === 'Unknown') ? null : v;
      },
      customFilterListOptions: { render: v => `Is Subprocessor: ${v}` },
      display: 'false',
    },
  },
  {
    name: 'isResellerStr',
    label: 'Sells Data?',
    options: {
      customBodyRenderLite: (dataIndex) => {
        const v = tableData[dataIndex]?.isResellerStr;

        return (!v || v === 'Unknown') ? null : v;
      },
      customFilterListOptions: { render: v => `Is Reseller: ${v}` },
      display: 'false',
    },
  },
  {
    name: 'mfaStatusStr',
    label: 'MFA',
    options: {
      customBodyRenderLite: (dataIndex) => {
        const v = tableData[dataIndex]?.mfaStatusStr;

        return (!v || v === 'Unknown') ? null : v;
      },
      customFilterListOptions: { render: v => `MFA: ${v}` },
      display: 'false',
    },
  },
  {
    name: 'ssoStatusStr',
    label: 'SSO',
    options: {
      customBodyRenderLite: (dataIndex) => {
        const v = tableData[dataIndex]?.ssoStatusStr;

        return (!v || v === 'Unknown') ? null : v;
      },
      customFilterListOptions: { render: v => `SSO: ${v}` },
      display: 'false',
    },
  },
  {
    name: 'contactEmail',
    label: 'Contact Email',
    options: {
      filter: false,
      display: 'false',
    },
  },
  {
    name: 'contactName',
    label: 'Contact Name',
    options: {
      filter: false,
      display: 'false',
    },
  },
  {
    name: 'contactPhone',
    label: 'Contact Phone',
    options: {
      filter: false,
      display: 'false',
    },
  },
  {
    name: 'contactTitle',
    label: 'Contact Title',
    options: {
      filter: false,
      display: 'false',
    },
  },
  {
    name: 'notes',
    label: 'Notes',
    options: {
      customBodyRenderLite: dataIndex => truncateString(tableData[dataIndex]?.notes || ''),
      display: 'false',
      filter: false,
    },
  },
  {
    name: 'internalNotes',
    label: 'Internal Notes',
    options: {
      customBodyRenderLite: dataIndex => truncateString(tableData[dataIndex]?.internalNotes || ''),
      display: 'false',
      filter: false,
    },
  },
  {
    name: 'nbAttachments',
    label: 'Attachments',
    options: {
      display: 'false',
      filter: false,
      searchable: false,
    },
  },
];

interface ITableDatum extends IVendorDto {
  isResellerStr: string;
  isSubprocessorStr: string;
  mfaStatusStr: string;
  nbAttachments: string;
  ssoStatusStr: string;
  statusStr: string;
}

function TheGlobalVendorPage() {
  const [ currVendor, setCurrVendor ] = useState<IVendorDto | null>(null);
  const [ isDialogOpen, setIsDialogOpen ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ tableData, setTableData ] = useState<ITableDatum[]>([]);
  const [ vendors, setVendors ] = useState<IVendorDto[]>([]);

  useEffect(() => {
    setIsLoading(true);

    API.get('globalVendor')
      .then(res => setVendors(res.data?.data ?? []))
      .catch((err) => {
        showErrorResultBar('Unexpected error loading vendors');
        Sentry.captureException(err);
      })
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    setTableData(vendors.map(d => Object({
      ...d,
      isResellerStr: (d.isDataReseller && isDataResellerSelections[d.isDataReseller]) || 'Unknown',
      isSubprocessorStr: (d.isSubprocessor && isSubprocessorSelections[d.isSubprocessor]) || 'Unknown',
      mfaStatusStr: (d.mfaStatus && authStatusMap[d.mfaStatus]) || 'Unknown',
      nbAttachments: d.documents?.length ? d.documents.length.toString() : '',
      ssoStatusStr: (d.ssoStatus && authStatusMap[d.ssoStatus]) || 'Unknown',
      statusStr: (d.status && vendorStatusMap[d.status]) || 'Unknown',
    })));
  }, [ vendors ]);

  const handleClickEdit = (vendor: IVendorDto) => () => {
    setCurrVendor(vendor);
    setIsDialogOpen(true);
  };

  const handleCloseEdit = () => {
    setIsDialogOpen(false);
    setCurrVendor(null);
  };

  const handleSaveVendor = (vendor: IVendorDto) => {
    if (currVendor === null) {
      handleCreateVendor(vendor);
    } else {
      handleUpdateVendor(vendor);
    }
  };

  const handleCreateVendor = (newVendor: IVendorDto) => {
    const updatedVendorData = [ newVendor ].concat(vendors);
    setVendors(updatedVendorData);
  };

  const handleUpdateVendor = (updatedVendor: IVendorDto) => {
    const updatedVendors = vendors.slice();
    const updatedIdx = updatedVendors.findIndex(v => v.id === updatedVendor.id);
    if (updatedIdx !== -1) {
      updatedVendors[updatedIdx] = updatedVendor;
      setVendors(updatedVendors);
    }
  };

  return (
    <>
      <SpioDataTable
        title={<DataTableTitleWithButton
          onButtonClick={() => setIsDialogOpen(true)}
          title="Global Vendor Management"
        />}
        columns={getTableColumns(tableData)}
        data={tableData}
        options={{
          print: false,
          filterType: 'multiselect',
          selectableRows: 'none',
          textLabels: {
            body: {
              noMatch: isLoading ? 'Loading...' : 'No records found',
              toolTip: 'Sort',
            },
          },
          expandableRows: true,
          expandableRowsOnClick: true,
          renderExpandableRow: (rowData, rowMeta) => {
            const colSpan = rowData.length + 1;
            const vendorData: IVendorDto = vendors[rowMeta.dataIndex];

            return vendorData ? (
              <TableRow>
                <TableCell colSpan={colSpan}>
                  <VendorDetailsInfo
                    isGlobalVendor={true}
                    onClickEdit={handleClickEdit(vendorData)}
                    onUpdateVendor={handleUpdateVendor}
                    vendorData={vendorData}
                  />
                </TableCell>
              </TableRow>
            ) : null;
          },
        }}
      />
      <VendorEditDialog
        isGlobalVendor={true}
        onClose={handleCloseEdit}
        onSave={handleSaveVendor}
        open={isDialogOpen}
        vendorData={currVendor}
      />
    </>
  );
}

TheGlobalVendorPage.requiredAuthZ = {
  tier: 1,
  permission: 'vendor_global:manage',
};
TheGlobalVendorPage.routePath = '/global-vendor';
TheGlobalVendorPage.title = 'Global Vendors';

export default TheGlobalVendorPage;
