import { Typography } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';
import { TypographyProps } from '@mui/material/Typography';
import React from 'react';

const styles = createStyles({
  body1: {
    marginTop: '1rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '50rem',
    paddingLeft: '1em',
    paddingRight: '1em',
  },
});

export const BodyText = withStyles(styles)(
  ({ children, classes, ...otherProps }: TypographyProps) =>
    (
      <Typography
        {...otherProps}
        align={otherProps.align || 'left'}
        className={classes!.body1}
        variant="body1">
        {children}
      </Typography>
    ));
