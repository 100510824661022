import { Typography } from '@mui/material';
import React, { useEffect } from 'react';
import useAuth from '../services/auth/useAuth';

export default function TheLogoutPage() {
  const { logout } = useAuth();

  useEffect(() => { logout(); }, [ logout ]);

  return (
    <Typography variant="h3" align="center">Logout in progress.</Typography>
  );
}
