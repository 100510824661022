import React, { useEffect, useState } from 'react';
import { Button, DialogActions, DialogContent, DialogProps, Grid, Link, MenuItem, Typography } from '@mui/material';
import * as Sentry from '@sentry/react';
import { Formik } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import { RiskImpact, RiskProbability, RiskStatus } from '../../../../backend/src/risk/enums';
import { IRiskDto, IRiskUpdateDto } from '../../../../backend/src/risk/interfaces';
import { preventNonNumericInput } from '../../helpers';
import API from '../../services/ApiService';
import { SaveButton } from '../buttons';
import { FormGridRow, FormikAutocompleteTextField, FormikDatePicker, FormikTextField } from '../forms';
import { showErrorResultBar, showSuccessResultBar } from '../ResultSnackbar';
import StyledDialogTitle from '../StyledDialogTitle';
import ResponsiveDialog from './ResponsiveDialog';

const MAX_RISK_SCORE = 100;
const DEFAULT_RISK_CATS = [
  'Control not implemented',
  'Policy exception',
  'Vendor security',
];

export type RiskRank = RiskProbability | RiskImpact;
export type RiskRankStr = 'High' | 'Medium' | 'Low' | 'Unknown';

export interface IRiskRankInfo {
  score: number;
  text: RiskRankStr;
}

export const rankMap: {[ key in RiskRank ]: IRiskRankInfo} = {
  high: {
    text: 'High',
    score: 1,
  },
  medium: {
    text: 'Medium',
    score: MAX_RISK_SCORE / 2,
  },
  low: {
    text: 'Low',
    score: MAX_RISK_SCORE,
  },
  unknown: {
    text: 'Unknown',
    score: 0, // ie, higher than 'high'
  },
};

export const riskStatusSelections: Omit<{[ key in RiskStatus ]: string}, 'archived'> = {
  new: 'New',
  accepted: 'Accepted',
  mitigated: 'Mitigated',
  transferred: 'Transferred',
  closed: 'Closed',
};

const RiskSchema = Yup.object().shape({
  category: Yup
    .string()
    .nullable(),
  details: Yup
    .string(),
  impact: Yup
    .mixed()
    .oneOf(Object.keys(rankMap)),
  mitigationPlan: Yup
    .string(),
  owner: Yup
    .string()
    .max(255),
  probability: Yup
    .mixed()
    .oneOf(Object.keys(rankMap)),
  responsePlan: Yup
    .string(),
  risk: Yup
    .string()
    .trim()
    .required('- Required'),
  status: Yup
    .mixed()
    .oneOf(Object.keys(riskStatusSelections)),
  score: Yup
    .number()
    .positive()
    .max(MAX_RISK_SCORE)
    .label(' - Risk score')
    .required('- Required'),
  reportedAt: Yup
    .date()
    .max(moment().endOf('day').toDate(), 'The Reported Date cannot be in the future')
    .typeError('Invalid date'),
  reviewedAt: Yup
    .date()
    .when('reportedAt', (reportedAt, schema) => moment(reportedAt).isValid() ? (
      schema.min(moment(reportedAt).utc().startOf('day').toDate(), 'The Reviewed Date cannot be before the Reported Date')
    ) : (
      schema
    ))
    .max(moment().endOf('day').toDate(), 'The Reviewed Date cannot be in the future')
    .typeError('Invalid date'),
});

export const HELPER_TEXT = {
  category: 'The risk category or type',
  details: 'A more detailed description of the risk and its causes',
  impact: 'Estimate of the severity of the cost impact',
  mitigationPlan: 'Actions to be taken to help prevent this risk from occurring or to lower its severity',
  owner: 'Responsible party for this risk',
  probability: 'Estimate of the likelihood of the risk occurring',
  responsePlan: 'Actions to be taken if the risk actually occurs',
  risk: 'A brief name indicating the cause of the risk',
  status: 'New (under consideration), Accepted (without mitigation), Mitigated (mitigation plan in place), or Transferred (transferred to 3rd party)',
  score: `Estimate of an overall risk score from 1 (high) to ${MAX_RISK_SCORE} (low)`,
  reportedAt: 'The date when the risk was first reported',
  reviewedAt: 'The date when the risk was last reviewed',
};

const calcScore = (probabilityVal: RiskProbability, impactVal: RiskImpact) => {
  const probability = rankMap[probabilityVal];
  const impact = rankMap[impactVal];

  const probabilityScore = probability ? probability.score : 0;
  const impactScore = impact ? impact.score : 0;
  const aveScoreInt = Math.floor(0.5 * (probabilityScore + impactScore));

  return Math.max(1, aveScoreInt);
};

export interface RiskEditDialogProps extends DialogProps {
  riskData: IRiskDto | null;
  onClose: () => void;
  onUpdate: (risk: IRiskDto) => void;
}

export default function RiskEditDialog({ riskData, onClose, onUpdate, open }: RiskEditDialogProps) {
  const [ toAutoScore, setToAutoScore ] = useState(true);

  useEffect(() => {
    if (riskData === null) {
      setToAutoScore(true);
    } else {
      const { score, probability, impact } = riskData;
      setToAutoScore(score === calcScore(probability, impact));
    }
  }, [ riskData ]);

  const handleSave = async (formValues: IRiskUpdateDto) => {
    try {
      const res = riskData === null ?
        await API.post('risk', formValues) :
        await API.patch(`risk/${riskData.id}`, formValues);
      onUpdate(res.data?.data);
      showSuccessResultBar('Risk saved successfully');

      if (riskData === null) {
        onClose();
      }
    } catch (err: any) {
      const errorMsg = err.response?.data?.error ?? 'Unexpected error saving risk';
      showErrorResultBar(errorMsg);
      Sentry.captureException(err);
    }
  };

  return (
    <ResponsiveDialog
      disableBackdropClick
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
    >
      <StyledDialogTitle onClose={onClose}>
        {riskData === null ? 'Create new risk register entry' : 'Edit risk register entry'}
      </StyledDialogTitle>
        <Formik
          enableReinitialize
          initialValues={{
            category: riskData?.category || '',
            details: riskData?.details || '',
            impact: riskData?.impact || 'unknown',
            mitigationPlan: riskData?.mitigationPlan || '',
            owner: riskData?.owner || '',
            probability: riskData?.probability || 'unknown',
            responsePlan: riskData?.responsePlan || '',
            risk: riskData?.risk || '',
            score: riskData?.score || 1,
            status: riskData?.status || 'new',
            reportedAt: riskData?.reportedAt || moment().startOf('day').toDate(),
            reviewedAt: riskData?.reviewedAt || moment().startOf('day').toDate(),
          }}
          validationSchema={RiskSchema}
          onReset={onClose}
          onSubmit={async (values, { setSubmitting }) => {
            await handleSave(values);
            setSubmitting(false);
          }}
        >
          {formikProps => (
            <>
              <DialogContent>
                <Grid container>
                  {/* 'Risk identification' section */}
                  <FormGridRow
                    divider
                    title="Risk identification and tracking"
                  >
                    <FormikTextField
                      autoFocus
                      field="risk"
                      formikProps={formikProps}
                      helperTextStr={HELPER_TEXT.risk}
                      label="Risk"
                      required
                    />
                    <FormikTextField
                      field="status"
                      formikProps={formikProps}
                      helperTextStr={HELPER_TEXT.status}
                      label="Status"
                      select
                    >
                      {Object.entries(riskStatusSelections).map(([ key, label ]) => (
                        <MenuItem
                          key={key}
                          value={key}
                        >
                          {label}
                        </MenuItem>
                      ))}
                    </FormikTextField>
                    <FormikTextField
                      field="owner"
                      formikProps={formikProps}
                      helperTextStr={HELPER_TEXT.owner}
                      label="Owner"
                    />
                    <FormikAutocompleteTextField
                      autocompleteProps={{
                        freeSolo: true,
                        groupBy: ()=>'Examples:',
                        options: DEFAULT_RISK_CATS,
                      }}
                      field="category"
                      formikProps={formikProps}
                      helperTextStr={HELPER_TEXT.category}
                      label="Category"
                    />
                    <FormikTextField
                      field="details"
                      formikProps={formikProps}
                      helperTextStr={HELPER_TEXT.details}
                      label="Details"
                      multiline
                    />
                    <FormikDatePicker
                      field="reportedAt"
                      formikProps={formikProps}
                      helperTextStr={HELPER_TEXT.reportedAt}
                      label="Reported Date"
                      disableFuture
                    />
                    <FormikDatePicker
                      field="reviewedAt"
                      formikProps={formikProps}
                      helperTextStr={HELPER_TEXT.reviewedAt}
                      label="Reviewed Date"
                      minDate={moment(formikProps.values['reportedAt'])}
                      disableFuture
                    />
                  </FormGridRow>
                  <FormGridRow
                    divider
                    title="Analysis"
                  >
                    <FormikTextField
                      field="probability"
                      formikProps={formikProps}
                      helperTextStr={HELPER_TEXT.probability}
                      label="Probability"
                      select
                      onChange={e => {
                        if (toAutoScore) {
                          const newProbability = e.target.value as RiskProbability;
                          const impact = formikProps.values.impact;
                          const newScore = calcScore(newProbability, impact);

                          formikProps.setFieldValue('score', newScore);
                        }

                        formikProps.handleChange(e);
                      }}
                    >
                      {Object.entries(rankMap).map(([ key, rankInfo ]) => (
                        <MenuItem
                          key={key}
                          value={key}
                        >
                          {rankInfo.text}
                        </MenuItem>
                      ))}
                    </FormikTextField>
                    <FormikTextField
                      field="impact"
                      formikProps={formikProps}
                      helperTextStr={HELPER_TEXT.impact}
                      label="Impact"
                      select
                      onChange={e => {
                        if (toAutoScore) {
                          const newImpact = e.target.value as RiskImpact;
                          const probability = formikProps.values.probability;
                          const newScore = calcScore(probability, newImpact);

                          formikProps.setFieldValue('score', newScore);
                        }

                        formikProps.handleChange(e);
                      }}
                    >
                      {Object.entries(rankMap).map(([ key, rankInfo ]) => (
                        <MenuItem
                          key={key}
                          value={key}
                        >
                          {rankInfo.text}
                        </MenuItem>
                      ))}
                    </FormikTextField>
                    <FormikTextField
                      field="score"
                      formikProps={formikProps}
                      helperTextStr={HELPER_TEXT.score}
                      label="Risk score"
                      required
                      type="number"
                      onChange={e => {
                        const newScore = parseInt(e.target.value, 10);
                        const probability = formikProps.values.probability;
                        const impact = formikProps.values.impact;
                        setToAutoScore(newScore === calcScore(probability, impact));
                        formikProps.handleChange(e);
                      }}
                      onKeyPress={preventNonNumericInput}
                    />
                    {!toAutoScore && <div>
                      <Link
                        component="button"
                        variant="body2"
                        onClick={() => {
                          const impact = formikProps.values.impact;
                          const probability = formikProps.values.probability;
                          formikProps.setFieldValue('score', calcScore(probability, impact));
                          setToAutoScore(true);
                        }}
                      >
                        Reset to the default score calculation
                      </Link>
                      <br />
                      <Typography variant="caption">
                        The default score is the average of the probability and impact, with high/unknown=1, medium={MAX_RISK_SCORE / 2} and low={MAX_RISK_SCORE}.
                      </Typography>
                    </div>}
                  </FormGridRow>
                  <FormGridRow
                    title="Mitigation and response"
                  >
                    <FormikTextField
                      field="mitigationPlan"
                      formikProps={formikProps}
                      helperTextStr={HELPER_TEXT.mitigationPlan}
                      label="Mitigation plan"
                      multiline
                    />
                    <FormikTextField
                      field="responsePlan"
                      formikProps={formikProps}
                      helperTextStr={HELPER_TEXT.responsePlan}
                      label="Response plan"
                      multiline
                    />
                  </FormGridRow>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  disabled={formikProps.isSubmitting}
                  onClick={formikProps.handleReset}
                  color="primary"
                >
                  {riskData === null ? 'Cancel' : 'Close'}
                </Button>
                <SaveButton
                  disabled={formikProps.isSubmitting || Object.values(formikProps.errors).filter(v => !!v).length > 0}
                  onClick={formikProps.handleSubmit}
                />
              </DialogActions>
            </>
          )}
        </Formik>
    </ResponsiveDialog>
  );
}
