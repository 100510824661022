import React from 'react';
import { Button, Theme } from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import { ButtonProps } from '@mui/material/Button';

const styles = (theme: Theme) => createStyles({
  leftIcon: {
    marginRight: theme.spacing(0.5),
  },
});

export interface DeleteButtonProps extends Omit<ButtonProps, 'classes'>, WithStyles<typeof styles> {
  text?: string;
}

function DeleteButton({ classes, disabled, onClick, text }: DeleteButtonProps) {
  return (
    <Button
      variant="contained"
      size="small"
      color="primary"
      disabled={disabled ?? false}
      onClick={onClick}
    >
      <DeleteIcon className={classes.leftIcon} />
      {text ?? 'Delete'}
    </Button>
  );
}

export default withStyles(styles, { name: 'DeleteButton', withTheme: true })(DeleteButton);
