import moment from 'moment';
import _ from 'lodash';

export function formatDate(date) {
  if (date) {
    return moment(date).local().format('YYYY-MM-DD');
  }
}

export function formatDateTime(datetime) {
  if (datetime) {
    return moment(datetime).local().format('YYYY-MM-DD h:mm:ss a');
  }
}

export function formatSeconds(totalSeconds = 0) {
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = Math.ceil(totalSeconds % 60);

  return _.padStart(minutes.toString(), 2, '0') + ':' + _.padStart(seconds.toString(), 2, '0');
}

export const TrainingVids = {
  WelcomeToSPIO: 'c6c98ce7-b77b-412f-9235-4ecfb4b8b7a3', // short intro to the UI
  SecurityAwareness: '31776523-985a-4f40-b78d-55714f6970b8', // general security awareness training (full version)
  IntroToSPIO: '588dccb6-d1ba-4c3f-993f-815d5c9ba94a', // longer intro to the overall program
};

export const DEFAULT_SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL_ADDRESS || 'support@securityprogram.io';

export const StaticLinks = {
  marketing: 'https://www.securityprogram.io',
  privacy: 'https://www.securityprogram.io/privacy/',
  security: 'https://www.securityprogram.io/security-policy/',
  terms: 'https://www.securityprogram.io/terms-of-service/',
  scheduleDemo: 'https://calendly.com/mattkonda',
};

/**
 * Truncates a string that exceeds the max length by splitting and inserting a horizontal ellipsis (\u2026)
 * @param {string|undefined} s          The string to be truncated
 * @param {number} maxLength  Maximum length after which the string is truncated
 * @returns {string}          Original string if shorter than maxLength, truncated string otherwise
 */
export function truncateString(s, maxLength = 32) {
  if (!s) {
    return '';
  }

  const len = s.length;

  return len < maxLength ? s : `${s.substring(0, maxLength - 12)}\u2026${s.substring(len - 10)}`;
}

export function intervalToString(interval) {
  const parts = [
    'years',
    'months',
    'days',
    'hours',
    'minutes',
    'mins',
    'seconds',
    'secs',
  ];

  let outputParts = [];

  if (!interval) {
    return undefined;
  }

  parts.forEach(p => {
    if (interval[p]) {
      outputParts.push(`${interval[p]} ${p}`);
    }
  });

  return outputParts.join(', ');
}

export function errorParser(err, defaultMsg) {
  let errorMsg = '';

  if (err && err.response && err.response.data) {
    const errorData = err.response.data;
    const errorTypeStr = errorData.error || '';
    const errorDetailStr = errorData.message || '';
    const sep = (errorTypeStr && errorDetailStr) ? ': ' : '';

    errorMsg = `${errorTypeStr}${sep}${errorDetailStr}`;
  }

  return errorMsg || defaultMsg;
}

export function preventNonNumericInput(event) {
  // Some browsers allow non-numerical input for type="number", which we prevent here.
  // (Note that 'key' will be 'undefined' on mobile, but there a numerical keypad should pop up.)
  const { key } = event;

  if (key && !key.match(/^[0-9]+$/)) {
    event.preventDefault();
  }
}

export const sanitizeForCSV = (cellContent) => {
  // Ensure all double quotes are escaped ("" is an escaped double quote)
  // and prefix any leading special characters with a single quote.
  // Note that you'll still want to surround the content with double quotes,
  // so the usage will be: `"${sanitizeForCSV(text)}"`
  return typeof cellContent === 'string'
    ? cellContent.replaceAll('"', '""').replace(/^\+|^-|^=|^@/, "'$&")
    : cellContent;
};
