import { useMemo } from 'react';
import { useOutlet } from 'react-router-dom';

export default function useOutletElement() {
  const outlet = useOutlet();

  return useMemo(() => {
    let props = outlet?.props.children.props;
    while (props?.value?.outlet) {
      props = props?.value?.outlet.props;
    }

    return props?.children?.type;
  }, [ outlet ]);
}
