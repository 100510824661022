import { Divider, Grid, Typography } from '@mui/material';
import { GridProps } from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles({
  divider: {
    width: '100%',
  },
  fieldGroupHeading: {
    marginTop: '0.5rem',
    color: 'inherit',
  },
});

export interface FormGridRowProps extends GridProps {
  divider?: boolean;
  subtitle?: string;
  title: string;
}

export default function FormGridRow({ children, divider = false, subtitle, title, ...otherGridProps }: FormGridRowProps) {
  const classes = useStyles();

  return (
    <>
      <Grid
        container
        spacing={2}
        item
        xs={12}
        {...otherGridProps}
      >
        <Grid item xs={12} md={3}>
          <Typography variant="body1" className={classes.fieldGroupHeading}>
            {title}
          </Typography>
          {subtitle && (
          <Typography variant="body2">
            {subtitle}
          </Typography>
          )}
        </Grid>
        <Grid item xs={12} md={9}>
          {children}
        </Grid>
      </Grid>
      {divider && (
      <Divider className={classes.divider}/>
      )}
    </>
  );
}
