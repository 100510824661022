import { TaskStatus } from '../../../backend/src/task/task-status.enum';
import { IFilters } from './TaskTable';

export const TASK_STATUS_MAP: Record<TaskStatus, string> = {
  not_started: 'Not Started',
  completed: 'Completed',
  past_due: 'Past Due',
  in_progress: 'In Progress',
};

export const OPEN_TASKS_FILTERS = [
  TASK_STATUS_MAP.not_started,
  TASK_STATUS_MAP.in_progress,
  TASK_STATUS_MAP.past_due,
];

export interface IFilterViewSelection {
  text: string;
  value: string;
  filter?: IFilters;
}

export type NonRoundViews =
  'CLEAR_FILTERS' |
  'MY_OPEN_TASKS' |
  'ALL_OPEN_TASKS' |
  'NIST_80053_OPEN_TASKS' |
  'NIST_800171_OPEN_TASKS' |
  'CIS_20_OPEN_TASKS' |
  'SOC2_OPEN_TASKS' |
  'CUSTOM';

export type RoundViews =
  'ROUND_1_OPEN_TASKS' |
  'ROUND_2_OPEN_TASKS' |
  'ROUND_3_OPEN_TASKS' |
  'ROUND_4_OPEN_TASKS' |
  'ROUND_5_OPEN_TASKS' |
  'ROUND_6_OPEN_TASKS' |
  'ROUND_7_OPEN_TASKS' |
  'ROUND_8_OPEN_TASKS' |
  'ROUND_9_OPEN_TASKS' |
  'ROUND_10_OPEN_TASKS' |
  'ROUND_11_OPEN_TASKS' |
  'ROUND_12_OPEN_TASKS';

export const ROUND_TO_ROUND_VIEW: { [round: number]: RoundViews } = {
  1: 'ROUND_1_OPEN_TASKS',
  2: 'ROUND_2_OPEN_TASKS',
  3: 'ROUND_3_OPEN_TASKS',
  4: 'ROUND_4_OPEN_TASKS',
  5: 'ROUND_5_OPEN_TASKS',
  6: 'ROUND_6_OPEN_TASKS',
  7: 'ROUND_7_OPEN_TASKS',
  8: 'ROUND_8_OPEN_TASKS',
  9: 'ROUND_9_OPEN_TASKS',
  10: 'ROUND_10_OPEN_TASKS',
  11: 'ROUND_11_OPEN_TASKS',
  12: 'ROUND_12_OPEN_TASKS',
};

export type FilterViews = NonRoundViews | RoundViews;

export type FilterViewSelections = Record<FilterViews, IFilterViewSelection>;

const getRoundFilter = (round: number): IFilterViewSelection => {
  return {
    text: `Round ${round} open tasks`,
    value: `round_${round}_open_tasks`,
    filter: {
      statusStr: OPEN_TASKS_FILTERS,
      tagNames: [ `round ${round}` ],
    },
  };
};

// Add new filter view selections here:
export const FILTER_VIEW_SELECTIONS: FilterViewSelections = {
  CLEAR_FILTERS: {
    text: 'Clear filters',
    value: 'clear_filters',
    filter: {},
  },
  MY_OPEN_TASKS: {
    text: 'My open tasks',
    value: 'my_open_tasks',
    filter: {
      // Note that the assigneeId needs to be filled in dynamically before this filter is used.
      assigneeId: [],
      statusStr: OPEN_TASKS_FILTERS,
    },
  },
  ALL_OPEN_TASKS: {
    text: 'All open tasks',
    value: 'all_open_tasks',
    filter: {
      statusStr: OPEN_TASKS_FILTERS,
    },
  },
  CIS_20_OPEN_TASKS: {
    text: 'CIS 20 open tasks',
    value: 'cis_20_open_tasks',
    filter: {
      statusStr: OPEN_TASKS_FILTERS,
      tagNames: [ 'cis 20: control (1?[0-9]|20)' ],
    },
  },
  NIST_80053_OPEN_TASKS: {
    text: 'NIST 800-53 open tasks',
    value: 'nist_80053_open_tasks',
    filter: {
      statusStr: OPEN_TASKS_FILTERS,
      tagNames: [ 'nist 800-53' ],
    },
  },
  NIST_800171_OPEN_TASKS: {
    text: 'NIST 800-171 open tasks',
    value: 'nist_800171_open_tasks',
    filter: {
      statusStr: OPEN_TASKS_FILTERS,
      tagNames: [ 'nist-800-171:.*' ],
    },
  },
  SOC2_OPEN_TASKS: {
    text: 'SOC 2 open tasks',
    value: 'soc2_open_tasks',
    filter: {
      statusStr: OPEN_TASKS_FILTERS,
      tagNames: [ 'soc:.*' ],
    },
  },
  CUSTOM: {
    text: 'Custom',
    value: 'custom',
  },
  ROUND_1_OPEN_TASKS: getRoundFilter(1),
  ROUND_2_OPEN_TASKS: getRoundFilter(2),
  ROUND_3_OPEN_TASKS: getRoundFilter(3),
  ROUND_4_OPEN_TASKS: getRoundFilter(4),
  ROUND_5_OPEN_TASKS: getRoundFilter(5),
  ROUND_6_OPEN_TASKS: getRoundFilter(6),
  ROUND_7_OPEN_TASKS: getRoundFilter(7),
  ROUND_8_OPEN_TASKS: getRoundFilter(8),
  ROUND_9_OPEN_TASKS: getRoundFilter(9),
  ROUND_10_OPEN_TASKS: getRoundFilter(10),
  ROUND_11_OPEN_TASKS: getRoundFilter(11),
  ROUND_12_OPEN_TASKS: getRoundFilter(12),
};

export const DEFAULT_VIEW = FILTER_VIEW_SELECTIONS.ALL_OPEN_TASKS;
export const VALID_VIEW_STRINGS = Object.values(FILTER_VIEW_SELECTIONS).map(sel => sel.value);
