import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
import * as Sentry from '@sentry/react';
import React, { useEffect, useState } from 'react';
import { IRoadmapItemDto } from '../../../backend/src/roadmap/interfaces';
import DataTableTitleWithButton from '../components/DataTableTitleWithButton';
import { showErrorResultBar } from '../components/ResultSnackbar';
import RoadmapItemEditDialog from '../components/dialogs/RoadmapItemEditDialog';
import SpioDataTable, { SpioDataTableColumn } from '../components/SpioDataTable';
import { formatDate, truncateString } from '../helpers';
import API from '../services/ApiService';


const getTableColumns = (tableData: ITableDatum[]): SpioDataTableColumn[] => [
  {
    name: 'dateStr',
    label: 'Date',
    options: {
      customFilterListOptions: { render: v => `Date: ${v}` },
      filterOptions: { fullWidth: true },
    },
  },
  {
    name: 'title',
    label: 'Title',
    options: {
      customFilterListOptions: { render: v => `Title: ${v}` },
      filterOptions: { fullWidth: true },
    },
  },
  {
    name: 'description',
    label: 'Description',
    options: {
      customBodyRenderLite: idx => truncateString(tableData[idx]?.description || '', 100),
      filter: false,
    },
  },
];

interface ITableDatum extends IRoadmapItemDto {
  dateStr: string;
}

function TheRoadmapAdminPage() {
  const [ isEditDialogOpen, setIsEditDialogOpen ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ roadmapItems, setRoadmapItems ] = useState<IRoadmapItemDto[]>([]);
  const [ selectedIdx, setSelectedIdx ] = useState<number>(-1);
  const [ selectedItem, setSelectedItem ] = useState<IRoadmapItemDto | null>(null);
  const [ tableData, setTableData ] = useState<ITableDatum[]>([]);

  useEffect(() => {
    setIsLoading(true);

    API.get('roadmapItem')
      .then(res => setRoadmapItems(res.data?.data ?? []))
      .catch((err) => {
        showErrorResultBar('Unexpected error loading roadmap');
        Sentry.captureException(err);
      })
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    setTableData(roadmapItems.map(d => Object({
      ...d,
      dateStr: formatDate(d.date),
    })));
  }, [ roadmapItems ]);

  const handleOpenAddDialog = () => {
    setSelectedIdx(-1);
    setSelectedItem(null);
    setIsEditDialogOpen(true);
  };

  const handleClickRow = (idx: number) => {
    setSelectedIdx(idx);
    setSelectedItem(roadmapItems[idx]);
    setIsEditDialogOpen(true);
  };

  const handleUpdate = (item: IRoadmapItemDto) => {
    if (selectedIdx === -1) {
      setRoadmapItems([ item ].concat(roadmapItems));
    } else {
      const updatedItems = roadmapItems.slice();
      updatedItems[selectedIdx] = item;
      setRoadmapItems(updatedItems);
    }
  };

  const handleDelete = () => {
    if (selectedIdx === -1) return;

    const updatedItems = roadmapItems.slice();
    updatedItems.splice(selectedIdx, 1);
    setRoadmapItems(updatedItems);
  };

  return <>
    <SpioDataTable
      title={<DataTableTitleWithButton
        onButtonClick={handleOpenAddDialog}
        title="Roadmap Items"
      />}
      columns={getTableColumns(tableData)}
      data={tableData}
      options={{
        filterType: 'multiselect',
        onRowClick: (_, rowMeta) => handleClickRow(rowMeta.dataIndex),
        print: false,
        selectableRows: 'none',
        sortOrder: {
          name: 'dateStr',
          direction: 'asc',
        },
        textLabels: {
          body: {
            noMatch: isLoading ? 'Loading...' : 'No records found',
            toolTip: 'Sort',
          },
        },
      }}
    />
    <RoadmapItemEditDialog
      open={isEditDialogOpen}
      itemData={selectedItem}
      onClose={() => setIsEditDialogOpen(false)}
      onDelete={handleDelete}
      onUpdate={handleUpdate}
    />
  </>;
}

TheRoadmapAdminPage.requiredAuthZ = {
  tier: 3,
  permission: 'roadmap:manage',
};
TheRoadmapAdminPage.routePath = '/admin/roadmap';
TheRoadmapAdminPage.title = 'Roadmap Items';
TheRoadmapAdminPage.icon = <ViewTimelineIcon />;

export default TheRoadmapAdminPage;
