import { Button, DialogActions, DialogContent, DialogProps, Typography } from '@mui/material';
import * as Sentry from '@sentry/react';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { ITaskDto, ITasksResponse } from '../../../../backend/src/task/interfaces';
import API from '../../services/ApiService';
import { SaveButton } from '../buttons';
import { FormikEditorTextArea, FormikTextField } from '../forms';
import { showErrorResultBar, showSuccessResultBar } from '../ResultSnackbar';
import StyledDialogTitle from '../StyledDialogTitle';
import ResponsiveDialog from './ResponsiveDialog';

interface IFormValues {
  description: string | null;
  name: string | null;
}

const FormSchema = Yup.object().shape({
  description: Yup
    .string(),
  name: Yup
    .string()
    .trim()
    .max(255, 'Task names are limited to 255 characters')
    .required('Required'),
});

export interface TaskEditDialogProps extends DialogProps {
  onClose: () => void;
  onUpdateTask: (updatedTaskInfo: ITasksResponse) => void;
  task: ITaskDto;
}

export default function TaskEditDialog(props: TaskEditDialogProps) {
  const { onClose, onUpdateTask, open, task } = props;
  const [ isModified, setIsModified ] = useState(false);
  const [ isReverting, setIsReverting ] = useState(false);
  const [ taskMasterVersion, setTaskMasterVersion ] = useState<ITaskDto | null>(null);

  useEffect(() => {
    API.get(`task/${task.id}/taskMasterVersion`)
      .then(res => {
        const { isModified, taskMasterVersion } = res.data.data;
        setIsModified(isModified);
        setTaskMasterVersion(taskMasterVersion);
      })
      .catch(Sentry.captureException);
  }, [ task.id ]);

  const isMatchingTaskMaster = ({ name, description }: IFormValues) => {
    if (taskMasterVersion === null) return false;

    return (
      name === taskMasterVersion.name &&
      (description === taskMasterVersion.description || (description === '' && !taskMasterVersion.description))
    );
  };

  const createUploadData = (formValues: IFormValues): IFormValues => {
    return (isModified && isReverting && isMatchingTaskMaster(formValues)) ? { description: null, name: null } : formValues;
  };

  const handleSave = async (formValues: IFormValues) => {
    try {
      const res = await API.put(`task/${task.id}`, createUploadData(formValues));
      onUpdateTask(res.data.data);
      showSuccessResultBar('Task updated.');
    } catch (err: any) {
      Sentry.captureException(err);
      showErrorResultBar('Unexpected error while updating the task.');
    } finally {
      onClose();
    }
  };

  return (
    <ResponsiveDialog
      disableBackdropClick={true}
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
    >
      <StyledDialogTitle
        onClose={onClose}
      >
        Edit Task
      </StyledDialogTitle>
      <Formik
        enableReinitialize
        initialValues={{
          description: task.description ?? '',
          name: task.name ?? '',
        }}
        validationSchema={FormSchema}
        onSubmit={async (values, { setSubmitting }) => {
          await handleSave(values);
          setSubmitting(false);
        }}
        onReset={_ => {
          onClose();
        }}
      >
        {formikProps => <>
          <DialogContent>
            <Typography variant="body1">
              Editing this task's name or description will only affect the task for the current organization.
            </Typography>
            <FormikTextField
              autoFocus
              field="name"
              formikProps={formikProps}
              label="Name"
              required
            />
            <FormikEditorTextArea
              field="description"
              formikProps={formikProps}
              helperTextStr="Description, instructions, etc. (Supports Markdown.)"
              label="Description"
            />
          </DialogContent>
          <DialogActions>
            {isModified && !!taskMasterVersion && (
              <Button
                sx={{ mr: 'auto' }}
                disabled={formikProps.isSubmitting || (isReverting && isMatchingTaskMaster(formikProps.values))}
                onClick={() => {
                  formikProps.setFieldValue('name', taskMasterVersion.name);
                  formikProps.setFieldValue('description', taskMasterVersion.description ?? '');
                  setIsReverting(true);
                }}
                color="primary"
              >
                Revert to Task Master
              </Button>
            )}
            <Button
              disabled={formikProps.isSubmitting}
              onClick={formikProps.handleReset}
              color="primary"
            >
              Cancel
            </Button>
            <SaveButton
              disabled={formikProps.isSubmitting || Object.values(formikProps.errors).filter(v => !!v).length > 0}
              onClick={formikProps.handleSubmit}
            />
          </DialogActions>
        </>}
      </Formik>
    </ResponsiveDialog>
  );
}
