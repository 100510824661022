import { Dialog, useMediaQuery, useTheme } from '@mui/material';
import { DialogProps } from '@mui/material/Dialog';
import React from 'react';

export type ResponsiveDialogProps = DialogProps & {
  disableBackdropClick?: boolean;
}

export default function ResponsiveDialog({ children, disableBackdropClick, ...dialogProps }: ResponsiveDialogProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

  function handleClose(event: {}, reason: 'backdropClick' | 'escapeKeyDown') {
    if ((!disableBackdropClick || reason !== 'backdropClick') && dialogProps.onClose) {
      dialogProps.onClose(event, reason);
    }
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      {...dialogProps}
      onClose={handleClose}
    >
      {children}
    </Dialog>
  );
}
