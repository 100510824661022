import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

const useStyles = makeStyles({
  root: {
    alignItems: 'center',
    backgroundColor: '#fafafa',
    borderColor: '#ccc',
    borderRadius: 2,
    borderStyle: 'dashed',
    borderWidth: 2,
    color: '#415468',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    fontSize: '1rem',
    outline: 'none',
    padding: '20px',
    transition: 'border .24s ease-in-out',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  active: {
    borderColor: '#2196f3',
  },
  accept: {
    borderColor: '#00e676',
  },
  reject: {
    borderColor: '#ff1744',
  },
});

export interface StyledDropzoneProps {
  disabled?: boolean;
  onUpdateFile: (file: File) => void;
  placeholder?: string;
}

export default function StyledDropzone({ disabled = false, onUpdateFile, placeholder }: StyledDropzoneProps) {
  const classes = useStyles();
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    disabled,
  });

  useEffect(() => {
    const file = acceptedFiles.pop();

    if (file) {
      onUpdateFile(file);
    }
  }, [ acceptedFiles, onUpdateFile ]);

  return (
    <div
      className={classNames(
        classes.root,
        isDragAccept && classes.accept,
        isDragActive && classes.active,
        isDragReject && classes.reject,
      )}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <p>{placeholder || 'Drop file here, or click to select file'}</p>
    </div>
  );
}
