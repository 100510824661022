import Page from '../components/Page';
import BuildingBlocksImage from '../components/BuildingBlocksImage';
import { Typography } from '@mui/material';
import React from 'react';
import { BodyText } from '../components/BodyText';
import useAuth from '../services/auth/useAuth';

function The404Page() {
  const { isAuthenticated } = useAuth();

  return (
    <Page center>
      <BuildingBlocksImage />
      <Typography variant="h2">
        Page not found
      </Typography>
      <BodyText align="center">
        Oops!! The page you requested could not be located.&nbsp;

        {isAuthenticated
          ? 'Please select a link from the navigation bar to proceed.'
          : 'Please login to proceed.'
        }
      </BodyText>
    </Page>
  );
}

The404Page.requiredAuthZ = {
  tier: 0,
};
The404Page.routePath = '/error/404';
The404Page.title = 'Not Found';

export default The404Page;
