import { Tab, Tabs } from '@mui/material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import * as Sentry from '@sentry/react';
import React, { useEffect, useState } from 'react';
import { ITagDto } from '../../../backend/src/tag/interfaces';
import { showErrorResultBar } from '../components/ResultSnackbar';
import TagTable from '../components/TagTable';
import TaskMasterTable from '../components/TaskMasterTable';
import API from '../services/ApiService';

function TheTaskMasterPage() {
  const [ tags, setTags ] = useState<ITagDto[]>([]);
  const [ selectedTabIdx, setSelectedTabIdx ] = useState(0);
  const [ updatedTag, setUpdatedTag ] = useState<ITagDto | undefined>();

  useEffect(() => {
    API.get('tag')
      .then(res => setTags(res.data?.data ?? []))
      .catch((err) => {
        showErrorResultBar('Unexpected error loading tags');
        Sentry.captureException(err);
      });
  }, []);

  function onUpdateTag(modifiedTag: ITagDto) {
    setUpdatedTag(modifiedTag);
  }

  function onUpdateTags(updatedTags: ITagDto[]) {
    setTags(updatedTags);
  }

  return <>
    <Tabs
      value={selectedTabIdx}
      onChange={(e, tabIdx) => setSelectedTabIdx(tabIdx)}
    >
      <Tab label="Task Master" />
      <Tab label="Tags" />
    </Tabs>
    {selectedTabIdx === 0 &&
      <TaskMasterTable
        tags={tags}
        updatedTag={updatedTag}
      />
    }
    {selectedTabIdx === 1 &&
      <TagTable
        tags={tags}
        onUpdateTag={onUpdateTag}
        onUpdateTags={onUpdateTags}
      />
    }
  </>;
}

TheTaskMasterPage.requiredAuthZ = {
  tier: 1,
  permission: 'admin:task_master',
};
TheTaskMasterPage.routePath = '/admin/taskMaster';
TheTaskMasterPage.title = 'Task Master';
TheTaskMasterPage.icon = <FormatListBulletedIcon />;

export default TheTaskMasterPage;
