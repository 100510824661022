import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export interface IGtagConfig {
  page_location: string;
  page_path: string;
}

declare global {
  interface Window {
    gtag?: (
      key: string,
      trackingId: string,
      config: IGtagConfig,
    ) => void;
  }
}

export const trackLocation = (config: IGtagConfig) => {
  const trackingId = process.env.REACT_APP_GA_TRACKING_ID;

  if (!window.gtag || !trackingId) {
    return;
  }

  window.gtag('config', trackingId, config);
};

export default function useTracking() {
  const { pathname } = useLocation();

  useEffect(() => {
    trackLocation({
      page_location: window.location.href,
      page_path: pathname,
    }); // unregisters the call listener
  }, [ pathname ]);
}
