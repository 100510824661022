import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/GetApp';
import React from 'react';

function DownloadButton(props: IconButtonProps) {
  return (
    <IconButton aria-label="Download" {...props} size="large">
      <DownloadIcon />
    </IconButton>
  );
}

export default DownloadButton;
