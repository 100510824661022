import { styled } from '@mui/material/styles';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Link, To } from 'react-router-dom';

const SmallOpenInIcon = styled(OpenInNewIcon)({
  verticalAlign: 'middle',
  height: '1rem',
});

export type SmallOpenIconWithLinkProps = {
  to: To;
}

export default function SmallOpenInIconWithLink(props: SmallOpenIconWithLinkProps) {
  return (
    <Link to={props.to}><SmallOpenInIcon /></Link>
  );
};
