import * as Sentry from '@sentry/react';
import { history } from 'prosemirror-history';
import { EditorState } from 'prosemirror-state';
import { getKeyCommands } from './keyCommands';
import { getMarkdownParser } from './markdown/parser';
import { schema } from './schema';

export const safeParse = (docContent: string) => {
  const parser = getMarkdownParser(schema);

  try {
    return parser.parse(docContent);
  } catch (err: any) {
    Sentry.captureException(err);

    return parser.parse('');
  }
};

export const createState = (docContent: string) => EditorState.create({
  doc: safeParse(docContent),
  schema,
  plugins: [
    history(),
    getKeyCommands(schema),
  ],
});
