import { FormControl, FormControlLabel, FormHelperText, Switch } from '@mui/material';
import { FormControlProps } from '@mui/material/FormControl';
import { FormikProps, FormikValues } from 'formik';
import React from 'react';

interface FormikSwitchFieldProps<Values extends FormikValues> extends FormControlProps {
  field: keyof Values;
  formikProps: FormikProps<Values>;
  helperTextStr?: string;
  label: string;
}

export default function FormikSwitchField<Values extends FormikValues>(props: FormikSwitchFieldProps<Values>) {
  const { field, formikProps, helperTextStr, label, variant = 'filled', ...otherFormControlProps } = props;
  const { values, handleChange } = formikProps;
  const fieldStr = field.toString();

  return (
    <FormControl
      variant={variant as any}
      {...otherFormControlProps}
    >
      <FormControlLabel
        control={
          <Switch
            checked={values[field]}
            id={fieldStr}
            name={fieldStr}
            onChange={handleChange}
          />}
        label={label}
      />
      {helperTextStr ? <FormHelperText>{helperTextStr}</FormHelperText> : null}
    </FormControl>
  );
}
