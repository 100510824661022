import { DialogContent, DialogProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { IRiskDto } from '../../../../backend/src/risk/interfaces';
import RiskDetails from '../RiskDetails';
import ResponsiveDialog from './ResponsiveDialog';

const useStyles = makeStyles({
  dialogContent: {
    'padding': '0',
    'overflowY': 'hidden',
    '&:first-child': {
      paddingTop: '0',
    },
  },
});

export interface RiskDetailsDialogProps extends DialogProps {
  onArchive: () => void;
  onClose: () => void;
  onUpdate: (risk: IRiskDto) => void;
  riskData: IRiskDto;
}

export default function RiskDetailsDialog(props: RiskDetailsDialogProps) {
  const { onArchive, onClose, onUpdate, open, riskData } = props;
  const classes = useStyles();

  return (
    <ResponsiveDialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
    >
      <DialogContent className={classes.dialogContent}>
        <RiskDetails
          disableTaskClickThrough
          isDialog
          onArchive={onArchive}
          onCloseDialog={onClose}
          onUpdate={onUpdate}
          riskData={riskData}
        />
      </DialogContent>
    </ResponsiveDialog>
  );
}
