import React from 'react';
import { TextField, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { IQuestionComponentProps } from './interfaces';
import { questionComponentStyles } from './question-component.styles';

function TextInputQuestion({ classes, formikProps, question }: IQuestionComponentProps) {
  const { values, handleBlur, handleChange } = formikProps;

  return (
    <>
      { !!question.text &&
        <Typography className={classes.questionTextContainer}>
          {question.text}
        </Typography>
      }
      <div className={classes.inputContainer}>
        <TextField
          id={question.id}
          name={question.id}
          value={values[question.id]}
          label={question.label || ''}
          placeholder={question.placeholder || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          fullWidth={true}
          margin="dense"
          InputLabelProps={{
            shrink: (!!question.placeholder) || (!!values[question.id]),
          }}
        />
      </div>
    </>
  );
};

export default withStyles(questionComponentStyles, { withTheme: true })(TextInputQuestion);
