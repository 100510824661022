import * as Sentry from '@sentry/react';
import { showErrorResultBar } from './ResultSnackbar';
import * as stripeJs from '@stripe/stripe-js';
import Stripe from 'stripe';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useState } from 'react';
import { Button, DialogActions, DialogContent, LinearProgress } from '@mui/material';
import { SaveButton } from './buttons';
import StyledDialogTitle from './StyledDialogTitle';
import { ResponsiveDialog } from './dialogs';

export interface StripeCardCaptureProps {
  open: boolean;
  onClose: () => void;
  afterSave: (paymentMethod: Stripe.PaymentMethod) => void;
}

export function StripeCardCapture({ afterSave, open, onClose }: StripeCardCaptureProps) {
  const [ isProcessing, setIsProcessing ] = useState(false);
  const [ isSaveDisabled, setIsSaveDisabled ] = useState(true);
  const stripeElements = useElements();
  const stripe = useStripe();

  async function handleSave(event: Event) {
    event.preventDefault();
    setIsProcessing(true);
    const cardElement = stripeElements!.getElement(CardElement)!;

    const { paymentMethod, error } = await stripe!.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error || paymentMethod === undefined || paymentMethod.card === undefined) {
      showErrorResultBar('Unable to tokenize card info');
      Sentry.captureException(error);
    } else {
      afterSave(paymentMethod as Stripe.PaymentMethod);
      onClose();
    }

    setIsProcessing(false);
  }

  function handleCardElementChange(event: stripeJs.StripeCardElementChangeEvent) {
    setIsSaveDisabled(!event.complete);
  }

  return (
    <ResponsiveDialog
      open={open}
      fullWidth={false}
      disableBackdropClick
    >
      <StyledDialogTitle
        onClose={onClose}
      >
        Enter payment method
        {isProcessing &&
          <LinearProgress
            variant="indeterminate"
          />
        }
      </StyledDialogTitle>
      <DialogContent style={{ minWidth: '400px' }}>
        <CardElement
          onChange={handleCardElementChange}
          onReady={element => element.focus()}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          size="small"
          disabled={isProcessing}
          onClick={onClose}
        >
          Cancel
        </Button>
        <SaveButton onClick={handleSave} disabled={isSaveDisabled || isProcessing} />
      </DialogActions>
    </ResponsiveDialog>
  );
}
