import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, DialogProps } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import { makeStyles } from '@mui/styles';
import * as Sentry from '@sentry/react';
import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { IVendorDto, IVendorQuestionnaireSendToDto } from '../../../../backend/src/vendor/interfaces';
import API from '../../services/ApiService';
import { FormikTextField } from '../forms';
import { showErrorResultBar, showSuccessResultBar } from '../ResultSnackbar';
import ResponsiveDialog from './ResponsiveDialog';

const useStyles = makeStyles({
  leftIcon: {
    marginRight: '4px',
  },
});

const VendorQuestionnaireEmailSchema = Yup.object().shape({
  ccTo: Yup
    .array()
    .transform(function (value, originalValue) {
      if (this.isType(value) && value !== null) {
        return value;
      }

      return originalValue ? originalValue.split(/[\s,]+/) : [];
    })
    .of(Yup.string().email(({ value }) => ` ${value} is not a valid email`).required()),
  sendTo: Yup
    .string()
    .label('Email')
    .email('Must be a valid email address')
    .required('Required'),
});

interface IFormValues {
  sendTo: string;
  ccTo: string;
}

const getInitialFormValues = (vendor: IVendorDto): IFormValues => ({
  ccTo: '',
  sendTo: vendor.contactEmail || '',
});

const createUploadData = (formValues: IFormValues): IVendorQuestionnaireSendToDto => {
  return {
    ccTo: formValues.ccTo.split(/[\s,]+/).filter(email => !!email) || [],
    sendTo: formValues.sendTo,
  };
};

export interface VendorSendQuestionnaireDialogProps extends DialogProps {
  onClose: () => void;
  onUpdateVendor: (newVendorData: IVendorDto) => void;
  vendorData: IVendorDto;
}

export default function VendorSendQuestionnaireDialog(props: VendorSendQuestionnaireDialogProps) {
  const classes = useStyles();
  const {
    onClose,
    onUpdateVendor,
    open,
    vendorData,
  } = props;

  const handleSendQuestionnaire = async (uploadData: IVendorQuestionnaireSendToDto) => {
    try {
      const res = await API.post(`vendor/${vendorData.id}/send-questionnaire`, uploadData);
      onUpdateVendor(res.data.data);
      showSuccessResultBar('Questionnaire sent successfully');
    } catch (err: any) {
      const errorMsg = err?.response?.data?.error ?? 'Unexpected error while sending questionnaire';
      showErrorResultBar(errorMsg);
      Sentry.captureException(err);
    }
  };

  return (
    <ResponsiveDialog
      open={open}
      onClose={onClose}
      disableBackdropClick
    >
      <Formik
        enableReinitialize
        initialValues={getInitialFormValues(vendorData)}
        validationSchema={VendorQuestionnaireEmailSchema}
        onReset={onClose}
        onSubmit={async (values, { setSubmitting }) => {
          await handleSendQuestionnaire(createUploadData(values));
          setSubmitting(false);
          onClose();
        }}
      >
        {formikProps => (
          <>
            <DialogTitle>Send questionnaire</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please {vendorData.contactEmail ? 'confirm' : 'enter'} the email
                address to which the questionnaire should be sent.
                Include in the Cc the relevant contact(s) from your organization.
              </DialogContentText>
                <FormikTextField
                  autoFocus
                  field="sendTo"
                  formikProps={formikProps}
                  label="To"
                  placeholder="vendor.contact@example.com"
                  required
                />
                <FormikTextField
                  field="ccTo"
                  formikProps={formikProps}
                  helperTextStr="Please enter a comma separated list of emails or one per line."
                  label="Cc"
                  multiline
                />
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                size="small"
                disabled={formikProps.isSubmitting}
                onClick={formikProps.handleReset}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                size="small"
                variant="contained"
                disabled={formikProps.isSubmitting || Object.keys(formikProps.errors).length > 0}
                onClick={(e: any) => formikProps.handleSubmit(e)}
              >
                <EmailIcon className={classes.leftIcon} />
                Send Questionnaire
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </ResponsiveDialog>
  );
}
