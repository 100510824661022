import {
  Accordion,
  AccordionDetails,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import AccordionIconSummary from '../components/AccordionIconSummary';
import useAuth from '../services/auth/useAuth';
import { IRequiredAuthZ } from './interfaces';
import ThePolicyAckReportPage from './ThePolicyAckReportPage';
import TheTasksSummaryCIS20Page from './TheTasksSummaryCIS20Page';
import TheTasksSummaryCISV8Page from './TheTasksSummaryCISV8Page';
import TheTasksSummaryCMMCPage from './TheTasksSummaryCMMCPage';
import TheTasksSummaryISO27001Page from './TheTasksSummaryISO27001Page';
import TheTasksSummaryNIST800171Page from './TheTasksSummaryNIST800171Page';
import TheTasksSummaryNistCsfPage from './TheTasksSummaryNistCsfPage';
import TheTasksSummarySOCPage from './TheTasksSummarySOCPage';
import TheTrainingPage from './TheTrainingPage';
import TheTrainingReportPage from './TheTrainingReportPage';
import TheUserAuditPage from './TheUserAuditPage';

interface IReportLink {
  path: string;
  subtitle: string;
  title: string;
};

interface IReportLinksBlock {
  links: IReportLink[];
  subtitle?: string;
  title: string;
  requiredAuthZ?: IRequiredAuthZ;
};

const rawReportLinks = {
  policiesDetailedReport: {
    path: ThePolicyAckReportPage.routePath,
    subtitle: 'A datatable containing the per-user, per-policy acknowledgement status.',
    title: ThePolicyAckReportPage.title,
  },
  tasksCIS20: {
    path: TheTasksSummaryCIS20Page.routePath,
    subtitle: `
      The CIS Controls V8 was released in May 2021.
      Many organizations continue to utilize the previous list of controls (v7.1), known as the CIS 20.
    `,
    title: TheTasksSummaryCIS20Page.title,
  },
  tasksCISV8: {
    path: TheTasksSummaryCISV8Page.routePath,
    subtitle: `
      The Center for Internet Security (CIS) publishes a list of the top 18
      Critical Security Controls ("CIS Controls V8"). These are a prioritized set of guidelines
      organizations should follow to help protect
      themselves from the most common attack vectors.
    `,
    title: TheTasksSummaryCISV8Page.title,
  },
  tasksCMMC: {
    path: TheTasksSummaryCMMCPage.routePath,
    subtitle: `
      The U.S. Department of Defense's Cybersecurity Maturity Model Certification (CMMC)
      combines various cybersecurity standards and best practices,
      serving as a cybersecurity verification mechanism for DoD's industry partners.
    `,
    title: TheTasksSummaryCMMCPage.title,
  },
  tasksISO27001: {
    path: TheTasksSummaryISO27001Page.routePath,
    subtitle: `
      The International Organization for Standardization (ISO) publishes the
      ISO/IEC 27001 standard,
      which specifies requirements for an information security management system.
    `,
    title: TheTasksSummaryISO27001Page.title,
  },
  tasksNIST800171: {
    path: TheTasksSummaryNIST800171Page.routePath,
    subtitle: `
      NIST Special Publication 800-171
      describes the security requirements for protecting the confidentiality of
      Controlled Unclassified Information (CUI) in non-federal systems,
      intended for use in contracts between federal agencies and non-federal organizations.
    `,
    title: TheTasksSummaryNIST800171Page.title,
  },
  tasksNistCsf: {
    path: TheTasksSummaryNistCsfPage.routePath,
    subtitle: `
      The NIST Cybersecurity Framework (CSF)
      consists of standards, guidelines and best practices to help manage cybersecurity risk.
    `,
    title: TheTasksSummaryNistCsfPage.title,
  },
  tasksSOC: {
    path: TheTasksSummarySOCPage.routePath,
    subtitle: `
      The American Institute of Certified Public Accountants (AICPA)
      publishes a table of Trust Services Criteria used to evaluate controls in a
      SOC 2 assessment.
    `,
    title: TheTasksSummarySOCPage.title,
  },
  trainingCoverageChartReport: {
    path: TheTrainingPage.routePathCoverage,
    subtitle: 'An overview chart for your organization\'s training coverage.',
    title: 'Training Coverage Overview Chart',
  },
  trainingDetailedReport: {
    path: TheTrainingReportPage.routePath,
    subtitle: 'A datatable containing the per-user, per-video training completion status.',
    title: 'Training Coverage Detailed Report',
  },
  userAuditHistory: {
    path: TheUserAuditPage.routePath + '?tab=' + TheUserAuditPage.tabs.history,
    subtitle: 'A datatable containing user audit history.',
    title: 'User Audit History Report',
  },
};

// Add the typing to reportLinks, restricting to the known keys:
const REPORT_LINKS: {
  [key in keyof typeof rawReportLinks]: IReportLink
} = rawReportLinks;

const reportLinksBlocks: IReportLinksBlock[] = [
  {
    title: 'Training Reports',
    subtitle: `
      Organization employees should follow regular security-related training.
      If your organization is using the built-in Training module for employee training
      then the following reports provide tracking information for training completion.
    `,
    links: [
      REPORT_LINKS.trainingCoverageChartReport,
      REPORT_LINKS.trainingDetailedReport,
    ],
  },
  {
    title: 'Policy Reports',
    subtitle: `
      Employees should read and acknowledge your organization's security policies.
      The following report tracks the policy acknowledgement status for employees in the system.
    `,
    links: [
      REPORT_LINKS.policiesDetailedReport,
    ],
  },
  {
    title: 'Task Summary Reports',
    subtitle: `
      The following reports track your organization's task completion status
      for various regulatory frameworks.
    `,
    links: [
      REPORT_LINKS.tasksCISV8,
      REPORT_LINKS.tasksCIS20,
      REPORT_LINKS.tasksCMMC,
      REPORT_LINKS.tasksISO27001,
      REPORT_LINKS.tasksNistCsf,
      REPORT_LINKS.tasksNIST800171,
      REPORT_LINKS.tasksSOC,
    ],
  },
  {
    title: 'User Audit History Reports',
    subtitle: 'The following is a record of user audits',
    links: [
      REPORT_LINKS.userAuditHistory,
    ],
    requiredAuthZ: TheUserAuditPage.requiredAuthZ,
  },
];

function ReportLinksAccordion(props: IReportLinksBlock) {
  const { links, subtitle, title } = props;
  const [ isExpanded, setIsExpanded ] = useState(true);

  return (
    <Accordion
      elevation={2}
      expanded={isExpanded}
      onChange={() => setIsExpanded(!isExpanded)}
    >
      <AccordionIconSummary title={title}>
        {subtitle}
      </AccordionIconSummary>
      <AccordionDetails>
        <List>
          {links.map(link => (
            <ListItem key={link.path} disablePadding>
              <ListItemButton
                component={RouterLink}
                to={link.path}
              >
                <ListItemText
                  primary={link.title}
                  secondary={link.subtitle}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
}

function TheReportsPage() {
  const { isGranted } = useAuth();

  return <>
    {reportLinksBlocks.map(linksBlock => (
      isGranted(linksBlock.requiredAuthZ) ?
      <ReportLinksAccordion
        key={linksBlock.title}
        title={linksBlock.title}
        subtitle={linksBlock.subtitle}
        links={linksBlock.links}
      /> : null
    ))}
  </>;
}

TheReportsPage.requiredAuthZ = {
  tier: 1,
  permission: 'dashboard',
};
TheReportsPage.routePath = '/reports';
TheReportsPage.title = 'Reports';

export default TheReportsPage;
