import { useEffect } from 'react';

export type AsyncEffect = (isActive: () => boolean) => Promise<any>;
export type EffectDestroyHandler<T> = (result: T) => void;
export type EffectDependencies = any[];

export default function useEffectAsync<T>(effect: AsyncEffect, destroy: EffectDestroyHandler<T> | null, inputs: EffectDependencies): void {
  useEffect(function () {
    let result: T;
    let mounted = true;

    const maybePromise = effect(function () {
      return mounted;
    });

    Promise.resolve(maybePromise).then(function (value) {
      result = value;
    });

    if (destroy) {
      return function () {
        mounted = false;
        destroy(result);
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, inputs);
}
