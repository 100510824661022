import { Button, DialogActions, DialogContent, DialogProps, MenuItem } from '@mui/material';
import * as Sentry from '@sentry/react';
import { Formik, useFormikContext } from 'formik';
import { sortBy } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { IReportingPeriodCreateDto, IReportingPeriodDto } from '../../../../backend/src/reporting-period/interfaces';
import API from '../../services/ApiService';
import { formatDate } from '../../helpers';
import { SaveButton } from '../buttons';
import { FormikDatePicker, FormikTextField } from '../forms';
import { showErrorResultBar } from '../ResultSnackbar';
import StyledDialogTitle from '../StyledDialogTitle';
import ResponsiveDialog from './ResponsiveDialog';

const DURATIONS_IN_MONTHS =  [ 1, 2, 3, 4, 5, 6, 9, 12, 18, 24 ];

interface IFormValues {
  name: string,
  startDate: Date,
  duration: number;
  endDate: Date | null,
}

export const getDefaultLastPeriodName = (penUltimatePeriodName: string) => `Beyond ${penUltimatePeriodName}`;

const getInitialFormValues = (finalPeriod: IReportingPeriodDto | null): IFormValues => {
  const name = '';
  const startDate = finalPeriod === null ? moment().startOf('year').toDate() : moment(finalPeriod.startDate).toDate();
  const duration = 12;
  const endDate = moment(startDate).add(duration, 'months').subtract(1 , 'day').toDate();

  return { duration, endDate, name, startDate };
};

const AutoUpdateEndDate = () => {
  // Uses the Formik context to auto-update the endDate if
  // either the startDate or the duration change.
  const { setFieldValue, values } = useFormikContext<IFormValues>();

  useEffect(() => {
    const startDate = values.startDate;
    if (moment(startDate).isValid()) {
      setFieldValue('endDate', moment(startDate).add(values.duration, 'months').subtract(1, 'day').toDate());
    }

  }, [ values.duration, values.startDate, setFieldValue ]);

  return null;
};

export interface ReportingPeriodCreateDialogProps extends DialogProps {
  reportingPeriods: IReportingPeriodDto[];
  onClose: () => void;
  onSave: (newReportingPeriods: IReportingPeriodDto[]) => void;
}

export default function ReportingPeriodCreateDialog(props: ReportingPeriodCreateDialogProps) {
  const { onClose, onSave, open, reportingPeriods } = props;
  const [ finalPeriod, setFinalPeriod ] = useState<IReportingPeriodDto | null>(null);
  const [ minDate, setMinDate ] = useState<Date | undefined>();

  const FormValidationSchema = useMemo(() => Yup.object({
    duration: Yup
      .number()
      .required('Required')
      .label('Reporting period duration'),
    endDate: Yup
      .date()
      .nullable()
      .typeError('Invalid date'),
    name: Yup
      .string()
      .required('Required')
      .label('Reporting period name'),
    startDate: Yup
      .date()
      .min(minDate ?? new Date(0), `Should be after the previous period's start date (at least ${formatDate(minDate)}).`)
      .required('Required')
      .typeError('Invalid date'),
  }), [ minDate ]);

  useEffect(() => {
    let theFinalPeriod: IReportingPeriodDto | null = null;
    let theMinDate: Date | undefined;
    const sortedPeriods = sortBy(reportingPeriods, [ 'startDate' ]);

    if (sortedPeriods.length > 0) {
      theFinalPeriod = sortedPeriods[sortedPeriods.length - 1];
    }

    // Disallow pushing the start date of this period on or before the
    // start date of the previous period (if one exists).
    if (sortedPeriods.length > 1) {
      const penultimatePeriod = sortedPeriods[sortedPeriods.length - 2];
      theMinDate = moment(penultimatePeriod.startDate).add(1, 'day').toDate();
    }

    setFinalPeriod(theFinalPeriod);
    setMinDate(theMinDate);
  }, [ reportingPeriods ]);

  const handleSave = async (formValues: IFormValues) => {
    const postUrl = finalPeriod === null ? 'reportingPeriod' : `reportingPeriod/${finalPeriod.id}/next`;

    try {
      const createDto: IReportingPeriodCreateDto = {
        startDate: moment(formValues.startDate).toISOString(),
        endDate: moment(formValues.endDate).toISOString(),
        name: formValues.name,
        nextPeriodName: getDefaultLastPeriodName(formValues.name),
      };
      const responseReportingPeriods = (await API.post(postUrl, createDto)).data?.data ?? [];
      onSave(responseReportingPeriods);
      onClose();
    } catch (err: any) {
      const errorMsg = err.response?.data?.error ?? 'Unexpected error saving reporting period data';
      showErrorResultBar(errorMsg);
      Sentry.captureException(err);
    }
  };

  return (
    <ResponsiveDialog
      disableBackdropClick
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
    >
      <StyledDialogTitle onClose={onClose}>Add reporting period</StyledDialogTitle>
      <Formik
        initialValues={getInitialFormValues(finalPeriod)}
        validateOnBlur={false}
        validationSchema={FormValidationSchema}
        onReset={onClose}
        onSubmit={async (values, { setSubmitting }) => {
          await handleSave(values);
          setSubmitting(false);
        }}
      >
        {formikProps => (
          <form>
            <DialogContent>
              <FormikTextField
                field="name"
                formikProps={formikProps}
                helperTextStr="Name for this reporting period."
                label="Name"
                placeholder={`FY ${moment(formikProps.values.startDate).isValid() ? moment(formikProps.values.startDate).year() : moment().year()}`}
                required
              />
              <FormikDatePicker
                field="startDate"
                formikProps={formikProps}
                helperTextStr={
                  finalPeriod !== null &&
                  moment(formikProps.values.startDate).isValid() &&
                  !moment(formikProps.values.startDate).isSame(finalPeriod.startDate, 'day') ?
                  `Note that changing this date from ${formatDate(finalPeriod.startDate)} will alter the end date of the previous period.` : ''}
                label="Start date"
                minDate={moment(minDate)}
                required
              />
              <FormikTextField
                field="duration"
                formikProps={formikProps}
                helperTextStr={
                  moment(formikProps.values.startDate).isValid() && moment(formikProps.values.endDate).isValid() ?
                  `Adds a period from ${formatDate(formikProps.values.startDate)} to ${formatDate(formikProps.values.endDate)}.` : undefined
                }
                label="Duration in months"
                required
                select
              >
                {DURATIONS_IN_MONTHS.map((months) => (
                  <MenuItem
                    key={months}
                    value={months}
                  >
                    {months}
                  </MenuItem>
                ))}
              </FormikTextField>
              <AutoUpdateEndDate />
            </DialogContent>
            <DialogActions disableSpacing>
              <Button
                disabled={formikProps.isSubmitting}
                onClick={formikProps.handleReset}
                color="primary"
              >
                Cancel
              </Button>
              <SaveButton
                disabled={
                  formikProps.isSubmitting ||
                  Object.values(formikProps.errors).filter(v => !!v).length > 0
                }
                type="submit"
                onClick={formikProps.handleSubmit}
              />
            </DialogActions>
          </form>
        )}
      </Formik>
    </ResponsiveDialog>
  );
}
