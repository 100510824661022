import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  IconButton,
  StyledComponentProps,
  Theme,
} from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import * as Sentry from '@sentry/react';
import { Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { ITagDto, ITagUpdateDto } from '../../../backend/src/tag/interfaces';
import API from '../services/ApiService';
import SaveButton from './buttons/SaveButton';
import DataTableTitleWithButton from './DataTableTitleWithButton';
import { FormikTextField } from './forms';
import { showErrorResultBar, showSuccessResultBar } from './ResultSnackbar';
import SpioDataTable, { SpioDataTableColumn } from './SpioDataTable';

const roundTagRegExp = /^round [1-9]\d?$/;

const styles = (theme: Theme) => createStyles({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const UpdateTagSchema = Yup.object().shape({
  name: Yup
    .string()
    .required('Required'),
  title: Yup
    .string(),
});

interface TagTableProps extends StyledComponentProps {
  tags: ITagDto[];
  onUpdateTag: (updatedTag: ITagDto) => void;
  onUpdateTags: (updatedTags: ITagDto[]) => void;
}

function TagTable({ classes, onUpdateTag, onUpdateTags, tags }: TagTableProps) {
  const [ selectedTag, setSelectedTag ] = useState<ITagDto>({} as ITagDto);
  const [ detailsOpen, setDetailsOpen ] = useState<boolean>(false);
  const [ tagToDelete, setTagToDelete ] = useState<ITagDto | undefined>(); // If defined, opens the 'Delete tag?' dialog.
  const [ selectedIdx, setSelectedIdx ] = useState<number>(0);

  function openAddDialog() {
    setSelectedIdx(-1);
    setSelectedTag({} as ITagDto);
    setDetailsOpen(true);
  }

  function openDetails(idx: number) {
    setSelectedIdx(idx);
    setSelectedTag(tags[idx]);
    setDetailsOpen(true);
  }

  function closeDetails() {
    setDetailsOpen(false);
  }

  async function saveTag(formValues: ITagUpdateDto) {
    const tagUpdate: ITagUpdateDto = {
      name: formValues.name.toLowerCase(),
      title: formValues.title || null,
    };
    let updatedTags: ITagDto[];

    try {
      if (selectedIdx === -1) { // New tag
        const res = await API.post('tag', tagUpdate);
        updatedTags = [ res.data?.data ].concat(tags);
      } else { // Existing tag
        const res = await API.patch(`tag/${tags[selectedIdx].id}`, tagUpdate);
        const updatedTag = res.data?.data;
        updatedTags = tags.slice();
        updatedTags[selectedIdx] = updatedTag;
        onUpdateTag(updatedTag);
      }

      onUpdateTags(updatedTags);
      setSelectedIdx(0);
      setDetailsOpen(false);
      showSuccessResultBar(`Tag ${selectedIdx === -1 ? 'added' : 'updated'} successfully`);
    } catch (err: any) {
      const errorMsg = err.response?.data?.message ?? `Unexpected error while updating tag: ${err.response?.data?.error}`;
      showErrorResultBar(errorMsg);
      Sentry.captureException(err);
    }
  }

  const deleteTag = (tag: ITagDto) => async (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    const updatedTags = tags.slice();

    try {
      await API.delete(`tag/${tag.id}`);
      updatedTags.splice(updatedTags.indexOf(tag), 1);
      onUpdateTag(tag);
      onUpdateTags(updatedTags);
      showSuccessResultBar('Tag deleted');
    } catch (err: any) {
      const errorMsg = err.response?.data?.message ?? `Unexpected error while updating tag: ${err.response?.data?.error}`;
      showErrorResultBar(errorMsg);
      Sentry.captureException(err);
    }

    closeDeleteDialog();
  };

  const confirmDelete = (tag: ITagDto) => async (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    setTagToDelete(tag);
  };

  const closeDeleteDialog = () => {
    setTagToDelete(undefined);
  };

  const getTableColumns = (): SpioDataTableColumn[] => [
    {
      name: 'name',
      label: 'Name',
      options: {
        customFilterListOptions: { render: v => `Tag name: ${v}` },
      },
    },
    {
      name: 'title',
      label: 'Title',
      options: {
        customFilterListOptions: { render: v => `Tag title: ${v}` },
      },
    },
    {
      name: 'permanent',
      label: 'Permanent',
      options: {
        customBodyRenderLite: dataIndex => tags[dataIndex]?.permanent ? 'True' : null,
        display: 'false',
        filter: false,
        searchable: false,
        sortDescFirst: true,
      },
    },
    {
      // The underlying column value should be a string or a number; here we (arbitrarily) use the id.
      name: 'id',
      label: 'Actions',
      options: {
        download: false,
        filter: false,
        searchable: false,
        sort: false,
        customBodyRenderLite: dataIndex => (
          <IconButton
            key="delete"
            disabled={tags[dataIndex]?.permanent}
            onClick={confirmDelete(tags[dataIndex])}
          >
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        ),
      },
    },
  ];

  return <>
    <SpioDataTable
      title={<DataTableTitleWithButton
        onButtonClick={openAddDialog}
        title="Tags"
      />}
      columns={getTableColumns()}
      data={tags}
      options={{
        filterType: 'textField',
        onRowClick: (_, rowMeta) => openDetails(rowMeta.dataIndex),
        print: false,
        selectableRows: 'none',
      }}
    />
    {/* Delete tag dialog */}
    <Dialog
      open={tagToDelete !== undefined}
      onClose={closeDeleteDialog}
    >
      <DialogTitle>{`Delete the tag '${tagToDelete?.name}'?`}</DialogTitle>
      <DialogActions>
        <Button onClick={closeDeleteDialog}>
          Cancel
        </Button>
        <Button onClick={deleteTag(tagToDelete!)}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
    {/* Add/update tag dialog */}
    <Dialog
      open={detailsOpen}
      onClose={closeDetails}
      fullWidth
    >
      <DialogTitle>
        {selectedIdx === -1 ? 'Add New' : 'Update'} Tag
        <IconButton
          aria-label="Close"
          className={classes!.closeButton}
          onClick={closeDetails}
          size="large">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Formik
        initialValues={{
          name: selectedTag.name ?? '',
          title: selectedTag.title ?? '',
        }}
        validationSchema={UpdateTagSchema}
        onReset={closeDetails}
        onSubmit={async (values, { setSubmitting }) => {
          await saveTag(values);
          setSubmitting(false);
        }}
      >
        { formikProps => (<>
          <DialogContent>
            <FormGroup>
              <FormikTextField
                autoFocus
                disabled={selectedTag.permanent}
                field="name"
                formikProps={formikProps}
                helperText={selectedTag.permanent ?
                  'This tag is permanent (its name is fixed and it may not be deleted)' :
                  'Unique tag name, case-insensitive (gets converted to lower case)'
                }
                label="Tag name"
                required
              />
              <FormikTextField
                field="title"
                formikProps={formikProps}
                helperText={selectedTag.permanent && roundTagRegExp.test(selectedTag.name) ?
                  `This title appears on the Dashboard "Rounds" widget for all organizations across the tenant.
                  Deleting this tag's title will remove this Round from the widget.` :
                  'User-friendly display name for this tag (optional)'
                }
                label="Tag title"
              />
            </FormGroup>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={formikProps.isSubmitting}
              onClick={formikProps.handleReset}
              color="primary"
            >
              Cancel
            </Button>
            <SaveButton
              disabled={formikProps.isSubmitting || Object.values(formikProps.errors).filter(v => !!v).length > 0}
              onClick={formikProps.handleSubmit}
            />
          </DialogActions>
        </>)}
      </Formik>
    </Dialog>
  </>;
}

export default withStyles(styles, { withTheme: true })(TagTable);
