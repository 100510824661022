import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import * as Sentry from '@sentry/react';
import { MUIDataTableTextLabels } from 'mui-datatables';
import React, { useCallback, useEffect, useState } from 'react';
import { IUserInviteDto } from '../../../backend/src/user/interfaces';
import API from '../services/ApiService';
import { formatDate } from '../helpers';
import { UserInviteDialog } from './dialogs';
import { showErrorResultBar, showSuccessResultBar } from './ResultSnackbar';
import SpioDataTable, { SpioDataTableColumn } from './SpioDataTable';
import StyledDataTableTitle from './StyledDataTableTitle';
import { roleDownloadMap } from './UserManagementUserDetails';

const getTableColumns = (tableData: ITableDatum[]): SpioDataTableColumn[] => {
  return [
    {
      name: 'email',
      label: 'Email',
      options: {
        filterOptions: { fullWidth: true }
      },
    },
    {
      name: 'createdAtStr',
      label: 'Sent',
      options: {
        filterOptions: { fullWidth: true }
      },
    },
    {
      name: 'roleStr',
      label: 'Role',
      options: {
        filterOptions: { fullWidth: true }
      },
    },
    // The underlying column value should be a string or a number; here we (arbitrarily) use the id.
    {
      name: 'id',
      label: 'Actions',
      options: {
        customBodyRenderLite: dataIndex => tableData[dataIndex]?.actions || '',
        download: false,
        filter: false,
        searchable: false,
        sort: false,
      },
  },
  ];
};

export interface UserInvitesTableProps {
  invites: IUserInviteDto[];
  isLoading: boolean;
  onAddInvites: (invites: IUserInviteDto[]) => void;
  onDeleteInvite: (inviteId: string) => void;
}

interface ITableDatum extends IUserInviteDto {
  actions: string | Array< React.ReactElement<any> >;
  createdAtStr: string;
  roleStr: string;
}

export default function UserInvitesTable(props: UserInvitesTableProps) {
  const { invites, isLoading, onAddInvites, onDeleteInvite } = props;
  const [ isDialogOpen, setIsDialogOpen ] = useState(false);
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const [ tableData, setTableData ] = useState<ITableDatum[]>([]);

  const handleDeleteInvite = useCallback((invite: IUserInviteDto) => async () => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);

    try {
      await API.delete(`user/invite/${invite.id}`);
      onDeleteInvite(invite.id);
      showSuccessResultBar(`Invitation deleted for ${invite.email}`);
    } catch (err: any) {
      showErrorResultBar(err.response?.data?.error ?? 'Unexpected error deleting invite');
      Sentry.captureException(err);
    } finally {
      setIsSubmitting(false);
    }
  }, [ isSubmitting, onDeleteInvite ]);

  useEffect(() => {
    setTableData(invites.map((invite) => Object({
      ...invite,
      createdAtStr: formatDate(invite.createdAt),
      roleStr: roleDownloadMap[invite.role].text || invite.role,
      actions: (
        <IconButton
          aria-label="delete invitation"
          onClick={handleDeleteInvite(invite)}
          size="large">
          <DeleteIcon fontSize="small" />
        </IconButton>
      ),
    })));
  }, [ handleDeleteInvite, invites ]);

  return (<>
    <SpioDataTable
      title={<StyledDataTableTitle
        buttonText="Invite new users"
        isLoading={isLoading}
        title="Invited users"
        onClickNewEntry={() => setIsDialogOpen(true)}
      />}
      columns={getTableColumns(tableData)}
      data={tableData}
      options={{
        download: false,
        elevation: 0,
        filterType: 'multiselect',
        print: false,
        selectableRows: 'none',
        textLabels: {
          body: {
            noMatch: isLoading ? 'Loading...' : 'No pending invitations',
          },
        } as MUIDataTableTextLabels,
      }}
    />
    <UserInviteDialog
      open={isDialogOpen}
      onAddInvites={onAddInvites}
      onClose={() => setIsDialogOpen(false)}
    />
  </>);
}
