import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import * as Sentry from '@sentry/react';
import React, { ReactElement, useState } from 'react';
import { IPolicyDocDto } from '../../../../backend/src/policy-doc/interfaces';
import { errorParser, formatDate } from '../../helpers';
import API from '../../services/ApiService';
import * as DocService from '../../services/DocService';
import { FileUploadButton } from '../buttons';
import { showErrorResultBar, showSuccessResultBar } from '../ResultSnackbar';
import StyledDialogTitle from '../StyledDialogTitle';
import {
  PolicyApprovalDialog,
  PolicyDiscardDialog,
  PolicyDocEditDialog,
  PolicyNewVersionDialog,
  RemoveCustomPolicyDialog,
  ResponsiveDialog,
  UploadCustomPolicyDialog,
} from '.';

const styles = (theme: Theme) => createStyles({
  referenceItem: {
    'paddingTop': theme.spacing(1),
    'paddingLeft': theme.spacing(2),
    'color': theme.typography.body1.color,

    '& a': {
      color: theme.typography.body1.color,
      cursor: 'pointer',
    },
  },
  removeCustomPolicyLink: {
    color: `${theme.palette.primary.main} !important`,
    paddingLeft: '1rem',
  },
});

export enum PolicyStatus {
  Approved = 'approved',
  Draft = 'draft',
  Pending = 'pending',
  Rejected = 'rejected',
}

export interface PolicyDetailsDialogProps extends WithStyles<typeof styles> {
  actions: ReactElement[];
  data: IPolicyDocDto;
  open: boolean;
  onApprove: (newData: IPolicyDocDto) => void;
  onClose: () => void;
  onUpdate: (newData: IPolicyDocDto) => void;
}

function PolicyDetailsDialog({ actions, classes, data, open, onApprove, onClose, onUpdate }: PolicyDetailsDialogProps) {
  const [ toShowApproveDialog, setToShowApproveDialog ] = useState(false);
  const [ toShowDiscardDialog, setToShowDiscardDialog ] = useState(false);
  const [ toShowNewVersionDialog, setToShowNewVersionDialog ] = useState(false);
  const [ toShowPolicyDocEditDialog, setToShowPolicyDocEditDialog ] = useState(false);
  const [ toShowRemoveCustomPolicyDialog, setToShowRemoveCustomPolicyDialog ] = useState(false);
  const [ toShowUploadCustomPolicyDialog, setToShowUploadCustomPolicyDialog ] = useState(false);
  const [ isSubmitting, setIsSubmitting ] = useState(false);

  // Handles marking as 'Pending' or 'Rejected':
  const handleUpdateStatus = (newStatus: PolicyStatus) => async () => {
    const defaultErrorMsg = 'Unexpected error while updating policy';
    let endpoint: string;

    if (newStatus === PolicyStatus.Pending) {
      endpoint = 'pending';
    } else if (newStatus === PolicyStatus.Rejected) {
      endpoint = 'reject';
    } else {
      showErrorResultBar(defaultErrorMsg);

      return;
    }

    try {
      setIsSubmitting(true);
      const res = await API.post(`policyDoc/${data.versionId}/${endpoint}`);
      onUpdate(res.data.data);
      showSuccessResultBar(`Policy successfully marked as ${newStatus}`);
    } catch (err: any) {
      showErrorResultBar(errorParser(err, defaultErrorMsg));
      Sentry.captureException(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <ResponsiveDialog
        open={open}
        onClose={onClose}
        fullWidth
      >
        <StyledDialogTitle onClose={onClose}>
          {data.name}
        </StyledDialogTitle>
        <DialogContent>
          <Grid
            container
            direction="column"
            spacing={4}
          >
            { data.description &&
              <Grid item>
                <Typography variant="body2">
                  {data.description}
                </Typography>
              </Grid>
            }
            <Grid item>
              <DialogContentText>
                Lifecycle
              </DialogContentText>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Owner:</TableCell>
                    <TableCell>{data.owner}</TableCell>
                  </TableRow>
                  {data.status === PolicyStatus.Approved ?
                    <>
                      <TableRow>
                        <TableCell>Approver:</TableCell>
                        <TableCell>{data.approver}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Approved:</TableCell>
                        <TableCell>{formatDate(data.approvalDate)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>In force:</TableCell>
                        <TableCell>{formatDate(data.inForceAt || data.approvalDate)}</TableCell>
                      </TableRow>
                    </>
                    : null}
                </TableBody>
              </Table>
            </Grid>
              { data.customPolicy &&
                <Grid item>
                  <DialogContentText>
                    Custom policy
                  </DialogContentText>
                  <Typography
                    className={classes.referenceItem}
                  >
                    <Link
                      onClick={DocService.documentDownloadHandler(data.customPolicy.id)}
                    >
                      {data.customPolicy.name}
                    </Link>
                    {!([ PolicyStatus.Rejected, PolicyStatus.Approved ].includes(data.status)) &&
                    <Link
                      component="a"
                      variant="caption"
                      onClick={() => setToShowRemoveCustomPolicyDialog(true)}
                      className={classes.removeCustomPolicyLink}
                    >
                      (remove)
                    </Link>
                    }
                  </Typography>
                </Grid>
              }
          </Grid>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-between' }}>
          <div>
            {actions}
          </div>
          <div>
            { data.status === PolicyStatus.Approved && (
              <Tooltip
                title="Create a new policy version to begin the policy review process or make corrections to the existing policy"
                placement="top"
              >
                <span>
                  <Button
                    disabled={isSubmitting}
                    onClick={() => setToShowNewVersionDialog(true)}
                  >
                    Create new version
                  </Button>
                </span>
              </Tooltip>
            )}
            {[ PolicyStatus.Draft, PolicyStatus.Pending ].includes(data.status) && (
              <Tooltip
                title="Open the Approve Policy dialog"
                placement="top"
              >
                <span>
                  <Button
                    disabled={isSubmitting}
                    onClick={() => setToShowApproveDialog(true)}
                  >
                    Approve
                  </Button>
                </span>
              </Tooltip>
            )}
            {[ PolicyStatus.Draft, PolicyStatus.Rejected ].includes(data.status) && (
              <Tooltip
                title="Mark the policy as pending"
                placement="top"
              >
                <span>
                  <Button
                    disabled={isSubmitting}
                    onClick={handleUpdateStatus(PolicyStatus.Pending)}
                  >
                    Pending
                  </Button>
                </span>
              </Tooltip>
            )}
            {[ PolicyStatus.Draft, PolicyStatus.Pending ].includes(data.status) && !data.prevVersionId && (
              <Tooltip
                title="Reject a policy if it does not apply to your company"
                placement="top"
              >
                <span>
                  <Button
                    disabled={isSubmitting}
                    onClick={handleUpdateStatus(PolicyStatus.Rejected)}
                  >
                    Reject
                  </Button>
                </span>
              </Tooltip>
            )}
            {[ PolicyStatus.Draft, PolicyStatus.Pending ].includes(data.status) && !data.customPolicy && (
              <Tooltip
                title="Replace the policy with your custom policy"
                placement="top"
              >
                <span>
                  <FileUploadButton
                    disabled={isSubmitting}
                    onClick={() => setToShowUploadCustomPolicyDialog(true)}
                  >
                    Upload
                  </FileUploadButton>
                </span>
              </Tooltip>
            )}
            {[ PolicyStatus.Draft, PolicyStatus.Pending ].includes(data.status) && !data.customPolicy && (
              <Tooltip
                title="Edit the existing policy"
                placement="top"
              >
                <span>
                  <Button
                    disabled={isSubmitting}
                    onClick={() => setToShowPolicyDocEditDialog(true)}
                  >
                    Edit
                  </Button>
                </span>
              </Tooltip>
            )}
            {[ PolicyStatus.Draft, PolicyStatus.Pending ].includes(data.status) && !!data.prevVersionId && (
              <Tooltip
                title="Discard this draft and revert to the last approved version"
                placement="top"
              >
                <span>
                  <Button
                    disabled={isSubmitting}
                    onClick={() => setToShowDiscardDialog(true)}
                  >
                    Discard
                  </Button>
                </span>
              </Tooltip>
            )}
            <Button
              onClick={onClose}
              color="primary"
            >
              Close
            </Button>
          </div>
        </DialogActions>
      </ResponsiveDialog>

      {/* New policy version dialog */}
      <PolicyNewVersionDialog
        onClose={() => setToShowNewVersionDialog(false)}
        onUpdate={onUpdate}
        open={toShowNewVersionDialog}
        policy={data}
      />
      {/* 'Discard policy' confirmation dialog */}
      <PolicyDiscardDialog
        onClose={() => setToShowDiscardDialog(false)}
        onUpdate={onUpdate}
        open={toShowDiscardDialog}
        policy={data}
      />
      {/* 'Remove custom policy' confirmation dialog */}
      <RemoveCustomPolicyDialog
        onClose={() => setToShowRemoveCustomPolicyDialog(false)}
        onUpdate={onUpdate}
        open={toShowRemoveCustomPolicyDialog}
        policy={data}
      />
      {/* Policy approval dialog */}
      {toShowApproveDialog &&
        <PolicyApprovalDialog
          onApprove={onApprove}
          onClose={() => setToShowApproveDialog(false)}
          open={toShowApproveDialog}
          policy={data}
        />
      }
      {/* File upload dialog */}
      <UploadCustomPolicyDialog
        onClose={() => setToShowUploadCustomPolicyDialog(false)}
        onUpdate={onUpdate}
        open={toShowUploadCustomPolicyDialog}
        policy={data}
      />
      {/* Policy editor dialog */}
      <PolicyDocEditDialog
        open={toShowPolicyDocEditDialog}
        onClose={() => setToShowPolicyDocEditDialog(false)}
        onUpdate={onUpdate}
        policyDoc={data}
      />
    </>
  );
}

export default withStyles(styles, { withTheme: true })(PolicyDetailsDialog);
