import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TableCell,
  TableRow,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/Error';
import RecentCommentIcon from '@mui/icons-material/ModeComment';
import CommentIcon from '@mui/icons-material/ModeCommentOutlined';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import classNames from 'classnames';
import { cloneDeep, findKey } from 'lodash';
import moment, { Moment } from 'moment';
import { MUIDataTableColumn } from 'mui-datatables';
import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import { ITaskAssigneeDto, ITaskCommentDto, ITaskDto, ITasksResponse } from '../../../backend/src/task/interfaces';
import { TaskStatus } from '../../../backend/src/task/task-status.enum';
import { formatDate } from '../helpers';
import useAuth from '../services/auth/useAuth';
import { STORAGE_KEYS, getStorageValue, setStorageValue } from '../services/BrowserStorageService';
import { DEFAULT_VIEW, FILTER_VIEW_SELECTIONS, TASK_STATUS_MAP, VALID_VIEW_STRINGS } from './FilterViewSelections';
import { showErrorResultBar } from './ResultSnackbar';
import SpioDataTable, { SpioDataTableColumn } from './SpioDataTable';
import TaskDetails from './TaskDetails';
import { TaskTableSelectedRowsToolbar } from './TaskTableSelectedRowsToolbar';

const styles = (theme: Theme) => createStyles({
  [theme.breakpoints.up('md')]: {
    commentCell: {
      textAlign: 'center',
    },
    commentCellText: {
      paddingLeft: '2px',
    },
  },
  [theme.breakpoints.down('md')]: {
    hideDn: {
      display: 'none',
    },
  },
  commentCellIcon: {
    fontSize: '0.875rem',
  },
  invalidAssigneeIcon: {
    marginLeft: '4px',
    verticalAlign: 'bottom',
  },
  percentCompleteCell: {
    display: 'inline-block',
    minWidth: '50px',
    textAlign: 'right',
  },
  recentCommentIconHighlight: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  titleContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  titleText: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  viewFormControl: {
    minWidth: 400,
  },
});

export const RECENT_COMMENT_PERIOD_IN_DAYS = 14;
export const UNASSIGNED_TASK_STR = 'Unassigned';

export const TASK_STATUS_ICONS: Record<TaskStatus, React.ReactNode> = {
  not_started: <NewReleasesIcon />,
  completed: <DoneIcon />,
  past_due: <PriorityHighIcon />,
  in_progress: <TimelapseIcon />,
};

export type FilterColumn =
  'assigneeId' |
  'assigneeName' |
  'dueDate' |
  'id' |
  'name' |
  'policies' |
  'statusStr' |
  'tagNames';

export type IFilters = Partial<Record<FilterColumn, string[]>>;

const FilterSchema = Yup.object().noUnknown().shape({
  assigneeId: Yup
    .array()
    .of(Yup.string()),
  assigneeName: Yup
    .array()
    .of(Yup.string()),
  dueDate: Yup
    .array()
    .of(Yup.string()),
  id: Yup
    .array()
    .of(Yup.string().uuid().label('Task ID'))
    .max(1),
  name: Yup
    .array()
    .of(Yup.string())
    .max(1),
  policies: Yup
    .array()
    .of(Yup.string()),
  statusStr: Yup
    .array()
    .of(Yup.mixed().oneOf(Object.values(TASK_STATUS_MAP))),
  tagNames: Yup
    .array()
    .of(Yup.string()),
}).required();

const getDefaultTasksView = (viewString?: string) => {
  return (viewString && VALID_VIEW_STRINGS.includes(viewString)) ? viewString : DEFAULT_VIEW.value;
};

export interface TaskTableProps extends WithStyles<typeof styles> {
  assignees: ITaskAssigneeDto[];
  handleDueDateChanged: (idx: number) => (newDate: Moment | null) => Promise<void>;
  handleCompletedDateChanged: (idx: number) => (newDate: Moment | null) => Promise<void>;
  isLoadingTasks: boolean;
  markComplete: (idx: number) => () => Promise<void>;
  markStarted: (idx: number) => () => Promise<void>;
  onUpdateTasks: (idxs: number[], updatedTasksInfo: ITasksResponse) => Promise<void>;
  tasks: ITaskDto[];
}

interface ITableDatum extends ITaskDto {
  nbComments: number;
  policies: string[];
  statusStr: string;
  tagNames: string[];
}

enum FiltersStatus {
  LOADING,
  APPLYING,
  READY,
}

function TaskTable(props: TaskTableProps) {
  const {
    assignees,
    classes,
    tasks,
    handleDueDateChanged,
    handleCompletedDateChanged,
    isLoadingTasks,
    markComplete,
    markStarted,
    onUpdateTasks,
  } = props;
  const { userId } = useAuth();
  const [ searchParams ] = useSearchParams();
  const [ tags, setTags ] = useState<string[]>([]);
  const [ filterViewSelections, setFilterViewSelections ] = useState(cloneDeep(FILTER_VIEW_SELECTIONS));
  // On init start with the 'Custom' view; the others have the side effect of overwriting the filters:
  const [ filters, setFilters ] = useState<IFilters>({});
  const [ filtersStatus, setFiltersStatus ] = useState(FiltersStatus.LOADING);
  const [ filterViewSelection, setFilterViewSelection ] = useState(FILTER_VIEW_SELECTIONS.CUSTOM.value);
  const [ initRowsExpanded, setInitRowsExpanded ] = useState<number[]>([]);
  const [ rowsSelected, setRowsSelected ] = useState<number[]>([]);
  const [ tableData, setTableData ] = useState<ITableDatum[]>([]);
  const [ newSearchText, setNewSearchText ] = useState<string | undefined>(getStorageValue(STORAGE_KEYS.TASKS_SEARCH));;

  const updateFilterViewSelection = (newSelection: string) => {
    setFilterViewSelection(newSelection);
    setStorageValue(STORAGE_KEYS.TASKS_VIEW, newSelection);
  };

  const updateFilters = useCallback((newFilters: IFilters) => {
    setFilters(newFilters);
    setStorageValue(STORAGE_KEYS.TASKS_FILTERS, newFilters);
  }, []);

  const handleUpdateTask = (idx: number) => (updatedTaskInfo: ITasksResponse) => {
    return onUpdateTasks([ idx ], updatedTaskInfo);
  };

  const handleUpdateTasks = async (idxs: number[], updatedTasksInfo: ITasksResponse) => {
    await onUpdateTasks(idxs, updatedTasksInfo);
    setRowsSelected([]);
  };

  useEffect(() => {
    setTableData(tasks.map(d => Object({
      ...d,
      assigneeName: d.assigneeName || UNASSIGNED_TASK_STR,
      nbComments: d.comments?.length ?? 0,
      policies: d.policyDocNames,
      statusStr: TASK_STATUS_MAP[d.status],
      tagNames: d.tags ? d.tags.map(tag => tag.name).sort() : [],
    })));

    const tagsSet = new Set<string>([]);

    tasks.forEach(task => {
      if (task.tags) {
        task.tags.forEach(tag => {
          tagsSet.add(tag.name);
        });
      }
    });

    const orderedTags = [ ...tagsSet ].sort();
    setTags(orderedTags);
  }, [ tasks ]);

  // Set the view and filters; use cached values, else defaults:
  useEffect(() => {
    // Dynamically add the user's id to the MY_OPEN_TASKS filter:
    const filterViewSels = cloneDeep(FILTER_VIEW_SELECTIONS);
    if (filterViewSels.MY_OPEN_TASKS.filter && filterViewSels.MY_OPEN_TASKS.filter.assigneeId) {
      filterViewSels.MY_OPEN_TASKS.filter.assigneeId.push(userId ?? '');
    }
    setFilterViewSelections(filterViewSels);

    // Set the filters and view selection; if an ID query parameter is supplied, then filter on it:
    const taskId = searchParams.get('id');
    let initFiltersRaw = {};
    if (taskId) {
      initFiltersRaw = { id: [ taskId ]};
      setFilterViewSelection(FILTER_VIEW_SELECTIONS.CUSTOM.value);
      setNewSearchText(undefined);
    } else {
      initFiltersRaw = getStorageValue(STORAGE_KEYS.TASKS_FILTERS);
      setFilterViewSelection(getDefaultTasksView(getStorageValue(STORAGE_KEYS.TASKS_VIEW)));
    }

    // Validate the filters and reset on error:
    try {
      setFilters(FilterSchema.validateSync(initFiltersRaw));
    } catch (err: any) {
      showErrorResultBar(`Invalid filter value: ${err.message}`);
      updateFilters({});
    }

    setFiltersStatus(FiltersStatus.APPLYING);
  }, [ searchParams, updateFilters, userId ]);

  // After the tasks and filters are loaded, if there is an ID filtered then expand that row.
  useEffect(() => {
    if (isLoadingTasks || filtersStatus !== FiltersStatus.APPLYING) return;

    const [ taskId ] = filters.id ?? [];
    const idx = taskId ? tasks.findIndex(t => t.id === taskId) : -1;
    setInitRowsExpanded(idx !== -1 ? [ idx ] : []);

    setFiltersStatus(FiltersStatus.READY);
  }, [ filters, filtersStatus, isLoadingTasks, tasks ]);

  // When the predefined view changes, update the filters accordingly:
  useEffect(() => {
    // 'Custom' means the user is changing the filters by hand so don't change them here.
    if (filterViewSelection === filterViewSelections.CUSTOM.value) {
      return;
    }

    const newFilterView = Object.values(filterViewSelections).find(sel => sel.value === filterViewSelection);

    if (newFilterView && newFilterView.filter) {
      updateFilters(newFilterView.filter);
    }
  }, [ filterViewSelections, filterViewSelection, updateFilters ]);

  const tableHeaders: SpioDataTableColumn[] = [
    {
      name: 'assigneeId',
      label: '',
      options: {
        customFilterListOptions: { render: (v: string) => v === userId ? 'My tasks' : v },
        download: false,
        display: 'excluded',
        filter: false,
        filterList: filters.assigneeId || [],
      },
    },
    {
      name: 'id',
      label: 'Task ID',
      options: {
        customFilterListOptions: { render: (v: string) => `ID: ${v}` },
        display: 'false',
        filter: false,
        filterList: filters.id || [],
      },
    },
    {
      name: 'progression',
      label: 'Order',
      options: {
        display: 'false',
        filter: false,
      },
    },
    {
      name: 'statusStr',
      label: 'Status',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const statusStr = tableData[dataIndex]?.statusStr;
          const statusInfo = (findKey(TASK_STATUS_MAP, v => v === statusStr) || 'not_started') as TaskStatus;

          return TASK_STATUS_ICONS[statusInfo];
        },
        filterList: filters.statusStr || [],
      },
    },
    {
      name: 'name',
      label: 'Title',
      options: {
        filter: true,
        filterType: 'textField',
        filterList: filters.name || [],
      },
    },
    {
      name: 'assigneeName',
      label: 'Assigned To',
      options: {
        customFilterListOptions: { render: (v: string) => `Assigned To: ${v}` },
        customBodyRenderLite: (dataIndex) => {
          const { assigneeId, assigneeName } = tableData[dataIndex] ?? {};

          if (!assigneeName || assigneeName === UNASSIGNED_TASK_STR) {
            return null;
          }

          // The first condition prevents the flash from 'invalid' to 'valid' while the assignees list is still loading:
          const isValidAssignee = assignees.length === 0 || assignees.some(a => a.id === assigneeId);

          return <>
            <span>{assigneeName}</span>
            {!isValidAssignee &&
              <Tooltip
                title="This user no longer has access to tasks"
              >
                <ErrorIcon
                  className={classes.invalidAssigneeIcon}
                  fontSize="small"
                />
              </Tooltip>
            }
          </>;
        },
        filterList: filters.assigneeName || [],
        filterOptions: {
          logic: (value, filterVals) => {
            const searchableName = value === null ? UNASSIGNED_TASK_STR : value;

            return !filterVals.includes(searchableName);
          },
        },
      },
    },
    {
      name: 'dueDate',
      label: 'Due',
      options: {
        customFilterListOptions: { render: (v: string) => `Due: ${v}` },
        filterList: filters.dueDate || [],
      },
    },
    {
      name: 'percentComplete',
      label: 'Percent Complete',
      options: {
        customBodyRenderLite: dataIndex => (
          <span className={classes.percentCompleteCell}>
            {tableData[dataIndex]?.percentComplete}%
          </span>
        ),
        display: 'false',
        filter: false,
        searchable: false,
      },
    },
    {
      name: 'policies',
      label: 'Policies',
      options: {
        customBodyRenderLite: dataIndex => <>
          {tableData[dataIndex]?.policies?.map(policyName => <div key={policyName}>{policyName}</div>)}
        </>,
        filterList: filters.policies || [],
        sort: false,
      },
    },
    {
      name: 'nbComments',
      label: 'Comments',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const nbComments = tableData[dataIndex]?.nbComments ?? 0;
          let isRecent = false;
          let tooltipText = 'Expand to see comments';
          if (nbComments > 0) {
            const mostRecentComment: ITaskCommentDto | null = (tableData[dataIndex]?.comments ?? [])[0] ?? null;
            if (mostRecentComment !== null) {
              isRecent = moment().diff(mostRecentComment.updatedAt, 'days') <= RECENT_COMMENT_PERIOD_IN_DAYS;
              tooltipText = `${isRecent ? 'Recent' : 'Last'} update: ${formatDate(mostRecentComment.updatedAt)} (${mostRecentComment.authorName})`;
            }
          }

          return !!nbComments && (
            <Tooltip title={tooltipText}>
              <div className={classNames(classes.commentCell, isRecent && classes.recentCommentIconHighlight)}>
                {isRecent ? (
                  <RecentCommentIcon className={classNames(classes.commentCellIcon, classes.hideDn)} />
                ) : (
                  <CommentIcon className={classNames(classes.commentCellIcon, classes.hideDn)} />
                )}
                <span className={classes.commentCellText}>
                  {nbComments}
                </span>
              </div>
            </Tooltip>
          );
        },
        setCellProps: () => Object({ nowrap: 'true' }),
        download: false,
        filter: false,
        searchable: false,
      },
    },
    {
      name: 'tagNames',
      label: 'Tags',
      options: {
        download: true,
        display: 'false',
        customBodyRenderLite: dataIndex => {
          const tagNames = tableData[dataIndex]?.tagNames || [];

          return tagNames.map(tagName => <div key={tagName}>{tagName}</div>);
        },
        filter: true,
        filterList: filters.tagNames || [],
        filterOptions: {
          names: tags,
          logic(tagNamesProp: any, filterVals) {
            const tagNames = tagNamesProp as string[];

            try {
              return filterVals.map(f => new RegExp(`^${f}$`)).every(f => tagNames.every(t => !f.test(t)));
            } catch (err: any) {
              return false;
            }
          },
        },
        searchable: true,
        setCellProps: () => Object({ nowrap: 'true' }),
        sort: false,
      },
    },
    {
      name: 'description',
      label: 'Description',
      options: {
        download: false,
        display: 'excluded',
        filter: false,
      },
    },
  ];

  function onViewChange(event: SelectChangeEvent<string>) {
    const newSelection = event.target.value;
    updateFilterViewSelection(newSelection);
  }

  function onManualFilterChange(changedColumn: string | MUIDataTableColumn | null, filterList: string[][]) {
    // This is fired when a user deletes a filter chip or modifies the filters directly.
    // (It's not fired when the predefined view changes.)
    //
    if (changedColumn === null) {
      // User has clicked the 'Reset' button in the filter dialog.
      // (MuiDataTable sets the 'changedColumn' to 'null' in this case.)
      updateFilterViewSelection(filterViewSelections.CLEAR_FILTERS.value);

      return;
    }

    // Set the filters by hand.
    // (Using the native filtering-change mechanism is faster, but can result in conflicts.)
    // Changing the 'filters' object causes the 'filterList's to update in 'tableHeaders'
    // which in turn fires the table's built in filtering.
    const changedCol = (typeof changedColumn === 'string' ? changedColumn : changedColumn.name) as FilterColumn;
    const newFilters = cloneDeep(filters);
    const idx = tableHeaders.findIndex(col => col.name === changedCol);
    newFilters[changedCol] = filterList[idx];
    updateFilters(newFilters);
    updateFilterViewSelection(filterViewSelections.CUSTOM.value);
  }

  const Title = withStyles(styles, { withTheme: true })(() => {
    return (
      <>
        <div className={classes.titleContainer}>
          <Typography
            variant="h6"
            className={classes.titleText}
          >
            Tasks
          </Typography>
          <FormControl
            className={classes.viewFormControl}
            variant="standard"
          >
            <InputLabel htmlFor="filter-view-select">
              Predefined Filter View
            </InputLabel>
            <Select
              value={filterViewSelection}
              onChange={onViewChange}
              inputProps={{
                name: 'filter-view-select',
                id: 'filter-view-select',
              }}
            >
              {Object.values(filterViewSelections).map(filterView => (
                <MenuItem
                  key={filterView.value}
                  value={filterView.value}
                >
                  {filterView.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </>
    );
  });

  return (isLoadingTasks || (filtersStatus !== FiltersStatus.READY) ? (
    <Paper>
      <Typography variant="h6" px={3} py={2}>Tasks</Typography>
      <CircularProgress sx={{ m: 4 }} />
    </Paper>
   ) : (
    <SpioDataTable
      title={<Title />}
      columns={tableHeaders}
      data={tableData}
      options={{
        textLabels: {
          body: { toolTip: 'Sort' },
        },
        onFilterChange: onManualFilterChange,
        filterType: 'multiselect',
        rowsExpanded: initRowsExpanded,
        rowsSelected,
        selectableRows: 'multiple',
        onRowSelectionChange: (_, allRowsSelected) => {
          setRowsSelected(allRowsSelected.map(row => row.dataIndex));
        },
        // By default the search is skipped for columns with display 'false' or 'excluded'.
        // We want to include those columns and only skip those with searchable 'false'.
        customSearch: (searchQuery, currentRow, columns) => {
          return columns.some((col, i) => col.searchable && currentRow[i]?.toString().toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0);
        },
        onSearchChange: text => {
          setNewSearchText(text ?? undefined);
          setStorageValue(STORAGE_KEYS.TASKS_SEARCH, text);
        },
        searchText: newSearchText,
        customToolbarSelect: (selectedRows) => (
          <TaskTableSelectedRowsToolbar
            assignees={assignees}
            onCancel={() => setRowsSelected([])}
            onUpdate={handleUpdateTasks}
            selectedRows={selectedRows}
            tasks={tasks}
          />
        ),
        print: false,
        download: true,
        downloadOptions: {
          filename: `Tasks_${moment().format('YYYYMMDD')}.csv`,
          filterOptions: {
            useDisplayedRowsOnly: true,
          },
        },
        expandableRows: true,
        expandableRowsOnClick: true,
        renderExpandableRow: (rowData, rowMeta) => {
          const colSpan = rowData.length + 1;
          const myData = tasks[rowMeta.dataIndex];
          const rowDataIndex = rowMeta.dataIndex;

          return myData ? (
            <TableRow>
              <TableCell
                colSpan={colSpan}
              >
                <TaskDetails
                  assignees={assignees}
                  markComplete={markComplete(rowDataIndex)}
                  markStarted={markStarted(rowDataIndex)}
                  onDueDateChange={handleDueDateChanged(rowDataIndex)}
                  onCompletedDateChange={handleCompletedDateChanged(rowDataIndex)}
                  onUpdateTask={handleUpdateTask(rowDataIndex)}
                  taskData={myData} />
              </TableCell>
            </TableRow>
          ) : null;
        },
      }}
    />
   )
  );
}

export default withStyles(styles, { withTheme: true })(TaskTable);
