import { common, red } from '@mui/material/colors';
import { createTheme, Theme } from '@mui/material';

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {
  }
}

const basicTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#db4e1d',
      contrastText: common.white,
    },
    secondary: {
      main: '#2f2f68',
      contrastText: common.black,
    },
    error: {
      main: red[500],
    },
    background: {
      default: '#e6e6e6',
    },
    success: {
      main: '#83e673',
    },
  },
  typography: {
    body1: {
      color: '#212b36',
    },
    body2: {
      color: '#637381',
    },
  },
});

const spioTheme = createTheme(basicTheme, {
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          color: basicTheme.typography.body1.color,
          backgroundColor: common.white,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          marginLeft: basicTheme.spacing(1),
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          padding: basicTheme.spacing(2),
          margin: basicTheme.spacing(2),
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          margin: 0,
          padding: basicTheme.spacing(1),
        },
      },
    },
    MuiDialogContent: {
      defaultProps: {
        dividers: true,
      },
      styleOverrides: {
        root: {
          margin: 0,
          padding: basicTheme.spacing(2),
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          background: basicTheme.palette.secondary.main,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          margin: basicTheme.spacing(1),
          width: '100%',
        },
      },
    },
    MuiGrid: {
      defaultProps: {
        spacing: 1,
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          color: basicTheme.typography.body1.color,
          '&.Mui-selected': {
            fontWeight: 500,
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: basicTheme.typography.body1.color,
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    // Custom components
    MUIDataTableSelectCell: {
      styleOverrides: {
        expandDisabled: {
          visibility: 'hidden', // hides the 'expand' icon for rows whose expansion is disabled
        },
      },
    },
    MUIDataTableToolbarSelect: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
        },
      },
    },
  },
});

const lcsPalette = createTheme(spioTheme, {
  palette: {
    mode: 'light',
    primary: {
      // main: '#ffb976',
      main: '#003f39',
      dark: '#001f1e',
      contrastText: '#ffb976',
    },
    secondary: {
      main: '#ffb976',
      dark: '#a67246',
      contrastText: common.white,
    },
  },
});

const munisecTheme = createTheme(lcsPalette, {
  components: {
    TheLoginLayout: {
      styleOverrides: {
        logo: {
          backgroundImage: 'url(https://spio-public-assets.s3.us-east-2.amazonaws.com/images/logos/munisec_logo.png)',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          '& img': {
            display: 'none',
          },
        },
        root: {
          '& .MuiAppBar-root': {
            backgroundColor: lcsPalette.palette.primary.main,
          },
          '& .MuiButton-text': {
            color: lcsPalette.palette.primary.contrastText,
          },
        },
      },
    },

    MuiDrawer: {
      styleOverrides: {
        paper: {
          background: 'rgb(238, 238, 238)',
        },
      },
    },

    StylizedName: {
      defaultProps: {
        partOneContent: 'muni',
        partTwoContent: 'sec.io',
      },
    },

    TheNavLinkList: {
      styleOverrides: {
        listItems: {
          color: common.black,
        },
        categoryHeader: {
          color: common.black,
        },
        toolbarIcon: {
          backgroundColor: lcsPalette.palette.primary.main,
        },
      },
    },
  },
});

const Themes: { [index: string]: Theme } = {
  spioTheme,
  munisecTheme,
};

export default Themes;
