import {
  Card,
  CardActionArea,
  CardHeader,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { IProviderOrg, IProviderSyncSummary } from '../../../backend/src/user-audit/interfaces';
import * as helpers from '../helpers';
import { AwsGovCloud } from './icons';
import { getOrgAuditStatus, userAuditStatusMap } from './user-audit/UserAuditTableHelpers';

export const AWS_GOV_CLOUD_PARTITION = 'aws-us-gov';

const useStyles = makeStyles({
  card: {
    margin: 0,
    padding: 0,
    height: '100%',
  },
  cardActionArea: {
    height: '100%',
  },
  cardHeader: {
    paddingBottom: 0,
  },
  cardHeaderIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '18px',
    marginLeft: '0.5rem',
  },
  cardTitle: {
    fontSize: '18px',
  },
  indented: {
    paddingLeft: '0.5rem',
  },
  progressCircle: {
    margin: '3.5rem 8rem',
    display: 'block',
  },
  syncSummary: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.85rem',
    padding: '16px',
    paddingTop: '8px',
    paddingLeft: '24px',
  },
});

function CardHeaderInfo({ org }: { org: IProviderOrg }) {
  const classes = useStyles();

  return (
    <Typography variant="body1" className={classes.cardTitle}>
      {org.name}
      {org.accountId.startsWith(AWS_GOV_CLOUD_PARTITION) &&
        <Tooltip title="AWS GovCloud Account">
          <AwsGovCloud className={classes.cardHeaderIcon} />
        </Tooltip>
      }
    </Typography>
  );
}

const getStatusStr = (org: IProviderOrg) => userAuditStatusMap[getOrgAuditStatus(org)] ?? 'Unknown';

function SyncSummary({ org }: { org: IProviderOrg }) {
  const classes = useStyles();
  const status = getOrgAuditStatus(org);
  const latestAudit = org.syncs[0] as IProviderSyncSummary;
  const prevAudit = org.syncs[1] as IProviderSyncSummary;

  let syncDateStr: string | undefined;
  let confirmDateStr: string | undefined;
  let confirmedBy: string | undefined;
  let confirmSyncDateStr: string | undefined;

  switch (status) {
    case 'confirmed':
      syncDateStr = 'No new syncs';
      confirmDateStr = helpers.formatDate(latestAudit?.confirmedAt) ?? '';
      confirmedBy = latestAudit?.confirmedByName ?? '';
      confirmSyncDateStr = helpers.formatDate(latestAudit?.syncedAt) ?? '';
      break;
    case 'new':
      syncDateStr = 'No new syncs';
      confirmDateStr = 'Never';
      break;
    case 'pending':
      syncDateStr = helpers.formatDateTime(latestAudit?.syncedAt) ?? '';
      confirmDateStr = helpers.formatDate(prevAudit?.confirmedAt) ?? 'Never';
      confirmedBy = prevAudit?.confirmedByName ?? '';
      confirmSyncDateStr = helpers.formatDate(prevAudit?.syncedAt) ?? '';
      break;
    default:
      break;
  }

  return (
    <Typography variant="body2" component="span" display="block" className={classes.syncSummary}>
      <div>Latest sync: {syncDateStr}</div>
      <div>Last confirmed: {confirmDateStr}</div>
      {confirmedBy ? <div className={classes.indented}>by: {confirmedBy}</div> : ''}
      {confirmSyncDateStr ? <div className={classes.indented}>with sync data: {confirmSyncDateStr}</div> : ''}
    </Typography>
  );
}

interface ProviderOrgSummaryProps {
  handleSelectOrgIdx: (idx: number) => void;
  handleSyncOrg: (idx: number) => Promise<void>;
  isLoading: boolean;
  orgs: IProviderOrg[];
  refreshingOrgIdx: number;
  selectedOrgIdx: number;
}

export default function ProviderOrgSummary(props: ProviderOrgSummaryProps) {
  const classes = useStyles();
  const { handleSelectOrgIdx, handleSyncOrg, isLoading, orgs, refreshingOrgIdx, selectedOrgIdx } = props;

  const clickOrg = (idx: number) => () => {
    handleSelectOrgIdx(idx);
  };

  const clickSyncOrg = (idx: number) => async () => {
    handleSyncOrg(idx);
  };

  return <>
    {isLoading ? (
      <CircularProgress
        className={classes.progressCircle}
      />
    ) : orgs.length === 0 ? (
      <Typography>
        You don't yet have any accounts/organizations set up to be audited.
        To begin, click the <b>ADD</b> button above.
      </Typography>
    ) : (
      <Grid container>
        {orgs.map((org, idx) => (
          <Grid
            key={org.id}
            item
            xs={4}
            xl={3}
          >
            <Card
              className={classes.card}
              onClick={clickOrg(idx)}
              raised={idx === selectedOrgIdx}
            >
              <CardActionArea className={classes.cardActionArea}>
                <CardHeader
                  className={classes.cardHeader}
                  title={<CardHeaderInfo org={org} />}
                  subheader={getStatusStr(org)}
                  action={refreshingOrgIdx === idx ? (
                    <IconButton aria-label="sync in progress" component="div" size="large">
                      <CircularProgress size={25} />
                    </IconButton>
                  ) : (
                    <Tooltip title="Resync">
                      <IconButton
                        aria-label="resync"
                        component="div"
                        onClick={clickSyncOrg(idx)}
                        size="large">
                        <SyncIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                />
                <SyncSummary org={org} />
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    )}
  </>;
}
