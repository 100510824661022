import { Badge, Tab, Tabs } from '@mui/material';
import * as Sentry from '@sentry/react';
import { partition, sortBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { IPolicyAcknowledgementDto, IPolicyDocDto } from '../../../backend/src/policy-doc/interfaces';
import { DownloadButton } from '../components/buttons';
import { PolicyAcknowledgementDialog, PolicyDetailsDialog, PolicyDocDialog } from '../components/dialogs';
import PolicyListCard from '../components/PolicyListCard';
import PolicyManagementTable from '../components/PolicyManagementTable';
import { showErrorResultBar, showInfoResultBar } from '../components/ResultSnackbar';
import { errorParser } from '../helpers';
import API from '../services/ApiService';
import useAuth from '../services/auth/useAuth';
import { downloadPolicy as DocServiceDownloadPolicy } from '../services/DocService';

function ThePolicyPage() {
  const { isPolicyManager } = useAuth();
  const [ isAcknowledgeDialogOpen, setIsAcknowledgeDialogOpen ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isPolicyDocOpen, setIsPolicyDocOpen ] = useState(false);
  const [ isDetailsOpen, setIsDetailsOpen ] = useState(false);
  const [ policies, setPolicies ] = useState<IPolicyDocDto[]>([]);
  const [ policiesAcknowledged, setPoliciesAcknowledged ] = useState<IPolicyDocDto[]>([]);
  const [ policiesToAcknowledge, setPoliciesToAcknowledge ] = useState<IPolicyDocDto[]>([]);
  const [ policiesUnacknowledged, setPoliciesUnacknowledged ] = useState<IPolicyDocDto[]>([]);
  const [ selectedPolicy, setSelectedPolicy] = useState<IPolicyDocDto | null>(null);
  const [ selectedTabIdx, setSelectedTabIdx ] = useState(0);

  useEffect(() => {
    setIsLoading(true);

    API.get('policyDoc')
      .then(res => setPolicies(res.data.data))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  // For Policy Managers, need to fetch the latest unacknowledged Approved (since their latest policies may be Pending):
  useEffect(() => {
    if (isPolicyManager) {
      API.get('policyDoc/unacknowledged')
        .then(res => setPoliciesUnacknowledged(res.data.data));
    }
  }, [ isPolicyManager ]);

  // For Trainees, partition out the (un)acknowledged from the policies, since Trainees only have access to the latest Approved anyway:
  useEffect(() => {
    if (!isPolicyManager) {
      const approvedPolicies = policies.filter(policy => policy.status === 'approved');
      const [ ack, unack ] = partition(approvedPolicies, 'userAcknowledgedAt');
      setPoliciesAcknowledged(ack);
      setPoliciesUnacknowledged(unack);
    }
  }, [ isPolicyManager, policies ]);

  // Both Policy Managers and Trainees

  const handleDownloadPolicy = async (policy: IPolicyDocDto) => {
    try {
      showInfoResultBar('Preparing policy document. It should automatically download soon.');
      await DocServiceDownloadPolicy(policy.versionId);
    } catch (err: any) {
      showErrorResultBar(errorParser(err, 'An unexpected error occurred while downloading the policy.'));
      Sentry.captureException(err);
    }
  };

  const handleOpenPolicyDoc = async (policy: IPolicyDocDto) => {
    if (policy.customPolicy) {
      handleDownloadPolicy(policy);
    } else {
      setSelectedPolicy(policy);
      setIsPolicyDocOpen(true);
    }
  };

  const handleOpenAcknowledgeDialog = (thePolicies: IPolicyDocDto[]) => {
    setPoliciesToAcknowledge(thePolicies);
    setIsAcknowledgeDialogOpen(true);
  };

  const handleAcknowledgePolicies = (acknowledgements: IPolicyAcknowledgementDto[]) => {
    // For both Policy Managers and Trainees, update any of the 'policies' that are affected:
    const policiesCopy = policies.slice();
    acknowledgements.forEach(({ userAcknowledgedAt, versionId }) => {
      const idx = policiesCopy.findIndex(p => p.versionId === versionId);
      if (idx !== -1) {
        policiesCopy[idx].userAcknowledgedAt = userAcknowledgedAt;
      }
    });
    setPolicies(policiesCopy);

    // For Policy Managers, update the Unacknowledged explicitly:
    if (isPolicyManager) {
      const ackVersionIds = acknowledgements.map(a => a.versionId);
      setPoliciesUnacknowledged(policiesUnacknowledged.filter(p => !ackVersionIds.includes(p.versionId)));
    }
  };

  // Policy Managers

  const handleOpenDetails = (policy: IPolicyDocDto) => {
    setSelectedPolicy(policy);
    setIsDetailsOpen(true);
  };

  const handleApprovePolicy = (policy: IPolicyDocDto) => {
    // Assumption is that immediately after the user Approves a policy, the policy is still Unacknowledged.
    // The old Approved version may or may not be among the Unacknowledged; remove it if it's there,
    // then add the new Approved policy.
    const unacknowledgedCopy = policiesUnacknowledged.filter(p => p.id !== policy.id);
    setPoliciesUnacknowledged(sortBy([ policy ].concat(unacknowledgedCopy), 'name'));

    handleUpdatePolicyDetails(policy);
  };

  const handleUpdatePolicyDetails = (policy: IPolicyDocDto) => {
    const idx = policies.findIndex(p => p.id === policy.id);

    if (idx !== -1) {
      const policiesCopy = policies.slice();
      policiesCopy[idx] = policy;
      setPolicies(policiesCopy);
    }
    setIsDetailsOpen(false);
  };

  return <>
    {isPolicyManager ? (<>
    {/* Policy Manager view */}
      <Tabs
        value={selectedTabIdx}
        onChange={(e, tabIdx) => setSelectedTabIdx(tabIdx)}
      >
        <Tab label="Policy Management" />
        <Tab
          label={<Badge badgeContent={policiesUnacknowledged.length} color="primary">Policy Acknowledgement</Badge>}
        />
      </Tabs>
      {selectedTabIdx === 0 &&
        <PolicyManagementTable
          isLoading={isLoading}
          onDownloadPolicy={handleDownloadPolicy}
          onOpenDetails={handleOpenDetails}
          onOpenPolicyDoc={handleOpenPolicyDoc}
          policies={policies}
        />
      }
      {selectedTabIdx === 1 &&
        (policiesUnacknowledged.length === 0 ? (
        <PolicyListCard
          policies={[]}
          title="No policies currently need acknowledgement"
          subtitle="As new policies are approved they will appear below to be acknowledged"
        />
        ) : (
        <PolicyListCard
          onAcknowledgePolicies={handleOpenAcknowledgeDialog}
          onDownloadPolicy={handleDownloadPolicy}
          onOpenPolicyDoc={handleOpenPolicyDoc}
          policies={policiesUnacknowledged}
          title={`Acknowledge policies (${policiesUnacknowledged.length} left)`}
          subtitle="Select the policies that you have read and agree to then click the Acknowledge button"
        />
      ))}
      {selectedPolicy !== null &&
      <PolicyDetailsDialog
        open={isDetailsOpen}
        data={selectedPolicy}
        actions={[<DownloadButton key="download" onClick={() => handleDownloadPolicy(selectedPolicy)} />]}
        onApprove={handleApprovePolicy}
        onClose={() => setIsDetailsOpen(false)}
        onUpdate={handleUpdatePolicyDetails}
      />
      }
    </>) : (<>
    {/* Trainee view */}
      {!isLoading && policiesAcknowledged.length === 0 && policiesUnacknowledged.length === 0 &&
      <PolicyListCard
        policies={[]}
        title="No policies currently need acknowledgement"
        subtitle="Your company's security policies will appear below when they are ready to be read and acknowledged"
      />
      }
      {policiesUnacknowledged.length > 0 &&
      <PolicyListCard
        onAcknowledgePolicies={handleOpenAcknowledgeDialog}
        onDownloadPolicy={handleDownloadPolicy}
        onOpenPolicyDoc={handleOpenPolicyDoc}
        policies={policiesUnacknowledged}
        title={`Acknowledge policies (${policiesUnacknowledged.length} left)`}
        subtitle="Select the policies that you have read and agree to then click the Acknowledge button"
      />
      }
      {policiesAcknowledged.length > 0 &&
      <PolicyListCard
        onDownloadPolicy={handleDownloadPolicy}
        onOpenPolicyDoc={handleOpenPolicyDoc}
        policies={policiesAcknowledged}
        title="Policies"
        subtitle={policiesUnacknowledged.length > 0 ? 'Following are the policies that you have already acknowledged' : "Your company's security policies"}
      />
      }
    </>)}
    {/* Dialogs for both Policy Managers and Trainees */}
    {policiesToAcknowledge.length !== 0 &&
    <PolicyAcknowledgementDialog
      onClose={() => setIsAcknowledgeDialogOpen(false)}
      onUpdate={handleAcknowledgePolicies}
      open={isAcknowledgeDialogOpen}
      policies={policiesToAcknowledge}
    />
    }
    {selectedPolicy !== null &&
    <PolicyDocDialog
      open={isPolicyDocOpen}
      onClose={() => setIsPolicyDocOpen(false)}
      onEdit={() => handleOpenDetails(selectedPolicy)}
      policyDoc={selectedPolicy}
      actions={[<DownloadButton key="download" onClick={() => handleDownloadPolicy(selectedPolicy)} />]}
    />
    }
  </>;
}

ThePolicyPage.requiredAuthZ = {
  tier: 1,
  permission: 'policies',
};
ThePolicyPage.routePath = '/policies';
ThePolicyPage.title = 'Policies';

export default ThePolicyPage;
