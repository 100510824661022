import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import {
  ChartTasksByCategory,
  MetricsSummary,
  RoadmapHorizontal,
  TaskStatusByRound,
  TrainingCoverageChart,
  UpNextTasks,
} from '../components/widgets';
import API from '../services/ApiService';
import useAuth from '../services/auth/useAuth';

function TheDashboardPage() {
  const { isGranted } = useAuth();
  const [ isLoadingTasks, setIsLoadingTasks ] = useState(false);
  const [ isLoadingUpNextTasks, setIsLoadingUpNextTasks ] = useState(false);
  const [ taskCategoriesSummary, setTaskCategoriesSummary ] = useState([]);
  const [ taskOverallSummary, setTaskOverallSummary ] = useState([]);
  const [ taskRoundsSummary, setTaskRoundsSummary ] = useState([]);
  const [ upNextTasks, setUpNextTasks ] = useState([]);
  const [ reloadTasks, setReloadTasks ] = useState(true);

  useEffect(() => {
    // If the status of a task changes then we need to reload all the task widgets.
    if (reloadTasks) {
      setIsLoadingTasks(true);
      setIsLoadingUpNextTasks(true);

      API.get('task/dashboard')
        .then(res => {
          const data = (res.data && res.data.data) ? res.data.data : {};
          setTaskCategoriesSummary(data.byCategory || []);
          setTaskOverallSummary(data.overall || []);
          setTaskRoundsSummary(data.byRound || []);
          setUpNextTasks(data.upNext || []);
        })
        .finally(_ => {
          setReloadTasks(false);
          setIsLoadingTasks(false);
          setIsLoadingUpNextTasks(false);
        });
    }
  }, [ reloadTasks ]);

  const reloadUpNextTasks = async () => {
    // If e.g. only the assignment of a task changes then we only need to reload the Up Next widget.
    setIsLoadingUpNextTasks(true);

    API.get('task/dashboard/upNext')
      .then(res => {
        const data = (res.data && res.data.data) ? res.data.data : {};
        setUpNextTasks(data.upNext || []);
      })
      .finally(_ => {
        setIsLoadingUpNextTasks(false);
      });
  };

  return (
    <Grid container>
      {isGranted(TaskStatusByRound.requiredAuthZ) &&
        <Grid item xs={12}>
          <TaskStatusByRound
            taskOverallSummary={taskOverallSummary}
            taskRoundsSummary={taskRoundsSummary}
          />
        </Grid>
      }

      {isGranted(RoadmapHorizontal.requiredAuthZ) &&
        <Grid item xs={12}>
          <RoadmapHorizontal />
        </Grid>
      }
      {isGranted(MetricsSummary.requiredAuthZ) &&
        <Grid item xs={12} sm={4}>
          <MetricsSummary />
        </Grid>
      }

      {isGranted(UpNextTasks.requiredAuthZ) &&
        <Grid item xs={12} sm={isGranted(MetricsSummary.requiredAuthZ) ? 8 : 6}>
          <UpNextTasks
            isLoading={isLoadingUpNextTasks}
            onUpdateAssignment={reloadUpNextTasks}
            onUpdateStatus={() => setReloadTasks(true)}
            setTasks={setUpNextTasks}
            tasks={upNextTasks}
          />
        </Grid>
      }

      {isGranted(ChartTasksByCategory.requiredAuthZ) &&
        <Grid item xs={12} sm={6}>
          <ChartTasksByCategory
            isLoading={isLoadingTasks}
            taskCategoriesSummary={taskCategoriesSummary}
            taskOverallSummary={taskOverallSummary}
          />
        </Grid>
      }

      {isGranted(TrainingCoverageChart.requiredAuthZ) &&
        <Grid item xs={12} sm={6}>
          <TrainingCoverageChart />
        </Grid>
      }
    </Grid>
  );
}

TheDashboardPage.requiredAuthZ = {
  tier: 1,
  permission: 'dashboard',
};
TheDashboardPage.routePath = '/dashboard';
TheDashboardPage.title = 'Dashboard';

export default TheDashboardPage;
