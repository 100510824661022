import { QuestionType, RadioYesNoQuestion, TextInputQuestion } from './';
import { IQuestionBlock, IQuestionProps } from './interfaces';
import { QUESTION_BLOCKS_V1, QUESTION_BLOCKS_V2 } from './question-blocks';

export const getQuestionComponent = (questionType: QuestionType) => {
  switch (questionType) {
    case QuestionType.RadioYesNo:
      return RadioYesNoQuestion;
    case QuestionType.TextInput:
    default:
      return TextInputQuestion;
  }
};

export const extractQuestionsFromBlocks = (questionBlocks: IQuestionBlock[]) => questionBlocks.reduce<IQuestionProps[]>((acc, curr) => acc.concat(curr.questions.map(q => q.props)), []);

export const ALL_VENDOR_QUESTIONS = extractQuestionsFromBlocks([
  ...QUESTION_BLOCKS_V1,
  ...QUESTION_BLOCKS_V2,
]);
