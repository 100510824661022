import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, ListItem, ListItemIcon } from '@mui/material';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { FileUploadButtonDialog } from '../components/dialogs';
import API from '../services/ApiService';
import useAuth from '../services/auth/useAuth';
import * as DocService from '../services/DocService';
import AccordionIconSummary from '../components/AccordionIconSummary';

function DocAccordion({ docs, headerText, onClickDoc }) {
  const [ isExpanded, setIsExpanded ] = useState(false);

  useEffect(() => {
    setIsExpanded(docs.length > 0);
  }, [ docs ]);

  return (
    <Accordion
      elevation={2}
      expanded={isExpanded}
      onChange={() => setIsExpanded(!isExpanded)}
    >
      <AccordionIconSummary title={headerText} />

      <AccordionDetails>
        <List>
          {docs.map(doc => (
            <ListItem
              key={doc.id}
              button
              onClick={onClickDoc(doc.id)}
            >
              <ListItemIcon>
                <InsertDriveFileIcon />
              </ListItemIcon>
              <ListItemText
                primary={doc.name}
                secondary={doc.description}
              />
            </ListItem>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
}

function TheDocumentsPage() {
  const { isGranted, orgId } = useAuth();
  const [ reports, setReports ] = useState([]);
  const [ evidence, setEvidence ] = useState([]);
  const [ templates, setTemplates ] = useState([]);

  useEffect(() => {
    API.get('document')
      .then((res) => {
        const updatedDocs = res.data;

        setReports(updatedDocs.filter(doc => doc.category === 'report'));
        setEvidence(updatedDocs.filter(doc => doc.category === 'evidence'));
      });
  }, []);

  useEffect(() => {
    API.get('document/template')
      .then((res) => {
        const templateDocs = res.data;
        setTemplates(templateDocs);
      });
  }, []);

  function onAddDocument(newDoc) {
    // If the new doc belongs to us, then add it to the list of displayed docs.
    if (newDoc.orgId === orgId) {
      switch (newDoc.category) {
        case 'report':
          setReports(reports.concat([ newDoc ]));
          break;

        case 'evidence':
          setEvidence(evidence.concat([ newDoc ]));
          break;

        default:
        // ignore
      }
    }
  }

  return (
    <>
      <DocAccordion
        docs={reports}
        headerText="Reports"
        onClickDoc={DocService.documentDownloadHandler}
      />
      <DocAccordion
        docs={templates}
        headerText="Templates"
        onClickDoc={DocService.documentTemplateDownloadHandler}
      />
      <DocAccordion
        docs={evidence}
        headerText="Evidence"
        onClickDoc={DocService.documentDownloadHandler}
      />

      {isGranted({ permission: 'documents:upload' }) &&
      <FileUploadButtonDialog
        buttonText="Upload New File"
        onAddDocument={onAddDocument}
        showCategorySelection
      />
      }
    </>
  );
}

TheDocumentsPage.requiredAuthZ = {
  tier: 2,
  permission: 'documents',
};
TheDocumentsPage.routePath = '/documents';
TheDocumentsPage.title = 'Documents';

export default TheDocumentsPage;
