import { Button } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useIconStyles = makeStyles({
  leftIcon: {
    marginRight: '0.5rem',
  },
});

export interface FileUploadButtonProps extends ButtonProps {
  showIcon?: boolean;
}

export default function FileUploadButtonDialog({ children, showIcon = false, ...rest }: FileUploadButtonProps) {
  const iconClasses = useIconStyles();

  return (
    <Button
      {...rest}
    >
      {!!showIcon && <InsertDriveFileIcon className={iconClasses.leftIcon} />}
      {children}
    </Button>
  );
}
