import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import * as Sentry from '@sentry/react';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useTracking } from './hooks';
import { AuthRoute, TheDashboardLayout, TheLoginLayout, TheQuestionnaireLayout } from './layouts';
import Themes from './theme';
import {
  The404Page,
  TheAttestationPage,
  TheAuthCallback,
  TheBetaPage,
  TheBillingPageRedirect,
  TheDashboardPage,
  TheDocumentsPage,
  TheGlobalVendorPage,
  TheHomePageRedirect,
  TheLoginPage,
  TheLogoutPage,
  TheMarketingBundlePage,
  TheNetworkScanPage,
  TheNetworkScanRequestPage,
  TheOrganizationPage,
  ThePolicyAckReportPage,
  ThePolicyPage,
  TheQuestionnairePage,
  TheRegisterPage,
  TheReportsPage,
  TheRiskRegisterPage,
  TheRoadmapAdminPage,
  TheSignUpPage,
  TheSupportPage,
  TheTaskMasterPage,
  TheTasksPage,
  TheTasksSummaryCIS20Page,
  TheTasksSummaryCISV8Page,
  TheTasksSummaryCMMCPage,
  TheTasksSummaryISO27001Page,
  TheTasksSummaryNIST800171Page,
  TheTasksSummaryNistCsfPage,
  TheTasksSummarySOCPage,
  TheTrainingPage,
  TheTrainingReportPage,
  TheUpgradePage,
  TheUserAuditAwsPage,
  TheUserAuditAzurePage,
  TheUserAuditGithubPage,
  TheUserAuditGooglePage,
  TheUserAuditPage,
  TheUserManagementPage,
  TheVendorQuestionnairePage,
  TheVendorTrackingPage,
  TheWelcomePage,
} from './pages';
import useAuth from './services/auth/useAuth';

const App = () => {
  useTracking();
  const { isAuthenticated, isRegistered } = useAuth();

  return (
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={Themes[process.env.REACT_APP_DEFAULT_THEME ?? 'spioTheme']}>
            <CssBaseline />
            <Routes>
              <Route path={TheHomePageRedirect.routePath} element={<TheHomePageRedirect />} />
              <Route path="/signup" element={<TheSignUpPage redirectUrl={TheWelcomePage.routePath} />} />
              <Route element={<TheLoginLayout />}>
                <Route path={TheAuthCallback.routePath} element={<TheAuthCallback />} />
                <Route path={TheLoginPage.routePath} element={<TheLoginPage />} />
                <Route path="/logout" element={<TheLogoutPage />} />
                <Route path="/register" element={
                  isRegistered ? <Navigate to={TheWelcomePage.routePath} replace /> : <TheRegisterPage />
                } />
                {!isAuthenticated && <Route path={The404Page.routePath} element={<The404Page />} />}
              </Route>
              <Route element={<TheQuestionnaireLayout />}>
                <Route path={TheVendorQuestionnairePage.routePath} element={<TheVendorQuestionnairePage />} />
              </Route>
              <Route element={<AuthRoute />}>
                <Route element={<TheDashboardLayout />}>
                  <Route path={TheAttestationPage.routePath} element={<TheAttestationPage />} />
                  <Route path={TheBetaPage.routePathWithParam} element={<TheBetaPage />} />
                  <Route path={TheBetaPage.routePath} element={<TheBetaPage />} />
                  <Route path={TheBillingPageRedirect.routePath} element={<TheBillingPageRedirect />} />
                  <Route path={TheDashboardPage.routePath} element={<TheDashboardPage />} />
                  <Route path={TheDocumentsPage.routePath} element={<TheDocumentsPage />} />
                  <Route path={TheGlobalVendorPage.routePath} element={<TheGlobalVendorPage />} />
                  <Route path={TheMarketingBundlePage.routePath} element={<TheMarketingBundlePage />} />
                  <Route path={TheNetworkScanPage.routePath} element={<TheNetworkScanPage />} />
                  <Route path={TheNetworkScanRequestPage.routePath} element={<TheNetworkScanRequestPage />} />
                  <Route path={TheOrganizationPage.routePath} element={<TheOrganizationPage />} />
                  <Route path={ThePolicyAckReportPage.routePath} element={<ThePolicyAckReportPage />} />
                  <Route path={ThePolicyPage.routePath} element={<ThePolicyPage />} />
                  <Route path={TheReportsPage.routePath} element={<TheReportsPage />} />
                  <Route path={TheQuestionnairePage.routePath} element={<TheQuestionnairePage />} />
                  <Route path={TheRiskRegisterPage.routePath} element={<TheRiskRegisterPage />} />
                  <Route path={TheRoadmapAdminPage.routePath} element={<TheRoadmapAdminPage />} />
                  <Route path={TheSupportPage.routePath} element={<TheSupportPage />} />
                  <Route path={TheTaskMasterPage.routePath} element={<TheTaskMasterPage />} />
                  <Route path={TheTasksPage.routePathWithParam} element={<TheTasksPage />} />
                  <Route path={TheTasksPage.routePath} element={<TheTasksPage />} />
                  <Route path={TheTasksSummaryCIS20Page.routePath} element={<TheTasksSummaryCIS20Page />} />
                  <Route path={TheTasksSummaryCISV8Page.routePath} element={<TheTasksSummaryCISV8Page />} />
                  <Route path={TheTasksSummaryCMMCPage.routePath} element={<TheTasksSummaryCMMCPage />} />
                  <Route path={TheTasksSummaryISO27001Page.routePath} element={<TheTasksSummaryISO27001Page />} />
                  <Route path={TheTasksSummaryNIST800171Page.routePath} element={<TheTasksSummaryNIST800171Page />} />
                  <Route path={TheTasksSummaryNistCsfPage.routePath} element={<TheTasksSummaryNistCsfPage />} />
                  <Route path={TheTasksSummarySOCPage.routePath} element={<TheTasksSummarySOCPage />} />
                  <Route path={TheTrainingPage.routePathWithParam} element={<TheTrainingPage />} />
                  <Route path={TheTrainingPage.routePath} element={<TheTrainingPage />} />
                  <Route path={TheTrainingReportPage.routePath} element={<TheTrainingReportPage />} />
                  <Route path={TheUpgradePage.routePath} element={<TheUpgradePage />} />
                  <Route path={TheUserAuditAwsPage.routePath} element={<TheUserAuditAwsPage />} />
                  <Route path={TheUserAuditAzurePage.routePath} element={<TheUserAuditAzurePage />} />
                  <Route path={TheUserAuditGithubPage.routePath} element={<TheUserAuditGithubPage />} />
                  <Route path={TheUserAuditGooglePage.routePath} element={<TheUserAuditGooglePage />} />
                  <Route path={TheUserAuditPage.routePath} element={<TheUserAuditPage />} />
                  <Route path={TheUserManagementPage.routePath} element={<TheUserManagementPage />} />
                  <Route path={TheVendorTrackingPage.routePath} element={<TheVendorTrackingPage />} />
                  <Route path={TheWelcomePage.routePath} element={<TheWelcomePage />} />
                  {isAuthenticated && <Route path={The404Page.routePath} element={<The404Page />} />}
                </Route>
              </Route>
              <Route path="*" element={<Navigate to={The404Page.routePath} replace />} />
            </Routes>
          </ThemeProvider>
        </StyledEngineProvider>
      </LocalizationProvider>
    </Sentry.ErrorBoundary>
  );
};

export default App;
