import { IQuestionBlock } from '../interfaces';
import { QuestionType } from '../question-type.enum';

const QUESTION_BLOCKS: IQuestionBlock[] = [
  {
    label: 'Client Data Handled',
    questions: [
      {
        props: {
          id: '131ad28c-dc03-46d2-b455-27a28f78792f',
          text: 'What is the most sensitive Client data your system will process?',
          label: 'Data type(s) processed',
          placeholder: 'Models, accounts, PII, PCI, etc.',
        },
        type: QuestionType.TextInput,
      },
    ],
  },
  {
    label: 'Liability',
    questions: [
      {
        props: {
          id: 'b1ba35a0-df01-4e3e-85d6-a413e349b379',
          text: 'Is the Vendor accepting legal responsibility for proper handling of Client data?',
        },
        type: QuestionType.RadioYesNo,
      },
    ],
  },
  {
    label: 'Vendor Security Program',
    questions: [
      {
        props: {
          id: '9277140a-3af0-4ef1-9fce-b16602f59f5c',
          text: 'Does the Vendor have security policies?',
        },
        type: QuestionType.RadioYesNo,
      },
      {
        props: {
          id: '1fff0307-b31f-4f2a-b562-309e22286d2e',
          text: 'Does the Vendor have a designated security leader?',
        },
        type: QuestionType.RadioYesNo,
      },
      {
        props: {
          id: 'ff316392-07a0-4268-b77c-17b7855527a4',
          text: 'If \'Yes\', please provide their contact info and their overall role in the company.',
          label: 'Security leader name',
        },
        type: QuestionType.TextInput,
      },
      {
        props: {
          id: '4169ef03-7670-41b1-b406-32ff593337fc',
          label: 'Security leader email',
        },
        type: QuestionType.TextInput,
      },
      {
        props: {
          id: 'df873bf2-fc2e-4c8a-b2cd-c3372319176e',
          label: 'Security leader role in company',
        },
        type: QuestionType.TextInput,
      },
    ],
  },
  {
    label: 'IAM',
    questions: [
      {
        props: {
          id: '93a22e1d-c510-4db5-8c7b-c268498cb0a9',
          text: 'Does the Vendor limit access to Client data to named accounts protected with multi-factor authentication?',
        },
        type: QuestionType.RadioYesNo,
      },
      {
        props: {
          id: '45522b49-6219-4b07-80ab-3340509c3d01',
          text: 'What is the identity system or systems?',
          label: 'IAM System',
        },
        type: QuestionType.TextInput,
      },
      {
        props: {
          id: '99b996a6-af45-48aa-bdf8-0258e54f59da',
          text: 'Does the Vendor perform monthly user audits?',
        },
        type: QuestionType.RadioYesNo,
      },
      {
        props: {
          id: '74685879-d356-4163-9be9-b0cf671101a1',
          text: 'How do subcontractors and the Vendor’s vendors fit into this answer?',
          label: 'Vendor/Contractor Coverage',
        },
        type: QuestionType.TextInput,
      },
    ],
  },
  {
    label: 'Audit Trails',
    questions: [
      {
        props: {
          id: '2305e6fd-08be-4a79-819b-d1f389efa1d0',
          text: 'Does the Vendor maintain audit trails to identify user access to Client data?',
        },
        type: QuestionType.RadioYesNo,
      },
    ],
  },
  {
    label: 'Security Training',
    questions: [
      {
        props: {
          id: '424fe333-a794-482f-8a38-a9e324c28d36',
          text: 'Does the Vendor train employees on security? (Min: phishing, sharing passwords, data classification.)',
        },
        type: QuestionType.RadioYesNo,
      },
    ],
  },
  {
    label: 'Vulnerability Scanning',
    questions: [
      {
        props: {
          id: '077a6275-3f2b-4be3-9e3a-60edf7401143',
          text: 'Does the Vendor scan for vulnerabilities in their systems (internal and external)?',
        },
        type: QuestionType.RadioYesNo,
      },
      {
        props: {
          id: 'df3be2cf-9ca4-4c76-a01a-9db5769f3642',
          text: 'Please provide a summary of the results.',
          label: 'Number of high severity items',
        },
        type: QuestionType.TextInput,
      },
      {
        props: {
          id: '1a125c05-646a-47cf-8f33-5abcd88e4e9b',
          label: 'Number of medium severity items',
        },
        type: QuestionType.TextInput,
      },
      {
        props: {
          id: 'ce38909b-98c9-4b2a-8391-92ddc1603d6d',
          label: 'Number of low severity items',
        },
        type: QuestionType.TextInput,
      },
    ],
  },
  {
    label: 'Cloud Security',
    questions: [
      {
        props: {
          id: 'b74ff6bc-eb2d-433b-a672-76ca70359033',
          text: 'Does the Vendor system run in the cloud?',
        },
        type: QuestionType.RadioYesNo,
      },
      {
        props: {
          id: '3e8d74c3-8c06-43b5-bc31-93272c0fee28',
          text: 'How are cloud security configurations actively managed?',
          label: 'Cloud security management',
          placeholder: 'Expert help, AWS best practices, specific tools, etc.',
        },
        type: QuestionType.TextInput,
      },
    ],
  },
  {
    label: 'Data Encryption',
    questions: [
      {
        props: {
          id: 'e073290a-7969-4847-9f9f-55956fed96ad',
          text: 'Is Client data encrypted with TLS 1.2+ in transit everywhere in the Vendor system?',
        },
        type: QuestionType.RadioYesNo,
      },
      {
        props: {
          id: 'bd56b0a6-06db-4360-8de8-af764aced14f',
          text: 'Is Client data always encrypted at rest with AES-256 or comparable encryption?',
        },
        type: QuestionType.RadioYesNo,
      },
      {
        props: {
          id: '851ead4c-8c7e-44f5-9988-fff6cea72fef',
          label: 'Mechanism',
          placeholder: 'E.g. AWS TDE',
        },
        type: QuestionType.TextInput,
      },
      {
        props: {
          id: '3c8085b1-97f4-4955-9a07-cb4b506c8b04',
          label: 'Algorithm',
          placeholder: 'E.g. AES-256',
        },
        type: QuestionType.TextInput,
      },
    ],
  },
  {
    label: 'Business Continuity Plan',
    questions: [
      {
        props: {
          id: '174a7782-295f-4e54-bbee-4ff065ff5243',
          text: 'Does the Vendor have a Business Continuity Plan (BCP) in place?',
        },
        type: QuestionType.RadioYesNo,
      },
      {
        props: {
          id: '0af9317d-b9f6-4103-80fb-713541d9405f',
          text: 'What are the Vendor’s Recovery Time Objective and Recovery Point Objective?',
          label: 'Recovery Time Objective',
        },
        type: QuestionType.TextInput,
      },
      {
        props: {
          id: 'd68e17bf-a456-4906-810a-b4f2e1bd6182',
          label: 'Recovery Point Objective',
        },
        type: QuestionType.TextInput,
      },
      {
        props: {
          id: '28507951-7968-4168-bb8a-98e35e36a17f',
          text: 'Has the BCP program been tested?',
        },
        type: QuestionType.RadioYesNo,
      },
    ],
  },
];

export default QUESTION_BLOCKS;
