import  { Typography } from '@mui/material';
import { TypographyProps } from '@mui/material/Typography';
import React from 'react';
import { useLocation } from 'react-router-dom';
import BuildingBlocksImage from '../components/BuildingBlocksImage';
import Page from '../components/Page';
import useAuth from '../services/auth/useAuth';

interface LocationState {
  from?: {
    pathname?: string;
    search?: string;
  };
}

interface LoginFailedInfoProps extends TypographyProps {
  header?: string;
}

const LoginFailedInfo = ({ header, children }: LoginFailedInfoProps) => (
  <Page center>
    <BuildingBlocksImage />
    <Typography variant="h4" mt={2}>
      {header}
    </Typography>
    <Typography maxWidth="35rem" mt={2} mx="auto" px={2} textAlign="left">
      {children}
    </Typography>
  </Page>
);

export enum LoginRouteHash {
  AutoEnrollFailed = '#autoEnrollFailed',
  GenericFailed = '#failed',
  UserBlocked = '#blocked',
  VerifyEmail = '#verify',
}


export const getLoginRoute = (routeHash: LoginRouteHash) => `${TheLoginPage.routePath}${routeHash}`;

function TheLoginPage() {
  const { login } = useAuth();
  const { hash, state } = useLocation();

  switch (hash) {
    case LoginRouteHash.GenericFailed:
      return (
        <LoginFailedInfo header="Hmmm... something went wrong">
          Try logging in again. If you continue to end up here then reach out to us via chat or email.
        </LoginFailedInfo>
      );

    case LoginRouteHash.AutoEnrollFailed:
      return (
        <LoginFailedInfo header="Auto-enroll failed">
          Your account has been created but you may not log in without an invite.
          Please contact your designated security officer and ask to be
          invited to your organization.
        </LoginFailedInfo>
      );

    case LoginRouteHash.UserBlocked:
      return (
        <LoginFailedInfo header="Account disabled">
          Access to your account has been disabled.
          Please contact your designated security officer to reobtain access.
        </LoginFailedInfo>
      );

    case LoginRouteHash.VerifyEmail:
      return (
        <LoginFailedInfo header="Almost there!">
          Check your email for a verification token.
          You'll need to verify your email address before logging in for the first time.
        </LoginFailedInfo>
      );

    default:
      const from = (state as LocationState)?.from;
      const fromUrl = from ? `${from.pathname ?? '/'}${from.search ?? ''}` : undefined;
      login(fromUrl);

      return null;
  }
}

TheLoginPage.routePath = '/login';

export default TheLoginPage;
