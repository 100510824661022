import { Theme, Typography } from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import React from 'react';
import { CaptionedAddButton } from '../components/buttons';

const styles = (theme: Theme) => createStyles({
  iconContainer: {
    textAlign: 'center',
  },
  scoreContainer: {
    textAlign: 'right',
  },
  titleContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  titleText: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
});

export interface StyledDataTableTitleProps extends WithStyles <typeof styles> {
  buttonText?: string;
  isLoading?: boolean;
  title: string;
  onClickNewEntry?: () => void;
}

function StyledDataTableTitle(props: StyledDataTableTitleProps) {
  const {
    classes,
    buttonText = 'New Entry',
    isLoading = false,
    title,
    onClickNewEntry,
  } = props;

  return (
    <div className={classes.titleContainer}>
      <Typography
        variant="h5"
        className={classes.titleText}
      >
        {title}
      </Typography>
      {!isLoading && onClickNewEntry &&
      <CaptionedAddButton
        color="primary"
        variant="text"
        onClick={onClickNewEntry}
      >
        {buttonText}
      </CaptionedAddButton>
      }
    </div>
  );
}

export default withStyles(styles, { withTheme: true })(StyledDataTableTitle);
