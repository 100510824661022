import { FormikProps, FormikValues } from 'formik';
import React from 'react';
import { EditorTextArea } from '../editor/components/EditorTextArea';

interface FormikEditorTextAreaProps<Values extends FormikValues> {
  field: keyof Values;
  formikProps: FormikProps<Values>;
  helperTextStr?: string;
  label: string;
  onChange?: (e: React.ChangeEvent<any>) => void;
}

export default function FormikEditorTextArea<Values extends FormikValues>(props: FormikEditorTextAreaProps<Values>) {
  const { field, formikProps, helperTextStr, label } = props;
  const { setFieldValue, values } = formikProps;
  const fieldStr = field.toString();

  return (
    <EditorTextArea
      helperText={helperTextStr}
      label={label}
      onChange={val => setFieldValue(fieldStr, val)}
      sx={{ ml: 0, mr: 0 }}
      value={values[field] || ''}
    />
  );
}
