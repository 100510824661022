import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import ReactPlayer from 'react-player';
import { Button, Grid, Slider, ToggleButton, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import ClosedCaptionIcon from '@mui/icons-material/ClosedCaption';
import ClosedCaptionDisabledIcon from '@mui/icons-material/ClosedCaptionDisabled';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import VolumeUp from '@mui/icons-material/VolumeUp';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import screenfull from 'screenfull';
import { formatSeconds } from '../helpers';

const DEFAULT_VOLUME = 0.85;
const UPDATE_POLL_SEC = 5;

const styles = theme => ({
  bumpUp: {
    marginTop: '-5px',
  },
  playerWrapper: {
    height: '40vh',
    marginBottom: '8px',
  },
  reactPlayer: {
    background: theme.palette.background,
  },
});

const PlayerButton = withStyles({
  root: {
    minWidth: 0,
    marginLeft: 0,
    marginTop: '-5px',
  },
})(Button);

const TogglePlayerButton = withStyles({
  root: {
    border: 'none',
    minWidth: 0,
    marginLeft: 0,
    marginTop: '-5px',
    padding: '5px 7px',
  },
})(ToggleButton);

class VideoPlayer extends Component {
  state = {
    inErrorRecovery: false,
    isInitializing: true,
    isUserPaused: true,
    isPolling: false,
    lastPoll: moment(),
    light: false,
    maxPlayed: this.props.played || 0,
    maxPlayedSeconds: 0,
    played: this.props.played || 0,
    playedSeconds: 0,
    playing: false,
    toShowCaptions: false,
    volume: DEFAULT_VOLUME,
  };

  classes = this.props.classes;
  _isMounted = false;

  constructor(props) {
    super(props);
    this.logVideoStop = this.logVideoStop.bind(this);
  }

  onError = (error) => {
    let played;

    if (this.player && this.player.getDuration() !== 0) {
      played = this.player.getCurrentTime() / this.player.getDuration();
    } else {
      played = 0;
    }

    this.setState({
      inErrorRecovery: true,
      playing: false,
      played,
      wasPlaying: this.state.playing,
    });

    if (this.props.onError) {
      this.props.onError(error);
    }
  };

  onPlay = () => {
    this.setState({
      isUserPaused: false,
      playing: true,
    });
  };

  onPause = () => {
    this.setState({ playing: false });
    this.logVideoUpdate();
  };

  onProgress = progressState => {
    if (!this.state.playing) {
      return;
    }

    const { played, playedSeconds } = progressState;
    const maxPlayed = Math.max(played, this.state.maxPlayed);
    const maxPlayedSeconds = Math.max(playedSeconds, this.state.maxPlayedSeconds);

    if (moment().diff(this.state.lastPoll, 'seconds') >= UPDATE_POLL_SEC) {
      this.logVideoUpdate();
    }

    this.setState({
      maxPlayed,
      maxPlayedSeconds,
      played,
      playedSeconds,
    });
  };

  onReady = (player) => {
    if (this.props.captionUrl) {
      this.updateToShowCaptions(this.state.toShowCaptions);
    }

    this.player = player;
    // After the initial 'onReady', seek to the user's last location.
    // ('onReady' seems to also be called after every 'onSeek'.)
    if (this.state.isInitializing) {
      const playedSeconds = this.state.played * this.player.getDuration();
      this.player.seekTo(this.state.played, 'fraction');
      this.setState({
        isInitializing: false,
        maxPlayedSeconds: playedSeconds,
        playedSeconds,
      });
    } else if (this.state.inErrorRecovery) {
      this.player.seekTo(this.state.played, 'fraction');
      this.setState({ inErrorRecovery: false, playing: this.state.wasPlaying, wasPlaying: undefined });
    }
  };

  onClickPlayPause = () => {
    this.setState({
      isUserPaused: !this.state.isUserPaused,
      playing: !this.state.playing,
    });
  };

  onChangeScrubSlider = (event, newValueInSeconds) => {
    // This just changes the displayed position in the slider, without actually seeking.
    if (this.state.playing) {
      this.setState({ playing: false });
    }
    const newPlayedSeconds = Math.min(newValueInSeconds, this.state.maxPlayedSeconds);
    if (Number.isFinite(newPlayedSeconds)) {
      this.setState({ playedSeconds: newPlayedSeconds });
    }
  };

  onCommitScrubSlider = (event, newValueInSeconds) => {
    // Seek once the user is done scrubbing.
    const newPlayedSeconds = Math.min(newValueInSeconds, this.state.maxPlayedSeconds);
    if (Number.isFinite(newPlayedSeconds)) {
      this.player.seekTo(newPlayedSeconds, 'seconds');
    }
    // Don't auto-play if user pauses then seeks:
    if (!this.state.isUserPaused) {
      this.setState({ playing: true });
    }
  };

  onToggleCaptions = () => {
    const toShowCaptions = !this.state.toShowCaptions;
    this.updateToShowCaptions(toShowCaptions);
    this.setState({ toShowCaptions });
  };

  onClickFullscreen = async () => {
    await screenfull.request(findDOMNode(this.player));
  };

  logVideoStop(event) {
    if (event) {
      event.preventDefault();
      event.returnValue = '';
    }

    this.logVideoUpdate();
  }

  logVideoUpdate() {
    if (!this.props.onUpdateVideo || this.state.isPolling) {
      return;
    }

    if (this._isMounted) {
      this.setState({ isPolling: true });
    }

    const updateInfo = {
      maxPlayed: this.state.maxPlayed,
      played: this.state.played,
    };

    this.props.onUpdateVideo(updateInfo)
      .then(() => {
        if (this._isMounted) {
          this.setState({ lastPoll: moment() });
        }
      })
      .finally(() => this._isMounted && this.setState({ isPolling: false }));
  }

  componentDidMount() {
    this._isMounted = true;
    window.addEventListener('beforeunload', this.logVideoStop);
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('beforeunload', this.logVideoStop);
    this.logVideoStop();
  }

  updateToShowCaptions = (toShowCaptions) => {
    const trackEl = document.querySelector('track');
    if (trackEl && trackEl.track) {
      trackEl.track.mode = toShowCaptions ? 'showing' : 'hidden';
    }
  };

  render() {
    const { playing, light } = this.state;

    return (
      <>
        <div className={this.classes.playerWrapper}>
          <ReactPlayer
            className={this.classes.reactPlayer}
            width="100%"
            height="100%"
            url={this.props.url}
            config={{ file: {
              attributes: {
                crossOrigin: 'anonymous',
              },
              tracks: !this.props.captionUrl ? [] : [
                { kind: 'captions', src: this.props.captionUrl, srcLang: 'en', default: true },
              ]
            }}}
            playing={playing}
            controls={false}
            light={light}
            loop={false}
            onReady={this.onReady}
            onPlay={this.onPlay}
            onProgress={this.onProgress}
            onPause={this.onPause}
            onError={this.onError}
            volume={this.state.volume}
          />
        </div>
        <Grid container justifyContent="space-between" spacing={2}>
          <Grid item xs={1}>
            <PlayerButton
              onClick={this.onClickPlayPause}
            >
              {playing ? <PauseIcon /> : <PlayArrowIcon />}
            </PlayerButton>
          </Grid>
          <Grid item xs={6} container alignItems="center">
            <Grid item xs>
              <Slider
                min={0}
                max={this.player && this.player.getDuration()}
                marks={[ { value: this.state.maxPlayedSeconds } ]}
                size="small"
                value={this.state.playedSeconds}
                onChange={this.onChangeScrubSlider}
                onChangeCommitted={this.onCommitScrubSlider}
              />
            </Grid>
            <Grid item xs="auto">
              <Typography
                className={this.classes.bumpUp}
                variant="body2"
              >
                {formatSeconds(this.state.playedSeconds)} / {formatSeconds(this.player && this.player.getDuration())}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={3} container alignItems="center" spacing={1}>
            <Grid item>
              <VolumeUp />
            </Grid>
            <Grid item xs>
              <Slider
                aria-labelledby="continuous-slider"
                min={0}
                max={1}
                size="small"
                step={0.02}
                value={this.state.volume}
                onChange={(_, newValue) => this.setState({ volume: newValue })}
              />
            </Grid>
          </Grid>
          <Grid item xs={2}>
            {!this.props.captionUrl ? (
              <PlayerButton disabled>
                <ClosedCaptionDisabledIcon />
              </PlayerButton>
            ) : (
              <TogglePlayerButton
                color="primary"
                selected={this.state.toShowCaptions}
                value="captions"
                onClick={this.onToggleCaptions}
              >
                <ClosedCaptionIcon />
              </TogglePlayerButton>
            )}
            <PlayerButton
              style={{ float: 'right' }}
              onClick={this.onClickFullscreen}
            >
              <FullscreenIcon />
            </PlayerButton>
          </Grid>
        </Grid>
      </>
    );
  }
}

VideoPlayer.propTypes = {
  captionUrl: PropTypes.string,
  onError: PropTypes.func,
  onUpdateVideo: PropTypes.func,
  classes: PropTypes.object,
  played: PropTypes.number,
  url: PropTypes.string.isRequired,
};

export default withStyles(styles, { withTheme: true })(VideoPlayer);
