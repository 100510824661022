import { TableCell, TableRow } from '@mui/material';
import { createStyles, makeStyles, WithStyles } from '@mui/styles';
import HelpIcon from '@mui/icons-material/Help';
import React, { useEffect, useState } from 'react';
import { IRiskDto } from '../../../backend/src/risk/interfaces';
import DataTableTitleWithButton from '../components/DataTableTitleWithButton';
import RiskEditDialog, { rankMap, RiskRankStr } from '../components/dialogs/RiskEditDialog';
import RiskDetails, { riskStatusMap } from '../components/RiskDetails';
import { showErrorResultBar } from '../components/ResultSnackbar';
import SpioDataTable, { SortDirection, SpioDataTableColumn } from '../components/SpioDataTable';
import { formatDate, truncateString } from '../helpers';
import alphaHCircleImg from '../images/alpha-h-circle.svg';
import alphaLCircleImg from '../images/alpha-l-circle.svg';
import alphaMCircleImg from '../images/alpha-m-circle.svg';
import API from '../services/ApiService';

const styles = createStyles({
  iconContainer: {
    paddingLeft: '1rem',
    textAlign: 'left',
  },
  scoreContainer: {
    paddingLeft: '1rem',
    textAlign: 'left',
  },
});

const useStyles = makeStyles(styles);

export interface IRiskRankStrInfo {
  icon: JSX.Element;
  score: number;
}

// TODO: Prefer to use the new column option 'filterOptions.renderValue'
export const rankStrMap: { [key in RiskRankStr]: IRiskRankStrInfo } = {
  High: {
    icon: <img src={alphaHCircleImg} width="24" height="24" alt="high" />,
    score: rankMap.high.score,
  },
  Medium: {
    icon: <img src={alphaMCircleImg} width="24" height="24" alt="medium" />,
    score: rankMap.medium.score,
  },
  Low: {
    icon: <img src={alphaLCircleImg} width="24" height="24" alt="low" />,
    score: rankMap.low.score,
  },
  Unknown: {
    icon: <HelpIcon color="disabled" />,
    score: rankMap.unknown.score,
  },
};

const scoreCompare = (order: SortDirection) => {
  // Sort probability and impact based on a score.
  const reverseFactor = (order === 'desc' ? 1 : -1);

  return (a: { data: any }, b: { data: any }) => {
    const valA = a.data;
    const valB = b.data;
    const scoreA = rankStrMap[valA as RiskRankStr]?.score || 0;
    const scoreB = rankStrMap[valB as RiskRankStr]?.score || 0;
    const sortFactor = (scoreA < scoreB ? -1 : 1);

    return reverseFactor * sortFactor;
  };
};

const getTableColumns = (tableData: ITableDatum[], { classes }: WithStyles<typeof styles>): SpioDataTableColumn[] => [
  {
    name: 'id',
    label: 'ID',
    options: {
      display: 'false',
      filter: false,
    },
  },
  {
    name: 'risk',
    label: 'Risk',
    options: {
      customFilterListOptions: { render: v => `Risk: ${v}` },
      filter: false,
    },
  },
  {
    name: 'createdAtStr',
    label: 'Date Added',
    options: {
      display: 'false',
      filter: false,
    },
  },
  {
    name: 'statusUpdatedAtStr',
    label: 'Last Status Update',
    options: {
      customFilterListOptions: { render: v => `Last Status Update: ${v}` },
      display: 'false',
    },
  },
  {
    name: 'statusStr',
    label: 'Status',
  },
  {
    name: 'categoryStr',
    label: 'Category',
    options: {
      customBodyRenderLite: dataIndex => truncateString(tableData[dataIndex]?.category || ''),
      customFilterListOptions: { render: v => `Category: ${v}` },
    },
  },
  {
    name: 'details',
    label: 'Details',
    options: {
      customBodyRenderLite: dataIndex => truncateString(tableData[dataIndex]?.details || ''),
      display: 'false',
      filter: false,
    },
  },
  {
    name: 'owner',
    label: 'Owner',
    options: {
      customFilterListOptions: { render: v => `Owner: ${v}` },
    },
  },
  {
    name: 'probabilityStr',
    label: 'Probability',
    options: {
      customBodyRenderLite: dataIndex => (
        <div className={classes.iconContainer}>
          {rankStrMap[tableData[dataIndex]?.probabilityStr as RiskRankStr]?.icon}
        </div>
      ),
      customFilterListOptions: { render: v => `Probability: ${v}` },
      sortCompare: scoreCompare,
    },
  },
  {
    name: 'impactStr',
    label: 'Impact',
    options: {
      customBodyRenderLite: dataIndex => (
        <div className={classes.iconContainer}>
          {rankStrMap[tableData[dataIndex]?.impactStr as RiskRankStr]?.icon}
        </div>
      ),
      customFilterListOptions: { render: v => `Impact: ${v}` },
      sortCompare: scoreCompare,
    },
  },
  {
    name: 'score',
    label: 'Score',
    options: {
      customBodyRenderLite: dataIndex => (
        <div className={classes.scoreContainer}>
          {tableData[dataIndex]?.score}
        </div>
      ),
      filter: false,
    },
  },
  {
    name: 'mitigationPlan',
    label: 'Mitigation Plan',
    options: {
      customBodyRenderLite: dataIndex => truncateString(tableData[dataIndex]?.mitigationPlan || ''),
      customFilterListOptions: { render: v => `Mitigation Plan: ${v}` },
      display: 'false',
      filter: false,
    },
  },
  {
    name: 'responsePlan',
    label: 'Response Plan',
    options: {
      customBodyRenderLite: dataIndex => truncateString(tableData[dataIndex]?.responsePlan || ''),
      customFilterListOptions: { render: v => `Response Plan: ${v}` },
      display: 'false',
      filter: false,
    },
  },
];

interface ITableDatum extends IRiskDto {
  createdAtStr: string;
  impactStr: string;
  probabilityStr: string;
  statusStr: string;
  statusUpdatedAtStr: string;
}

function TheRiskRegisterPage() {
  const classes = useStyles();

  const [ isDialogOpen, setIsDialogOpen ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ risks, setRisks ] = useState<IRiskDto[]>([]);
  const [ tableData, setTableData ] = useState<ITableDatum[]>([]);

  useEffect(() => {
    setIsLoading(true);

    API.get('risk')
      .then(res => setRisks((res.data && res.data.data) || []))
      .catch(() => {
        showErrorResultBar('Unexpected error loading risk register data');
      })
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    setTableData(risks.map(d => Object({
      ...d,
      categoryStr: d.category ?? '',
      createdAtStr: formatDate(d.createdAt),
      impactStr: rankMap[d.impact].text,
      probabilityStr: rankMap[d.probability].text,
      statusStr: riskStatusMap[d.status] || 'Unknown',
      statusUpdatedAtStr: formatDate(d.statusUpdatedAt),
    })));
  }, [ risks ]);

  const handleArchive = (selectedIdx: number) => () => {
    const updatedData = risks.slice();
    updatedData.splice(selectedIdx, 1);
    setRisks(updatedData);
  };

  const handleCreate = (risk: IRiskDto) => {
    const updatedData = [ risk ].concat(risks);
    setRisks(updatedData);
  };

  const handleUpdate = (selectedIdx: number) => (risk: IRiskDto) => {
    const updatedData = risks.slice();
    updatedData[selectedIdx] = risk;
    setRisks(updatedData);
  };

  return (<>
    <SpioDataTable
      title={<DataTableTitleWithButton
        onButtonClick={() => setIsDialogOpen(true)}
        title="Risk Register"
      />}
      columns={getTableColumns(tableData, { classes })}
      data={tableData}
      options={{
        print: false,
        filterType: 'multiselect',
        selectableRows: 'none',
        textLabels: {
          body: {
            noMatch: isLoading ? 'Loading...' : 'No records found',
            toolTip: 'Sort',
          },
        },
        expandableRows: true,
        expandableRowsOnClick: true,
        renderExpandableRow: (rowData, rowMeta) => {
          const colSpan = rowData.length + 1;
          const myData = risks[rowMeta.dataIndex];

          return myData ? (
            <TableRow>
              <TableCell colSpan={colSpan}>
                <RiskDetails
                  riskData={myData}
                  onArchive={handleArchive(rowMeta.dataIndex)}
                  onUpdate={handleUpdate(rowMeta.dataIndex)}
                />
              </TableCell>
            </TableRow>
          ) : null;
        },
      }}
    />
    <RiskEditDialog
      open={isDialogOpen}
      riskData={null}
      onClose={() => setIsDialogOpen(false)}
      onUpdate={handleCreate}
    />
  </>);
}

TheRiskRegisterPage.requiredAuthZ = {
  tier: 2,
  permission: 'risk_register',
};
TheRiskRegisterPage.routePath = '/risk-register';
TheRiskRegisterPage.title = 'Risk Register';

export default TheRiskRegisterPage;
