import { Paper, PaperProps, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import classNames from 'classnames';
import * as React from 'react';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  center: {
    textAlign: 'center',
  },
}));

export interface PageProps extends PaperProps {
  center?: boolean;
}

export default function Page({ center, children, ...other }: PageProps) {
  const classes = useStyles();

  return (
    <Paper className={classNames(classes.root, center && classes.center)} {...other}>
      {children}
    </Paper>
  );
}
