import React, { useEffect, useState } from 'react';
import { blueGrey, green } from '@mui/material/colors';
import { defaults, HorizontalBar } from 'react-chartjs-2';
import { ICoverageReportWidgetDto } from '../../../../backend/src/user/interfaces';

defaults.global.defaultFontFamily = '"Roboto", "Helvetica", "Arial", sans-serif';

interface IChartDataInput {
  dataCompleted: number[];
  dataRemaining: number[];
  labels: string[];
}

interface IChartDataSet {
  label: string;
  data: number[];
  [key: string]: any;
}

interface IChartData {
  labels: string[];
  datasets: IChartDataSet[];
}

const rawDataToChartData = (rawData: ICoverageReportWidgetDto): IChartDataInput => {
  const total = rawData.total ?? 0;
  const data = rawData.data ?? [];

  const labels = data.map(el => el.name);
  const dataCompleted = data.map(el => el.count);
  const dataRemaining = data.map(el => total < el.count ? 0 : total - el.count);

  return {
    labels,
    dataCompleted,
    dataRemaining,
  };
};

const getChartDataObject = ({ dataCompleted, dataRemaining, labels }: IChartDataInput, completedLabel: string = 'Completed'): IChartData => {
  return {
    labels,
    datasets: [
      {
        label: completedLabel,
        backgroundColor: green[500],
        borderColor: green[800],
        borderWidth: 1,
        hoverBackgroundColor: green[800],
        hoverBorderColor: green[800],
        stack: '1',
        data: dataCompleted,
      },
      {
        label: 'Remaining',
        backgroundColor: blueGrey[100],
        borderColor: blueGrey[300],
        borderWidth: 1,
        hoverBackgroundColor: blueGrey[300],
        hoverBorderColor: blueGrey[300],
        stack: '1',
        data: dataRemaining,
      },
    ],
  };
};

const chartOptions = {
  scales: {
    xAxes: [{
      stacked: true,
      ticks: {
        beginAtZero: true,
        precision: 0,
      },
    }],
    yAxes: [{
      stacked: true,
      ticks: {
        fontColor: 'rgba(0, 0, 0, 0.87)',
        fontSize: 13,
      },
    }],
  },
};

export interface HorizontalBarCoverageChartProps {
  aspectRatio?: number;
  completedLabel?: string;
  data: ICoverageReportWidgetDto;
}

export default function HorizontalBarCoverageChart({ aspectRatio = 2, completedLabel, data }: HorizontalBarCoverageChartProps) {
  const [ chartData, setChartData ] = useState<IChartData>({ labels: [], datasets: [] });

  useEffect(() => {
    setChartData(getChartDataObject(rawDataToChartData(data), completedLabel));
  }, [ completedLabel, data ]);

  return (
    <HorizontalBar
      data={chartData}
      options={chartOptions}
      height={150}
      width={150 * aspectRatio}
    />
  );
}
