import API from '../../services/ApiService';
import { formatDate } from '../../helpers';
import { IUserAuditHistoryDto } from '../../../../backend/src/user-audit/interfaces';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import SpioDataTable, { SpioDataTableColumn } from '../SpioDataTable';
import { showErrorResultBar } from '../ResultSnackbar';

const auditHistoryTableColumns: SpioDataTableColumn[] = [
  {
    name: 'id',
    label: 'ID',
    options: {
      display: 'false',
      filter: false,
    },
  },
  {
    name: 'provider',
    label: 'Provider',
    options: {
      customFilterListOptions: { render: v => `Provider: ${v}` },
    },
  },
  {
    name: 'tenant',
    label: 'Tenant Name',
    options: {
      customFilterListOptions: { render: v => `Tenant: ${v}` },
    },
  },
  {
    name: 'syncedAtStr',
    label: 'Sync Date',
    options: {
      customFilterListOptions: { render: v => `Sync Date: ${v}` },
    },
  },
  {
    name: 'confirmedAtStr',
    label: 'Confirm Date',
    options: {
      customFilterListOptions: { render: v => `Confirm Date: ${v}` },
    },
  },
  {
    name: 'numAdded',
    label: 'Added',
    options: {
      customFilterListOptions: { render: v => `Num Added: ${v}` },
      filter: false,
    },
  },
  {
    name: 'numRemoved',
    label: 'Removed',
    options: {
      customFilterListOptions: { render: v => `Num Removed: ${v}` },
      filter: false,
    },
  },
  {
    name: 'numChanged',
    label: 'Changed',
    options: {
      customFilterListOptions: { render: v => `Num Changed: ${v}` },
      filter: false,
    },
  },
  {
    name: 'confirmedByName',
    label: 'Confirming User',
    options: {
      customFilterListOptions: { render: v => `Confirming User: ${v}` },
    },
  },
];

interface ITableDatum extends Pick<IUserAuditHistoryDto, 'confirmedByName' | 'id' | 'provider' | 'tenant'> {
  confirmedAtStr: string;
  numAdded: number | null;
  numChanged: number | null;
  numRemoved: number | null;
  syncedAtStr: string;
}

export default function UserAuditHistoryTable() {
  const [ isLoading, setIsLoading ] = useState(false);
  const [ tableData, setTableData ] = useState<ITableDatum[]>([]);

  useEffect(() => {
    setIsLoading(true);

    API
      .get('userAudit/history')
      .then((res) => {
        const data: IUserAuditHistoryDto[] = res.data?.data || [];
        setTableData(data.map(d => Object({
          confirmedAtStr: formatDate(d.confirmedAt) ?? '',
          confirmedByName: d.confirmedByName ?? '',
          id: d.id,
          numAdded: d.deltaSummary?.numAdded ?? null,
          numChanged: d.deltaSummary?.numChanged ?? null,
          numRemoved: d.deltaSummary?.numRemoved ?? null,
          provider: d.provider?.toUpperCase() ?? '',
          syncedAtStr: formatDate(d.syncedAt) ?? '',
          tenant: d.tenant,
        })));
      })
      .catch(err => {
        Sentry.captureException(err);
        showErrorResultBar('An error occurred while loading user audit history data.');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  return (
      <SpioDataTable
        title={'User Audit History'}
        columns={auditHistoryTableColumns}
        data={tableData}
        options={{
          textLabels: {
            body: {
              noMatch: isLoading ? 'Loading...' : 'No User Audit History found',
              toolTip: 'Sort',
            },
          },
          elevation: 1,
          downloadOptions: {
            filename: `UserAudit_History_${moment().format('YYYYMMDD')}.csv`,
          },
          filterType: 'multiselect',
          print: false,
          selectableRows: 'none',
          sort: true,
          sortOrder: {
            name: 'name',
            direction: 'asc',
          },
        }}
      />
  );
}
