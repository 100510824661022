import {
  Button,
  DialogActions,
  DialogContent,
  DialogProps,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as Sentry from '@sentry/react';
import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { ITaskDto, ITasksResponse } from '../../../../backend/src/task/interfaces';
import { preventNonNumericInput } from '../../helpers';
import API from '../../services/ApiService';
import { SaveButton } from '../buttons';
import { showErrorResultBar, showSuccessResultBar } from '../ResultSnackbar';
import StyledDialogTitle from '../StyledDialogTitle';
import ResponsiveDialog from './ResponsiveDialog';

const useStyles = makeStyles({
  percentInput: {
    'textAlign': 'right',
    // Hides the numeric up/down native control within the input text field:
    '-moz-appearance': 'textfield',
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      'margin': '0',
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      'margin': '0',
    },
  },
  textField: {
    paddingRight: '1rem',
  },
});

const FormSchema = Yup.object().shape({
  percentComplete: Yup
    .number()
    .integer('The percentage must be an integer.')
    .min(0, 'The percentage must be at least zero.')
    .max(100, 'The maximum is 100%.')
    .label('Percent Complete')
    .required('Required'),
});

export interface TaskPercentCompleteDialogProps extends DialogProps {
  onClose: () => void;
  onUpdateTask: (updatedTaskInfo: ITasksResponse) => void;
  task: ITaskDto;
}

export default function TaskPercentCompleteDialog(props: TaskPercentCompleteDialogProps) {
  const classes = useStyles();
  const { onClose, onUpdateTask, open, task } = props;

  const handleSave = async (formValues: { percentComplete: number }) => {
    try {
      await API.put(`task/${task.id}`, formValues);
      onUpdateTask(formValues);
      showSuccessResultBar('Task\'s percent complete updated.');
    } catch (err: any) {
      Sentry.captureException(err);
      showErrorResultBar('Unexpected error while updating the task.');
    } finally {
      onClose();
    }
  };

  return (
    <ResponsiveDialog
      disableBackdropClick={true}
      fullWidth
      open={open}
      onClose={onClose}
    >
      <StyledDialogTitle
        onClose={onClose}
      >
        Task Percent Complete
      </StyledDialogTitle>
      <Formik
        enableReinitialize
        initialValues={{
          percentComplete: task.percentComplete || 0,
        }}
        validationSchema={FormSchema}
        onSubmit={async (values, { setSubmitting }) => {
          await handleSave(values);
          setSubmitting(false);
        }}
        onReset={_ => {
          onClose();
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleReset,
          handleSubmit,
          isSubmitting,
        }) => (
          <>
            <DialogContent>
              <Grid container>
                <Grid item xs={8}>
                  <Typography variant="body1">
                    Provide an estimate for the amount of progress you've made toward completing this task.
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    autoFocus
                    className={classes.textField}
                    fullWidth
                    id="percent-complete"
                    label="Percent Complete"
                    name="percentComplete"
                    type="number"
                    value={values.percentComplete}
                    InputProps={{
                      classes: {
                        input: classes.percentInput,
                      },
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onKeyPress={preventNonNumericInput}
                    onKeyUp={(e) => e.keyCode === 13 ? handleSubmit() : null}
                    helperText={errors.percentComplete}
                    error={!!errors.percentComplete}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                size="small"
                disabled={isSubmitting}
                onClick={handleReset}
              >
                Cancel
              </Button>
              <SaveButton
                disabled={isSubmitting || Object.keys(errors).length > 0}
                onClick={handleSubmit}
              />
            </DialogActions>
          </>
        )}
      </Formik>
    </ResponsiveDialog>
  );
}
