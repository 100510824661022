import { RedirectLoginOptions, useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import globToRegExp from 'glob-to-regexp';
import IdTokenVerifier from 'idtoken-verifier';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IUserAppMeta } from '../../../../backend/src/auth/interfaces';
import { IOrgDto } from '../../../../backend/src/org/interfaces';
import { IRequiredAuthZ } from '../../pages/interfaces';
import { APP_BASE_URL, CUSTOM_CLAIM_PREFIX, DEFAULT_LOGIN_REDIRECT_URL } from '../../constants';
import { STORAGE_KEYS, clearStorage, setStorageValue } from '../BrowserStorageService';
import API, { initAccessTokenInterceptor } from '../ApiService';
import AuthContext, { AuthContextInterface } from './authContext';
import { getLoginRoute, LoginRouteHash } from '../../pages/TheLoginPage';

export interface AuthProviderProps {
  children?: React.ReactNode;
}

const AuthProvider = (props: AuthProviderProps) => {
  const { children } = props;
  const Auth0Context = useAuth0();
  const {
    error,
    isAuthenticated,
    getAccessTokenSilently,
    isLoading: isAuth0Loading,
    loginWithRedirect,
    logout: auth0Logout,
    user,
  } = Auth0Context;
  const [ currentOrg, setCurrentOrg ] = useState<IOrgDto>();
  const [ isEmailFree, setIsEmailFree ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ isRegistered, setIsRegistered ] = useState(false);
  const [ orgId, setOrgId ] = useState<string | null>();
  const [ orgTier, setOrgTier ] = useState(0);
  const [ permissions, setPermissions ] = useState<string[]>([]);
  const [ primaryOrgId, setPrimaryOrgId ] = useState<string | null>();
  const [ userId, setUserId ] = useState<string | null>(null);

  const [ isRefreshing, setIsRefreshing ] = useState(true);

  const login = useCallback((redirectUrl = DEFAULT_LOGIN_REDIRECT_URL, options?: RedirectLoginOptions) => {
    setStorageValue(STORAGE_KEYS.LOGIN_REDIRECT_URL, redirectUrl);
    loginWithRedirect(options);
  }, [ loginWithRedirect ]);

  const logout = useCallback((redirectPath = '/') => {
    clearStorage();
    auth0Logout({
      client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
      returnTo: `${APP_BASE_URL}${redirectPath}`,
    });
  }, [ auth0Logout ]);

  const setSessionVariables = useCallback(async (rawAccessToken: string) => {
    setIsLoading(true);

    let theOrg: IOrgDto | undefined;
    const { payload: decodedAccessToken } = new IdTokenVerifier({
      issuer: `${process.env.REACT_APP_AUTH_DOMAIN}`,
      audience: `${process.env.REACT_APP_AUTH_API_IDENTIFIER}`,
    }).decode(rawAccessToken);

    const appMeta: IUserAppMeta = decodedAccessToken[`${CUSTOM_CLAIM_PREFIX}appMetadata`] ?? {};
    const userEmail = decodedAccessToken[`${CUSTOM_CLAIM_PREFIX}email`] ?? undefined;
    const userId = decodedAccessToken.sub ?? null;
    const userName = decodedAccessToken[`${CUSTOM_CLAIM_PREFIX}name`] ?? undefined;
    const thePrimaryOrgId = appMeta.orgId ?? null;
    const theOrgId = appMeta.activeOrgId ?? thePrimaryOrgId;
    if (theOrgId) {
      const res = await API.get(`org/${theOrgId}`);
      theOrg = res?.data?.data;
    }
    const newTier = theOrg?.tier ?? 0;

    setCurrentOrg(theOrg);
    setIsEmailFree(!!appMeta.isEmailFree);
    setIsRegistered(newTier > 0);
    setOrgId(theOrgId);
    setOrgTier(newTier);
    setPermissions(decodedAccessToken['permissions'] ?? []);
    setPrimaryOrgId(appMeta.orgId ?? null);
    setUserId(decodedAccessToken.sub ?? null);

    Sentry.configureScope(scope => {
      scope.setUser({
        id: userId,
        email: userEmail,
        name: userName,
        orgId: theOrgId,
      });
    });

    setIsLoading(false);
  }, []);

  const initSession = useCallback(async () => {
    if (error) {
      switch (error.message) {
        case 'email_not_verified':
          logout(getLoginRoute(LoginRouteHash.VerifyEmail));
          return;

        case 'user is blocked':
          logout(getLoginRoute(LoginRouteHash.UserBlocked));
          return;

        default:
          logout(getLoginRoute(LoginRouteHash.GenericFailed));
          return;
      }
    } else if (isAuthenticated) {
      const token = await getAccessTokenSilently();
      await setSessionVariables(token);
    }

    setIsLoading(false);
  }, [ error, logout, getAccessTokenSilently, isAuthenticated, setSessionVariables ]);

  const renewSession = useCallback(async (ignoreCache: boolean = true) => {
    const token = await getAccessTokenSilently({ ignoreCache });
    await setSessionVariables(token);
  }, [ getAccessTokenSilently, setSessionVariables ]);

  const isGranted = useCallback((requiredAuthZ: IRequiredAuthZ = {}, isGlobPermissionMatch: boolean = false): boolean => {
    const { permission: requiredPermission, tier: requiredTier = 0 } = requiredAuthZ;
    const hasRequiredTier = orgTier >= requiredTier;
    let hasRequiredPermission = true;

    if (requiredPermission) {
      hasRequiredPermission = isGlobPermissionMatch ?
        permissions.some(p => globToRegExp(requiredPermission).test(p)) :
        permissions.includes(requiredPermission);
    }

    return hasRequiredPermission && hasRequiredTier;
  }, [ orgTier, permissions ]);

  // Initialize the API's request interceptor:
  useEffect(() => {
    initAccessTokenInterceptor(getAccessTokenSilently);
  }, [ getAccessTokenSilently ]);

  // Initialize the session variables upon refresh:
  useEffect(() => {
    if (!isAuth0Loading && isRefreshing) {
      setIsRefreshing(false);
      initSession().catch(Sentry.captureException);
    }
  }, [ initSession, isAuth0Loading, isRefreshing ]);

  const contextValue = useMemo<AuthContextInterface>(() => {
    return {
      currentOrg,
      isAuthenticated,
      isEmailFree,
      isGlobalVendorReader: isGranted({ permission: 'vendor_global:read' }),
      isGranted,
      isLoading: isLoading || isAuth0Loading,
      isOrgManager: isGranted({ tier: 1, permission: 'org' }), // this is only a proxy
      isPolicyManager: isGranted({ permission: 'policies:manage' }),
      isRegistered,
      login,
      logout,
      orgId,
      primaryOrgId,
      renewSession,
      user,
      userId,
    };
  }, [
    currentOrg,
    isAuth0Loading,
    isAuthenticated,
    isEmailFree,
    isGranted,
    isLoading,
    isRegistered,
    login,
    logout,
    orgId,
    primaryOrgId,
    renewSession,
    user,
    userId,
  ]);

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
