import { DialogProps, Typography } from '@mui/material';
import * as Sentry from '@sentry/react';
import { sortBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { IReportingPeriodDto } from '../../../../backend/src/reporting-period/interfaces';
import API from '../../services/ApiService';
import { formatDate } from '../../helpers';
import { ConfirmationDialog } from '../dialogs';
import { getDateRangeStr } from '../org/OrgReportingPeriods';
import { showErrorResultBar } from '../ResultSnackbar';
import { getDefaultLastPeriodName } from './ReportingPeriodCreateDialog';

export interface ReportingPeriodDeleteDialogProps extends DialogProps {
  reportingPeriod: IReportingPeriodDto;
  reportingPeriods: IReportingPeriodDto[];
  onClose: () => void;
  onDelete: (deletedIds: string[], updatedPeriods: IReportingPeriodDto[]) => void;
}

export default function ReportingPeriodDeleteDialog(props: ReportingPeriodDeleteDialogProps) {
  const { onClose, onDelete, open, reportingPeriod, reportingPeriods } = props;
  const [ dateRangeStr, setDateRangeStr ] = useState('');
  const [ periodsToDelete, setPeriodsToDelete ] = useState<IReportingPeriodDto[]>([]);
  const [ updatedName, setUpdatedName ] = useState<string | null>(null);

  useEffect(() => {
    const sortedPeriods = sortBy(reportingPeriods, [ 'startDate' ]);
    const currPeriodIdx = sortedPeriods.findIndex(p => p.id === reportingPeriod.id);
    if (currPeriodIdx === -1) return;

    let thePeriodsToDelete: IReportingPeriodDto[] = [];
    let theUpdatedName: string | null = null;

    if (sortedPeriods.length === 2) {
      // If there is only one date range, then delete both dates
      thePeriodsToDelete = reportingPeriods.slice();
    } else if (sortedPeriods.length > 2 && currPeriodIdx === sortedPeriods.length - 2) {
      // Else if we're deleting the final period, only delete the final end date
      // and update the name of the new final end date
      thePeriodsToDelete = [ sortedPeriods[currPeriodIdx + 1] ];
      theUpdatedName = getDefaultLastPeriodName(sortedPeriods[currPeriodIdx - 1].name);
    } else {
      thePeriodsToDelete = [ sortedPeriods[currPeriodIdx] ];
    }

    setDateRangeStr(getDateRangeStr(sortedPeriods, currPeriodIdx));
    setPeriodsToDelete(sortBy(thePeriodsToDelete, [ 'startDate' ]));
    setUpdatedName(theUpdatedName);
  }, [ reportingPeriod, reportingPeriods ]);

  const handleConfirmDelete = async (confirmed: boolean) => {
    try {
      let deletedIds: string[] = [];
      let updatedPeriods: IReportingPeriodDto[] = [];

      if (confirmed) {
        deletedIds = periodsToDelete.map(p => p.id);
        await Promise.all(deletedIds.map(id => API.delete(`reportingPeriod/${id}`)));

        if (updatedName !== null && !deletedIds.includes(reportingPeriod.id)) {
          updatedPeriods = [ (await API.patch(`reportingPeriod/${reportingPeriod.id}`, {
            name: updatedName,
          })).data?.data ];
        }
      }
      onDelete(deletedIds, updatedPeriods);
      onClose();
    } catch (err: any) {
      const errorMsg = err.response?.data?.error ?? 'Unexpected error deleting reporting period';
      showErrorResultBar(errorMsg);
      Sentry.captureException(err);
    }
  };

  return (
    <ConfirmationDialog
      onResponse={handleConfirmDelete}
      open={open}
      title={`Remove the period ${dateRangeStr}?`}
    >
      <>
        <Typography
          component="span"
          display="block"
          gutterBottom
        >
          Users will no longer be able to filter on this reporting
          period but no data associated with this date range will be lost.
        </Typography>
        {reportingPeriods.length > 2 ? (
        <Typography
          component="span"
          display="block"
        >
          The date{periodsToDelete.length > 1 ? 's' : ''} {periodsToDelete.map(p => formatDate(p.startDate)).join(' and ')} will
          be removed from the timeline and the bracketing date ranges will be merged.
        </Typography>
        ) : null}
      </>
    </ConfirmationDialog>
  );
}
