import React from 'react';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { IQuestionComponentProps } from './interfaces';
import { questionComponentStyles } from './question-component.styles';

function RadioYesNoQuestion({ classes, formikProps, question }: IQuestionComponentProps) {
  const { values, handleChange } = formikProps;

  return (
    <>
      <Typography className={classes.questionTextContainer}>
        {question.text}
      </Typography>
      <div className={classes.inputContainer}>
        <FormControl
          component="fieldset"
          margin="dense"
        >
          <RadioGroup
            id={question.id}
            name={question.id}
            value={values[question.id]}
            onChange={handleChange}
          >
            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="No" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
      </div>
    </>
  );
};

export default withStyles(questionComponentStyles, { withTheme: true })(RadioYesNoQuestion);
