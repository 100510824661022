import { TableCell, TableRow } from '@mui/material';
import ListAlt from '@mui/icons-material/ListAlt';
import * as Sentry from '@sentry/react';
import React, { useEffect, useState } from 'react';
import { IQuestionnaireDto } from '../../../backend/src/questionnaire/interfaces';
import API from '../services/ApiService';
import DataTableTitleWithButton from '../components/DataTableTitleWithButton';
import { QuestionnaireEditDialog } from '../components/dialogs';
import { showErrorResultBar } from '../components/ResultSnackbar';
import SpioDataTable, { SpioDataTableColumn } from '../components/SpioDataTable';
import QuestionnaireDetails, { questionnaireStatusMap } from '../components/QuestionnaireDetails';
import { formatDate, truncateString } from '../helpers';

export const UNASSIGNED_DATA_STR = '(blank)';

const getTableColumns = (tableData: ITableDatum[]): SpioDataTableColumn[] => [
  {
    name: 'id',
    label: 'ID',
    options: {
      display: 'false',
      filter: false,
    },
  },
  {
    name: 'name',
    label: 'Name',
    options: {
      customFilterListOptions: { render: v => `Name: ${v}` },
    },
  },
  {
    name: 'statusStr',
    label: 'Status',
    options: {
      customFilterListOptions: { render: v => `Status: ${v}` },
    },
  },
  {
    name: 'statusUpdatedAtStr',
    label: 'Last Status Update',
    options: {
      customFilterListOptions: { render: v => `Last Status Update: ${v}` },
    },
  },
  {
    name: 'dueDateStr',
    label: 'Due Date',
    options: {
      customFilterListOptions: { render: v => `Due Date: ${v || UNASSIGNED_DATA_STR}` },
    },
  },
  {
    name: 'requestorEmail',
    label: 'Requestor Email',
    options: {
      customFilterListOptions: { render: v => `Requestor Email: ${v || UNASSIGNED_DATA_STR}` },
      display: 'false',
    },
  },
  {
    name: 'requestorName',
    label: 'Requestor Name',
    options: {
      customFilterListOptions: { render: v => `Requestor Name: ${v || UNASSIGNED_DATA_STR}` },
      display: 'false',
    },
  },
  {
    name: 'notes',
    label: 'Notes',
    options: {
      customBodyRenderLite: dataIndex => truncateString(tableData[dataIndex]?.notes || ''),
      display: 'false',
      filter: false,
    },
  },
];

interface ITableDatum extends IQuestionnaireDto {
  dueDateStr: string;
  statusStr: string;
  statusUpdatedAtStr: string;
}

function TheQuestionnairePage() {
  const [ isDialogOpen, setIsDialogOpen ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ tableData, setTableData ] = useState<ITableDatum[]>([]);
  const [ questionnaires, setQuestionnaires ] = useState<IQuestionnaireDto[]>([]);

  useEffect(() => {
    setIsLoading(true);

    API.get('questionnaire')
      .then(res => setQuestionnaires(res.data?.data ?? []))
      .catch((err) => {
        showErrorResultBar('Unexpected error loading questionnaires');
        Sentry.captureException(err);
      })
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    setTableData(questionnaires.map(d => Object({
      ...d,
      dueDateStr: formatDate(d.dueDate),
      statusStr: (d.status && questionnaireStatusMap[d.status]) || 'Unknown',
      statusUpdatedAtStr: formatDate(d.statusUpdatedAt),
    })));
  }, [ questionnaires ]);

  const handleArchive = (selectedIdx: number) => () => {
    const updatedData = questionnaires.slice();
    updatedData.splice(selectedIdx, 1);
    setQuestionnaires(updatedData);
  };

  const handleCreate = (newQuestionnaire: IQuestionnaireDto) => {
    const updatedData = [ newQuestionnaire ].concat(questionnaires);
    setQuestionnaires(updatedData);
  };

  const handleUpdate = (selectedIdx: number) => (updatedVendor: IQuestionnaireDto) => {
    const updatedData = questionnaires.slice();
    updatedData[selectedIdx] = updatedVendor;
    setQuestionnaires(updatedData);
  };

  return (
    <>
      <SpioDataTable
        title={<DataTableTitleWithButton
          onButtonClick={() => setIsDialogOpen(true)}
          title="Questionnaires"
        />}
        columns={getTableColumns(tableData)}
        data={tableData}
        options={{
          print: false,
          filterType: 'multiselect',
          selectableRows: 'none',
          textLabels: {
            body: {
              noMatch: isLoading ? 'Loading...' : 'No records found',
              toolTip: 'Sort',
            },
          },
          expandableRows: true,
          expandableRowsOnClick: true,
          renderExpandableRow: (rowData, rowMeta) => {
            const colSpan = rowData.length + 1;
            const myData = questionnaires[rowMeta.dataIndex];

            return myData ? (
              <TableRow>
                <TableCell colSpan={colSpan}>
                  <QuestionnaireDetails
                    onArchive={handleArchive(rowMeta.dataIndex)}
                    onUpdate={handleUpdate(rowMeta.dataIndex)}
                    questionnaireData={myData}
                  />
                </TableCell>
              </TableRow>
            ) : null;
          },
        }}
      />
      <QuestionnaireEditDialog
        onClose={() => setIsDialogOpen(false)}
        onSave={handleCreate}
        open={isDialogOpen}
        questionnaireData={null}
      />
    </>
  );
}

TheQuestionnairePage.icon = <ListAlt />;
TheQuestionnairePage.requiredAuthZ = {
  tier: 3,
  permission: 'questionnaires',
};
TheQuestionnairePage.routePath = '/questionnaires';
TheQuestionnairePage.title = 'Questionnaires';

export default TheQuestionnairePage;
