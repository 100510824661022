import React from 'react';
import PropTypes from 'prop-types';
import deburr from 'lodash/deburr';
import Downshift from 'downshift';
import { createStyles, withStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';

// See https://material-ui.com/components/autocomplete/#downshift

const styles = (theme) => createStyles({
  root: {
    flexGrow: 1,
  },
  container: {
    display: 'inline-flex',
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 100,
    marginTop: theme.spacing(0.5),
    bottom: 85,
    left: 0,
    right: 0,
    maxHeight: '230px',
    overflowY: 'auto',
    width: '40%',
  },
  chip: {
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
  },
  divider: {
    height: theme.spacing(2),
  },
});

function renderInput(inputProps) {
  const { InputProps, classes, ref, ...other } = inputProps;

  return (
    <TextField
      margin="normal"
      variant="outlined"
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        ...InputProps,
      }}
      {...other}
    />
  );
}

function renderSuggestion(suggestionProps) {
  const { suggestion, index, itemProps, highlightedIndex, selectedItems } = suggestionProps;
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItems || '').indexOf(suggestion.name) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.name}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {suggestion.name}
    </MenuItem>
  );
}

renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItems: PropTypes.string,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
};

function getSuggestions(value, selectedItems, allSuggestions) {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;

  const suggestionsToShow = allSuggestions.filter(suggestion => {
    const toShow =
      suggestion.name.slice(0, inputLength).toLowerCase() === inputValue &&
      !selectedItems.find(selectedItem => selectedItem.id === suggestion.id);

    return toShow;
  });

  return suggestionsToShow;
}

function TagsInput(props) {
  const { classes, onChange, suggestions, value } = props;
  const [inputValue, setInputValue] = React.useState('');
  const [selectedItems, setSelectedItems] = React.useState(value);

  function handleKeyDown(event) {
    // Delete the last item via backspace
    if (selectedItems.length && !inputValue.length && event.key === 'Backspace') {
      setSelectedItems(selectedItems.slice(0, selectedItems.length - 1));
    }
  }

  function handleInputChange(event) {
    // The text the user is currently entering
    setInputValue(event.target.value);
  }

  function handleChange(item) {
    // When the user enters a new item
    const newSelection = suggestions.find(suggestion => suggestion.name === item);
    const isValid = !!newSelection;
    const isAlreadySelected = selectedItems.findIndex(selectedItem => selectedItem.name === item) !== -1;

    if (isValid && !isAlreadySelected) {
      const newSelectedItems = [...selectedItems, newSelection];

      setInputValue('');
      setSelectedItems(newSelectedItems);
      onChange(newSelectedItems);
    }
  }

  const handleDelete = item => () => {
    const newSelectedItems = [...selectedItems];
    newSelectedItems.splice(newSelectedItems.indexOf(item), 1);
    setSelectedItems(newSelectedItems);
    onChange(newSelectedItems);
  };

  return (
    <Downshift
      id="downshift-multiple"
      inputValue={inputValue}
      onChange={handleChange}
      selectedItem={selectedItems}
    >
      {({
        getInputProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        isOpen,
        inputValue,
        openMenu,
        selectedItem: selectedItems,
        highlightedIndex,
      }) => {
        const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
          onFocus: openMenu,
          onKeyDown: handleKeyDown,
        });

        return (
          <div className={classes.container}>
            {renderInput({
              fullWidth: true,
              classes,
              label: 'Tags',
              InputLabelProps: getLabelProps(),
              InputProps: {
                startAdornment: selectedItems.map(item => (
                  <Chip
                    key={item.id}
                    tabIndex={-1}
                    label={item.name}
                    className={classes.chip}
                    onDelete={handleDelete(item)}
                  />
                )),
                onBlur,
                onChange: event => {
                  handleInputChange(event);
                  onChange(event);
                },
                onFocus,
              },
              inputProps,
            })}

              {isOpen ? (
                <Paper
                  className={classes.paper}
                  elevation={5}
                  square
                >
                  {getSuggestions(inputValue, selectedItems, suggestions).map((suggestion, index) =>
                    renderSuggestion({
                      suggestion,
                      index,
                      itemProps: getItemProps({ item: suggestion.name }),
                      highlightedIndex,
                      selectedItems,
                    }),
                  )}
                </Paper>
              ) : null}
          </div>
        );
      }}
    </Downshift>
  );
}

TagsInput.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  suggestions: PropTypes.array.isRequired,
  value: PropTypes.array.isRequired,
};

export default withStyles(styles, { withTheme: true })(TagsInput);
