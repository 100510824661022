// Adapted from https://github.com/hubgit/react-prosemirror/blob/master/react-prosemirror-config-default/src/menu.js

import CodeIcon from '@mui/icons-material/Code';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatIndentDecreaseIcon from '@mui/icons-material/FormatIndentDecrease';
import FormatIndentIncreaseIcon from '@mui/icons-material/FormatIndentIncrease';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import GridOffIcon from '@mui/icons-material/GridOff';
import GridOnIcon from '@mui/icons-material/GridOn';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import RedoIcon from '@mui/icons-material/Redo';
import UndoIcon from '@mui/icons-material/Undo';
import { setBlockType, toggleMark, wrapIn } from 'prosemirror-commands';
import { redo, undo } from 'prosemirror-history';
import { MarkType, Node as ProsemirrorNode, NodeType, Schema } from 'prosemirror-model';
import { liftListItem, sinkListItem, wrapInList } from 'prosemirror-schema-list';
import { EditorState, NodeSelection, Transaction } from 'prosemirror-state';
import {
  addColumnAfter,
  addColumnBefore,
  addRowAfter,
  addRowBefore,
  deleteColumn,
  deleteRow,
  deleteTable,
  isInTable,
  selectedRect,
} from 'prosemirror-tables';
import React, { ReactNode } from 'react';
import {
  DeleteColumnIcon,
  DeleteRowIcon,
  InsertColumnAfterIcon,
  InsertColumnBeforeIcon,
  InsertRowAboveIcon,
  InsertRowBelowIcon,
} from '../../icons';

const isMac = typeof navigator !== 'undefined' ? /Mac/.test(navigator.platform) : false;
const modKey = isMac ? <span>&#8984;</span> : <span>Ctrl+</span>;

export const isMarkActive = (type: MarkType) => (state: EditorState) => {
  const { from, $from, to, empty } = state.selection;

  return empty
    ? !!type.isInSet(state.storedMarks || $from.marks())
    : state.doc.rangeHasMark(from, to, type);
};

// Using 'isMarkActive' for links will return true if a link is _anywhere_ inside the selection.
// We typically won't want this when editing links.
// Ideally we want to know if the selection includes and only includes a single link,
// but for now just check that the cursor (ie, an empty selection) is inside a link.
// TODO: Improve check for isLinkActive to allow for a selection within the link.
export const isLinkActive = (state: EditorState) => {
  const { $from, empty } = state.selection;

  return empty && state.schema.marks.link.isInSet(state.storedMarks || $from.marks());
};

export const isBlockActive = (type: NodeType, attrs = {}) => (state: EditorState) => {
  const { $from, to } = state.selection;
  const node = state.selection instanceof NodeSelection ? state.selection.node : undefined;

  if (node) {
    return node.hasMarkup(type, attrs);
  }

  return to <= $from.end() && $from.parent.hasMarkup(type, attrs);
};

const isInTableBody = (state: EditorState) => {
  if (!isInTable(state)) {
    return false;
  }

  const rect = selectedRect(state);
  const isTopRow = !!rect && rect.top === 0;

  return !isTopRow;
};

const insertTable = (schema: Schema) => (state: EditorState, dispatch: (tr: Transaction) => void) => {
  let rowCount = 3;
  let colCount = 3;
  const headerCells: ProsemirrorNode[] = [];
  const bodyCells: ProsemirrorNode[] = [];

  while (colCount--) {
    const newHeaderCell = schema.nodes.table_header.createAndFill();
    const newBodyCell = schema.nodes.table_cell.createAndFill();

    if (newHeaderCell && newBodyCell) {
      headerCells.push(newHeaderCell);
      bodyCells.push(newBodyCell);
    }
  }

  const rows: ProsemirrorNode[] = [];
  const headerRow = schema.nodes.table_row.createAndFill(null, headerCells);
  if (headerRow) {
    rows.push(headerRow);
  }
  rowCount--;

  while (rowCount--) {
    const bodyRow = schema.nodes.table_row.createAndFill(null, bodyCells);

    if (bodyRow) {
      rows.push(bodyRow);
    }
  }

  const table = schema.nodes.table.createAndFill(null, rows);
  if (table) {
    dispatch(state.tr.replaceSelectionWith(table));
  }

  return true;
};

export type MarkdownMenuItemGroups = MarkdownMenuItemGroup[];

export interface MarkdownMenuItemGroup {
  key: string;
  isDropdown: boolean;
  items: MarkdownMenuItem[];
}

export interface MarkdownMenuItem {
  active?: (state: EditorState, dispatch?: (tr: Transaction) => void) => boolean;
  content: ReactNode;
  enable?: (state: EditorState, dispatch?: (tr: Transaction) => void) => boolean;
  isLink?: boolean;
  run: (state: EditorState, dispatch: (tr: Transaction) => void) => boolean;
  key: string;
  title: NonNullable<ReactNode>;
  toHideWhenDisabled?: boolean;
}

export function getMarkdownMenuItems(schema: Schema): MarkdownMenuItemGroups {
  return [
    {
      key: 'history',
      isDropdown: false,
      items: [
        {
          key: 'undo',
          title: <span>Undo ({modKey}Z)</span>,
          content: <UndoIcon />,
          enable: undo,
          run: undo,
        },
        {
          key: 'redo',
          title: <span>Redo ({modKey}Y)</span>,
          content: <RedoIcon />,
          enable: redo,
          run: redo,
        },
      ],
    },
    {
      key: 'text-styles',
      isDropdown: true,
      items: [
        {
          key: 'normal-text',
          title: 'Change to paragraph',
          content: 'Normal text',
          active: isBlockActive(schema.nodes.paragraph),
          enable: setBlockType(schema.nodes.paragraph),
          run: setBlockType(schema.nodes.paragraph),
        },
        {
          key: 'heading-1',
          title: 'Change to heading level 1',
          content: 'Heading 1',
          active: isBlockActive(schema.nodes.heading, { level: 1 }),
          enable: setBlockType(schema.nodes.heading, { level: 1 }),
          run: setBlockType(schema.nodes.heading, { level: 1 }),
        },
        {
          key: 'heading-2',
          title: 'Change to heading level 2',
          content: 'Heading 2',
          active: isBlockActive(schema.nodes.heading, { level: 2 }),
          enable: setBlockType(schema.nodes.heading, { level: 2 }),
          run: setBlockType(schema.nodes.heading, { level: 2 }),
        },
        {
          key: 'heading-3',
          title: 'Change to heading level 3',
          content: 'Heading 3',
          active: isBlockActive(schema.nodes.heading, { level: 3 }),
          enable: setBlockType(schema.nodes.heading, { level: 3 }),
          run: setBlockType(schema.nodes.heading, { level: 3 }),
        },
        {
          key: 'heading-4',
          title: 'Change to heading level 4',
          content: 'Heading 4',
          active: isBlockActive(schema.nodes.heading, { level: 4 }),
          enable: setBlockType(schema.nodes.heading, { level: 4 }),
          run: setBlockType(schema.nodes.heading, { level: 4 }),
        },
      ],
    },
    {
      key: 'inline-formatting',
      isDropdown: false,
      items: [
        {
          key: 'bold',
          title: <span>Bold ({modKey}B)</span>,
          content: <FormatBoldIcon />,
          active: isMarkActive(schema.marks.strong),
          run: toggleMark(schema.marks.strong),
        },
        {
          key: 'italic',
          title: <span>Italic ({modKey}I)</span>,
          content: <FormatItalicIcon />,
          active: isMarkActive(schema.marks.em),
          run: toggleMark(schema.marks.em),
        },
        {
          key: 'code',
          title: <span>Code ({modKey}`)</span>,
          content: <CodeIcon />,
          active: isMarkActive(schema.marks.code),
          run: toggleMark(schema.marks.code),
        },
        {
          key: 'link',
          isLink: true,
          title: <span>Add link to selected text</span>,
          content: <InsertLinkIcon />,
          active: () => false, // ie, never show the link button as pressed
          enable: (state: EditorState) => !state.selection.empty && !isMarkActive(schema.marks.link)(state),
          run: () => false, // this shouldn't be called for the link menu button
        },
      ],
    },
    {
      key: 'insert-blocks',
      isDropdown: false,
      items: [
        {
          key: 'block_quote',
          title: <span>Block quote (Ctrl+&gt;)</span>,
          content: <FormatQuoteIcon />,
          active: isBlockActive(schema.nodes.blockquote),
          enable: wrapIn(schema.nodes.blockquote),
          run: wrapIn(schema.nodes.blockquote),
        },
        {
          key: 'ordered_list',
          title: <span>Numbered list ({modKey}+Shift+7)</span>,
          content: <FormatListNumberedIcon />,
          active: isBlockActive(schema.nodes.ordered_list),
          enable: wrapInList(schema.nodes.ordered_list),
          run: wrapInList(schema.nodes.ordered_list),
        },
        {
          key: 'bullet_list',
          title: <span>Bulleted list ({modKey}+Shift+8)</span>,
          content: <FormatListBulletedIcon />,
          active: isBlockActive(schema.nodes.bullet_list),
          enable: wrapInList(schema.nodes.bullet_list),
          run: wrapInList(schema.nodes.bullet_list),
        },
        {
          key: 'lift',
          title: <span>Decrease indent ({modKey}[)</span>,
          content: <FormatIndentDecreaseIcon />,
          enable: liftListItem(schema.nodes.list_item),
          run: liftListItem(schema.nodes.list_item),
        },
        {
          key: 'sink',
          title: <span>Increase indent ({modKey}])</span>,
          content: <FormatIndentIncreaseIcon />,
          enable: sinkListItem(schema.nodes.list_item),
          run: sinkListItem(schema.nodes.list_item),
        },
      ],
    },
    {
      key: 'table',
      isDropdown: false,
      items: [
        {
          key: 'insert_table',
          title: <span>Insert table</span>,
          content: <GridOnIcon />,
          enable: (state: EditorState) => !deleteTable(state), // quick check to prevent tables w/in tables
          run: insertTable(schema),
        },
        {
          key: 'add-table-row-before',
          title: <span>Insert row above</span>,
          content: <InsertRowAboveIcon />,
          enable: isInTableBody,
          run: addRowBefore,
          toHideWhenDisabled: true,
        },
        {
          key: 'add-table-row-after',
          title: <span>Insert row below</span>,
          content: <InsertRowBelowIcon />,
          enable: addRowAfter,
          run: addRowAfter,
          toHideWhenDisabled: true,
        },
        {
          key: 'add-table-column-before',
          title: <span>Insert column left</span>,
          content: <InsertColumnBeforeIcon />,
          enable: addColumnBefore,
          run: addColumnBefore,
          toHideWhenDisabled: true,
        },
        {
          key: 'add-table-column-after',
          title: <span>Insert column right</span>,
          content: <InsertColumnAfterIcon />,
          enable: addColumnAfter,
          run: addColumnAfter,
          toHideWhenDisabled: true,
        },
        {
          key: 'delete-table-row',
          title: <span>Delete row</span>,
          content: <DeleteRowIcon />,
          enable: isInTableBody,
          run: deleteRow,
          toHideWhenDisabled: true,
        },
        {
          key: 'delete-table-column',
          title: <span>Delete column</span>,
          content: <DeleteColumnIcon />,
          enable: deleteColumn,
          run: deleteColumn,
          toHideWhenDisabled: true,
        },
        {
          key: 'delete-table',
          title: <span>Delete table</span>,
          content: <GridOffIcon />,
          enable: deleteTable,
          run: deleteTable,
          toHideWhenDisabled: true,
        },
      ],
    },
  ];
}
