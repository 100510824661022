import { Link } from '@mui/material';
import React from 'react';
import { ICategoryLabelMap, TaskSummaryByCategory } from '../components/TaskSummaryByCategory';

// The ordering here is used to order the displayed results for 'Criteria'.
const topCategoryMap: ICategoryLabelMap = {
  'nist-csf:id': {
    abbreviation: 'ID',
    title: 'Identify',
    subtitle: `
      Develop an organizational understanding to manage cybersecurity risk to systems, people, assets, data, and capabilities.
    `,
  },
  'nist-csf:pr': {
    abbreviation: 'PR',
    title: 'Protect',
    subtitle: `
      Develop and implement appropriate safeguards to ensure delivery of critical services
    `,
  },
  'nist-csf:de': {
    abbreviation: 'DE',
    title: 'Detect',
    subtitle: `
      Develop and implement appropriate activities to identify the occurrence of a cybersecurity event
    `,
  },
  'nist-csf:rs': {
    abbreviation: 'RS',
    title: 'Respond',
    subtitle: `
      Develop and implement appropriate activities to take action regarding a detected cybersecurity incident
    `,
  },
  'nist-csf:rc': {
    abbreviation: 'RC',
    title: 'Recover',
    subtitle: `
      Develop and implement appropriate activities to maintain plans for resilience
      and to restore any capabilities or services that were impaired due to a cybersecurity incident.
    `,
  },
};

// The ordering here is used to order the displayed results for the 'Categories' within 'Functions'.
const subCategoryMap: ICategoryLabelMap = {
  // ID
  'nist-csf:id.am': {
    abbreviation: 'ID.AM',
    title: 'Asset Management',
  },
  'nist-csf:id.be': {
    abbreviation: 'ID.BE',
    title: 'Business Environment',
  },
  'nist-csf:id.gv': {
    abbreviation: 'ID.GV',
    title: 'Governance',
  },
  'nist-csf:id.ra': {
    abbreviation: 'ID.RA',
    title: 'Risk Assessment',
  },
  'nist-csf:id.rm': {
    abbreviation: 'ID.RM',
    title: 'Risk Management Strategy',
  },
  'nist-csf:id.sc': {
    abbreviation: 'ID.SC',
    title: 'Supply Chain Risk Management',
  },
  // PR
  'nist-csf:pr.ac': {
    abbreviation: 'PR.AC',
    title: 'Identity Management, Authentication and Access Control',
  },
  'nist-csf:pr.at': {
    abbreviation: 'PR.AT',
    title: 'Awareness and Training',
  },
  'nist-csf:pr.ds': {
    abbreviation: 'PR.DS',
    title: 'Data Security',
  },
  'nist-csf:pr.ip': {
    abbreviation: 'PR.IP',
    title: 'Information Protection Processes and Procedures',
  },
  'nist-csf:pr.ma': {
    abbreviation: 'PR.MA',
    title: 'Maintenance',
  },
  'nist-csf:pr.pt': {
    abbreviation: 'PR.PT',
    title: 'Protective Technology',
  },
  // DE
  'nist-csf:de.ae': {
    abbreviation: 'DE.AE',
    title: 'Anomalies and Events',
  },
  'nist-csf:de.cm': {
    abbreviation: 'DE.CM',
    title: 'Security Continuous Monitoring',
  },
  'nist-csf:de.dp': {
    abbreviation: 'DE.DP',
    title: 'Detection Processes',
  },
  // RS
  'nist-csf:rs.rp': {
    abbreviation: 'RS.RP',
    title: 'Response Planning',
  },
  'nist-csf:rs.co': {
    abbreviation: 'RS.CO',
    title: 'Communications',
  },
  'nist-csf:rs.an': {
    abbreviation: 'RS.AN',
    title: 'Analysis',
  },
  'nist-csf:rs.mi': {
    abbreviation: 'RS.MI',
    title: 'Mitigation',
  },
  'nist-csf:rs.im': {
    abbreviation: 'RS.IM',
    title: 'Improvements',
  },
  // RC
  'nist-csf:rc.rp': {
    abbreviation: 'RC.RP',
    title: 'Recovery Planning',
  },
  'nist-csf:rc.im': {
    abbreviation: 'RC.IM',
    title: 'Improvements',
  },
  'nist-csf:rc.co': {
    abbreviation: 'RC.CO',
    title: 'Communications',
  },
};

function TheTasksSummaryNistCsfPage() {
  return (
    <TaskSummaryByCategory
      apiRoute="task/summary/nistcsf"
      subCategoryMap={subCategoryMap}
      title="NIST CSF"
      topCategoryLabel="Function/Category"
      topCategoryMap={topCategoryMap}
    >
      <>
        The <Link
          href="https://www.nist.gov/cyberframework"
          target="_blank"
          rel="noopener noreferrer"
        >
          NIST Cybersecurity Framework (CSF)
        </Link> includes
        a list of core cybersecurity activities
        broken down by Function, Category, and Subcategory.
        Each Function has at least
        one associated task your organization should complete.
        In the table below we indicate how far along your organization has come in terms of
        implementing NIST CSF.
      </>
    </TaskSummaryByCategory>
  );
}

TheTasksSummaryNistCsfPage.requiredAuthZ = {
  tier: 1,
  permission: 'tasks',
};
TheTasksSummaryNistCsfPage.routePath = '/tasks/nistcsf';
TheTasksSummaryNistCsfPage.title = 'Task Summary: NIST CSF';

export default TheTasksSummaryNistCsfPage;
