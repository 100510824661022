import {
  FormControl,
  FormControlClasses,
  FormControlLabel,
  FormGroupClasses,
  FormLabel,
  FormLabelClasses,
  Radio,
  RadioGroup,
} from '@mui/material';
import classNames from 'classnames';
import { FormikProps, FormikValues } from 'formik';
import React from 'react';

export interface FormikRadioFieldClasses {
  formControl?: Partial<FormControlClasses>;
  formLabel?: Partial<FormLabelClasses>;
  radioGroup?: Partial<FormGroupClasses>;
  radioSelectionRoot?: string;
  radioSelectionSelected?: string;
}

export interface FormikRadioFieldProps<Values extends FormikValues> {
  classes?: FormikRadioFieldClasses;
  field: keyof Values;
  formikProps: FormikProps<Values>;
  label: React.ReactNode;
  selections: { [key: string]: string | number | React.ReactElement };
}

export default function FormikRadioField<Values extends FormikValues>(props: FormikRadioFieldProps<Values>) {
  const { classes, field, formikProps, label, selections } = props;
  const { values, handleChange, handleBlur } = formikProps;
  const fieldStr = field.toString();

  return (
    <FormControl classes={classes?.formControl}>
      <FormLabel classes={classes?.formLabel}>{label}</FormLabel>
      <RadioGroup
        classes={classes?.radioGroup}
        id={fieldStr}
        name={fieldStr}
        value={values[field].toString()}
        onBlur={handleBlur}
        onChange={handleChange}
      >
        {Object.entries(selections).map(([ key, radioLabel ]) => (
          <FormControlLabel
            key={key}
            className={classNames(
              classes?.radioSelectionRoot,
              key === values.role && classes?.radioSelectionSelected,
            )}
            control={<Radio />}
            label={radioLabel}
            value={key}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
