import React from 'react';
import { Button, Theme } from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import SaveIcon from '@mui/icons-material/Save';
import { ButtonProps } from '@mui/material/Button';

const styles = (theme: Theme) => createStyles({
  leftIcon: {
    marginRight: theme.spacing(0.5),
  },
});

export interface SaveButtonProps extends Omit<ButtonProps, 'classes' | 'onClick'>, WithStyles<typeof styles> {
  onClick?: (e: any) => void;
  text?: string;
}

function SaveButton({ classes, disabled = false, onClick, text, type }: SaveButtonProps) {
  return (
    <Button
      variant="contained"
      size="small"
      color="primary"
      disabled={disabled}
      type={type} // to allow for "submit"
      onClick={onClick}
    >
      <SaveIcon className={classes.leftIcon} />
      {text || 'Save'}
    </Button>
  );
}

export default withStyles(styles, { name: 'SaveButton', withTheme: true })(SaveButton);
