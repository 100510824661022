import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Link,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import * as Sentry from '@sentry/react';
import classnames from 'classnames';
import { sortBy } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { IReportingPeriodDto } from '../../../../backend/src/reporting-period/interfaces';
import { formatDate } from '../../helpers';
import API from '../../services/ApiService';
import { showErrorResultBar, showSuccessResultBar } from '../ResultSnackbar';
import { CaptionedAddButton } from '../buttons';
import { ReportingPeriodCreateDialog, ReportingPeriodDeleteDialog, ReportingPeriodEditDialog } from '../dialogs';

const ADD_REPORTING_PERIOD_BUTTON_TITLE = 'Add Reporting Period';

const useStyles = makeStyles((theme: Theme) => createStyles({
  addDateButton: {
    margin: 0,
  },
  buttonLink: {
    'color': 'inherit',
    'textDecoration': 'underline',
    'cursor': 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  cardContent: {
    paddingTop: 0,
  },
  dateRangeStr: {
    fontWeight: 500,
    fontHeight: '1.05rem',
  },
  headerCard: {
    width: '100%',
    margin: 0,
  },
  progressCircle: {
    margin: '2rem',
  },
  listItemFlash: {
    animation: `$flashEffect 700ms ${theme.transitions.easing.easeInOut}`,
  },
  '@keyframes flashEffect': {
    '0%': {
      backgroundColor: theme.palette.secondary.main,
    },
    '100%': {
      backgroundColor: 'transparent',
    }
  },
}));

export const getDateRangeStr = (reportingPeriods: IReportingPeriodDto[], currIdx: number): string => {
  const startDate = reportingPeriods[currIdx]?.startDate ?? null;
  const nextStartDate = reportingPeriods[currIdx + 1]?.startDate ?? null;
  const endDate = nextStartDate ? moment(nextStartDate).subtract(1, 'day').format() : null;
  const startDateStr = `${startDate ? formatDate(startDate) : '(no start date'}`;

  return endDate ? `${startDateStr} to ${formatDate(endDate)}` : `Beyond ${startDateStr}`;
};

function OrgReportingPeriods() {
  const classes = useStyles();
  const [ editIdx, setEditIdx ] = useState<number | null>(null);
  const [ deleteIdx, setDeleteIdx ] = useState<number | null>(null);
  const [ isCreateDialogOpen, setIsCreateDialogOpen ] = useState(false);
  const [ isDeleteDialogOpen, setIsDeleteDialogOpen ] = useState(false);
  const [ isEditDialogOpen, setIsEditDialogOpen ] = useState(false);
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ reportingPeriods, setReportingPeriods ] = useState<IReportingPeriodDto[]>([]);
  const [ reportingPeriodsToDisplay, setReportingPeriodsToDisplay ] = useState<IReportingPeriodDto[]>([]);
  const [ sampleSelectId, setSampleSelectId ] = useState<string>(''); // for the 'Reporting period filter demo'
  const [ updatedIds, setUpdatedIds ] = useState<string[]>([]);       // for the css animation on updated dates

  // Initial page load
  useEffect(() => {
    setIsLoading(true);

    API.get('reportingPeriod')
      .then((res) => {
        const theReportingPeriods = res.data?.data ?? [];
        setReportingPeriods(sortBy(theReportingPeriods, [ 'startDate' ]));
      })
      .catch((err) => {
        showErrorResultBar('Unexpected error loading reporting periods');
        Sentry.captureException(err);
      })
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    // Don't show the final date (the period w/out end) unless that's the only date in the timeline:
    setReportingPeriodsToDisplay(reportingPeriods.length > 1 ? reportingPeriods.slice(0, -1) : reportingPeriods);
  }, [ reportingPeriods ]);

  const handleClickDelete = (idx: number) => () => {
    setDeleteIdx(idx);
    setIsDeleteDialogOpen(true);
  };

  const handleClickEdit = (idx: number) => () => {
    setEditIdx(idx);
    setIsEditDialogOpen(true);
    setSampleSelectId('');
    setUpdatedIds([]);
  };

  const handleClickNew = () => {
    setEditIdx(null);
    setDeleteIdx(null);
    setIsCreateDialogOpen(true);
  };

  const handleDelete = (deletedIds: string[], updatedPeriods: IReportingPeriodDto[]) => {
    setIsDeleteDialogOpen(false);
    setDeleteIdx(null);
    if (deletedIds.length > 0 || updatedPeriods.length > 0) {
      const filterIds = deletedIds.concat(updatedPeriods.map(p => p.id));
      const updatedReportingPeriods = reportingPeriods.filter(p => !filterIds.includes(p.id)).concat(updatedPeriods);
      setReportingPeriods(sortBy(updatedReportingPeriods, [ 'startDate' ]));
      showSuccessResultBar('Reporting periods updated');
    }
  };

  const handleSave = (updatedPeriods: IReportingPeriodDto[]) => {
    const ids = updatedPeriods.map(p => p.id);
    const updatedReportingPeriods = reportingPeriods.filter(p => !ids.includes(p.id)).concat(updatedPeriods);
    setSampleSelectId('');
    setUpdatedIds(ids);
    setReportingPeriods(sortBy(updatedReportingPeriods, [ 'startDate' ]));
    showSuccessResultBar('Reporting periods updated');
    setEditIdx(null);
  };

  return <>
    <Card className={classes.headerCard}>
      <CardHeader title="Reporting Periods" />
      <CardContent className={classes.cardContent}>
        {isLoading ? (
          <CircularProgress className={classes.progressCircle} />
        ) : (
          <Grid container>
            <Grid item xs={12} sm={6} md={4}>
              <List>
                {reportingPeriodsToDisplay.map((reportingPeriod, idx) => (
                  <ListItem
                    key={reportingPeriod.id}
                    button
                    divider={idx < reportingPeriodsToDisplay.length - 1}
                    onClick={handleClickEdit(idx)}
                    className={classnames(updatedIds.includes(reportingPeriod.id) && classes.listItemFlash)}
                  >
                    <Tooltip title="Edit this reporting period">
                      <ListItemText
                        primary={
                          <Typography className={classes.dateRangeStr}>
                            {getDateRangeStr(reportingPeriods, idx)}
                          </Typography>
                        }
                        secondary={
                          <Typography
                            component="span"
                            display="block"
                          >
                            {reportingPeriod.name}
                          </Typography>
                        }
                        secondaryTypographyProps={{ noWrap: true }}
                      />
                    </Tooltip>
                    <ListItemSecondaryAction>
                      <Tooltip title="Delete this reporting period">
                        <IconButton edge="end" onClick={handleClickDelete(idx)} size="large">
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
                <ListItem>
                  <CaptionedAddButton
                    className={classes.addDateButton}
                    size="small"
                    onClick={handleClickNew}
                  >
                    {ADD_REPORTING_PERIOD_BUTTON_TITLE}
                  </CaptionedAddButton>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
              <Card>
                <CardHeader titleTypographyProps={{variant: 'h6'}} title="Instructions" />
                <CardContent>
                  <Typography gutterBottom>
                    Reporting periods allow you to filter reports on date ranges that you've set up.
                  </Typography>
                  {reportingPeriods.length === 0 ? (<>
                    <Typography>
                      The first time you enter a reporting period you will be asked for its start date and duration.
                      This will define three periods:
                    </Typography>
                      <ul>
                        <li><Typography>The period before the start date.</Typography></li>
                        <li><Typography>The period between the start and end date.</Typography></li>
                        <li><Typography>The period extending beyond the end date.</Typography></li>
                      </ul>
                    <Typography gutterBottom>
                      Thereafter you will be able to add additional periods.
                    </Typography>
                  </>) : (<>
                    <Typography gutterBottom>
                      Click on a reporting period to edit its name or dates.
                      Click the <Link
                        className={classes.buttonLink}
                        component="a"
                        onClick={handleClickNew}
                      >
                        {ADD_REPORTING_PERIOD_BUTTON_TITLE}
                    </Link> button to append a new reporting period.
                    </Typography>
                    <Typography gutterBottom>
                      The reporting period filter currently looks like:
                    </Typography>
                    <FormControl variant="filled">
                      <InputLabel id="select-label">Reporting period filter demo</InputLabel>
                      <Select
                        labelId="select-label"
                        value={sampleSelectId}
                        onChange={(e) => setSampleSelectId(e.target.value as string)}
                      >
                        <MenuItem
                          value="0"
                        >
                          <Grid container>
                            <Grid item xs={6}>
                              <Box fontWeight={500} overflow="hidden" textOverflow="ellipsis">
                                Before {reportingPeriods[0].name}
                              </Box>
                            </Grid>
                            <Grid item xs={6}>
                              Before {formatDate(reportingPeriods[0].startDate)}
                            </Grid>
                          </Grid>
                        </MenuItem>
                        {reportingPeriods.map((period, idx) => (
                          <MenuItem
                            key={period.id}
                            value={period.id}
                          >
                            <Grid container>
                              <Grid item xs={6}>
                                <Box fontWeight={500} overflow="hidden" textOverflow="ellipsis">
                                  {period.name || <i>unnamed</i>}
                                </Box>
                              </Grid>
                              <Grid item xs={6}>
                                {getDateRangeStr(reportingPeriods, idx)}
                              </Grid>
                            </Grid>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>)}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
    <ReportingPeriodCreateDialog
      open={isCreateDialogOpen}
      reportingPeriods={reportingPeriods}
      onClose={() => setIsCreateDialogOpen(false)}
      onSave={handleSave}
    />
    {editIdx !== null && reportingPeriods[editIdx] &&
      <ReportingPeriodEditDialog
        open={isEditDialogOpen}
        reportingPeriod={reportingPeriods[editIdx]}
        reportingPeriods={reportingPeriods}
        onClose={() => setIsEditDialogOpen(false)}
        onSave={handleSave}
      />
    }
    {deleteIdx !== null && reportingPeriods[deleteIdx] &&
      <ReportingPeriodDeleteDialog
        open={isDeleteDialogOpen}
        reportingPeriod={reportingPeriods[deleteIdx]}
        reportingPeriods={reportingPeriods}
        onClose={() => setIsDeleteDialogOpen(false)}
        onDelete={handleDelete}
      />
    }
  </>;
}

OrgReportingPeriods.requiredAuthZ = {
  tier: 1,
  permission: 'reporting_period',
};

export default OrgReportingPeriods;
