import { DialogTitle, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { DialogTitleProps } from '@mui/material/DialogTitle';
import { ModalProps } from '@mui/material/Modal';
import React from 'react';
import CloseButton from './buttons/CloseButton';

export interface StyledDialogTitleProps extends DialogTitleProps {
  onClose?: ModalProps['onClose'];
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export default function StyledDialogTitle({ onClose, children }: StyledDialogTitleProps) {
  const classes = useStyles();

  return (
    <DialogTitle className={classes!.root}>
      <Typography variant="h6" component="span">{children}</Typography>
      {onClose &&
        <CloseButton
          className={classes!.closeButton}
          onClick={e => onClose(e, 'escapeKeyDown')} />
      }
    </DialogTitle>
  );
}
