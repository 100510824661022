import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Divider, Hidden, IconButton, Menu, MenuItem, Theme, Toolbar, Typography } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TheOrganizationPage, TheUserManagementPage } from '../pages';
import { IRequiredAuthZ } from '../pages/interfaces';
import TheBillingPageRedirect from '../pages/TheBillingPageRedirect';
import useAuth from '../services/auth/useAuth';
import ChangeOrgMenu from './ChangeOrgMenu';
import StylizedName from './StylizedName';

const useStyles = makeStyles((theme: Theme) => createStyles({
  navMenuButton: {
    marginRight: 20,
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  divider: {
    marginTop: '4px',
    marginBottom: '4px',
  },
  grow: {
    flexGrow: 1,
  },
}));

interface IMenuLink {
  requiredAuthZ: IRequiredAuthZ;
  path: string;
  text: string;
}

const MENU_LINKS: IMenuLink[] = [
  {
    requiredAuthZ: TheOrganizationPage.requiredAuthZ,
    path: TheOrganizationPage.routePath,
    text: 'Org Settings',
  },
  {
    requiredAuthZ: TheUserManagementPage.requiredAuthZ,
    path: TheUserManagementPage.routePath,
    text: 'Users',
  },
];

export interface TheAppBarProps {
  onDrawerToggle: () => void;
  onSwitchOrg: (orgId: string) => void;
  title: string;
}

export default function TheAppBar({ onDrawerToggle, onSwitchOrg, title }: TheAppBarProps) {
  const classes = useStyles();
  const { currentOrg, isGranted, logout, user } = useAuth();
  const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null);
  const [ subAnchorEl, setSubAnchorEl ] = useState<null | HTMLElement>(null);
  const [ menuLinks, setMenuLinks ] = useState<IMenuLink[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    setMenuLinks(MENU_LINKS.filter(link => isGranted(link.requiredAuthZ)));
  }, [ isGranted ]);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSubMenuOpen = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    setSubAnchorEl(event.currentTarget);
  };

  const handleSubMenuClose = () => {
    setSubAnchorEl(null);
  };

  const handleSwitchOrg = (orgId: string) => {
    handleFullMenuClose();
    onSwitchOrg(orgId);
  };

  const handleFullMenuClose = () => {
    handleSubMenuClose();
    handleMenuClose();
  };

  const handleMenuClick = (path: string) => () => {
    navigate(path);
    handleMenuClose();
  };

  const handleLogout = () => {
    logout();
    handleMenuClose();
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="static"
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="Open drawer"
          onClick={onDrawerToggle}
          className={classes.navMenuButton}
          size="large">
          <MenuIcon />
        </IconButton>

        <Typography
          variant="h6"
          color="inherit"
          className={classes.grow}
        >
          <StylizedName />
          <span style={{ fontWeight: 900 }}>&nbsp;| </span>
          {title}
        </Typography>

        <Hidden mdDown>
          <Typography
            variant="h6"
            color="inherit"
          >
            {currentOrg?.name ?? ''}
            <span style={{ fontWeight: 900 }}>&nbsp;| </span>
          </Typography>
        </Hidden>
        <IconButton
          aria-owns={anchorEl ? 'material-appbar' : undefined}
          aria-haspopup="true"
          onClick={handleProfileMenuOpen}
          color="inherit"
          size="large"
        >
          { user?.picture ? <img src={user?.picture} alt="Profile menu" height="40px" /> : <AccountCircleIcon fontSize="inherit" /> }
        </IconButton>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          PaperProps={{
            style: {
              minWidth: 170,
            },
          }}
        >
          {menuLinks.map(link => (
            <MenuItem
              key={link.path}
              onClick={handleMenuClick(link.path)}
            >
              {link.text}
            </MenuItem>
          ))}
          {menuLinks.length > 0 &&
            <Divider className={classes.divider} />
          }
          {isGranted({ permission: 'subscription:manage' }) &&
            <MenuItem onClick={handleMenuClick(TheBillingPageRedirect.routePath)}>
              Billing
            </MenuItem>
          }
          {(isGranted({ permission: 'admin:change_org' }) || isGranted({ permission: 'partner:change_org' })) &&
            <MenuItem
              component="a"
              onClick={handleSubMenuOpen}
            >
              Switch Org
            </MenuItem>
          }
          {isGranted({ permission: 'admin:change_org' }) &&
            <Divider className={classes.divider} />
          }
          {(isGranted({ permission: 'admin:change_org' }) || isGranted({ permission: 'partner:change_org' })) &&
            <ChangeOrgMenu
              onClickOrg={handleFullMenuClose}
              onSwitchOrg={handleSwitchOrg}
              anchorEl={subAnchorEl}
              open={Boolean(subAnchorEl)}
              onClose={handleSubMenuClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              PaperProps={{
                style: {
                  maxHeight: 500,
                  minWidth: 200,
                },
              }}
            />
          }
          <MenuItem
            onClick={handleLogout}
          >
            Logout
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
