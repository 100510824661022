import { Button, DialogActions, DialogContent,DialogProps, Grid, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as Sentry from '@sentry/react';
import { Formik, useFormikContext } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import {
  VendorAuthStatus,
  VendorIsDataReseller,
  VendorIsSubprocessor,
  VendorStatus,
} from '../../../../backend/src/vendor/enums';
import { IIdNameDto } from '../../../../backend/src/common/id-name-dto.interface';
import { IGlobalVendorDto, IVendorDto, IVendorUpdateDto } from '../../../../backend/src/vendor/interfaces';
import { preventNonNumericInput } from '../../helpers';
import API from '../../services/ApiService';
import { SaveButton } from '../buttons';
import TheVendorTrackingPage from '../../pages/TheVendorTrackingPage';
import { FormGridRow, FormikAutocompleteTextField, FormikDatePicker, FormikRadioField, FormikTextField } from '../forms';
import { FormikRadioFieldClasses } from '../forms/FormikRadioField';
import { showErrorResultBar, showSuccessResultBar } from '../ResultSnackbar';
import StyledDialogTitle from '../StyledDialogTitle';
import ResponsiveDialog from './ResponsiveDialog';

const VENDOR_TRACKING_PAGE_TITLE = TheVendorTrackingPage.title;

const useStyles = makeStyles({
  inputField: {
    marginRight: 0,
    marginLeft: 0,
  },
  radioFormControl: {
    marginTop: '0.5rem',
    marginRight: 0,
    marginLeft: 0,
  },
  radioFormLabel: {
    lineHeight: 1.5,
  },
  radioSelections: {
    paddingLeft: '25px',
  },
});

export const isDataResellerSelections: {[ key in VendorIsDataReseller ]: string} = {
  yes: 'Yes',
  no: 'No',
  unknown: 'Unknown',
};

export const isSubprocessorSelections: {[ key in VendorIsSubprocessor ]: string} = {
  yes: 'Yes',
  no: 'No',
  unknown: 'Unknown',
};

export const mfaSelections: {[ key in VendorAuthStatus ]: string} = {
  enforced: 'MFA is enforced',
  enabled: 'MFA is enabled but not enforced',
  available: 'MFA is available but not enabled',
  unavailable: 'MFA is not available',
  not_applicable: 'Not applicable',
  unknown: 'Unknown',
};

export const ssoSelections: {[ key in VendorAuthStatus ]: string} = {
  enforced: 'SSO is enforced',
  enabled: 'SSO is enabled but not enforced',
  available: 'SSO is available but not enabled',
  unavailable: 'SSO is not available',
  not_applicable: 'Not applicable',
  unknown: 'Unknown',
};

export const vendorStatusSelections: Omit<{[ key in VendorStatus ]: string}, 'archived'> = {
  pending: 'Pending',
  needs_followup: 'Additional info needed',
  objected: 'Objections identified',
  approved: 'Approved',
};

interface IGlobalVendorSelection {
  id: string;
  label: string;
}
interface IFormValues extends Omit<IVendorUpdateDto, 'urls'> {
  globalVendorSelection: IGlobalVendorSelection | null;
  urlCompliance?: string | null;
  urlMain?: string | null;
  urlPrivacy?: string | null;
  urlSecurity?: string | null;
  urlSupport?: string | null;
  urlTerms?: string | null;
}

const getInitialFormValues = (vendor: IGlobalVendorDto | IVendorDto | null): IFormValues => ({
  ...getFormValues(vendor),
  globalVendorSelection: null,
});

const getFormValues = (vendor: IGlobalVendorDto | IVendorDto | null): Omit<IFormValues, 'globalVendorSelection'> => ({
  contactEmail: vendor?.contactEmail || '',
  contactName: vendor?.contactName || '',
  contactPhone: vendor?.contactPhone || '',
  contactTitle: vendor?.contactTitle || '',
  dataHandled: vendor?.dataHandled || '',
  dataTier: typeof vendor?.dataTier === 'number' ? vendor.dataTier : null,
  department: vendor?.department || '',
  externalId: vendor?.externalId || '',
  implementedAt: vendor?.implementedAt || new Date(),
  internalNotes: vendor?.internalNotes || '',
  isDataReseller: vendor?.isDataReseller || 'unknown', // this sort of assignment requires the special enum set up
  isSubprocessor: vendor?.isSubprocessor || 'unknown',
  mfaStatus: vendor?.mfaStatus || 'unknown',
  notes: vendor?.notes || '',
  ownerEmail: vendor?.ownerEmail || '',
  retiredAt: vendor?.retiredAt || null,
  reviewedAt: vendor?.reviewedAt || new Date(),
  ssoStatus: vendor?.ssoStatus || 'unknown',
  status: vendor?.status || 'pending',
  urlCompliance: vendor?.urls?.urlCompliance || '',
  urlMain: vendor?.urls?.urlMain || '',
  urlPrivacy: vendor?.urls?.urlPrivacy || '',
  urlSecurity: vendor?.urls?.urlSecurity || '',
  urlSupport: vendor?.urls?.urlSupport || '',
  urlTerms: vendor?.urls?.urlTerms || '',
  vendorName: vendor?.vendorName || '',
});

const VendorSchema = Yup.object().shape({
  contactEmail: Yup
    .string()
    .label('Contact Email')
    .email('Invalid email format.'),
  contactName: Yup
    .string()
    .label('Contact Name')
    .max(255),
  contactPhone: Yup
    .string()
    .label('Contact phone'),
  contactTitle: Yup
    .string()
    .label('Contact title'),
  dataHandled: Yup
    .string()
    .label('Data Handled'),
  dataTier: Yup
    .number()
    .nullable(true)
    .integer('Must be an integer.')
    .min(0, 'Must be at least zero.')
    .max(9999, 'Must be less than 10000.')
    .label('Data Tier'),
  department: Yup
    .string()
    .label('Data Handled'),
  externalId: Yup
    .string()
    .label('External ID')
    .max(255),
  implementedAt: Yup
    .date()
    .label('Start date')
    .required(),
  internalNotes: Yup
    .string()
    .label('Internal notes'),
  isDataReseller: Yup
    .mixed()
    .label('Reseller')
    .oneOf(Object.keys(isDataResellerSelections) as VendorIsDataReseller[]),
  isSubprocessor: Yup
    .mixed()
    .label('Subprocessor')
    .oneOf(Object.keys(isSubprocessorSelections) as VendorIsSubprocessor[]),
  mfaStatus: Yup
    .mixed()
    .label('MFA')
    .oneOf(Object.keys(mfaSelections) as VendorAuthStatus[]),
  notes: Yup
    .string()
    .label('Notes'),
  ownerEmail: Yup
    .string()
    .label('Owner Email')
    .email('Invalid email format.'),
  retiredAt: Yup
    .date()
    .when('implementedAt', (implementedAt, schema) => moment(implementedAt).isValid() ? (
      schema.min(moment(implementedAt).utc().startOf('day').toDate(), 'Should not be before the Start date.')
    ) : (
      schema
    ))
    .nullable()
    .label('Retirement date')
    .typeError('Invalid date'),
  reviewedAt: Yup
    .date()
    .label('Last review date')
    .required(),
  ssoStatus: Yup
    .mixed()
    .label('SSO')
    .oneOf(Object.keys(ssoSelections) as VendorAuthStatus[]),
  status: Yup
    .mixed()
    .label('Decision Status')
    .oneOf(Object.keys(vendorStatusSelections) as VendorStatus[]),
  urlCompliance: Yup
    .string()
    .matches(/^https?:\/\//i, 'The url must begin with "http://" or "https://')
    .url('Invalid url')
    .label('Compliance URL'),
  urlMain: Yup
    .string()
    .matches(/^https?:\/\//i, 'The url must begin with "http://" or "https://')
    .url('Invalid url')
    .label('Main Site URL'),
  urlPrivacy: Yup
    .string()
    .matches(/^https?:\/\//i, 'The url must begin with "http://" or "https://')
    .url('Invalid url')
    .label('Privacy Policy URL'),
  urlSecurity: Yup
    .string()
    .matches(/^https?:\/\//i, 'The url must begin with "http://" or "https://')
    .url('Invalid url')
    .label('Security Policy URL'),
  urlSupport: Yup
    .string()
    .matches(/^https?:\/\//i, 'The url must begin with "http://" or "https://')
    .url('Invalid url')
    .label('Support URL'),
  urlTerms: Yup
    .string()
    .matches(/^https?:\/\//i, 'The url must begin with "http://" or "https://')
    .url('Invalid url')
    .label('Terms of Service URL'),
  vendorName: Yup
    .string()
    .label('Vendor Name')
    .max(255)
    .trim()
    .required('Required'),
});

const createUploadData = (formValues: IFormValues): IVendorUpdateDto => {
  return {
    contactEmail: formValues.contactEmail || null,
    contactName: formValues.contactName || null,
    contactPhone: formValues.contactPhone || null,
    contactTitle: formValues.contactTitle || null,
    dataHandled: formValues.dataHandled || null,
    dataTier: typeof formValues.dataTier === 'number' ? formValues.dataTier : null,
    department: formValues.department || null,
    externalId: formValues.externalId || null,
    implementedAt: formValues.implementedAt || undefined,
    internalNotes: formValues.internalNotes || null,
    isDataReseller: formValues.isDataReseller || 'unknown',
    isSubprocessor: formValues.isSubprocessor || 'unknown',
    mfaStatus: formValues.mfaStatus || 'unknown',
    ownerEmail: formValues.ownerEmail || null,
    retiredAt: formValues.retiredAt || null,
    reviewedAt: formValues.reviewedAt || undefined,
    ssoStatus: formValues.ssoStatus || 'unknown',
    status: formValues.status || 'pending',
    notes: formValues.notes || null,
    urls: {
      urlCompliance: formValues.urlCompliance || null,
      urlMain: formValues.urlMain || null,
      urlPrivacy: formValues.urlPrivacy || null,
      urlSecurity: formValues.urlSecurity || null,
      urlSupport: formValues.urlSupport || null,
      urlTerms: formValues.urlTerms || null,
    },
    vendorName: formValues.vendorName,
  };
};

function AutoUpdateVendorFieldsFromGlobalVendor({ selectedGlobalVendor }: { selectedGlobalVendor: IGlobalVendorDto | null}) {
  // Uses the Formik context to auto-update the vendor fields.
  const { setValues } = useFormikContext<Omit<IFormValues, 'globalVendorSelection'>>();

  useEffect(() => {
    if (selectedGlobalVendor !== null) {
      setValues(getFormValues(selectedGlobalVendor), true);
    }
  }, [ selectedGlobalVendor, setValues ]);

  return null;
}

export interface VendorEditDialogProps extends DialogProps {
  globalVendors?: IIdNameDto[] | null;
  isGlobalVendor?: boolean;
  onClose: () => void;
  onSave: (newVendor: IVendorDto) => void;
  vendorData: IVendorDto | null;
}

export default function VendorEditDialog({ globalVendors = null, isGlobalVendor = false, onClose, onSave, open, vendorData }: VendorEditDialogProps) {
  const classes = useStyles();
  const radioFieldClasses: FormikRadioFieldClasses = {
    formControl: { root: classes.radioFormControl },
    formLabel: { root: classes.radioFormLabel },
    radioGroup: { root: classes.radioSelections },
  };
  const [ globalVendorOptions, setGlobalVendorOptions ] = useState<Array<{ id: string, label: string }> | null>(null);
  const [ selectedGlobalVendor, setSelectedCurrGlobalVendor ] = useState<IGlobalVendorDto | null>(null);
  const [ toShowAllFields, setToShowAllFields ] = useState(false);
  const vendorUrl = isGlobalVendor ? 'globalVendor' : 'vendor';

  useEffect(() => {
    if (globalVendors !== null) {
      setGlobalVendorOptions(globalVendors.map(({ id, name }) => ({ id, label: name })));
    } else {
      setGlobalVendorOptions(null);
    }
  }, [ globalVendors ]);

  const handleGlobalVendorChange = (newValue: { id: string; label: string }) => {
    API.get(`globalVendor/${newValue.id}`)
      .then(res => setSelectedCurrGlobalVendor(res.data?.data ?? null))
      .catch((err) => {
        showErrorResultBar('Unexpected error loading global vendor');
        Sentry.captureException(err);
      });
  };

  const handleSave = async (formValues: IVendorUpdateDto) => {
    try {
      const isNewVendor = vendorData === null;
      let updatedVendor: IVendorDto;

      if (isNewVendor) {
        updatedVendor = selectedGlobalVendor ?
          (await API.post(`/vendor/fromGlobal/${selectedGlobalVendor.id}`, formValues)).data?.data:
          (await API.post(vendorUrl, formValues)).data?.data;
      } else {
        updatedVendor = (await API.patch(`${vendorUrl}/${vendorData.id}`, formValues)).data?.data;
      }
      onSave(updatedVendor);
      showSuccessResultBar('Vendor saved successfully');

      handleClose();
    } catch (err: any) {
      const errorMsg = err.response?.data?.message ?? err.response?.data?.error ?? 'Unexpected error saving vendor';
      showErrorResultBar(errorMsg);
      Sentry.captureException(err);
    }
  };

  const handleClose = () => {
    onClose();
    setSelectedCurrGlobalVendor(null);
    setToShowAllFields(false);
  };

  return (
    <ResponsiveDialog
      disableBackdropClick
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleClose}
    >
      <StyledDialogTitle onClose={handleClose}>
        {vendorData === null ? `Create new ${isGlobalVendor ? 'global' : ''} vendor` : `Edit ${isGlobalVendor ? 'global' : ''} vendor`}
      </StyledDialogTitle>
      <Formik
        initialValues={getInitialFormValues(vendorData)}
        validateOnChange={false} // normally all fields are validated on any change to any field; this is too slow here
        validationSchema={VendorSchema}
        onReset={handleClose}
        onSubmit={async (values, { setSubmitting }) => {
          await handleSave(createUploadData(values));
          setSubmitting(false);
        }}
      >
        {formikProps => (
          <>
            <DialogContent>
              <Grid container>
                {globalVendorOptions && (
                  <FormGridRow
                    divider
                    title="Import from global"
                  >
                    <FormikAutocompleteTextField
                      autocompleteProps={{
                        options: globalVendorOptions,
                        onChange: (e, v, r) => handleGlobalVendorChange(v as { id: string, label: string })
                      }}
                      field="globalVendorSelection"
                      formikProps={formikProps}
                      label="Global vendor"
                    />
                    <AutoUpdateVendorFieldsFromGlobalVendor
                      selectedGlobalVendor={selectedGlobalVendor}
                    />
                </FormGridRow>

                )}
                {/* 'Tracking info' section */}
                <FormGridRow
                  divider
                  title="Tracking info"
                >
                  <FormikTextField
                    autoFocus
                    field="vendorName"
                    formikProps={formikProps}
                    label={isGlobalVendor ? 'Global vendor name' : 'Vendor name'}
                    placeholder="Vendor XYZ"
                    required
                  />
                  <FormikTextField
                    field="status"
                    formikProps={formikProps}
                    helperTextStr="Decision status: Pending (awaiting response); Additional Info Needed; Objections Identified; or Approved."
                    label="Decision status"
                    select
                  >
                    {Object.entries(vendorStatusSelections).map(([ key, label ]) => (
                      <MenuItem
                        key={key}
                        value={key}
                      >
                        {label}
                      </MenuItem>
                    ))}
                  </FormikTextField>
                  {(vendorData === null || toShowAllFields) && (
                  <FormikDatePicker
                    field="implementedAt"
                    formikProps={formikProps}
                    helperTextStr={isGlobalVendor ? '' : 'When your company began doing business with the vendor.'}
                    initValue={vendorData?.implementedAt}
                    label="Start date"
                    required
                  />
                  )}
                  {(vendorData !== null || toShowAllFields) && (
                  <FormikDatePicker
                    field="reviewedAt"
                    formikProps={formikProps}
                    helperTextStr={isGlobalVendor ? '' : 'Refer to your Partner Security Policy for the frequency of vendor reviews.'}
                    initValue={vendorData?.reviewedAt}
                    label="Last review date"
                    required
                  />
                  )}
                  {toShowAllFields && (
                  <FormikDatePicker
                    field="retiredAt"
                    formikProps={formikProps}
                    helperTextStr={isGlobalVendor ? '' : 'When your company ceased doing business with the vendor.'}
                    initValue={vendorData?.retiredAt}
                    label="Retirement date"
                    minDate={moment(formikProps.values.implementedAt)}
                    clearable
                    nullable
                  />
                  )}
                  {toShowAllFields && (
                  <FormikTextField
                    field="ownerEmail"
                    formikProps={formikProps}
                    helperTextStr={isGlobalVendor ? '' : 'Email of person on your team responsible for this vendor.'}
                    label="Owner email"
                  />
                  )}
                  {toShowAllFields && (
                  <FormikTextField
                    field="department"
                    formikProps={formikProps}
                    helperTextStr={isGlobalVendor ? '' : 'The internal department(s) using this vendor.'}
                    label="Department"
                  />
                  )}
                  {toShowAllFields && (
                  <FormikTextField
                    field="externalId"
                    formikProps={formikProps}
                    label="External ID"
                  />
                  )}
                </FormGridRow>
                {/* 'Data handled' section */}
                <FormGridRow
                  divider
                  subtitle="The most sensitive data processed by this vendor"
                  title="Data handled"
                >
                  <FormikTextField
                    field="dataTier"
                    formikProps={formikProps}
                    helperTextStr={isGlobalVendor ? '' : 'A number indicating the data sensitivity. Refer to your Data Classification Policy for the data tiers.'}
                    label="Data tier"
                    onKeyPress={preventNonNumericInput}
                    type="number"
                  />
                  <FormikTextField
                    field="dataHandled"
                    formikProps={formikProps}
                    label="Description of data handled"
                    multiline
                  />
                  {toShowAllFields && (
                  <FormikRadioField
                    classes={radioFieldClasses}
                    field="isSubprocessor"
                    formikProps={formikProps}
                    label="Is this vendor a subprocessor?"
                    selections={isSubprocessorSelections}
                  />
                  )}
                  {toShowAllFields && (
                  <FormikRadioField
                    classes={radioFieldClasses}
                    field="isDataReseller"
                    formikProps={formikProps}
                    label="Does this vendor resell your data?"
                    selections={isDataResellerSelections}
                  />
                  )}
                </FormGridRow>
                {/* 'Authentication' section */}
                {toShowAllFields && (
                <FormGridRow
                  divider
                  title="Authentication"
                >
                  <FormikRadioField
                    classes={radioFieldClasses}
                    field="mfaStatus"
                    formikProps={formikProps}
                    label="Is multi-factor authentication (MFA) enabled? Is it enforced, if the vendor allows this?"
                    selections={mfaSelections}
                  />
                  <FormikRadioField
                    classes={radioFieldClasses}
                    field="ssoStatus"
                    formikProps={formikProps}
                    label="Is single sign-on (SSO) available? Is it enabled? Is it enforced, if the vendor allows this?"
                    selections={ssoSelections}
                  />
                </FormGridRow>
                )}
                {/* 'Contact info' section */}
                <FormGridRow
                  divider
                  subtitle="The vendor point of contact"
                  title="Contact info"
                >
                  <FormikTextField
                    field="contactEmail"
                    formikProps={formikProps}
                    label="Email"
                  />
                  {toShowAllFields && <>
                  <FormikTextField
                    field="contactName"
                    formikProps={formikProps}
                    label="Name"
                  />
                  </>}
                  {toShowAllFields && <>
                  <FormikTextField
                    field="contactPhone"
                    formikProps={formikProps}
                    label="Phone"
                  />
                  </>}
                  {toShowAllFields && <>
                  <FormikTextField
                    field="contactTitle"
                    formikProps={formikProps}
                    label="Job title or role"
                  />
                  </>}
                </FormGridRow>
                {/* 'URLs' section */}
                <FormGridRow
                  divider
                  title="URLs"
                >
                  <FormikTextField
                    field="urlMain"
                    formikProps={formikProps}
                    label="Main website"
                    placeholder="https://example.com"
                  />
                  {toShowAllFields && <>
                  <FormikTextField
                    field="urlCompliance"
                    formikProps={formikProps}
                    label="Compliance"
                    placeholder="https://example.com/compliance"
                  />
                  <FormikTextField
                    field="urlPrivacy"
                    formikProps={formikProps}
                    label="Privacy policy"
                    placeholder="https://example.com/privacy_policy"
                  />
                  <FormikTextField
                    field="urlSecurity"
                    formikProps={formikProps}
                    label="Security policy"
                    placeholder="https://example.com/security_policy"
                  />
                  <FormikTextField
                    field="urlSupport"
                    formikProps={formikProps}
                    label="Support page"
                    placeholder="https://example.com/support"
                  />
                  <FormikTextField
                    field="urlTerms"
                    formikProps={formikProps}
                    label="Terms of service"
                    placeholder="https://example.com/terms_of_service"
                  />
                  </>}
                </FormGridRow>
                {/* 'Notes' section */}
                <FormGridRow
                  title="Additional notes"
                  subtitle={isGlobalVendor ? `Only the "Vendor notes" will be displayed on the ${VENDOR_TRACKING_PAGE_TITLE} page` : ''}
                >
                  <FormikTextField
                    field="notes"
                    formikProps={formikProps}
                    label={isGlobalVendor ? 'Vendor notes' : 'Notes'}
                    multiline
                  />
                  {isGlobalVendor ? (
                    <FormikTextField
                      field="internalNotes"
                      formikProps={formikProps}
                      helperTextStr={`The internal notes will not be displayed on the ${VENDOR_TRACKING_PAGE_TITLE} page.`}
                      label="Internal notes"
                      multiline
                    />
                    ) : null
                  }
                </FormGridRow>
              </Grid>
            </DialogContent>
            <DialogActions disableSpacing>
              <Button
                sx={{ mr: 'auto' }}
                disabled={formikProps.isSubmitting}
                onClick={() => setToShowAllFields(prevValue => !prevValue)}
              >
                Show {toShowAllFields ? 'less' : 'more'}
              </Button>
              <Button
                disabled={formikProps.isSubmitting}
                onClick={formikProps.handleReset}
              >
                {vendorData === null ? 'Cancel' : 'Close'}
              </Button>
              <SaveButton
                disabled={formikProps.isSubmitting || Object.values(formikProps.errors).filter(v => !!v).length > 0}
                onClick={formikProps.handleSubmit}
              />
            </DialogActions>
          </>
        )}
      </Formik>
    </ResponsiveDialog>
  );
}
