import { Button, Theme } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';
import { ButtonProps } from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import classNames from 'classnames';
import React from 'react';

const styles = createStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(2),
  },
}));

function CaptionedAddButton({ classes, className, onClick, children, ...rest }: ButtonProps) {
  return (
    <Button
      className={classNames(classes!.root, className)}
      variant="outlined"
      onClick={onClick}
      {...rest}
    >
      <AddIcon />
      {children}
    </Button>
  );
}

export default withStyles(styles, { withTheme: true })(CaptionedAddButton);
