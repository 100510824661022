import { Link } from '@mui/material';
import React from 'react';
import { ICategoryLabelMap, TaskSummaryByCategory } from '../components/TaskSummaryByCategory';

const topCategoryMap: ICategoryLabelMap = {
  'nist-800-171:3.1.': {
    abbreviation: '3.1',
    title: 'Access Control',
  },
  'nist-800-171:3.2.': {
    abbreviation: '3.2',
    title: 'Awareness and Training',
  },
  'nist-800-171:3.3.': {
    abbreviation: '3.3',
    title: 'Audit and Accountability',
  },
  'nist-800-171:3.4.': {
    abbreviation: '3.4',
    title: 'Configuration Management',
  },
  'nist-800-171:3.5.': {
    abbreviation: '3.5',
    title: 'Identification and Authentication',
  },
  'nist-800-171:3.6.': {
    abbreviation: '3.6',
    title: 'Incident Response',
  },
  'nist-800-171:3.7.': {
    abbreviation: '3.7',
    title: 'Maintenance',
  },
  'nist-800-171:3.8.': {
    abbreviation: '3.8',
    title: 'Media Protection',
  },
  'nist-800-171:3.9.': {
    abbreviation: '3.9',
    title: 'Personnel Security',
  },
  'nist-800-171:3.10.': {
    abbreviation: '3.10',
    title: 'Physical Protection',
  },
  'nist-800-171:3.11.': {
    abbreviation: '3.11',
    title: 'Risk Assessment',
  },
  'nist-800-171:3.12.': {
    abbreviation: '3.12',
    title: 'Security Assessment',
  },
  'nist-800-171:3.13.': {
    abbreviation: '3.13',
    title: 'System and Communications Protection',
  },
  'nist-800-171:3.14.': {
    abbreviation: '3.14',
    title: 'System and Information Integrity',
  },
};

function TheTasksSummaryNIST800171Page() {
  return (
    <TaskSummaryByCategory
      apiRoute="task/summary/nist800171"
      title="NIST SP 800-171"
      topCategoryLabel="Requirement"
      topCategoryMap={topCategoryMap}
    >
      <>
        <Link
          href="https://csrc.nist.gov/publications/detail/sp/800-171/rev-2/final"
          target="_blank"
          rel="noopener noreferrer"
        >
          NIST Special Publication 800-171
        </Link> describes
        14 families of recommended security requirements for protecting the confidentiality of
        Controlled Unclassified Information (CUI)
        in non-federal systems and organizations.
        These requirements are intended for use in contracts between
        federal agencies and non-federal organizations.
        The requirements are grouped from 3.1 to 3.14, with each group having several
        associated tasks your organization should complete.
        In the table below we indicate how far along your organization has come in terms of implementing the
        NIST SP 800-171 security requirements.
      </>
    </TaskSummaryByCategory>
  );
}

TheTasksSummaryNIST800171Page.requiredAuthZ = {
  tier: 1,
  permission: 'tasks',
};
TheTasksSummaryNIST800171Page.routePath = '/tasks/nist800171';
TheTasksSummaryNIST800171Page.title = 'Task Summary: NIST SP 800-171';

export default TheTasksSummaryNIST800171Page;
