import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import useAuth from '../services/auth/useAuth';

export default function AuthRoute() {
  const location = useLocation();
  const { isAuthenticated, isLoading, isRegistered } = useAuth();

  if (isLoading) {
    return <Outlet />;
  } else if (isAuthenticated) {
    if (!isRegistered && location.pathname !== '/register') {
      return (
        <Navigate
          to="/register"
          state={{ from: location }}
        />
      );
    }

    return <Outlet />;
  } else {
    return (
      <Navigate
        to="/login"
        state={{ from: location }}
      />
    );
  }
}
