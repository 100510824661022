import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material';
import MUIDataTable, {
  debounceSearchRender,
  Display,
  MUIDataTableColumn,
  MUIDataTableColumnOptions,
  MUIDataTableOptions,
  MUIDataTableProps,
  MUIDataTableState,
  MUISortOptions,
} from 'mui-datatables';
import React, { useCallback, useEffect, useState } from 'react';
import { sanitizeForCSV } from '../helpers';
import { getStorageValue, setStorageValue, STORAGE_KEYS } from '../services/BrowserStorageService';
import spioTheme from '../theme';

const datatableTheme = (spioDataTableOptions: SpioDataTableOptions) => createTheme(spioTheme, {
  components: spioDataTableOptions.onRowClick ? {
    MUIDataTableBodyRow: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
        },
      },
    },
  } : {},
});

export interface SpioDataTableSelectedRowIndex {
  index: number;
  dataIndex: number;
}

export interface SpioDataTableSelectedRows {
  data: SpioDataTableSelectedRowIndex[];
  lookup: { [key: number]: boolean };
}
export const spioDataTableRowsPerPageOptions = [ 10, 25, 50, 100 ];

export type SortDirection =
  'asc' |
  'desc' |
  'none';


export interface SpioDataTableOptions extends MUIDataTableOptions {
  // For adding rows to the top of your CSV file.
  // Usage: downloadHeader = [ [ 'row1 col1 text', 'row1 col2 text' ], [ 'row2 col1 text' ], ... ]
  // In a given row if you include more columns than are in the table itself, they'll get cut off.
  // It's fine to include fewer columns than are in the table, it'll get padded to match.
  downloadHeader?: string[][];
}

export interface SpioDataTableProps extends MUIDataTableProps {
  className?: string;
  columns: SpioDataTableColumn[];
  initFilters?: string[][];
  options?: SpioDataTableOptions;
}

// The published typescript defs have an overly permissive 'display'.
// The published typescript defs are missing 'sortCompare'.
export interface SpioDataTableColumnOptions extends MUIDataTableColumnOptions {
  customDownloadRender?: (dataIndex: number) => string;
  display?: Display;
  sortCompare?: (order: SortDirection) => (arg1: { data: any }, arg2: { data: any}) => number;
}

export interface SpioDataTableColumn extends MUIDataTableColumn {
  options?: SpioDataTableColumnOptions;
}


function getDefaultPageSize(): number {
  let storedPageSize = getStorageValue(STORAGE_KEYS.DATA_TABLE_PAGE_SIZE);

  if (storedPageSize && spioDataTableRowsPerPageOptions.includes(parseInt(storedPageSize, 10))) {
    return Number(storedPageSize);
  } else {
    return 10;
  }
}
// v3 notes:
// There no longer seems to be a need to store the searchText.
// Continue to store the filters so that we can use our 'initFilters' prop.
// (The built-in 'filterList' is too aggressive at bringing back cleared filters.)
// Continue to store the rowsExpanded and dataLengthCache state, else all rows collapse when we click 'New Entry'.
// Continue to store the display else the viewColumns get reset when we click 'New Entry'.
function SpioDataTable(props: SpioDataTableProps) {
  const [ dataLengthCache, setDataLengthCache ] = useState<number>(props.data?.length ?? 0);
  const [ display, setDisplay ] = useState<Display[]>(props.columns.map(column => column.options?.display ?? 'true'));
  const [ filters, setFilters ] = useState<string[][]>(props.initFilters || []);
  const [ rowsExpanded, setRowsExpanded ] = useState<number[]>(props.options?.rowsExpanded ?? []);
  const [ rowsPerPage, setRowsPerPage ] = useState<number>(getDefaultPageSize());
  const [ tableColumns, setTableColumns ] = useState<SpioDataTableColumn[]>([]);
  const [ sortOrder, setSortOrder ] = useState<MUISortOptions | undefined>(props?.options?.sortOrder);

  const handleSortChange = useCallback((changedColumn: string, direction: 'asc' | 'desc') => {
    if (props?.options?.onColumnSortChange) {
      props.options.onColumnSortChange(changedColumn, direction);
    } else {
      setSortOrder({ name: changedColumn, direction });
    }
  }, [ props ]);

  useEffect(() => {
    setTableColumns(props.columns.map((colInfo, i) => ({
      ...colInfo,
      options: {
        filterList: filters[i], // persists the filters upon re-render
        ...(colInfo.options),
        display: display[i], // persists the viewed columns upon re-render
      },
    })));
  }, [ display, filters, props.columns ]);

  const spioDataTableOptions: SpioDataTableOptions = {
    caseSensitive: false,
    customSearchRender: debounceSearchRender(500),
    expandableRowsHeader: false,
    onChangeRowsPerPage: handleManualRowsPerPageChange,
    responsive: 'standard',
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: spioDataTableRowsPerPageOptions,
    onFilterChange: (_, filterList: string[][]) => setFilters(filterList),
    onDownload: (buildHead, buildBody, columns, data) => {
      const headerNames = columns.map((col: any) => {
        return {
          name: col.label,
          download: col.download,
        };
      });

      const downloadData = data.map((row: any) => {
        return {
          index: row.index,
          data: row.data.map((d: any, i: number) => {
            return columns[i].customDownloadRender ? columns[i].customDownloadRender(row.index) : (d?.toString() ?? '');
          }),
        };
      });

      let header = '';
      if (props.options?.downloadHeader) {
        const downloadHeaderRows = props.options.downloadHeader || [];
        const numCols = columns.filter((col: any) => col.download).length;

        const csvRows = downloadHeaderRows.map((row: string[]) => {
          // Sanitizes the cell contents and enforces that there are exactly 'numCols' columns:
          return row.map(d => `"${sanitizeForCSV(d)}"`).concat(Array(numCols)).slice(0, numCols).join(',');
        });
        header = csvRows.join('\r\n').concat('\r\n');
      }

      const head = buildHead(headerNames);
      const body = buildBody(downloadData);

      return `${header}${head}${body}`.trim();
    },
    onRowExpansionChange: (currentRowsExpanded, allRowsExpanded) =>
      setRowsExpanded(allRowsExpanded.map(row => row.dataIndex)),

    ...props.options,

    rowsExpanded,
    onViewColumnsChange: (changedColumn: string, action: string) => {
      const colIndex = props.columns.findIndex(col => col.name === changedColumn);
      if (colIndex >= 0 && colIndex < display.length) {
        const newDisplay = display.slice();
        newDisplay[colIndex] = action === 'add' ? 'true' : 'false';
        setDisplay(newDisplay);
      }

      if (props.options?.onViewColumnsChange) {
        props.options.onViewColumnsChange(changedColumn, action);
      }
    },
    onTableChange: (action: string, tableState: MUIDataTableState) => {
      // Closes any expanded rows if we're changing the data array length.
      // (B/c the expanded rows are sticky-to-index, not sticky-to-data.)
      // TODO: Investigate better ways of handling expanded rows on data length changes.

      const newDataLength = tableState.data.length;

      if (newDataLength !== dataLengthCache) {
        setRowsExpanded([]);
        setDataLengthCache(newDataLength);
      }

      if (props.options?.onTableChange) {
        props.options.onTableChange(action, tableState);
      }
    },

    sortOrder,
    onColumnSortChange: handleSortChange,
  };

  function handleManualRowsPerPageChange(pageSize: number) {
    setStorageValue(STORAGE_KEYS.DATA_TABLE_PAGE_SIZE, pageSize.toString());

    setRowsPerPage(pageSize);
  }


  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={datatableTheme(spioDataTableOptions)}>
        <MUIDataTable
          {...props}
          columns={tableColumns}
          options={spioDataTableOptions}
        />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

SpioDataTable.propTypes = MUIDataTable.propTypes;
SpioDataTable.defaultProps = MUIDataTable.defaultProps;
SpioDataTable.displayName = MUIDataTable.displayName;

export default SpioDataTable;
