import { Button, DialogActions, DialogContent, DialogProps, MenuItem, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as Sentry from '@sentry/react';
import React, { useEffect, useState } from 'react';
import { ITaskAssigneeDto, ITaskDto, ITasksResponse, ITasksUpdateDto } from '../../../../backend/src/task/interfaces';
import API from '../../services/ApiService';
import { showErrorResultBar, showSuccessResultBar } from '../ResultSnackbar';
import StyledDialogTitle from '../StyledDialogTitle';
import ResponsiveDialog from './ResponsiveDialog';

const useStyles = makeStyles({
  dialogContent: {
    minWidth: '400px',
  },
  dropdownItemEmail: {
    fontSize: '0.8rem',
  },
});

// This gets used as the 'value' for the 'Unassigned' dropdown choice.
const UNASSIGNED_ID = 'unassigned';

const assigneeToId = (assignee: ITaskAssigneeDto | null) => {
  return (assignee && assignee.id) ? assignee.id : UNASSIGNED_ID;
};

export interface TaskAssignmentDialogProps extends DialogProps {
  assignees: ITaskAssigneeDto[];
  onClose: () => void;
  onUpdateTasks: (updatedTasksInfo: ITasksResponse) => void;
  tasks: ITaskDto[];
}

export default function TaskAssignmentDialog(props: TaskAssignmentDialogProps) {
  const classes = useStyles();
  const { assignees, onClose, onUpdateTasks, open, tasks } = props;

  const [ assignee, setAssignee ] = useState<ITaskAssigneeDto | null>(null);
  const [ isSubmitting, setIsSubmitting ] = useState<boolean>(false);
  const [ isValidAssignee, setIsValidAssignee ] = useState(true);
  const [ selectedAssigneeId, setSelectedAssigneeId ] = useState<string>(UNASSIGNED_ID);

  // In the case of a single task, use its assignee as the default selection.
  useEffect(() => {
    if (tasks.length === 1 && tasks[0].assigneeId) {
      const { assigneeId, assigneeName } = tasks[0];
      setAssignee({ id: assigneeId, name: assigneeName });
    } else {
      setAssignee(null);
    }
  }, [ tasks ]);

  // The assigneeId is used by the select dropdown.
  useEffect(() => {
    setSelectedAssigneeId(assigneeToId(assignee));
  }, [ assignee ]);

  // In the case of a single task with an invalid (old) assignee, still show them in the list.
  useEffect(() => {
    setIsValidAssignee(assignee === null || assignees.some(a => a.id === assignee.id));
  }, [ assignee, assignees ]);

  const handleChangeDropdown = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelection = event.target.value;

    setSelectedAssigneeId(newSelection);
  };

  const handleAssignTasks = async () => {
    const newAssigneeId = selectedAssigneeId === UNASSIGNED_ID ? null : selectedAssigneeId;
    // Don't update if the assignee hasn't changed.
    const tasksToUpdate = tasks.filter(task => task.assigneeId !== newAssigneeId);

    if (tasksToUpdate.length === 0) {
      onUpdateTasks({});
      onClose();
      showSuccessResultBar('None of the selected task assignments needed updating.');

      return;
    }

    const updateDto: ITasksUpdateDto = {
      assigneeId: newAssigneeId,
      ids: tasksToUpdate.map(task => task.id),
    };

    try {
      setIsSubmitting(true);
      const res: ITasksResponse = (await API.patch('task', updateDto)).data.data;
      onUpdateTasks(res);
      onClose();
      showSuccessResultBar(`Task assignment${tasks.length > 1 ? 's' : ''} updated.`);
    } catch (err: any) {
      Sentry.captureException(err);
      showErrorResultBar(`Unable to assign task${tasks.length > 1 ? 's' : ''}, please try again later.`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const resetData = () => {
    setSelectedAssigneeId(assigneeToId(assignee));
  };

  return (
    <ResponsiveDialog
      disableBackdropClick={true}
      open={open}
      onClose={onClose}
      TransitionProps={{ onExited: resetData}}
    >
      <StyledDialogTitle
        onClose={onClose}
      >
        Assign Task{tasks.length > 1 ? 's' : ''}
      </StyledDialogTitle>
      <DialogContent
        className={classes.dialogContent}
      >
        <TextField
          id="task-assignee"
          select
          label="Assign to"
          value={selectedAssigneeId}
          onChange={handleChangeDropdown}
          helperText={`Please select a team member for ${tasks.length > 1 ? 'these tasks' : 'this task'}`}
        >
          <MenuItem
            value={UNASSIGNED_ID}
          >
            Unassigned
          </MenuItem>
          {assignees.map(currAssignee => (
            <MenuItem
              key={currAssignee.id}
              value={currAssignee.id}
            >
              {currAssignee.name}
              {currAssignee.name !== currAssignee.email &&
                <span className={classes.dropdownItemEmail}>&nbsp;({currAssignee.email})</span>
              }
            </MenuItem>
          ))}
          {!isValidAssignee && assignee &&
            <MenuItem
              value={assignee.id}
            >
              {assignee.name}
              <span className={classes.dropdownItemEmail}>&nbsp;(No longer has access to tasks)</span>
            </MenuItem>
          }
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          size="small"
          disabled={isSubmitting}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          size="small"
          color="primary"
          disabled={isSubmitting}
          onClick={handleAssignTasks}
        >
          Assign
        </Button>
      </DialogActions>
    </ResponsiveDialog>
  );
}
