import {
  Button,
  DialogActions,
  DialogContent,
  DialogProps,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as Sentry from '@sentry/react';
import { Formik } from 'formik';
import { mapValues } from 'lodash';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { RoleUpdate } from '../../../../backend/src/auth/enums';
import {
  IUserInviteDto,
  IUserInviteError,
  IUserInvitesResponse,
  IUserInviteUploadDto,
} from '../../../../backend/src/user/interfaces';
import API from '../../services/ApiService';
import { FormikRadioField, FormikSwitchField, FormikTextField } from '../forms';
import { FormikRadioFieldClasses } from '../forms/FormikRadioField';
import { showErrorResultBar } from '../ResultSnackbar';
import { RoleSelectionLabel, roleUpdateMap } from '../UserManagementUserDetails';
import ResponsiveDialog from './ResponsiveDialog';

const useStyles = makeStyles({
  inputContainer: {
    padding: '0 2rem',
  },
  roleSelectionContainer: {
    margin: 0,
    paddingTop: '1rem',
    paddingBottom: '1rem',
    borderRadius: '4px',
  },
  selectedRoleContainer: {
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
  },
});

const UserInviteSchema = Yup.object().shape({
  emails: Yup
    .array()
    .transform(function (value, originalValue) {
      if (this.isType(value) && value !== null) {
        return value;
      }

      return originalValue ? originalValue.split(/[\s,]+/) : [];
    })
    .of(Yup.string().email(({ value }) => ` ${value} is not a valid email`).required())
    .required('Required.'),
  role: Yup
    .mixed()
    .oneOf(Object.keys(roleUpdateMap) as RoleUpdate[])
    .required(),
});

interface IFormValues {
  emails: string;
  role: RoleUpdate;
  toSendInviteEmails: boolean;
}

const initialFormValues: IFormValues = {
  emails: '',
  role: 'Org Trainee',
  toSendInviteEmails: true,
};

const createUploadData = (formValues: IFormValues): IUserInviteUploadDto => {
  return {
    emails: formValues.emails.split(/[\s,]+/).filter(email => !!email) || [],
    role: formValues.role,
    toSendInviteEmails: formValues.toSendInviteEmails,
  };
};

export interface UserInviteDialogProps extends DialogProps {
  onAddInvites: (invites: IUserInviteDto[]) => void;
  onClose: () => void;
}

export default function UserInviteDialog({ onAddInvites, onClose, open }: UserInviteDialogProps) {
  const classes = useStyles();
  const radioFieldClasses: FormikRadioFieldClasses = {
    radioGroup: { root: classes.inputContainer },
    radioSelectionRoot: classes.roleSelectionContainer,
    radioSelectionSelected: classes.selectedRoleContainer,
  };
  const [ addedInvites, setAddedInvites ] = useState<IUserInviteDto[]>([]);
  const [ inviteErrors, setInviteErrors ] = useState<IUserInviteError[]>([]);
  const [ toShowResponseDialog, setToShowResponseDialog ] = useState(false);

  const handleSend = async (formValues: IUserInviteUploadDto) => {
    try {
      const res: IUserInvitesResponse = (await API.post('user/invite', formValues)).data?.data;
      const { errors = [], invites = [] } = res || {};

      if (errors.length === 0 && invites.length === 0) {
        showErrorResultBar('No invitations were sent');
        onClose();
      }

      setAddedInvites(invites);
      setInviteErrors(errors);
      setToShowResponseDialog(true);
      onAddInvites(invites);
    } catch (err: any) {
      const errorMsg = err.response?.data?.error ?? 'Unexpected error sending invites';
      showErrorResultBar(errorMsg);
      Sentry.captureException(err);
    }
  };

  const handleExited = () => {
    setToShowResponseDialog(false);
    setAddedInvites([]);
    setInviteErrors([]);
  };

  return (
    <ResponsiveDialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
      TransitionProps={{ onExited: handleExited }}
    >
      {toShowResponseDialog ? (<>
        <DialogContent>
          {addedInvites.length > 0 && (<>
            <Typography gutterBottom variant="h6">
              {addedInvites.length > 1 ?
                'Invitations will be sent to the following addresses:' :
                'An invitation will be sent to the following address:'
              }
            </Typography>
            <List disablePadding>
              {addedInvites.map(invite => (
                <ListItem key={invite.email}>
                  <ListItemText
                    primary={invite.email}
                    secondary={roleUpdateMap[invite.role]?.text || invite.role}
                  />
                </ListItem>
              ))}
            </List>
          </>)}
          {inviteErrors.length > 0 && (<>
            <Typography gutterBottom variant="h6">
              Not all of the invitations were sent:
            </Typography>
            <List disablePadding>
              {inviteErrors.map(inviteError => (
                <ListItem key={inviteError.email}>
                  <ListItemText
                    primary={inviteError.email}
                    secondary={inviteError.message}
                  />
                </ListItem>
              ))}
            </List>
          </>)}
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={onClose}
          >
            Close
          </Button>
        </DialogActions>
      </>) : (
        <Formik
          enableReinitialize
          initialValues={initialFormValues}
          validationSchema={UserInviteSchema}
          onReset={onClose}
          onSubmit={async (values, { setSubmitting }) => {
            await handleSend(createUploadData(values));
            setSubmitting(false);
          }}
        >
          {formikProps => (<>
            <DialogContent>
              {/* Invite emails */}
              <Typography gutterBottom variant="h6">
                Email addresses
              </Typography>
              <FormikTextField
                className={classes.inputContainer}
                autoFocus
                field="emails"
                formikProps={formikProps}
                helperTextStr="Please enter a comma separated list of emails or one per line."
                multiline
                placeholder="user@example.com"
                rows={4}
                variant="outlined"
              />
              <FormikSwitchField
                className={classes.inputContainer}
                field="toSendInviteEmails"
                formikProps={formikProps}
                helperTextStr="Send invitation emails to these email addresses.
                  (Note that user sign up is based on the user's email address. Invitation emails are not required for sign up.)"
                label="Notify invited users"
              />
              {/* Role selection */}
              <FormikRadioField
                classes={radioFieldClasses}
                field="role"
                formikProps={formikProps}
                label={<Typography gutterBottom variant="h6">Choose a role</Typography>}
                selections={mapValues(roleUpdateMap, roleSelection => <RoleSelectionLabel roleSelection={roleSelection} />)}
              />
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                disabled={formikProps.isSubmitting}
                onClick={formikProps.handleReset}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                disabled={formikProps.isSubmitting || Object.keys(formikProps.errors).length > 0}
                size="small"
                variant="contained"
                onClick={() => formikProps.handleSubmit()}
              >
                Send invitations
              </Button>
            </DialogActions>
          </>)}
        </Formik>
      )}
    </ResponsiveDialog>
  );
}
