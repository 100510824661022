import { Link } from '@mui/material';
import React from 'react';
import { ICategoryLabelMap, TaskSummaryByCategory } from '../components/TaskSummaryByCategory';

const CONTROL_FAMILY_BASIC = 'Basic';
const CONTROL_FAMILY_FOUNDATIONAL = 'Foundational';
const CONTROL_FAMILY_ORGANIZATIONAL = 'Organizational';

const topCategoryMap: ICategoryLabelMap = {
  'cis 20: control 1': {
    title: `Control 1: ${CONTROL_FAMILY_BASIC}`,
    subtitle: 'Inventory and Control of Hardware Assets',
    tags: [
      'cis 20: control 1',
    ],
  },
  'cis 20: control 2': {
    title: `Control 2: ${CONTROL_FAMILY_BASIC}`,
    subtitle: 'Inventory and Control of Software Assets',
    tags: [
      'cis 20: control 2',
    ],
  },
  'cis 20: control 3': {
    title: `Control 3: ${CONTROL_FAMILY_BASIC}`,
    subtitle: 'Continuous Vulnerability Management',
    tags: [
      'cis 20: control 3',
    ],
  },
  'cis 20: control 4': {
    title: `Control 4: ${CONTROL_FAMILY_BASIC}`,
    subtitle: 'Controlled Use of Administrative Privileges',
    tags: [
      'cis 20: control 4',
    ],
  },
  'cis 20: control 5': {
    title: `Control 5: ${CONTROL_FAMILY_BASIC}`,
    subtitle: 'Secure Configuration for Hardware and Software on Mobile Devices, Laptops, Workstations and Servers',
    tags: [
      'cis 20: control 5',
    ],
  },
  'cis 20: control 6': {
    title: `Control 6: ${CONTROL_FAMILY_BASIC}`,
    subtitle: 'Maintenance, Monitoring and Analysis of Audit Logs',
    tags: [
      'cis 20: control 6',
    ],
  },
  'cis 20: control 7': {
    title: `Control 7: ${CONTROL_FAMILY_FOUNDATIONAL}`,
    subtitle: 'Email and Web Browser Protections',
    tags: [
      'cis 20: control 7',
    ],
  },
  'cis 20: control 8': {
    title: `Control 8: ${CONTROL_FAMILY_FOUNDATIONAL}`,
    subtitle: 'Malware Defenses',
    tags: [
      'cis 20: control 8',
    ],
  },
  'cis 20: control 9': {
    title: `Control 9: ${CONTROL_FAMILY_FOUNDATIONAL}`,
    subtitle: 'Limitation and Control of Network Ports, Protocols and Services',
    tags: [
      'cis 20: control 9',
    ],
  },
  'cis 20: control 10': {
    title: `Control 10: ${CONTROL_FAMILY_FOUNDATIONAL}`,
    subtitle: 'Data Recovery Capabilities',
    tags: [
      'cis 20: control 10',
    ],
  },
  'cis 20: control 11': {
    title: `Control 11: ${CONTROL_FAMILY_FOUNDATIONAL}`,
    subtitle: 'Secure Configuration for Network Devices, such as Firewalls, Routers and Switches',
    tags: [
      'cis 20: control 11',
    ],
  },
  'cis 20: control 12': {
    title: `Control 12: ${CONTROL_FAMILY_FOUNDATIONAL}`,
    subtitle: 'Boundary Defense',
    tags: [
      'cis 20: control 12',
    ],
  },
  'cis 20: control 13': {
    title: `Control 13: ${CONTROL_FAMILY_FOUNDATIONAL}`,
    subtitle: 'Data Protection',
    tags: [
      'cis 20: control 13',
    ],
  },
  'cis 20: control 14': {
    title: `Control 14: ${CONTROL_FAMILY_FOUNDATIONAL}`,
    subtitle: 'Controlled Access Based on the Need to Know',
    tags: [
      'cis 20: control 14',
    ],
  },
  'cis 20: control 15': {
    title: `Control 15: ${CONTROL_FAMILY_FOUNDATIONAL}`,
    subtitle: 'Wireless Access Control',
    tags: [
      'cis 20: control 15',
    ],
  },
  'cis 20: control 16': {
    title: `Control 16: ${CONTROL_FAMILY_FOUNDATIONAL}`,
    subtitle: 'Account Monitoring and Control',
    tags: [
      'cis 20: control 16',
    ],
  },
  'cis 20: control 17': {
    title: `Control 17: ${CONTROL_FAMILY_ORGANIZATIONAL}`,
    subtitle: 'Implement a Security Awareness and Training Program',
    tags: [
      'cis 20: control 17',
    ],
  },
  'cis 20: control 18': {
    title: `Control 18: ${CONTROL_FAMILY_ORGANIZATIONAL}`,
    subtitle: 'Application Software Security',
    tags: [
      'cis 20: control 18',
    ],
  },
  'cis 20: control 19': {
    title: `Control 19: ${CONTROL_FAMILY_ORGANIZATIONAL}`,
    subtitle: 'Incident Response and Management',
    tags: [
      'cis 20: control 19',
    ],
  },
  'cis 20: control 20': {
    title: `Control 20: ${CONTROL_FAMILY_ORGANIZATIONAL}`,
    subtitle: 'Penetration Tests and Red Team Exercises',
    tags: [
      'cis 20: control 20',
    ],
  },
};

function TheTasksSummaryCIS20Page() {
  return (
    <TaskSummaryByCategory
      apiRoute="task/summary/cis20"
      title="CIS 20"
      topCategoryLabel="Control"
      topCategoryMap={topCategoryMap}
    >
      <>
        The <Link
          href="https://www.cisecurity.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Center for Internet Security (CIS)
        </Link> publishes a list of <Link
          href="https://www.cisecurity.org/controls/cis-controls-list/"
          target="_blank"
          rel="noopener noreferrer"
        >
          the top Critical Security Controls
        </Link>. The former CIS 20 have been updated, with
        CIS V8 representing the most recent version of the CIS Controls,
        though many organizations continue to utilize the CIS 20 framework.
        The CIS 20 are a prioritized set of guidelines organizations should follow to help protect
        themselves from the most common attack vectors. Each control has at least
        one associated task your organization should complete.
        In the table below we indicate how far along your organization has come in terms of
        implementing these controls.
      </>
    </TaskSummaryByCategory>
  );
}

TheTasksSummaryCIS20Page.requiredAuthZ = {
  tier: 1,
  permission: 'tasks',
};
TheTasksSummaryCIS20Page.routePath = '/tasks/cis20';
TheTasksSummaryCIS20Page.title = 'Task Summary: CIS 20';

export default TheTasksSummaryCIS20Page;
