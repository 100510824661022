import { FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { createStyles, makeStyles } from '@mui/styles';
import { cloneDeep } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import useAuth from '../services/auth/useAuth';
import { STORAGE_KEYS, getStorageValue } from '../services/BrowserStorageService';
import TaskDetails from './TaskDetails';

const useStyles = makeStyles(createStyles({
  viewFormControl: {
    minWidth: '10rem',
  },
}));

// TODO: Convert to TSX and use enum from server
const OPEN_TASKS_FILTERS = [
  'not_started',
  'in_progress',
  'past_due',
];

// Add new filter view selections here:
const FILTER_VIEW_SELECTIONS = {
  all_tasks: {
    text: 'All tasks',
    value: 'all_tasks',
    filter: {},
  },
  my_open_tasks: {
    text: 'My open tasks',
    value: 'my_open_tasks',
    filter: {
      assigneeId: [],
      status: OPEN_TASKS_FILTERS,
    },
  },
  all_open_tasks: {
    text: 'All open tasks',
    value: 'all_open_tasks',
    filter: {
      status: OPEN_TASKS_FILTERS,
    },
  },
  nist_open_tasks: {
    text: 'NIST open tasks',
    value: 'nist_open_tasks',
    filter: {
      status: OPEN_TASKS_FILTERS,
      tags: [ 'nist 800-53' ],
    },
  },
  round_1_open_tasks: {
    text: 'Round 1 open tasks',
    value: 'round_1_open_tasks',
    filter: {
      status: OPEN_TASKS_FILTERS,
      tags: [ 'round 1' ],
    },
  },
  round_2_open_tasks: {
    text: 'Round 2 open tasks',
    value: 'round_2_open_tasks',
    filter: {
      status: OPEN_TASKS_FILTERS,
      tags: [ 'round 2' ],
    },
  },
};

function getDefaultTasksView() {
  const viewString = getStorageValue(STORAGE_KEYS.TASKS_VIEW);

  return (viewString && Object.values(FILTER_VIEW_SELECTIONS).map(sel => sel.value).includes(viewString)) ?
    viewString :
    FILTER_VIEW_SELECTIONS.round_1_open_tasks.value;
}

function TaskCards(props) {
  const {
    assignees,
    tasks,
    handleDueDateChanged,
    handleCompletedDateChanged,
    markComplete,
    markStarted,
    onUpdateTasks,
  } = props;
  const classes = useStyles();
  const { userId } = useAuth();
  const [ visibleIdx, setVisibleIdx ] = useState([]);
  const [ page, setPage ] = useState(0);
  const [ lastPage, setLastPage ] = useState(0);
  const [ filterViewSelections, setFilterViewSelections ] = useState(cloneDeep(FILTER_VIEW_SELECTIONS));
  const [ filterViewSelection, setFilterViewSelection ] = useState(getDefaultTasksView());
  const [ filteredTaskCount, setFilteredTaskCount ] = useState(0);
  const cardsPerPage = 4;

  useEffect(() => {
    const filterViewSels = cloneDeep(FILTER_VIEW_SELECTIONS);
    filterViewSels.my_open_tasks.filter.assigneeId.push(userId);
    setFilterViewSelections(filterViewSels);
  }, [ userId ]);

  useEffect(() => {
    const filterConditions = filterViewSelections[filterViewSelection].filter;
    const newVisible = tasks.map(d => {
        let assigneeIdMatch = true;
        let statusMatch = true;
        let tagsMatch = true;

        if (filterConditions.assigneeId) {
          assigneeIdMatch = filterConditions.assigneeId.includes(d.assigneeId);
        }

        if (filterConditions.status) {
          statusMatch = filterConditions.status.includes(d.status);
        }

        if (filterConditions.tags && filterConditions.tags.length > 0) {
          const dataTagNames = d.tags.map(t => t.name);

          tagsMatch = dataTagNames.some(t => filterConditions.tags.includes(t));
        }

        return assigneeIdMatch && statusMatch && tagsMatch;
      },
    );

    const newFilteredTaskCount = newVisible.filter(d => d).length;
    setFilteredTaskCount(newFilteredTaskCount);

    setVisibleIdx(newVisible);
    setLastPage(Math.ceil(newFilteredTaskCount / cardsPerPage) - 1);
  }, [ tasks, filterViewSelection, filterViewSelections ]);

  const handleUpdateTask = (idx) => (updatedTaskInfo) => {
    onUpdateTasks([ idx ], updatedTaskInfo);
  };

  function onViewChange(event) {
    const filterViewSelection = event.target.value;
    setFilterViewSelection(filterViewSelection);
  }

  const PageButtons = () => <Fragment>
    <Grid
      item
      container
      alignItems="center"
      justifyContent="center"
      spacing={2}
      direction="row"
    >
      <Grid item xs={12} sm={5} style={{ padding: '1rem' }}>
        <FormControl className={classes.viewFormControl}>
          <InputLabel htmlFor="filter-view-select">
            Predefined Filter View
          </InputLabel>
          <Select
            value={filterViewSelection}
            onChange={onViewChange}
            inputProps={{
              name: 'filter-view-select',
              id: 'filter-view-select',
            }}
          >
            {Object.values(filterViewSelections).map(filterView => (
              <MenuItem
                key={filterView.value}
                value={filterView.value}
              >
                {filterView.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={7}>
        <IconButton disabled={page === 0} onClick={() => setPage(page - 1)}
                    size="large"><KeyboardArrowLeftIcon /></IconButton>
        <Typography variant="caption">
          {cardsPerPage * page + 1}-{cardsPerPage * (page + 1) > filteredTaskCount ? filteredTaskCount : cardsPerPage * (page + 1)} of {filteredTaskCount}
        </Typography>
        <IconButton
          disabled={page === lastPage}
          onClick={() => setPage(page + 1)}
          size="large"><KeyboardArrowRightIcon /></IconButton>
      </Grid>
    </Grid>
  </Fragment>;

  const Cards = () => {
    if (tasks && tasks.length > 0) {
      let item = 0;
      let cardsDisplayed = 0;
      const itemsToSkip = cardsPerPage * page;
      const CardsForCurrentPage = Array(cardsPerPage);

      tasks.forEach((task, i) => {
        if (cardsDisplayed < cardsPerPage
          && visibleIdx[i]
          && item++ >= itemsToSkip) {

          CardsForCurrentPage[cardsDisplayed++] = (
            <Grid item xs={12} md={6} key={i}>
              <TaskDetails
                assignees={assignees}
                isCardView
                markComplete={markComplete(i)}
                markStarted={markStarted(i)}
                onDueDateChange={handleDueDateChanged(i)}
                onCompletedDateChange={handleCompletedDateChanged(i)}
                onUpdateTask={handleUpdateTask(i)}
                taskData={task} />
            </Grid>
          );
        }
      });

      return CardsForCurrentPage;
    } else {
      return null;
    }
  };

  return (
    <>
      <Grid container>
        <PageButtons />
        <Cards />
        <PageButtons />
      </Grid>
    </>
  );
}

TaskCards.title = 'Tasks';
TaskCards.requiredAuthZ = {
  tier: 1,
  permission: 'tasks',
};

export default TaskCards;
