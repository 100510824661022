import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const questionComponentStyles = (theme: Theme) => createStyles({
  questionTextContainer: {
    marginTop: theme.spacing(1),
  },
  inputContainer: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
});
