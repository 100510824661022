import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Theme,
  Tooltip,
} from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AttachIcon from '@mui/icons-material/AttachFile';
import CalendarIcon from '@mui/icons-material/CalendarToday';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import ErrorIcon from '@mui/icons-material/Error';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { DatePicker } from '@mui/lab';
import * as Sentry from '@sentry/react';
import classnames from 'classnames';
import { sortBy } from 'lodash';
import moment, { Moment } from 'moment';
import ReactMarkdown from 'react-markdown';
import rehypeSanitize, { defaultSchema } from 'rehype-sanitize';
import remarkGfm from 'remark-gfm';
import { IIdNameDto } from '../../../backend/src/common/id-name-dto.interface';
import IDocument from '../../../backend/src/document/document.interface';
import { IRiskDto } from '../../../backend/src/risk/interfaces';
import { ITaskAssigneeDto, ITaskDto, ITasksResponse } from '../../../backend/src/task/interfaces';
import { APP_BASE_URL } from '../constants';
import * as Helpers from '../helpers';
import { TheTasksPage } from '../pages';
import API from '../services/ApiService';
import useAuth from '../services/auth/useAuth';
import * as DocService from '../services/DocService';
import { CloseButton } from './buttons';
import {
  FileUploadDialog,
  RemoveFileDialog,
  RemoveRiskFromTaskDialog,
  RiskDetailsDialog,
  RiskEditDialog,
  TaskEditDialog,
  TaskAssignmentDialog,
  TaskPercentCompleteDialog,
} from './dialogs';
import { TASK_STATUS_MAP } from './FilterViewSelections';
import { showErrorResultBar, showSuccessResultBar } from './ResultSnackbar';
import { DetailSectionHeading } from './RiskDetails';
import { RouterLink } from './RouterLink';
import TaskComments from './TaskComments';
import TaskRiskLinkMenu from './TaskRiskLinkMenu';

const styles = (theme: Theme) => createStyles({
  dialogCardRoot: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    margin: '0',
    padding: '0',
  },
  dialogCardContent: {
    margin: theme.spacing(2),
    overflowY: 'auto',
  },
  dialogCardActions: {
    paddingTop: '16px',
  },
  smallIconButton: {
    'height': '14px',
    'width': '14px',
    'marginTop': '-6px',
    'marginLeft': '0.2rem',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  smallIconButtonIcon: {
    fontSize: '18px',
  },
  prependSmallIcon: {
    fontSize: '14px',
    height: '14px',
    marginRight: '5px',
    marginBottom: '-1px',
  },
  cardHeader: {
    paddingBottom: '0',
  },
  dateContainer: {
    textAlign: 'center',
  },
  editButton: {
    verticalAlign: 'bottom',
  },
  invalidAssigneeIcon: {
    marginLeft: '4px',
    verticalAlign: 'middle',
  },
  buttonLink: {
    'color': 'inherit',
    'textDecoration': 'underline',
    'cursor': 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  primaryColumn: {
    padding: theme.spacing(2),
  },
  referenceRouterLink: {
    'color': theme.typography.body1.color,
    'display': 'block',
    'marginTop': '4px',
    'textDecoration': 'underline',
    '&:first-of-type': {
      marginTop: 0,
    },
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  referencesContainer: {
    paddingLeft: '1rem',
    paddingBottom: '0.8rem',
  },
  secondaryColumn: {
    backgroundColor: '#f5f5f5',
    opacity: '0.8',
    fontSize: '0.8125rem',
    padding: theme.spacing(2),
  },
  tagChips: {
    textAlign: 'right',
  },
  tagChip: {
    fontSize: '0.7rem',
    height: '20px',
    margin: theme.spacing(0.25),
  },
  tagLabel: {
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  taskDescription: {
    color: theme.typography.body1.color,
  },
});

export interface TaskDetailsProps extends WithStyles<typeof styles> {
  assignees?: ITaskAssigneeDto[];
  isCardView?: boolean;
  isDialog?: boolean;
  markComplete: () => Promise<void>;
  markStarted: () => Promise<void>;
  onCloseDialog?: () => void;
  onDueDateChange: (newDate: Moment | null) => Promise<void>;
  onCompletedDateChange: (newDate: Moment | null) => Promise<void>;
  onUpdateTask: (updatedTaskInfo: ITasksResponse) => void | Promise<void>;
  taskData: ITaskDto;
}

function TaskDetails(props: TaskDetailsProps) {
  const {
    assignees = [],
    classes,
    isCardView = false,
    isDialog = false,
    markComplete,
    markStarted,
    onCloseDialog = () => undefined,
    onDueDateChange,
    onCompletedDateChange,
    onUpdateTask,
    taskData,
  } = props;
  const { isGranted } = useAuth();

  const [ assignee, setAssignee ] = useState<ITaskAssigneeDto | null>(null);
  const [ documents, setDocuments ] = useState(taskData.documents || []);
  const [ documentToArchive, setDocumentToArchive ] = useState<IIdNameDto | null>(null);
  const [ isTaskAssigned, setIsTaskAssigned ] = useState(false);
  const [ isValidAssignee, setIsValidAssignee ] = useState(true);
  const [ menuAnchorEl, setMenuAnchorEl ] = useState<null | HTMLElement>(null);
  const [ risks, setRisks ] = useState(taskData.risks || []);
  const [ riskToRemove, setRiskToRemove ] = useState<IIdNameDto | null>(null);
  const [ selectedRisk, setSelectedRisk ] = useState<IRiskDto | null>(null);
  const [ subMenuAnchorEl, setSubMenuAnchorEl ] = useState<null | HTMLElement>(null);
  const [ toShowAssignDialog, setToShowAssignDialog ] = useState(false);
  const [ toShowCreateRiskDialog, setToShowCreateRiskDialog ] = useState(false);
  const [ toShowDueDatePickerDialog, setToShowDueDatePickerDialog ] = useState(false);
  const [ toShowEditDialog, setToShowEditDialog ] = useState(false);
  const [ toShowFileUploadDialog, setToShowFileUploadDialog ] = useState(false);
  const [ toShowPercentCompleteDialog, setToShowPercentCompleteDialog ] = useState(false);
  const [ toShowRemoveEvidenceDialog, setToShowRemoveEvidenceDialog ] = useState(false);
  const [ toShowRemoveRiskDialog, setToShowRemoveRiskDialog ] = useState(false);
  const [ toShowRiskDetailsDialog, setToShowRiskDetailsDialog ] = useState(false);
  const [ toShowCompletedDateDialog, setToShowCompletedDateDialog ] = useState(false);
  const [ displayedDueDate, setDisplayedDueDate ] = useState<Moment | null>(moment(taskData.dueDate));
  const [ displayedCompletedDate, setDisplayedCompletedDate ] = useState<Moment | null>(moment(taskData.completedAt));

  useEffect(() => {
    let currAssignee = null;

    if (taskData.assigneeId) {
      currAssignee = { id: taskData.assigneeId, name: taskData.assigneeName };
    }

    setIsTaskAssigned(!!currAssignee);
    setAssignee(currAssignee);
    setDisplayedCompletedDate(moment(taskData.completedAt));
  }, [ taskData.assigneeId, taskData.assigneeName, taskData.completedAt ]);

  useEffect(() => {
    // An 'invalid' assignee is one who's been assigned to a task already but is no longer in the list of allowed assignees.
    // 'assignee = null' corresponds to 'unassigned', which is considered valid.
    // If 'assignees' isn't passed in as a prop, it defaults to [] (ie, length = 0) and we don't worry about checking the validity.
    setIsValidAssignee(assignee === null || assignees.length === 0 || assignees.some(a => a.id === assignee.id));
  }, [ assignee, assignees ]);

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
    setSubMenuAnchorEl(null);
  };

  const handleClickMenuItem = (handler: (event: React.MouseEvent) => void) => (event: React.MouseEvent) => {
    handleCloseMenu();
    handler(event);
  };

  const handleArchiveRisk = () => {
    const riskId = selectedRisk?.id;
    setToShowRiskDetailsDialog(false);
    setSelectedRisk(null);

    if (riskId) {
      removeRiskFromRisks(riskId);
    }
  };

  const handleAttachRisk = async (risk: IIdNameDto) => {
    handleCloseMenu();
    attachRisk(risk);
  };

  const handleClickRemoveRisk = (risk: IIdNameDto) => () => {
    setRiskToRemove(risk);
    setToShowRemoveRiskDialog(true);
  };

  const handleClickRisk = (riskId: string) => async () => {
    try {
      const res = await API.get(`risk/${riskId}`);
      setSelectedRisk(res.data.data);
      setToShowRiskDetailsDialog(true);
    } catch (err: any) {
      Sentry.captureException(err);
      showErrorResultBar('Unexpected error accessing the risk');
    }
  };

  const handleCreateRisk = async (risk: IRiskDto) => {
    attachRisk({ id: risk.id, name: risk.risk });
  };

  const handleRemoveRisk = () => {
    const riskId = riskToRemove?.id;
    setToShowRemoveRiskDialog(false);
    setRiskToRemove(null);

    if (riskId) {
      removeRiskFromRisks(riskId);
    }
  };

  const handleUpdateRisk = (risk: IRiskDto) => {
    // Update the Risk Details info:
    setSelectedRisk(risk);

    // Update the list of Risks in the Task:
    const idx = risks.findIndex(r => r.id === risk.id);
    if (idx !== (-1)) {
      const updatedRisks = risks.slice();
      updatedRisks[idx] = { id: risk.id, name: risk.risk };
      setRisks(sortRisks(updatedRisks));
    }
  };

  const removeRiskFromRisks = (riskId: string) => {
    const idx = risks.findIndex(r => r.id === riskId);

    if (idx !== (-1)) {
      const updatedRisks = risks.slice();
      updatedRisks.splice(idx, 1);
      setRisks(updatedRisks);
    }
  };

  const attachRisk = async (risk: IIdNameDto) => {
    try {
      await API.put(`task/${taskData.id}/risk/${risk.id}`);
      const updatedRisks = risks.concat(risk);
      setRisks(sortRisks(updatedRisks));
      onUpdateTask({ risks: updatedRisks });
    } catch (err: any) {
      Sentry.captureException(err);
      showErrorResultBar('Unexpected error while linking to the risk');
    }
  };

  const sortRisks = (risksToSort: IIdNameDto[]) => sortBy(risksToSort, r => r.name?.toLowerCase());

  const handleDueDateChange = (newDate: Moment | null) => {
    if (onDueDateChange) {
      onDueDateChange(newDate);
    }
  };


  const handleCompletedDateChange = (newDate: Moment | null) => {
    if (onCompletedDateChange) {
      onCompletedDateChange(newDate);
    }
  };

  const handleCopyTaskURLToClipboard = () => {
    const taskUrl = `${APP_BASE_URL}${TheTasksPage.routePath}?id=${taskData.id}`;

    navigator.clipboard.writeText(taskUrl)
      .then(() => showSuccessResultBar('Copied task link to the clipboard'))
      .catch(() => showErrorResultBar('Could not copy link to clipboard'));
  };

  const handleClickRemoveEvidence = (document: IIdNameDto) => () => {
    setDocumentToArchive(document);
    setToShowRemoveEvidenceDialog(true);
  };

  const handleAttachEvidence = async (newDoc: IDocument) => {
    try {
      await API.put(`task/${taskData.id}/documents/${newDoc.id}`);
      const updatedDocs = documents.concat({ id: newDoc.id, name: newDoc.name || '' } as IIdNameDto);
      setDocuments(updatedDocs);
      onUpdateTask({ documents: updatedDocs });
      showSuccessResultBar('Document attached successfully.');
    } catch (err: any) {
      Sentry.captureException(err);
      showErrorResultBar('Unexpected error while attaching the document');
    }
  };

  const handleRemoveEvidence = async (docId: string) => {
    try {
      await API.delete(`task/${taskData.id}/documents/${docId}`);
      const updatedDocs = documents.slice().filter(doc => doc.id !== docId);
      setDocuments(updatedDocs);
      onUpdateTask({ documents: updatedDocs });
      showSuccessResultBar('Evidence archived.');

      return true;
    } catch (err: any) {
      Sentry.captureException(err);
      showErrorResultBar('Unexpected error while archiving evidence. Please try again.');

      return false;
    }
  };

  return <>
    <Card className={classnames(isDialog && classes.dialogCardRoot)}>
      <CardHeader
        action={<>
          <IconButton
            onClick={({ currentTarget }) => setMenuAnchorEl(currentTarget)}
            size="large">
            <MoreVertIcon />
          </IconButton>
          {isDialog && <CloseButton onClick={onCloseDialog} />}
        </>}
        className={classnames(!isDialog && classes.cardHeader)}
        title={taskData.name}
        subheader={
          isTaskAssigned ? (
            <>
              <span>
                <Tooltip
                  title="Reassign this task"
                >
                  <Link
                    className={classes.buttonLink}
                    component="a"
                    onClick={() => setToShowAssignDialog(true)}
                  >
                    Assigned to:
                  </Link>
                </Tooltip> {taskData.assigneeName}
              </span>
              {!isValidAssignee &&
                <Tooltip
                  title="This user no longer has access to tasks"
                >
                  <ErrorIcon className={classes.invalidAssigneeIcon} />
                </Tooltip>
              }
            </>
          ) : (
            <Tooltip
              title="Assign this task"
            >
              <Link
                className={classes.buttonLink}
                component="a"
                onClick={() => setToShowAssignDialog(true)}
              >
                Unassigned
              </Link>
            </Tooltip>
          )
        }
      />
      <Menu
        anchorEl={menuAnchorEl}
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={handleCloseMenu}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem
          disabled={assignees.length === 0}
          onClick={handleClickMenuItem(() => setToShowAssignDialog(true))}
        >
          {isTaskAssigned ? (
            <span>Reassign task</span>
          ) : (
            <span>Assign task</span>
          )}
        </MenuItem>
        <MenuItem onClick={handleClickMenuItem(() => setToShowFileUploadDialog(true))}>
          Attach evidence
        </MenuItem>
        <MenuItem
          disabled={taskData.status === 'completed'}
          onClick={handleClickMenuItem(() => setToShowDueDatePickerDialog(true))}
        >
          Change due date
        </MenuItem>
        <MenuItem onClick={handleClickMenuItem(() => setToShowCreateRiskDialog(true))}>
          Create new risk
        </MenuItem>
        <MenuItem onClick={e => setSubMenuAnchorEl(e.currentTarget)}>
          Link to existing risk
        </MenuItem>
        <MenuItem
          disabled={taskData.status === 'completed'}
          onClick={handleClickMenuItem(() => setToShowPercentCompleteDialog(true))}
        >
          Update percent complete
        </MenuItem>
        <MenuItem onClick={handleClickMenuItem(handleCopyTaskURLToClipboard)}>
          Copy task link to clipboard
        </MenuItem>
        {isGranted({ permission: 'admin:task_master' }) &&
          <MenuItem onClick={handleClickMenuItem(() => setToShowEditDialog(true))}>
            Edit task name/description
          </MenuItem>
        }
      </Menu>
      {isDialog && <Divider />}
      <CardContent className={classnames(isDialog && classes.dialogCardContent)}>
        <Grid container spacing={2}>
          {/* Primary column */}
          <Grid
            className={classes.primaryColumn}
            item
            xs={12}
            sm={isCardView ? 12 : 8}
            md={isCardView ? 12 : (isDialog ? 8 : 9)}
            container
            direction="column"
            spacing={0}
            justifyContent="space-between"
          >
            {/* Description */}
            <Grid item>
              {taskData.description &&
                <Box sx={{ typography: 'body1' }}>
                  <ReactMarkdown
                    children={taskData.description}
                    className="general-md-editor"
                    components={{
                      a: ({ children, href }) => <a children={children} href={href} rel="noopener noreferrer" target="_blank" />,
                    }}
                    rehypePlugins={[[
                      rehypeSanitize, {
                        ...defaultSchema,
                        protocols: { href: ['http', 'https'] }
                      }
                    ]]}
                    remarkPlugins={[ remarkGfm ]}
                  />
                </Box>
              }
            </Grid>
            {/* Comments */}
            <Grid item>
              <TaskComments
                comments={taskData.comments}
                onUpdateComments={(comments) => onUpdateTask({ comments })}
                taskId={taskData.id}
              />
            </Grid>
          </Grid>
          {/* Secondary column */}
          <Grid
            className={classes.secondaryColumn}
            item
            xs={12}
            sm={isCardView ? 12 : 4}
            md={isCardView ? 12 : (isDialog ? 4 : 3)}
          >
            {/* Status */}
            <DetailSectionHeading>
              Status: {TASK_STATUS_MAP[taskData.status]}
            </DetailSectionHeading>
            <div className={classes.referencesContainer}>
              {taskData.status === 'completed' && taskData.completedAt &&
                <Grid container justifyContent="space-between">
                  <Grid item>Completed:</Grid>
                  <Grid item>
                    <DatePicker
                      disableFuture
                      onAccept={handleCompletedDateChange}
                      onChange={date => setDisplayedCompletedDate(date)}
                      onClose={() => setToShowCompletedDateDialog(false)}
                      open={toShowCompletedDateDialog}
                      value={displayedCompletedDate}
                      renderInput={({ inputRef }) => (
                        <Tooltip
                          title="Update the completed date"
                        >
                          <Link
                            ref={inputRef}
                            className={classes.buttonLink}
                            component="a"
                            onClick={() => setToShowCompletedDateDialog(true)}
                          >
                            <CalendarIcon className={classes.prependSmallIcon} />
                            {Helpers.formatDate(taskData.completedAt)}
                          </Link>
                        </Tooltip>
                      )}
                    />
                  </Grid>
                </Grid>}
              {taskData.status === 'in_progress' &&
                <Grid container justifyContent="space-between">
                  <Grid item>Percent complete:</Grid>
                  <Grid item>
                    <Tooltip
                      title="Update the percent complete"
                    >
                      <Link
                        component="a"
                        onClick={() => setToShowPercentCompleteDialog(true)}
                        className={classes.buttonLink}
                      >
                        <EditIcon className={classes.prependSmallIcon} />
                        {taskData.percentComplete}%
                      </Link>
                    </Tooltip>
                  </Grid>
                </Grid>
              }
              <Grid container justifyContent="space-between">
                <Grid item>Due date:</Grid>
                <Grid item>
                  {taskData.status === 'completed' ? (
                    Helpers.formatDate(taskData.dueDate)
                  ) : (
                    <>
                      <DatePicker
                        disablePast
                        onAccept={handleDueDateChange}
                        onChange={date => setDisplayedDueDate(date)}
                        onClose={() => setToShowDueDatePickerDialog(false)}
                        open={toShowDueDatePickerDialog}
                        value={displayedDueDate}
                        renderInput={({ inputRef }) => (
                          <Tooltip
                            title="Change the due date"
                          >
                            <Link
                              ref={inputRef}
                              className={classes.buttonLink}
                              component="a"
                              onClick={() => setToShowDueDatePickerDialog(true)}
                            >
                              <CalendarIcon className={classes.prependSmallIcon} />
                              {Helpers.formatDate(taskData.dueDate)}
                            </Link>
                          </Tooltip>
                        )}
                      />
                    </>
                  )}
                </Grid>
              </Grid>
              {taskData.startedAt &&
                <Grid container justifyContent="space-between">
                  <Grid item>Started:</Grid>
                  <Grid item>{Helpers.formatDate(taskData.startedAt)}</Grid>
                </Grid>
              }
            </div>
            {/* Policies */}
            {(taskData.policyDocNames.length > 0) &&
              <>
                <DetailSectionHeading>Policies</DetailSectionHeading>
                <div className={classes.referencesContainer}>
                  {taskData.policyDocNames.map(policyDoc => (
                    <RouterLink key={policyDoc} to="/policies" className={classes.referenceRouterLink}>
                      {/* {Helpers.truncateString(p).replace(/ Policy$/g, '')} */}
                      {policyDoc}
                    </RouterLink>
                  ))}
                </div>
              </>
            }
            {/* Training videos */}
            {taskData.trainingVids && taskData.trainingVids.length > 0 &&
              <>
                <DetailSectionHeading>Training videos</DetailSectionHeading>
                <div className={classes.referencesContainer}>
                  {taskData.trainingVids.map(video => (
                    <RouterLink key={video.id} to={`/training/${video.id}`} className={classes.referenceRouterLink}>
                      {Helpers.truncateString(video.name)}
                    </RouterLink>
                  ))}
                </div>
              </>
            }
            {/* Attachments */}
            <DetailSectionHeading>
              Attachments
              <Tooltip
                title="Attach evidence"
              >
                <IconButton
                  classes={{
                    root: classes.smallIconButton,
                  }}
                  onClick={() => setToShowFileUploadDialog(true)}
                  size="large">
                  <AttachIcon className={classes.smallIconButtonIcon} />
                </IconButton>
              </Tooltip>
            </DetailSectionHeading>
            <div className={classes.referencesContainer}>
              {documents.length > 0 ? (
                documents.map(doc => (
                  <Grid key={doc.id} container justifyContent="space-between" wrap="nowrap">
                    <Grid item>
                      <Link
                        className={classes.buttonLink}
                        onClick={DocService.documentDownloadHandler(doc.id)}
                      >
                        {Helpers.truncateString(doc.name)}
                      </Link>
                    </Grid>
                    {taskData.status !== 'completed' && (
                      <Grid item>
                        <Tooltip
                          title="Remove this attachment"
                        >
                          <IconButton
                            classes={{
                              root: classes.smallIconButton,
                            }}
                            onClick={handleClickRemoveEvidence(doc)}
                            size="large">
                            <DeleteIcon className={classes.smallIconButtonIcon} />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    )}
                  </Grid>
                ))
              ) : (
                <em>None</em>
              )}
            </div>
            {/* Risks */}
            <DetailSectionHeading>
              Risks
              <Tooltip title="Create a new risk associated with this task">
                <IconButton
                  classes={{
                    root: classes.smallIconButton,
                  }}
                  onClick={() => setToShowCreateRiskDialog(true)}
                  size="large">
                  <AddCircleOutlineIcon className={classes.smallIconButtonIcon} />
                </IconButton>
              </Tooltip>
            </DetailSectionHeading>
            <div className={classes.referencesContainer}>
              {risks.length > 0 ? (
                risks.map(risk => (
                  <Grid key={risk.id} container justifyContent="space-between" wrap="nowrap">
                    <Grid item>
                      <Link
                        className={classes.buttonLink}
                        onClick={handleClickRisk(risk.id)}
                      >
                        {risk.name}
                      </Link>
                    </Grid>
                    <Grid item>
                      <Tooltip
                        title="Remove this risk"
                      >
                        <IconButton
                          classes={{
                            root: classes.smallIconButton,
                          }}
                          onClick={handleClickRemoveRisk(risk)}
                          size="large">
                          <DeleteIcon className={classes.smallIconButtonIcon} />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                ))
              ) : (
                <em>None</em>
              )}
            </div>
            {/* Tags */}
            <DetailSectionHeading>Tags</DetailSectionHeading>
            <div className={classes.referencesContainer}>
              {sortBy(taskData.tags, [ 'name' ]).map(tag => (
                <Chip
                  classes={{ label: classes.tagLabel }}
                  className={classes.tagChip}
                  key={tag.id}
                  label={tag.name}
                />
              ))}
            </div>
          </Grid>
        </Grid>
      </CardContent>
      {isDialog && <Divider />}
      <CardActions
        className={classnames(isDialog && classes.dialogCardActions)}
      >
        {taskData.status === 'completed' ? (
          <Button
            color="primary"
            size="small"
            onClick={markStarted}
          >
            Reopen
          </Button>
        ) : (
          <Button
            color="primary"
            size="small"
            onClick={markStarted}
            disabled={![ 'not_started', 'past_due' ].includes(taskData.status)}
          >
            Started
          </Button>
        )}
        <Button
          color="primary"
          size="small"
          onClick={markComplete}
          disabled={taskData.status === 'completed'}
        >
          Complete
        </Button>
      </CardActions>
    </Card>
    <FileUploadDialog
      dialogHeaderText="Attach Evidence"
      documentCategory="evidence/task"
      onAddDocument={handleAttachEvidence}
      onClose={() => setToShowFileUploadDialog(false)}
      open={toShowFileUploadDialog}
    />
    {documentToArchive &&
      <RemoveFileDialog
        document={documentToArchive}
        onClose={() => setToShowRemoveEvidenceDialog(false)}
        onExited={() => setDocumentToArchive(null)}
        onRemoveFile={handleRemoveEvidence}
        open={toShowRemoveEvidenceDialog}
      />
    }
    {riskToRemove &&
      <RemoveRiskFromTaskDialog
        risk={riskToRemove}
        task={taskData}
        onClose={() => setToShowRemoveRiskDialog(false)}
        onExited={() => setRiskToRemove(null)}
        onRemoveRisk={handleRemoveRisk}
        open={toShowRemoveRiskDialog}
      />
    }
    {selectedRisk &&
      <RiskDetailsDialog
        onArchive={handleArchiveRisk}
        onClose={() => setToShowRiskDetailsDialog(false)}
        onUpdate={handleUpdateRisk}
        open={toShowRiskDetailsDialog}
        riskData={selectedRisk}
      />
    }
    <RiskEditDialog
      open={toShowCreateRiskDialog}
      riskData={null}
      onClose={() => setToShowCreateRiskDialog(false)}
      onUpdate={handleCreateRisk}
    />
    {!!menuAnchorEl && // Load the risks for this sub-menu when the top-level menu opens
      <TaskRiskLinkMenu
        anchorEl={subMenuAnchorEl}
        excludeRiskIds={risks.map(r => r.id) || []}
        onAttachRisk={handleAttachRisk}
        onClose={() => setSubMenuAnchorEl(null)}
        open={Boolean(subMenuAnchorEl)}
        PaperProps={{
          style: {
            maxHeight: 500,
            minWidth: 200,
          },
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      />
    }
    <TaskAssignmentDialog
      assignees={assignees}
      onClose={() => setToShowAssignDialog(false)}
      onUpdateTasks={onUpdateTask}
      open={toShowAssignDialog}
      tasks={[ taskData ]}
    />
    <TaskPercentCompleteDialog
      onClose={() => setToShowPercentCompleteDialog(false)}
      onUpdateTask={onUpdateTask}
      open={toShowPercentCompleteDialog}
      task={taskData}
    />
    {toShowEditDialog && (
      <TaskEditDialog
        onClose={() => setToShowEditDialog(false)}
        onUpdateTask={onUpdateTask}
        open={toShowEditDialog}
        task={taskData}
      />
    )}
  </>;
}

export default withStyles(styles, { withTheme: true })(TaskDetails);
