import { AccordionSummary, Divider, Typography } from '@mui/material';
import { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as React from 'react';

function AccordionIconSummary(props: AccordionSummaryProps) {
  const { children, title } = props;

  return (
    <>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} {...props}>
        {title ?
          <div>
            <Typography variant="h6">{title}</Typography>
            {children}
          </div>
          :
          children
        }
      </AccordionSummary>
      <Divider />
    </>
  );
}

AccordionIconSummary.muiName = 'AccordionSummary';

export default AccordionIconSummary;
