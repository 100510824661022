import { Card, CardContent, Typography } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { LinedCardHeader } from './cards';

export interface ISubscriptionPlanDetails {
  id: number;
  name: string;
  price: string;
  subheader?: string;
  items: Array<string | string[]>;
}

export const SUBSCRIPTION_PLANS: ISubscriptionPlanDetails[] = [
  {
    id: 1,
    name: 'Startup',
    price: '$99/month',
    subheader: 'The foundation',
    items: [
      'Dashboard',
      'Standards aligned policies',
      [ 'CIS 20', 'NIST 800-53', 'NIST CSF', 'SOC 2' ],
      'Security Training',
      'Task Management',
      'Chat & Email Support',
    ],
  },
  {
    id: 2,
    name: 'Complete',
    price: '$499/month',
    subheader: 'Everything in Startup, plus tools to help you!',
    items: [
      'Attestation Letter',
      'Network Scans',
      'Risk Register',
      'User Audit',
      'Vendor Tracker',
      'Slack Support',
    ],
  },
  {
    id: 3,
    name: 'Assisted',
    price: '$2000/month',
    subheader: 'Everything in Complete, plus we help you do it!',
    items: [
      'Project Manager',
      'Team Handles Vendors',
      'Team Handles Questionnaires',
      'Team Coordinates Program',
      'Risk Assessment',
      'Additional support available',
    ],
  },
];

const useStyles = makeStyles({
  clickable: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  card: {
    margin: 0,
    padding: 0,
    height: '100%',
  },
  cardContent: {
    padding: '0.5rem',
    margin: '0.5rem',
  },
  cardItemList: {
    paddingLeft: '0.5rem',
    listStylePosition: 'inside',
  },
  cardItemSubList: {
    paddingLeft: '1rem',
    listStylePosition: 'inside',
  },
  cardItem: {
    display: 'list-item',
  },
});

export interface SubscriptionPlanCardProps {
  isEditing: boolean;
  isSelected: boolean;
  onClick?: () => void;
  plan: ISubscriptionPlanDetails;
}

export default function SubscriptionPlanCard({ isEditing, isSelected, onClick, plan }: SubscriptionPlanCardProps) {
  const classes = useStyles();
  const [ isRaised, setIsRaised ] = useState<boolean>(false);
  const [ elevation, setElevation ] = useState<number>(1);

  useEffect(() => {
    if (isRaised) {
      setElevation(10);
    } else if (isEditing) {
      setElevation(3);
    } else if (isSelected) {
      setElevation(10);
    } else {
      setElevation(1);
    }
  }, [ isEditing, isSelected, isRaised ]);

  return (
    <Card
      className={classNames(
        classes.card,
        isEditing && classes.clickable,
      )}
      elevation={elevation}
      onMouseOver={() => setIsRaised(isEditing && true)}
      onMouseOut={() => setIsRaised(false)}
      onClick={onClick}
    >
      <LinedCardHeader
        title={plan.name}
        subheader={plan.price}
        action={isSelected &&
        <CheckBoxIcon
          fontSize="large"
          color="secondary"
        />
        }
      />
      <CardContent className={classes.cardContent}>
        <Typography
          variant="body2"
          color="textPrimary"
          component="p"
        >
          {plan.subheader}
        </Typography>
        <ul className={classes.cardItemList}>
          {plan.items.map((planItem, idx) => Array.isArray(planItem) ? (
            <ul
              key={idx}
              className={classes.cardItemSubList}
            >
              {planItem.map(planSubItem => (
                <Typography
                  key={planSubItem}
                  className={classes.cardItem}
                  variant="body2"
                  color="textPrimary"
                  component="li"
                >
                  {planSubItem}
                </Typography>
              ))}
            </ul>
          ) : (
            <Typography
              key={idx}
              className={classes.cardItem}
              variant="body2"
              color="textPrimary"
              component="li"
            >
              {planItem}
            </Typography>
          ))}
        </ul>
      </CardContent>
    </Card>
  );
}
