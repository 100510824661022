import { Divider, Menu, MenuItem } from '@mui/material';
import { MenuProps } from '@mui/material/Menu';
import * as Sentry from '@sentry/react';
import React, { useEffect, useState } from 'react';
import { IOrgDto } from '../../../backend/src/org/interfaces';
import { showErrorResultBar, showSuccessResultBar } from './ResultSnackbar';
import API from '../services/ApiService';
import useAuth from '../services/auth/useAuth';

export interface ChangeOrgMenuProps extends MenuProps {
  onClickOrg: () => void;
  onSwitchOrg: (orgId: string) => void;
}

export default function ChangeOrgMenu({ onClickOrg, onSwitchOrg, ...menuProps }: ChangeOrgMenuProps) {
  const { orgId, primaryOrgId, renewSession } = useAuth();
  const [ activeOrgId, setActiveOrgId ] = useState<string | undefined>(orgId ?? undefined);
  const [ additionalOrgs, setAdditionalOrgs ] = useState<IOrgDto[]>([]);
  const [ primaryOrg, setPrimaryOrg ] = useState<IOrgDto>();

  useEffect(() => {
    (async function fetchOrgs() {
      try {
        const res = await API.get('org');
        const allOrgs: IOrgDto[] = res.data?.data || [];
        setAdditionalOrgs(allOrgs.filter(org => org.id !== primaryOrgId));
        setPrimaryOrg(allOrgs.find(org => org.id === primaryOrgId));
      } catch (err: any) {
        const errMessage = err?.response?.data?.error ?? 'Error retrieving cross-org list';
        showErrorResultBar(errMessage);
        Sentry.captureException(err);
      }
    })();
  }, [ primaryOrgId ]);

  const handleSwitchOrg = (org: IOrgDto) => async () => {
    showSuccessResultBar(`Switching to ${org.name}`);
    onClickOrg();

    try {
      await API.post(`auth/activateOrg/${org.id}`);
      await renewSession();
      showSuccessResultBar(`Switched org to ${org.name}`);
      setActiveOrgId(org.id);
      onSwitchOrg(org.id);
    } catch (err: any) {
      const errMessage = err?.response?.data?.error ?? 'Error switching orgs';
      showErrorResultBar(errMessage);
      Sentry.captureException(err);
    }
  };

  return (
    <Menu
      {...menuProps}
    >
      {!primaryOrg && additionalOrgs.length === 0 && (
        <MenuItem disabled>
          No available orgs
        </MenuItem>
      )}
      {primaryOrg && (
        <MenuItem
          onClick={handleSwitchOrg(primaryOrg)}
          disabled={primaryOrg.id === activeOrgId}
          selected={primaryOrg.id === activeOrgId}
        >
          {primaryOrg.name}
        </MenuItem>
      )}
      {primaryOrg && additionalOrgs.length > 0 && <Divider />}
      {additionalOrgs.map(org => (
        <MenuItem
          key={org.id}
          onClick={handleSwitchOrg(org)}
          disabled={org.id === activeOrgId}
          selected={org.id === activeOrgId}
        >
          {org.name}
        </MenuItem>
      ))}
    </Menu>
  );
}
