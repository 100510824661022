import { Button, DialogActions, DialogContent, DialogContentText, DialogProps, Divider } from '@mui/material';
import React from 'react';
import { ITrainingVideoViewDto, ITrainingViewUpdateDto } from '../../../../backend/src/training/interfaces';
import StyledDialogTitle from '../StyledDialogTitle';
import VideoPlayer from '../VideoPlayer';
import ResponsiveDialog from './ResponsiveDialog';

export interface IVideoViewInfo extends Pick<ITrainingVideoViewDto, 'description' | 'name'> {
  captionUrl?: string | null;
  played: number;
  url: string;
}

export interface VideoDialogProps extends DialogProps {
  videoViewInfo: IVideoViewInfo;
  onClose: () => void;
  onError?: (err: any) => void;
  onUpdateVideo?: (updateInfo: ITrainingViewUpdateDto) => Promise<void>;
}

export default function VideoDialog({ open, onClose, onError, onUpdateVideo, videoViewInfo }: VideoDialogProps) {
  const { captionUrl, description, name, played, url } = videoViewInfo;

  return (
    <ResponsiveDialog
      disableBackdropClick
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      onContextMenu={e => e.preventDefault()}
    >
      <StyledDialogTitle id="customized-dialog-title" onClose={onClose}>
        {name}
      </StyledDialogTitle>
      <DialogContent>
        {url &&
        <VideoPlayer
          captionUrl={captionUrl || null}
          played={played}
          url={url}
          onError={onError}
          onUpdateVideo={onUpdateVideo}
        />
        }
        {description && <>
        <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
        <DialogContentText>
          {description}
        </DialogContentText>
        </>}
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={onClose}
        >
          Close
        </Button>
      </DialogActions>
    </ResponsiveDialog>
  );
}
