import { Theme } from '@mui/material';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import React from 'react';
import CircleCheckIcon from './CircleCheckIcon';

const styles = (theme: Theme) => createStyles({
  icon: {
    color: theme.palette.success.main,
    stroke: theme.palette.success.main,
  },
});

export type ApprovedIconProps = WithStyles<typeof styles>;

function ApprovedIcon({ classes }: ApprovedIconProps) {
  return (
    <CircleCheckIcon
      className={classes.icon}
      text="Approved"
    />
  );
}

export default withStyles(styles, { withTheme: true })(ApprovedIcon);
