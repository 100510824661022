import { Button, Card, CardActions, CardContent, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { SaveButton } from '../buttons';
import { IOrg } from '../../pages/TheOrganizationPage';
import SubscriptionPlanCard, { SUBSCRIPTION_PLANS } from '../SubscriptionPlanCard';

const useStyles = makeStyles({
  switchField: {
    marginLeft: 0,
  },
  headerCard: {
    width: '100%',
    margin: 0,
  },
  leftIcon: {
    marginRight: '0.5rem',
  },
  addOrgButton: {
    marginTop: 0,
    marginBottom: 0,
  },
});

export interface SubscriptionPlanGridProps {
  isEditing: boolean;
  onClickPlan: (planId: number) => void;
  selectedPlanId: number;
}

export function SubscriptionPlanGrid({ isEditing, onClickPlan, selectedPlanId }: SubscriptionPlanGridProps) {
  const handleClickPlan = (planId: number) => () => {
    if (isEditing) {
      onClickPlan(planId);
    }
  };

  return (
    <Grid
      container
      alignItems="stretch"
    >
      {SUBSCRIPTION_PLANS.map(plan => (
        <Grid
          key={plan.id}
          item
          xs={12}
          md={4}
        >
          <SubscriptionPlanCard
            isEditing={isEditing}
            isSelected={selectedPlanId === plan.id}
            onClick={handleClickPlan(plan.id)}
            plan={plan}
          />
        </Grid>
      ))}
    </Grid>
  );
}

export interface OrgSubscriptionSettingsProps {
  org: IOrg;
}

export default function OrgSubscriptionSettings({ org }: OrgSubscriptionSettingsProps) {
  const classes = useStyles();

  const [ isEditing, setIsEditing ] = useState<boolean>(false);
  const [ selectedPlanId, setSelectedPlanId ] = useState<number>(0);

  useEffect(() => {
    setSelectedPlanId(org.tier);
  }, [org]);

  // const onClickPlan = (planId: number) => () => {
  //   if (isEditing) {
  //     setSelectedPlanId(planId);
  //   }
  // };

  const onClickCancel = () => {
    setSelectedPlanId(org.tier);
    setIsEditing(false);
  };

  const onClickSave = () => {
    setSelectedPlanId(org.tier);
    setIsEditing(false);
  };

  return (
    <>
      <Card className={classes.headerCard}>
        <CardContent>
          <SubscriptionPlanGrid
            onClickPlan={setSelectedPlanId}
            isEditing={isEditing}
            selectedPlanId={selectedPlanId}
          />
        </CardContent>
        <CardActions>
        { isEditing ? (
          <>
            <Button
              color="primary"
              onClick={onClickCancel}
            >
              Cancel
            </Button>
            <SaveButton
              // disabled={isSubmitting}
              onClick={onClickSave}
            />
          </>
        ) : (
          <Button
            color="primary"
            onClick={() => setIsEditing(true)}
          >
            Change plan
          </Button>
        )}
        </CardActions>
      </Card>
    </>
  );
}
