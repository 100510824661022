import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { initErrorInterceptor } from './services/ApiService';
import AppAuthProvider from './services/auth/appAuthProvider';
import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  debug: process.env.NODE_ENV === 'development',
  environment: process.env.REACT_APP_SENTRY_ENV || process.env.NODE_ENV,
});

initErrorInterceptor();

ReactDOM.render(
  <BrowserRouter basename={process.env.REACT_APP_ROUTER_BASENAME}>
    <AppAuthProvider>
      <App />
    </AppAuthProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

serviceWorker.unregister();
