import React, { useEffect, useState } from 'react';
import {
  Button,
  createStyles,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Theme,
  Typography,
} from '@mui/material';
import { withStyles, WithStyles } from '@mui/styles';
import { Formik, FormikProps } from 'formik';
import { extractQuestionsFromBlocks, getQuestionComponent } from './vendor-questionnaire';
import { QUESTION_BLOCKS_V2 as QUESTION_BLOCKS } from './vendor-questionnaire/question-blocks';

const styles = (theme: Theme) => createStyles({
  root: {
    [theme.breakpoints.up('md')]: {
      width: '60%',
    },
    textAlign: 'left',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
});

interface StepActionsProps extends WithStyles<typeof styles> {
  activeStep: number;
  nbSteps: number;
  handleBack: () => void;
  handleNext: () => void;
}

const StepActions = (props: StepActionsProps) => {
  const { classes, activeStep, nbSteps, handleBack, handleNext } = props;

  return (
    <div className={classes.actionsContainer}>
      <div>
        <Button
          size="small"
          disabled={activeStep === 0}
          onClick={handleBack}
          className={classes.button}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleNext}
          className={classes.button}
          disabled={activeStep === nbSteps - 1}
        >
          {activeStep === nbSteps - 1 ? 'Finished!' : 'Next'}
        </Button>
      </div>
    </div>
  );
};

interface QuestionnaireStepperProps extends WithStyles<typeof styles> {
  formikProps: FormikProps<Record<string, string> >;
}

function QuestionnaireStepper(props: QuestionnaireStepperProps) {
  const { classes, formikProps } = props;
  const [ activeStep, setActiveStep ] = useState(0);
  const [ isComplete, setIsComplete ] = useState(false);

  function handleNext() {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }

  function handleBack() {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }

  useEffect(() => {
    setIsComplete(prevIsComplete => prevIsComplete || activeStep === QUESTION_BLOCKS.length - 1);
  }, [ activeStep ]);

  return (
    <>
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        sx={{ padding: 3 }}
      >
        {QUESTION_BLOCKS.map(questionBlock => (
          <Step key={questionBlock.label}>
            <StepLabel>{questionBlock.label}</StepLabel>
            <StepContent>
              <>
                {questionBlock.questions.map((question) => {
                  const Component = getQuestionComponent(question.type);

                  return (
                    <Component
                      key={question.props.id}
                      formikProps={formikProps}
                      question={question.props}
                    />
                  );
                })}
              </>
              <StepActions
                classes={classes}
                activeStep={activeStep}
                handleBack={handleBack}
                handleNext={handleNext}
                nbSteps={QUESTION_BLOCKS.length}
              />
            </StepContent>
          </Step>
        )
      )}
      </Stepper>
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography>
            Please note that once you submit you will not be able to go back
            and change your responses.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            disabled={!isComplete}
            onClick={(e: any) => formikProps.handleSubmit(e)}
            className={classes.button}
          >
            Submit
          </Button>
        </Paper>
    </>
  );
}

const initValues = extractQuestionsFromBlocks(QUESTION_BLOCKS).reduce<Record<string, string> >((acc, curr) => {
  acc[curr.id] = '';

  return acc;
}, {});

interface VendorQuestionnaireDetailsProps extends WithStyles<typeof styles> {
  submitResponses: (formValues: Record<string, string>) => Promise<void>;
}

function VendorQuestionnaireDetails({ classes, submitResponses }: VendorQuestionnaireDetailsProps) {
  return (
    <Paper
      className={classes.root}
      elevation={2}
    >
      <Formik
        initialValues={initValues}
        onSubmit={async (values, { setSubmitting }) => {
          await submitResponses(values);
          setSubmitting(false);
        }}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {formikProps => (
          <QuestionnaireStepper
            classes={classes}
            formikProps={formikProps}
          />
        )}
      </Formik>
    </Paper>
  );
}

export default withStyles(styles, { withTheme: true })(VendorQuestionnaireDetails);
